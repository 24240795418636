import axiosAuth from 'utils/axios/axiosAuth';

const bannerApi = {
  getAll: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_banner`, {
      params: queryParams,
    });
  },

  get: (id) => {
    return axiosAuth.get(`getcare_banner/${id}`);
  },
  getSize: () => {
    return axiosAuth.get('getcare_banner_size');
  },
  getType: () => {
    return axiosAuth.get('getcare_banner_type');
  },
  getLocation: () => {
    return axiosAuth.get('getcare_banner_location');
  },
  uploadBanner: (formData) => {
    return axiosAuth.post('upload/single', formData);
  },
  update: ({ params }) => {
    return axiosAuth.post(`getcare_banner`, params);
  },
  getCustomerList: () => {
    return axiosAuth.get('getcare_customer');
  },
  findCustomer: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get('getcare_customer', {
      params: queryParams,
    });
  },

  findSaleChannel: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get('getcare_sales_channel', {
      params: queryParams,
    });
  },

  getSaleChannelList: () => {
    return axiosAuth.get('getcare_sales_channel');
  },
};

export default bannerApi;
