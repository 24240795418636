import {
  CHECK_PROMOTION,

  SAVE_LOADING,
  SAVE_ORDER_LIST,
  SAVE_ORDER_LIST_ITEM,
  REMOVE_ORDER_LIST_ITEMS,
  SAVE_PROMOTION_AVAILABLES,
  SAVE_PROMOTION_ACTIVES,

  SAVE_PROMOTION_DISCOUNTS,

  SAVE_USED_PROMOTIONS,
  SAVE_USED_PROMOTION_ITEM,

  SAVE_ORDER_AMOUNT,
} from 'redux/constants/tmk/testPromotionConstants';

export function checkPromotion(payload) {
  return {
    type: CHECK_PROMOTION,
    payload,
  };
}

// mutation
export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading,
  };
}

export function saveOrderList(orderList) {
  return {
    type: SAVE_ORDER_LIST,
    orderList,
  };
}
export function saveOrderListItem(orderListItem) {
  return {
    type: SAVE_ORDER_LIST_ITEM,
    orderListItem,
  };
}
export function removeOrderListItems(orderListItems) {
  return {
    type: REMOVE_ORDER_LIST_ITEMS,
    orderListItems,
  };
}

export function savepromotionDiscounts(promotionDiscounts) {
  return {
    type: SAVE_PROMOTION_DISCOUNTS,
    promotionDiscounts,
  };
}

export function savePromotionAvailables(promotionAvailables) {
  return {
    type: SAVE_PROMOTION_AVAILABLES,
    promotionAvailables,
  };
}

export function savePromotionActives(promotionActives) {
  return {
    type: SAVE_PROMOTION_ACTIVES,
    promotionActives,
  };
}

export function saveUsedPromotions(usedPromotions) {
  return {
    type: SAVE_USED_PROMOTIONS,
    usedPromotions,
  };
}
export function saveUsedPromotionItem(usedPromotionItem) {
  return {
    type: SAVE_USED_PROMOTION_ITEM,
    usedPromotionItem,
  };
}

export function saveOrderAmount(orderAmount) {
  return {
    type: SAVE_ORDER_AMOUNT,
    orderAmount,
  };
}
