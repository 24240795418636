import React, { PureComponent } from 'react';
import { Button } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectVoucherConfigGiftList,
  makeSelectVoucherConfigMoneyList,
} from 'redux/selectors';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import classes from './VoucherConfig.module.scss';
import gridClasses from './VoucherConfigGrid.module.scss';
import {
  listParamsMap,
  listTabVoucherConfigDisplayFields,
  listTabVoucherConfigMoneyDisplayFields,
  VOUCHER_TYPE_GIFT,
  VOUCHER_TYPE_MONEY,
} from 'utils/constanst/tmkVoucherConstant';
import { getDisplayFields, genID } from 'utils/helper';
import PropTypes from 'prop-types';
import VoucherConfigGiftItem from './VoucherConfigItem/VoucherConfigGiftItem';
import VoucherConfigMoneyItem from './VoucherConfigItem/VoucherConfigMoneyItem ';
import {
  exportVoucherGiftCodeUnusedList,
  removeVoucherConfigGiftItem,
  removeVoucherConfigMoneyItem,
  saveVoucherConfigGiftItem,
  saveVoucherConfigMoneyItem,
  selectVoucherConfigGiftItem,
  selectVoucherConfigMoneyItem,
  removeMultipleVoucherConfigGiftItem,
  removeMultipleVoucherConfigMoneyItem,
  deselectAllVoucherConfigGift,
  deselectAllVoucherConfigMoney,
  selectAllVoucherConfigGift,
  selectAllVoucherConfigMoney,
} from 'redux/actions/tmk/voucherAction';
class VoucherConfig extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isHeaderChecked: false,
      listParams: { ...listParamsMap },
    };
  }

  handleCreateNewRowGift = () => {
    this.props.saveVoucherConfigGiftItem({
      getcare_product_bonus: {
        name: null,
        code: null,
      },
      isEditing: true,
      idStr: genID(),
      isChecked: false,
      id: null,
      quantity: 0,
    });
  };

  handleCreateNewRowMoney = () => {
    this.props.saveVoucherConfigMoneyItem({
      id: null,
      idStr: genID(),
      isEditing: true,
      isChecked: false,
      amount: 0,
      quantity: 0,
    });
  };

  handleDeleteSelectedConfigMoney = () => {
    this.props.removeMultipleVoucherConfigMoneyItem();
    this.setState({
      isHeaderChecked: false,
    });
  };

  handleDeleteSelectedConfigGift = () => {
    this.props.removeMultipleVoucherConfigGiftItem();
    this.setState({
      isHeaderChecked: false,
    });
  };

  handleCheckbox = () => {
    this.setState((prevState) => {
      return {
        isHeaderChecked: !prevState.isHeaderChecked,
      };
    });

    if (this.state.isHeaderChecked) {
      if (this.props.voucherType === VOUCHER_TYPE_GIFT) {
        this.props.deselectAllVoucherConfigGift();
      } else {
        this.props.deselectAllVoucherConfigMoney();
      }
      return;
    } else {
      if (this.props.voucherType === VOUCHER_TYPE_GIFT) {
        this.props.selectAllVoucherConfigGift();
      } else {
        this.props.selectAllVoucherConfigMoney();
      }
    }
  };

  handleExportVoucher = (params) => {
    this.props.exportVoucherGiftCodeUnusedList({
      ...params,
    });
  };

  render() {
    const {
      voucherConfigGiftList,
      voucherConfigMoneyList,
      readOnly,
      isEditing,
      disabled,
      voucherType,
      isChanged
    } = this.props;

    const isItemChecked = (element) => element.isChecked;
    const disableDeleteBulkGift = voucherConfigGiftList.some(isItemChecked);
    const disableDeleteBulkMoney = voucherConfigMoneyList.some(isItemChecked);

    const displayFieldsGift = getDisplayFields(
      this.state.listParams,
      listTabVoucherConfigDisplayFields
    );

    const displayFieldsMoney = getDisplayFields(
      this.state.listParams,
      listTabVoucherConfigMoneyDisplayFields
    );
    return (
      <div className={classes.VoucherConfig}>
        <div className={`${classes.List}`}>
          <div
            className={`${gridClasses.Row} ${
              voucherType === VOUCHER_TYPE_GIFT
                ? gridClasses.RowGift
                : gridClasses.RowMoney
            } ${classes.Header}`}
          >
            <div className={classes.Col}>
              <Checkbox
                onChange={this.handleCheckbox}
                checked={this.state.isHeaderChecked}
              />
            </div>
            {voucherType === VOUCHER_TYPE_GIFT &&
              displayFieldsGift.map((item) => (
                <ListHeaderCol
                  key={item.name}
                  className={`${gridClasses.Col} ${
                    gridClasses[item.className]
                  }`}
                  {...item}
                />
              ))}

            {voucherType === VOUCHER_TYPE_MONEY &&
              displayFieldsMoney.map((item) => (
                <ListHeaderCol
                  key={item.name}
                  className={`${gridClasses.Col} ${
                    gridClasses[item.className]
                  }`}
                  {...item}
                />
              ))}

            <div className={`${classes.Col} ${gridClasses.Center}`}>
              {voucherType === VOUCHER_TYPE_GIFT && (
                <Button
                  disabled={readOnly || disabled}
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={this.handleCreateNewRowGift}
                >
                  Thêm
                </Button>
              )}
              {voucherType === VOUCHER_TYPE_MONEY && (
                <Button
                  disabled={readOnly || disabled}
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={this.handleCreateNewRowMoney}
                >
                  Thêm
                </Button>
              )}

              {voucherType === VOUCHER_TYPE_GIFT && (
                <Button
                  disabled={
                    voucherConfigGiftList.length === 0 || !disableDeleteBulkGift
                  }
                  className={classes.DeleteAction}
                  color="primary"
                  variant="contained"
                  size="small"
                  startIcon={<DeleteOutline />}
                  onClick={this.handleDeleteSelectedConfigGift}
                >
                  Xóa
                </Button>
              )}
              {voucherType === VOUCHER_TYPE_MONEY && (
                <Button
                  disabled={
                    voucherConfigMoneyList.length === 0 ||
                    !disableDeleteBulkMoney
                  }
                  className={classes.DeleteAction}
                  color="primary"
                  variant="contained"
                  size="small"
                  startIcon={<DeleteOutline />}
                  onClick={this.handleDeleteSelectedConfigMoney}
                >
                  Xóa
                </Button>
              )}
            </div>
          </div>
          <div className={classes.ItemContainer}>
            {voucherType === VOUCHER_TYPE_GIFT && voucherConfigGiftList ? (
              voucherConfigGiftList.length > 0 ? (
                voucherConfigGiftList.map((item, index) => (
                  <VoucherConfigGiftItem
                    key={`voucher-config-gift-${
                      item.id + genID() || item.idStr
                    }`}
                    {...item}
                    index={index}
                    readOnly={readOnly}
                    isChanged={isChanged}
                    voucherType={voucherType}
                    currentList={[
                      ...voucherConfigGiftList.map((item) => {
                        return { ...item };
                      }),
                    ]}
                    selectVoucherConfigGiftItem={
                      this.props.selectVoucherConfigGiftItem
                    }
                    deselectAllVoucherConfigGift={
                      this.props.deselectAllVoucherConfigGift
                    }
                    removeMultipleVoucherConfigGiftItem={
                      this.props.removeMultipleVoucherConfigGiftItem
                    }
                    selectAllVoucherConfigGift={
                      this.props.selectAllVoucherConfigGift
                    }
                    exportVoucher={this.handleExportVoucher}
                    isVoucherEditing={isEditing}
                    saveVoucherConfigGiftItem={
                      this.props.saveVoucherConfigGiftItem
                    }
                    removeVoucherConfigGiftItem={
                      this.props.removeVoucherConfigGiftItem
                    }
                  />
                ))
              ) : (
                <p className="NoData">Không có data</p>
              )
            ) : (
              ''
            )}

            {voucherConfigMoneyList && voucherType === VOUCHER_TYPE_MONEY ? (
              voucherConfigMoneyList.length > 0 ? (
                voucherConfigMoneyList.map((item, index) => (
                  <VoucherConfigMoneyItem
                    key={`voucher-config-money-${
                      item.id + genID() || item.idStr
                    }`}
                    {...item}
                    index={index}
                    readOnly={readOnly}
                    isChanged={isChanged}
                    voucherType={voucherType}
                    isVoucherEditing={isEditing}
                    saveVoucherConfigMoneyItem={
                      this.props.saveVoucherConfigMoneyItem
                    }
                    selectVoucherConfigMoneyItem={
                      this.props.selectVoucherConfigMoneyItem
                    }
                    deselectAllVoucherConfigMoney={
                      this.props.deselectAllVoucherConfigMoney
                    }
                    removeMultipleVoucherConfigMoneyItem={
                      this.props.removeMultipleVoucherConfigMoneyItem
                    }
                    selectAllVoucherConfigMoney={
                      this.props.selectAllVoucherConfigMoney
                    }
                    exportVoucher={this.handleExportVoucher}
                    removeVoucherConfigMonneyItem={
                      this.props.removeVoucherConfigMonneyItem
                    }
                  />
                ))
              ) : (
                <p className="NoData">Không có data</p>
              )
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  voucherConfigGiftList: makeSelectVoucherConfigGiftList(),
  voucherConfigMoneyList: makeSelectVoucherConfigMoneyList(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    selectVoucherConfigGiftItem: (payload) =>
      dispatch(selectVoucherConfigGiftItem(payload)),
    selectVoucherConfigMoneyItem: (payload) =>
      dispatch(selectVoucherConfigMoneyItem(payload)),
    deselectAllVoucherConfigGift: (payload) =>
      dispatch(deselectAllVoucherConfigGift(payload)),
    deselectAllVoucherConfigMoney: (payload) =>
      dispatch(deselectAllVoucherConfigMoney(payload)),
    removeMultipleVoucherConfigGiftItem: (payload) =>
      dispatch(removeMultipleVoucherConfigGiftItem(payload)),
    removeMultipleVoucherConfigMoneyItem: (payload) =>
      dispatch(removeMultipleVoucherConfigMoneyItem(payload)),
    selectAllVoucherConfigGift: (payload) =>
      dispatch(selectAllVoucherConfigGift(payload)),
    selectAllVoucherConfigMoney: (payload) =>
      dispatch(selectAllVoucherConfigMoney(payload)),
    saveVoucherConfigGiftItem: (payload) =>
      dispatch(saveVoucherConfigGiftItem(payload)),
    saveVoucherConfigMoneyItem: (payload) =>
      dispatch(saveVoucherConfigMoneyItem(payload)),
    removeVoucherConfigGiftItem: (payload) =>
      dispatch(removeVoucherConfigGiftItem(payload)),
    removeVoucherConfigMonneyItem: (payload) =>
      dispatch(removeVoucherConfigMoneyItem(payload)),
    exportVoucherGiftCodeUnusedList: (payload) =>
      dispatch(exportVoucherGiftCodeUnusedList(payload)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(VoucherConfig);
VoucherConfig.propTypes = {
  isEditing: PropTypes.bool,
  onRemoveCustomerItem: PropTypes.func,
  onSaveCustomerItem: PropTypes.func,
};

VoucherConfig.defaultProps = {
  isEditing: false,
};
