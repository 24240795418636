export const CHECK_PROMOTION = 'getcare/tmk/testPromotion/CHECK_PROMOTION';

// mutation
export const SAVE_LOADING = 'getcare/tmk/testPromotion/SAVE_LOADING';

export const SAVE_ORDER_LIST = 'getcare/tmk/testPromotion/SAVE_ORDER_LIST';
export const SAVE_ORDER_LIST_ITEM = 'getcare/tmk/testPromotion/SAVE_ORDER_LIST_ITEM';
export const REMOVE_ORDER_LIST_ITEMS = 'getcare/tmk/testPromotion/REMOVE_ORDER_LIST_ITEMS';

export const SAVE_PROMOTION_DISCOUNTS = 'getcare/tmk/testPromotion/SAVE_PROMOTION_DISCOUNTS';

export const SAVE_PROMOTION_AVAILABLES = 'getcare/tmk/testPromotion/SAVE_PROMOTION_AVAILABLES';
export const SAVE_PROMOTION_ACTIVES = 'getcare/tmk/testPromotion/SAVE_PROMOTION_ACTIVES';

export const SAVE_USED_PROMOTIONS = 'getcare/tmk/testPromotion/SAVE_USED_PROMOTIONS';
export const SAVE_USED_PROMOTION_ITEM = 'getcare/tmk/testPromotion/SAVE_USED_PROMOTION_ITEM';

export const SAVE_ORDER_AMOUNT = 'getcare/tmk/testPromotion/SAVE_ORDER_AMOUNT';
