import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPromotionProducts,
  makeSelectOriPromotionProducts,
} from 'redux/selectors';
import {
  savePromotionProducts,
  savePromotionProductItem,
  removePromotionProductItems,
  exportSaleProductTemplate
} from 'redux/actions/tmk/promotionActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  promotionProductsDisplayFields,
  promotionProductsParamsMap,
  promotionProductsComboDisplayFields,
  PROMOTION_TYPE_COMBO,
  PRODUCT_TYPE_ID,
  GROUP_TYPE_ID,
  isPromotionGroup,
  isPromotionCombo,
} from 'utils/constanst/tmkPromotionConstants';

import PromotionProduct from './PromotionProduct/PromotionProduct';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';
import FileImportDialog from 'components/FileImportDialog/FileImportDialog';

import gridClasses from './PromotionProductsGrid.module.scss';
import classes from './PromotionProducts.module.scss';

class PromotionProducts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isImportDialogOpen: false,
    }
  }

  handleCreateNewRow = () => {
    this.props.savePromotionProductItem({
      idStr: genID(),
      id: 0,
      type_id: isPromotionGroup(this.props.promotionTypeId) ? GROUP_TYPE_ID : PRODUCT_TYPE_ID,
      getcare_product: null,
      getcare_erp_group: null,
      getcare_uom_base: null,
      focus: 2,
      quantity_number: null,
      ratio: null,
      isEditing: true,
    });
  };
  handleSavePromotionProduct = (params) => {
    this.props.savePromotionProductItem({
      ...params,
    });
  };
  handleRemovePromotionProduct = (params) => {
    this.props.removePromotionProductItems([{ ...params }]);
  };
  handleRemovePromotionProducts = () => {
    this.props.removePromotionProductItems([...this.state.selectedItems]);
  };

  handleTemplateDownload = () => {
    this.props.exportSaleProductTemplate();
  }
  handleImportDialogOpen = () => {
    this.setState({
      isImportDialogOpen: true,
    });
  };
  handleImportDialogClose = () => {
    this.setState({
      isImportDialogOpen: false,
    });
  };
  handleCompleteImport = (importedProducts) => {
    const currentPromotionProducts = this.props.promotionProducts;
    if (importedProducts && importedProducts.length > 0) {
      const productMap = {};
      // Remove duplicates from imported products
      importedProducts.forEach(item => {
        const productId = item.getcare_product?.id;
        const baseUomId = item.getcare_uom_base?.id;
        const combinedKey = `${productId}_${baseUomId}`;
        // Attach some props as they are new items
        // Overwrite focus by 0 if it is not promotion combo, otherwise, leave it as is
        productMap[combinedKey] = {
          ...item,
          focus: isPromotionCombo(this.props.promotionTypeId) ? item.focus : 0,
          idStr: genID(),
          isEditing: false,
        }
      })

      // Filter duplicates from current list
      const filteredPromotionProducts = currentPromotionProducts.filter(current => {
        const productId = current.getcare_product?.id;
        const baseUomId = current.getcare_uom_base?.id;
        const combinedKey = `${productId}_${baseUomId}`;
        return !productMap[combinedKey];
      })

      // Combine and display them on the list
      const newPromotionProducts = Object.values(productMap).concat(filteredPromotionProducts);
      this.props.savePromotionProducts(newPromotionProducts);
    }
  };

  renderActions = () => {
    const { readOnly } = this.props;
    return (
      <>
        {/* TODO: Group promotions is currently not supported */}
        {!isPromotionGroup(this.props.promotionTypeId) && (
          <>
            <Button
              variant="contained"
              size="small"
              disabled={readOnly}
              onClick={this.handleTemplateDownload}>
              Download template
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={readOnly}
              onClick={this.handleImportDialogOpen}>
              Import File
            </Button>
          </>
        )}
        <Button
          color="primary"
          variant="contained"
          size="small"
          disabled={readOnly}
          onClick={this.handleCreateNewRow}>
          Thêm dòng
        </Button>
      </>
    )
  }

  render() {
    const { promotionProducts, promotionTypeId, readOnly } = this.props;
    const { isImportDialogOpen } = this.state;
    const displayFields = getDisplayFields(
      promotionProductsParamsMap,
      [PROMOTION_TYPE_COMBO].includes(promotionTypeId)
        ? promotionProductsComboDisplayFields
        : promotionProductsDisplayFields,
    );

    return (
      <>
        <div className={`${classes.ActionContainer}`}>
          {this.renderActions()}
        </div>
        <div className={`${classes.List}`}>
        <div className={`${classes.Header}`}>
        <div className={`${gridClasses.Row} ${classes.Header} ${[PROMOTION_TYPE_COMBO].includes(promotionTypeId) ? gridClasses.HasFocusProduct : ''}`}>
              {displayFields.map((item) => (
                <ListHeaderCol
                  key={item.name}
                  className={`${gridClasses.Col} ${gridClasses[item.className]}`}
                  {...item}
                />
              ))}
            </div>
          </div>
          <div className={`${classes.Body}`}>
            {promotionProducts.length ? (
              promotionProducts.map((item) => (
                <PromotionProduct
                  key={`promotion-product-${item.id || item.idStr}`}
                  {...item}
                  readOnly={readOnly}
                  currentList={[...promotionProducts]}
                  promotionTypeId={promotionTypeId}
                  savePromotionProduct={this.handleSavePromotionProduct}
                  removePromotionProduct={this.handleRemovePromotionProduct}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
        {isImportDialogOpen && (
          <FileImportDialog
            title="Import danh sách hàng bán"
            isOpen={isImportDialogOpen}
            onClose={this.handleImportDialogClose}
            onCompleteImport={this.handleCompleteImport}
          />
        )}
      </>
    );
  }
}

PromotionProducts.propTypes = {
  promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEditing: PropTypes.bool,
  readOnly: PropTypes.bool,
};

PromotionProducts.defaultProps = {
  isEditing: false,
  readOnly: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    savePromotionProductItem: (payload) =>
      dispatch(savePromotionProductItem(payload)),
    removePromotionProductItems: (payload) =>
      dispatch(removePromotionProductItems(payload)),
    savePromotionProducts: (payload) => dispatch(savePromotionProducts(payload)),
    exportSaleProductTemplate: (payload) => dispatch(exportSaleProductTemplate(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  promotionProducts: makeSelectPromotionProducts(),
  oriPromotionProducts: makeSelectOriPromotionProducts(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PromotionProducts);
