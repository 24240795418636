import axiosAuth from 'utils/axios/axiosAuth';

const voucherApi = {
  getAll: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_voucher_program`, {
      params: queryParams,
    });
  },

  getAllUsed: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_voucher`, {
      params: queryParams,
    });
  },

  getProductSuggestions: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_product`, {
      params: queryParams,
    });
  },
  
  get: (id) => {
    return axiosAuth.get(`getcare_voucher_program/${id}`);
  },
  
  create: ({ params }) => {
    return axiosAuth.post(`getcare_voucher_program`, params);
  },

  update: ({ params }) => {
    return axiosAuth.put(`getcare_voucher_program/${params.id}`, params);
  },
  getCustomerList: () => {
    return axiosAuth.get('getcare_customer');
  },

  getVendorList: () => {
    return axiosAuth.get('getcare_vendor');
  },

  findCustomer: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get('getcare_policy_price_customer_search', {
      params: queryParams,
    });
  },

  findProductBonus: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get('getcare_product_bonus', {
      params: queryParams,
    });
  },
  

  findSaleChannel: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get('getcare_sales_channel', {
      params: queryParams,
    });
  },

  getSaleChannelList: () => {
    return axiosAuth.get('getcare_sales_channel');
  },

  exportVoucherUnusedList: (id) => {
    return axiosAuth.post(`export/getcare_voucher?getcare_voucher_program_id=${id}`);
  },

  exportVoucherGifCodeUnusedList: (id) => {
    return axiosAuth.post(`export/getcare_voucher?getcare_voucher_gift_code_id=${id}`);
  },
};

export default voucherApi;
