import {
  SAVE_GLOBAL_WS,
  SAVE_WS_EVENT,
  REMOVE_WS_EVENT,
  REMOVE_WS_EVENT_ITEMS,
} from 'redux/constants/wsConstants';

export function saveGlobalWS(ws) {
  return {
    type: SAVE_GLOBAL_WS,
    ws
  };
}

export function saveWSEvent(event) {
  return {
    type: SAVE_WS_EVENT,
    event
  };
}
export function removeWSEvent(event) {
  return {
    type: REMOVE_WS_EVENT,
    event
  };
}
export function removeWSEventItems(items) {
  return {
    type: REMOVE_WS_EVENT_ITEMS,
    items
  };
}
