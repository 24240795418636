import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { suggestionPageSize2 } from 'utils/constanst/common';
import { debounce } from 'lodash';
import promotionApi from 'utils/api/tmk/promotionApi';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

import classes from './PromotionCodeSearch.module.scss';

class PromotionCodeSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      codeSearchInput: '',
      codeOptions: [],
      selectedCode: null,
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { promotionId, promotionCode } = this.props;
    if (promotionId && promotionId !== prevProps.promotionId) {
      this.setState({
        selectedCode: {
          id: promotionId,
          code: promotionCode,
        },
        codeOptions: [{ id: promotionId, code: promotionCode }],
      });
    }

    const { codeSearchInput } = this.state;
    if (codeSearchInput && codeSearchInput !== prevState.codeSearchInput) {
      this.loadSuggestedCodes({
        params: {
          code: codeSearchInput,
          page_size: suggestionPageSize2,
        }
      });
    }
  }

  loadSuggestedCodes = debounce(async ({ params }) => {
    const { data: response } = await promotionApi.getAll({ params });
    if (!response?.result) return;
    this.setState({
      codeOptions: response.data ? response.data : [],
    });
  }, 500)

  handleChangeSelectedCode = (e, newValue) => {
    this.setState({
      selectedCode: newValue,
    });
    if (newValue) {
      this.props.onSelectedCodeChange(newValue);
    }
  }

  render() {
    const { selectedCode, codeOptions, codeSearchInput } = this.state;

    return (<div className={classes.PromotionCodeSearch}>
      <Autocomplete
        size="small"
        handleHomeEndKeys={false}
        value={selectedCode || null}
        onChange={this.handleChangeSelectedCode}
        inputValue={codeSearchInput}
        onInputChange={(e, newValue) => {
          this.setState({
            codeSearchInput: newValue,
          });
        }}
        options={codeOptions}
        filterOptions={(x) => x}
        renderOption={(option) => option.code}
        getOptionLabel={(option) => option?.code || ''}
        getOptionSelected={(option, value) =>
          value && option && value.id && option.id ? option.id === value.id : null
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Search mã promotion"
          />
        )}
        fullWidth
        popupIcon={<SearchOutlinedIcon/>}
        className={classes.NoTransformPopupIconAutocomplete}
      />
    </div>);
  }
}

PromotionCodeSearch.propTypes = {
  promotionId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  promotionCode: PropTypes.string,
  onSelectedCodeChange: PropTypes.func,
};

PromotionCodeSearch.defaultProps = {
  promotionId: '',
  promotionCode: '',
};

export default PromotionCodeSearch;
