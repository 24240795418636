import React, { PureComponent } from 'react';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectBannerCustomerList,
  makeSelectCustomerList,
} from 'redux/selectors';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import classes from './BannerCustomerList.module.scss';
import gridClasses from './BannerCustomerGrid.module.scss';
import {
  listParamsMap,
  listBannerCustomerListDisplayFields,
} from 'utils/constanst/tmkBannerConstants';
import { getDisplayFields, genID } from 'utils/helper';
import PropTypes from 'prop-types';
import BannerCustomerItem from './BannerCustomerItem/BannerCustomerItem';
import { Checkbox } from '@material-ui/core';
import {
  getCustomerList,
  removeCustomerListItem,
  saveCustomerListItem,
} from 'redux/actions/tmk/bannerActions';
class BannerCustomerList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isHeaderChecked: false,
      listParams: { ...listParamsMap },
    };
  }
  handleCheckbox = () => {
    this.setState((prevState) => {
      return {
        isHeaderChecked: !prevState.isHeaderChecked,
      };
    });
  };

  componentDidMount() {
    this._initData();
  }

  _initData = () => {
    this.props.getCustomerList();
  };

  handleSaveBannerCustomerItem = (params) => {
    this.props.saveCustomerListItem({
      ...params,
    });
  };

  handleRemoveBannerCustomerItem = (params) => {
    this.props.removeCustomerListItem([{ ...params }]);
  };

  handleCreateNewRow = () => {
    this.props.saveCustomerListItem({
      getcare_customer: {
        isEditing: true,
        idStr: genID(),
        id: null,
        name: null,
        phone: null,
        address: null,
        code: null,
      },
    });
  };

  render() {
    const {
      bannerCustomerList,
      readOnly,
      isEditing,
      disabled,
      customerList,
    } = this.props;
    const displayFields = getDisplayFields(
      this.state.listParams,
      listBannerCustomerListDisplayFields
    );
    return (
      <div className={classes.BannerCustomerList}>
        <div className={`${classes.ActionContainer}`}>
          <Button
            disabled={readOnly || disabled}
            color="primary"
            variant="contained"
            size="small"
            onClick={this.handleCreateNewRow}
          >
            Thêm
          </Button>
        </div>
        <div className={`${classes.List}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            <div className={classes.Col}>
              <Checkbox
                onChange={this.handleCheckbox}
                checked={this.state.isHeaderChecked}
              />
            </div>
            {displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                className={`${gridClasses.Col} ${gridClasses[item.className]}`}
                {...item}
              />
            ))}
          </div>
          <div className={classes.ItemContainer}>
            {bannerCustomerList.length > 0 ? (
              bannerCustomerList.map((item, index) => (
                <BannerCustomerItem
                  key={`banner-customer-${
                    !!item.id ? item.id : item.getcare_customer.idStr
                  }`}
                  {...item.getcare_customer}
                  index={index}
                  readOnly={readOnly}
                  currentList={[
                    ...bannerCustomerList.map((item) => {
                      return { ...item.getcare_customer };
                    }),
                  ]}
                  isChecked={this.state.isHeaderChecked}
                  customerList={customerList}
                  saveCustomerListItem={this.handleSaveBannerCustomerItem}
                  removeCustomerListItem={this.handleRemoveBannerCustomerItem}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  bannerCustomerList: makeSelectBannerCustomerList(),
  customerList: makeSelectCustomerList(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerList: (payload) => dispatch(getCustomerList(payload)),
    saveCustomerListItem: (payload) => dispatch(saveCustomerListItem(payload)),
    removeCustomerListItem: (payload) =>
      dispatch(removeCustomerListItem(payload)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(BannerCustomerList);
BannerCustomerList.propTypes = {
  // promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEditing: PropTypes.bool,
  onRemoveCustomerItem: PropTypes.func,
  onSaveCustomerItem: PropTypes.func,
};

BannerCustomerList.defaultProps = {
  isEditing: false,
};
