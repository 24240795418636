import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPromotionCustomers,
  makeSelectOriPromotionCustomers,
} from 'redux/selectors';
import {
  savePromotionCustomerItem,
  removePromotionCustomerItems,
} from 'redux/actions/tmk/promotionActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  promotionCustomersDisplayFields,
  promotionCustomersParamsMap,
  GROUP_TYPE_ID,
} from 'utils/constanst/tmkPromotionConstants';

import PromotionCustomer from './PromotionCustomer/PromotionCustomer';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';

import gridClasses from './PromotionCustomersGrid.module.scss';
import classes from './PromotionCustomers.module.scss';

class PromotionCustomers extends PureComponent {
  handleCreateNewRow = () => {
    this.props.savePromotionCustomerItem({
      idStr: genID(),
      id: 0,
      type_id: GROUP_TYPE_ID,
      getcare_customer: null,
      getcare_erp_group: null,
      isEditing: true,
    });
  };
  handleSavePromotionCustomer = (params) => {
    this.props.savePromotionCustomerItem({
      ...params,
    });
  };
  handleRemovePromotionCustomer = (params) => {
    this.props.removePromotionCustomerItems([{ ...params }]);
  };
  handleRemovePromotionCustomers = () => {
    this.props.removePromotionCustomerItems([...this.state.selectedItems]);
  };

  render() {
    const { promotionCustomers, readOnly } = this.props;
    const displayFields = getDisplayFields(
      promotionCustomersParamsMap,
      promotionCustomersDisplayFields
    );

    return (
      <>
        <div className={`${classes.ActionContainer}`}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            disabled={readOnly}
            onClick={this.handleCreateNewRow}
          >
            Thêm dòng
          </Button>
        </div>
        <div className={`${classes.List}`}>
        <div className={`${classes.Header}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
              {displayFields.map((item) => (
                <ListHeaderCol
                  key={item.name}
                  className={`${gridClasses.Col} ${gridClasses[item.className]}`}
                  {...item}
                />
              ))}
            </div>
            </div>
          <div className={`${classes.Body}`}>  
          
            {promotionCustomers.length ? (
              promotionCustomers.map((item) => (
                <PromotionCustomer
                  key={`promotion-customer-${item.id || item.idStr}`}
                  {...item}
                  readOnly={readOnly}
                  currentList={[...promotionCustomers]}
                  savePromotionCustomer={this.handleSavePromotionCustomer}
                  removePromotionCustomer={this.handleRemovePromotionCustomer}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </>
    );
  }
}

PromotionCustomers.propTypes = {
  promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEditing: PropTypes.bool,
};

PromotionCustomers.defaultProps = {
  isEditing: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    savePromotionCustomerItem: (payload) =>
      dispatch(savePromotionCustomerItem(payload)),
    removePromotionCustomerItems: (payload) =>
      dispatch(removePromotionCustomerItems(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  promotionCustomers: makeSelectPromotionCustomers(),
  oriPromotionCustomers: makeSelectOriPromotionCustomers(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PromotionCustomers);
