import authReducer from './authReducer';
import wsReducer from './wsReducer';
import { combineReducers } from 'redux';
import promotionReducer from './tmk/promotionReducer';
import testPromotionReducer from './tmk/testPromotionReducer';
import bannerReducer from './tmk/bannerReducer'
import voucherReducer from './tmk/voucherReducer'

export default combineReducers({
  authReducer,
  wsReducer,
  promotionReducer,
  testPromotionReducer,
  bannerReducer,
  voucherReducer
});
