import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { isEqual, sortBy } from 'lodash';
import {
  makeSelectOriVoucherConfigGiftList,
  makeSelectOriVoucherConfigMoneyList,
  makeSelectOriVoucherCustomerList,
  makeSelectOriVoucherDetails,
  makeSelectOriVoucherProductList,
  makeSelectOriVoucherSaleChannelList,
  makeSelectVoucherConfigGiftList,
  makeSelectVoucherConfigMoneyList,
  makeSelectVoucherCustomerList,
  makeSelectVoucherDetails,
  makeSelectVoucherListQueryString,
  makeSelectVoucherLoading,
  makeSelectVoucherProductList,
  makeSelectVoucherSaleChannelList,
  makeSelectVoucherVendorList,
} from 'redux/selectors';
import { getVoucherVendorList } from 'redux/actions/tmk/voucherAction';
import classes from './Voucher.module.scss';
import Button from '@material-ui/core/Button';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import { validDate } from 'utils/helper';
import { format, isSameDay, isAfter } from 'date-fns';
import { Tabs, Tab } from '@material-ui/core';
import { toast } from 'react-toastify';
import { dateFormat } from 'utils/constanst/dateConstants';
import * as QueryString from 'query-string';
import { ACTIVE } from 'utils/constanst/common';
import VoucherDetails from 'components/tmk/Voucher/VoucherDetails/VoucherDetails';
import VoucherCustomer from 'components/tmk/Voucher/VoucherCustomer/VoucherCustomer';
import VoucherConfig from 'components/tmk/Voucher/VoucherConfig/VoucherConfig';
import VoucherSaleChannel from 'components/tmk/Voucher/VoucherSaleChannel/VoucherSaleChannel';
import VoucherProduct from 'components/tmk/Voucher/VoucherProduct/VoucherProduct';
import VoucherUnused from 'components/tmk/Voucher/VoucherUnused/VoucherUnused';
import {
  getVoucher,
  resetVoucher,
  saveVoucherDetails,
  updateVoucher,
  createVoucher,
} from 'redux/actions/tmk/voucherAction';
import {
  VOUCHER_DISCOUNT_UNIT_PERCENT,
  VOUCHER_TYPE_DISCOUNT,
  VOUCHER_TYPE_GIFT,
  VOUCHER_TYPE_MONEY,
} from 'utils/constanst/tmkVoucherConstant';
import { BASE_ON_PERIOD } from 'utils/constanst/tmkPromotionConstants';
class Voucher extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: 'voucher-customer',
      originalTabActive: '',
      isConfirmDialogOpen: false,
    };
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    this._loadData(id);
  }

  componentDidUpdate(prevProps, prevState) {
    const { voucherDetails } = this.props;
    const { tabActive, originalTabActive } = this.state;
    const { id } = this.props.match.params;
    if (id !== prevProps.match.params.id) {
      this._loadData(id);
    }
    if (voucherDetails && voucherDetails.id) {
      if (
        !prevProps.voucherDetails ||
        voucherDetails.id !== prevProps.voucherDetails.id
      ) {
        if (
          !prevProps.voucherDetails ||
          !prevProps.voucherDetails.id ||
          isNaN(prevProps.voucherDetails.id)
        ) {
          // created successfull
          this.props.history.replace(`/voucher/${voucherDetails.id}`);
          return;
        }
      }
    }
    if (
      tabActive === 'voucher-customer' &&
      originalTabActive === '' &&
      prevProps.voucherDetails &&
      voucherDetails &&
      voucherDetails.getcare_voucher_type_id !== VOUCHER_TYPE_DISCOUNT
    ) {
      this.setState({
        tabActive: 'voucher-configuration',
        originalTabActive: 'voucher-customer',
      });
    }

    if (
      tabActive === 'voucher-unused' &&
      originalTabActive !== '' &&
      prevProps.voucherDetails &&
      voucherDetails &&
      voucherDetails.getcare_voucher_type_id !== VOUCHER_TYPE_DISCOUNT
    ) {
      this.setState({
        tabActive: 'voucher-configuration',
      });
    }
  }

  _loadData = (id) => {
    this._resetState();
    if (this._isEditing()) {
      this.props.getVoucher({
        id,
      });
      return;
    }
    this.props.resetVoucher();
  };

  _resetState = () => {
    this.setState({
      tabActive: 'voucher-customer',
      originalTabActive: '',
      isConfirmDialogOpen: false,
    });
  };

  _isEditing = () => {
    const { id } = this.props.match.params;
    return id && !isNaN(id) && Number(id) > 0;
  };

  _backToList = () => {
    this.props.history.push(`/voucher${this.props.voucherListQueryString}`);
    this.props.resetVoucher();
  };

  handleSubmitVoucher = async () => {
    const hasErrors = await this.hasWarningMessages();
    if (hasErrors) return;
    if (this._isEditing()) {
      this.props.updateVoucher({
        params: {
          ...this._prepareData(),
        },
      });
    } else {
      this.props.createVoucher({
        params: {
          ...this._prepareData(),
        },
      });
    }
  };

  _isBaseOnPeriod = () => {
    return this.props.voucherDetails?.base_on === BASE_ON_PERIOD;
  };

  _prepareData = () => {
    const isEditing = this._isEditing();
    const isBaseOnPeriod = this._isBaseOnPeriod();
    const { voucherDetails } = this.props;
    return {
      id: voucherDetails?.id || undefined,
      name: voucherDetails.name,
      note: voucherDetails.note,
      code: voucherDetails.code,
      start_date: !!voucherDetails.start_date
        ? voucherDetails.start_date
        : null,
      end_date:
        isBaseOnPeriod && voucherDetails?.end_date
          ? voucherDetails?.end_date
          : null,
      active: !isEditing ? ACTIVE : voucherDetails.active,
      apply_for_delivery: voucherDetails.apply_for_delivery,
      value: parseInt(voucherDetails.value),
      thumbnail: voucherDetails.thumbnail,
      base_on: Number(voucherDetails.base_on),
      getcare_vendor_id: voucherDetails.getcare_vendor_id,
      getcare_policy_unit_id: voucherDetails.getcare_policy_unit_id,
      count_used_per_user: parseInt(voucherDetails.count_used_per_user),
      max_value: voucherDetails.has_max_value
        ? parseInt(voucherDetails.max_value)
        : 0,
      min_order_amount: parseInt(voucherDetails.min_order_amount),
      min_order_item: !!voucherDetails.min_order_item
        ? parseInt(voucherDetails.min_order_item)
        : null,
      count_used_total: parseInt(voucherDetails.count_used_total),
      getcare_voucher_type_id:
        voucherDetails.getcare_voucher_type_id ||
        voucherDetails?.getcare_voucher_type.id,
      getcare_voucher_gift_codes:
        voucherDetails.getcare_voucher_type_id === VOUCHER_TYPE_GIFT
          ? this._prepareVoucherConfigGift()
          : voucherDetails.getcare_voucher_type_id === VOUCHER_TYPE_MONEY
          ? this._prepareVoucherConfigMoney()
          : [],
      getcare_voucher_products: this._prepareVoucherProductList(),
      getcare_voucher_customers: this._prepareVoucherCustomerList() || [],
      getcare_voucher_sales_channel:
        this._prepareVoucherSaleChannelList() || [],
    };
  };
  _prepareVoucherConfigGift = () => {
    const { voucherConfigGiftList } = this.props;
    return voucherConfigGiftList.map((item) => {
      return {
        getcare_product_bonus_id: item.getcare_product_bonus_id
          ? item.getcare_product_bonus_id
          : item.getcare_product_bonus.id,
        quantity: item.quantity,
      };
    });
  };

  _prepareVoucherConfigMoney = () => {
    const { voucherConfigMoneyList } = this.props;
    return voucherConfigMoneyList.map((item) => {
      return { amount: item.amount, quantity: item.quantity };
    });
  };

  _prepareVoucherProductList = () => {
    const { voucherProductList } = this.props;
    return voucherProductList.map((item) => {
      return {
        getcare_product_id: item.getcare_product?.id || null,
        getcare_uom_base_id: item.getcare_uom_base_id
          ? item.getcare_uom_base_id
          : item.getcare_uom_base.id,
        min_quantity: item.min_quantity,
      };
    });
  };

  _prepareVoucherCustomerList = () => {
    const { voucherCustomerList } = this.props;
    return voucherCustomerList.map((item) => {
      return {
        type_id: item.type_id
          ? item.type_id
          : item.getcare_customer.getcare_customer_type_id,
        getcare_customer_id: item.getcare_customer?.id || null,
        getcare_erp_group_id: item.getcare_erp_group?.id || null,
      };
    });
  };

  _prepareVoucherSaleChannelList = () => {
    const { voucherSaleChannelList } = this.props;
    return voucherSaleChannelList.map((item) => {
      return {
        getcare_sales_channel_id: item.getcare_sales_channel_id
          ? item.getcare_sales_channel_id
          : item.id,
      };
    });
  };

  isStartDateValid = () => {
    if (!this.props.bannerDetails) return false;
    if (this._isEditing()) return true;
    const { start_date } = this.props.bannerDetails;
    return (
      !!start_date &&
      validDate(start_date) &&
      (this._isDuplicating()
        ? true
        : isSameDay(new Date(start_date), new Date()) ||
          isAfter(new Date(start_date), new Date()))
    );
  };

  isEndDateValid = () => {
    if (!this.props.bannerDetails) return false;
    const { end_date, start_date } = this.props.bannerDetails;
    return (
      start_date &&
      validDate(start_date) &&
      validDate(end_date) &&
      (isSameDay(new Date(end_date), new Date(start_date)) ||
        isAfter(new Date(end_date), new Date(start_date)))
    );
  };
  //end of form validation

  _getVoucherDetailsContrains = (params) => {
    return {
      id: params.id,
      name: params.name,
      note: params.note,
      thumbnail: params.thumbnail,
      getcare_policy_unit_id: params.getcare_policy_unit_id,
      startDate: validDate(params.start_date)
        ? format(validDate(params.start_date), dateFormat)
        : '',
      endDate: validDate(params.end_date)
        ? format(validDate(params.end_date), dateFormat)
        : '',
      base_on: params.base_on,
      getcare_vendor_id: params.getcare_vendor_id,
      apply_for_delivery: params.apply_for_delivery,
      has_max_value: params.has_max_value,
      max_value: params.max_value,
      value: params.value,
      count_used_per_user: params.count_used_per_user,
      min_order_amount: params.min_order_amount,
      active: params.active,
      count_used_total: params.count_used_total,
      applyId:
        params.getcare_banner_customer_apply_id ||
        params.getcare_banner_customer_apply?.id,
      min_order_item: params.min_order_item,
    };
  };

  _getListLength = (params) => {
    if (params?.length && params.length > -1) return params.length;
  };

  _isVoucherNotDiscountType = () => {
    return (
      this.props.voucherDetails?.getcare_voucher_type_id !==
      VOUCHER_TYPE_DISCOUNT
    );
  };

  //validation
  _isHasVendor = () => {
    const { voucherDetails } = this.props;
    if (!voucherDetails?.getcare_vendor_id) return false;
    if (voucherDetails.getcare_vendor_id) return true;
  };

  _isHasNote = () => {
    const { voucherDetails } = this.props;
    if (!voucherDetails?.note) return false;
    if (voucherDetails.note.trim() !== '') return true;
  };

  _isHasName = () => {
    const { voucherDetails } = this.props;
    if (!voucherDetails?.name) return false;
    if (voucherDetails.name && voucherDetails.name.trim() !== '') return true;
  };

  _isHasType = () => {
    const { voucherDetails } = this.props;
    if (!voucherDetails?.getcare_voucher_type_id) return false;
    if (voucherDetails.getcare_voucher_type_id === VOUCHER_TYPE_DISCOUNT)
      return this._isHasDiscountValue() && this._isDiscountValueValid();
    return (
      !!voucherDetails.getcare_voucher_type_id ||
      !!voucherDetails.getcare_voucher_type.id
    );
  };

  _isHasDiscountUnit = () => {
    const { voucherDetails } = this.props;
    if (!voucherDetails?.getcare_policy_unit_id) return false;
    return (
      !!voucherDetails.getcare_policy_unit_id ||
      !!voucherDetails.getcare_policy_unit_id.id
    );
  };

  _isHasDiscountValue = () => {
    const { voucherDetails } = this.props;
    if (!voucherDetails?.value) return false;
    return !!voucherDetails?.value || !!voucherDetails?.value;
  };

  _isDiscountValueValid = () => {
    const { voucherDetails } = this.props;
    if (!voucherDetails?.getcare_policy_unit_id) return false;
    if (
      voucherDetails?.getcare_policy_unit_id ===
        VOUCHER_DISCOUNT_UNIT_PERCENT &&
      parseInt(voucherDetails?.value) > 100
    ) {
      return false;
    }
    return true;
  };

  _isHasMinOrderAmount = () => {
    const { voucherDetails } = this.props;
    if (!voucherDetails?.min_order_amount) return false;
    return (
      !!voucherDetails.min_order_amount &&
      parseInt(voucherDetails.min_order_amount) > 0
    );
  };

  _isVoucherTypeValid = () => {
    const { voucherDetails } = this.props;
    if (!voucherDetails?.getcare_voucher_type_id) return false;
  };

  _isHasMaxValue = () => {
    const { voucherDetails } = this.props;
    if (!voucherDetails?.has_max_value) return false;
    if (!voucherDetails.has_max_value) return true;
    return (
      !!voucherDetails.has_max_value && parseInt(voucherDetails.max_value) > 0
    );
  };

  _isHasMaxCountPerUser = () => {
    const { voucherDetails } = this.props;
    if (!voucherDetails?.count_used_per_user) return false;
    return (
      !!voucherDetails.count_used_per_user &&
      parseInt(voucherDetails.count_used_per_user) > 0
    );
  };

  _isHasCountTotal = () => {
    const { voucherDetails } = this.props;
    if (!voucherDetails?.count_used_total) return false;
    return (
      !!voucherDetails.count_used_total &&
      parseInt(voucherDetails.count_used_total) > 0
    );
  };

  //start of check change

  _isDetailsChanged = () => {
    if (!this._isEditing()) return false;
    const { oriVoucherDetails, voucherDetails } = this.props;
    if (!voucherDetails || !oriVoucherDetails) return false;
    return !isEqual(
      this._getVoucherDetailsContrains(voucherDetails),
      this._getVoucherDetailsContrains(oriVoucherDetails)
    );
  };

  goToItem = (id) => {
    this.props.history.push(`/voucher/${id}`);
  };

  _getProductContrains = (params) => {
    return {
      min_quantity: params.min_quantity,
      name: params.name,
      getcare_uom_base_id: params.getcare_uom_base_id,
    };
  };

  _isProductsChanged = () => {
    if (!this._isEditing()) return false;

    const { voucherProductList, oriVoucherProductList } = this.props;
    if (!voucherProductList || !oriVoucherProductList) return false;

    const hasDeletedItem = oriVoucherProductList.some(
      (item) =>
        item.id && !voucherProductList.some((p) => p.id && p.id === item.id)
    );
    if (hasDeletedItem) return true;

    return voucherProductList.some((tempItem) => {
      const tempItemMap = this._getProductContrains(tempItem);
      return !oriVoucherProductList.some((item) =>
        isEqual(tempItemMap, this._getProductContrains(item))
      );
    });
  };

  _getCustomerContrains = (params) => {
    return {
      id: params.id,
      typeId: params.type_id,
      getcare_customer_id: params.getcare_customer?.id,
      getcare_erp_group_id: params.getcare_erp_group?.id,
    };
  };

  _isCustomerChanged = () => {
    if (!this._isEditing()) return false;
    const { voucherCustomerList, oriVoucherCustomerList } = this.props;
    if (!voucherCustomerList || !oriVoucherCustomerList) return false;

    const hasDeletedItem = oriVoucherCustomerList.some(
      (item) =>
        item.id && !voucherCustomerList.some((p) => p.id && p.id === item.id)
    );
    if (hasDeletedItem) return true;

    return voucherCustomerList.some((tempItem) => {
      const tempItemMap = this._getCustomerContrains(tempItem);
      return !oriVoucherCustomerList.some((item) =>
        isEqual(tempItemMap, this._getCustomerContrains(item))
      );
    });
  };

  _isCustomerTypesChanged = (types, comparedTypes) => {
    const { voucherCustomerList } = this.props;

    if (!types || !comparedTypes) return false;

    // default value of types is all of types
    if (
      this._isEditing() &&
      types.length === voucherCustomerList.length &&
      comparedTypes.length === voucherCustomerList.length
    )
      return false;

    if (types.length !== comparedTypes.length) return true;

    return !isEqual(
      sortBy(types.map((p) => this._getCustomerContrains(p))),
      sortBy(comparedTypes.map((p) => this._getCustomerContrains(p)))
    );
  };

  _getVoucherConfigGiftConstraint = (params) => {
    return {
      code: params.getcare_product_bonus.code,
    };
  };

  _getVoucherConfigMoneyConstraint = (params) => {
    return {
      id: params.id,
    };
  };

  isVoucherConfigGiftChanged = () => {
    if (!this._isEditing()) return false;
    const { oriVoucherConfigGiftList, voucherConfigGiftList } = this.props;
    if (!oriVoucherConfigGiftList || !voucherConfigGiftList) return false;

    const hasDeletedItem = oriVoucherConfigGiftList.some(
      (item) =>
        item.id && !voucherConfigGiftList.some((p) => p.id && p.id === item.id)
    );
    if (hasDeletedItem) return true;
    return voucherConfigGiftList.some((tempItem) => {
      const tempItemMap = this._getVoucherConfigGiftConstraint(tempItem);
      return !oriVoucherConfigGiftList.some((item) =>
        isEqual(tempItemMap, this._getVoucherConfigGiftConstraint(item))
      );
    });
  };

  isVoucherConfigMoneyChanged = () => {
    if (!this._isEditing()) return false;
    const { oriVoucherConfigMoneyList, voucherConfigMoneyList } = this.props;
    if (!oriVoucherConfigMoneyList || !voucherConfigMoneyList) return false;

    const hasDeletedItem = oriVoucherConfigMoneyList.some(
      (item) =>
        item.id && !voucherConfigMoneyList.some((p) => p.id && p.id === item.id)
    );
    if (hasDeletedItem) return true;
    return voucherConfigMoneyList.some((tempItem) => {
      const tempItemMap = this._getCustomerContrains(tempItem);
      return !oriVoucherConfigMoneyList.some((item) =>
        isEqual(tempItemMap, this._getCustomerContrains(item))
      );
    });
  };

  isVoucherConfigChanged = () => {
    return this._getVoucherType() === VOUCHER_TYPE_GIFT
      ? this.isVoucherConfigGiftChanged()
      : this.isVoucherConfigMoneyChanged();
  };

  _getSaleChannelContrains = (params) => {
    return {
      code: params.getcare_voucher_sales_channel.code,
    };
  };

  _isSaleChannelChanged = () => {
    if (!this._isEditing()) return false;
    const { voucherSaleChannelList, oriVoucherSaleChannelList } = this.props;
    if (!voucherSaleChannelList || !oriVoucherSaleChannelList) return false;

    const hasDeletedItem = oriVoucherSaleChannelList.some(
      (item) =>
        item.id && !voucherSaleChannelList.some((p) => p.id && p.id === item.id)
    );
    if (hasDeletedItem) return true;

    return voucherSaleChannelList.some((tempItem) => {
      const tempItemMap = this._getSaleChannelContrains(tempItem);
      return !oriVoucherSaleChannelList.some((item) =>
        isEqual(tempItemMap, this._getSaleChannelContrains(item))
      );
    });
  };

  hasNotCompletedForm = () => {
    return (
      this._isProductsNotCompleted() ||
      this._isCustomersNotCompleted() ||
      this._isVoucherConfigNotCompleted() ||
      this._isSaleChannelNotCompleted()
    );
  };

  _getVoucherType = () => {
    const { voucherDetails } = this.props;
    if (!voucherDetails) return VOUCHER_TYPE_GIFT;
    return voucherDetails.getcare_voucher_type_id;
  };

  _isVoucherConfigNotCompleted = () => {
    return this._getVoucherType() === VOUCHER_TYPE_GIFT
      ? this.props.voucherConfigGiftList.some((item) => item.isEditing)
      : this.props.voucherConfigMoneyList.some((item) => item.isEditing);
  };

  _isCustomersNotCompleted = () => {
    return this.props.voucherCustomerList.some((item) => item.isEditing);
  };

  _isProductsNotCompleted = () => {
    return this.props.voucherProductList.some((item) => item.isEditing);
  };

  _isSaleChannelNotCompleted = () => {
    return this.props.voucherSaleChannelList.some((item) => item.isEditing);
  };

  hasWarningMessages = async () => {
    if (this.hasNotCompletedForm()) {
      toast.error(
        'Có một dòng bạn đang làm dang dở. Hãy hoàn thành để tiếp tục.'
      );
      return true;
    }
    return false;
  };

  _isActive = () => {
    return this.props.voucherDetails?.active === ACTIVE;
  };

  _isValid = () => {
    return (
      this._isHasMinOrderAmount() &&
      this._isHasName() &&
      this._isHasType() &&
      this._isHasMaxCountPerUser() &&
      this._isHasCountTotal()
    );
  };

  handleTabChange = (e, value) => {
    this.setState({
      tabActive: value,
    });
  };

  handleUpdateStatus = ({ active }) => {
    this.props.saveVoucherDetails({
      ...this.props.voucherDetails,
      active: active,
    });
    this.props.updateVoucher({
      params: {
        ...this._prepareData(),
        active: active,
      },
    });
  };

  render() {
    const {
      voucherDetails,
      voucherCustomerList,
      oriVoucherCustomerList,
    } = this.props;
    const isEditing = this._isEditing();
    const isDetailsChanged = this._isDetailsChanged();
    const isProductsChanged = this._isProductsChanged();
    const isCustomerChanged = this._isCustomerChanged();
    const isCustomerTypesChanged = isEditing
      ? this._isCustomerTypesChanged(
          voucherCustomerList,
          oriVoucherCustomerList
        )
      : false;
    const isVoucherConfigChanged = this.isVoucherConfigChanged();
    const isSaleChannelChanged = this._isSaleChannelChanged();
    const voucherType = this._getVoucherType();
    const readOnly = this.props.voucherDetails?.id && !this._isActive();
    const isChanged =
      isDetailsChanged ||
      isProductsChanged ||
      isCustomerChanged ||
      isVoucherConfigChanged ||
      isCustomerTypesChanged ||
      isSaleChannelChanged;
    const isValid = this._isValid();
    const isVoucherNotDiscountType = this._isVoucherNotDiscountType();
    return (
      <div className="Voucher">
        <div
          className={`${classes.PageWrap} ${
            this.props.loading ? 'OverlayLoading' : ''
          }`}
        >
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>
              {isEditing
                ? `Xem chương trình phát hành Voucher`
                : `Tạo chương trình phát hành Voucher`}
            </h1>
            <Button
              variant="contained"
              color="default"
              onClick={this._backToList}
            >
              Hủy & Trở Về
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                (!isEditing && !isValid) ||
                (isEditing && (!isValid || !isChanged))
              }
              startIcon={<CheckOutlined />}
              onClick={this.handleSubmitVoucher}
            >
              {isEditing ? `Xác nhận thay đổi` : `Tạo Voucher`}
            </Button>
          </div>
          <div className={classes.PageMain}>
            <VoucherDetails
              isChanged={isChanged}
              isEditing={isEditing}
              isValid={isValid}
              isHasDiscountUnit={this._isHasDiscountUnit()}
              isHasDiscountValue={this._isHasDiscountValue()}
              isDiscountValueValid={this._isDiscountValueValid()}
              isHasMaxValue={this._isHasMaxValue()}
              isHasMinOrderAmount={this._isHasMinOrderAmount()}
              isHasName={this._isHasName()}
              isHasNote={this._isHasNote()}
              isHasType={this._isHasType()}
              isHasVendor={this._isHasVendor()}
              isHasMaxCountPerUser={this._isHasMaxCountPerUser()}
              isStartDateValid={this.isStartDateValid()}
              isHasCountTotal={this._isHasCountTotal()}
              isEndDateValid={this.isEndDateValid()}
              onUpdateStatus={this.handleUpdateStatus}
              readOnly={readOnly}
            />

            <Tabs
              className={classes.TabsWrap}
              value={this.state.tabActive}
              onChange={this.handleTabChange}
            >
              {isEditing
                ? (!isVoucherNotDiscountType || !isEditing) && (
                    <Tab
                      value="voucher-unused"
                      label={
                        <label className={classes.TabLabel}>Voucher{` `}</label>
                      }
                    />
                  )
                : ''}
              {isVoucherNotDiscountType && (
                <Tab
                  value="voucher-configuration"
                  label={
                    <label className={classes.TabLabel}>
                      Cấu hình voucher{` `}
                    </label>
                  }
                />
              )}
              {
                <Tab
                  value="voucher-mandatory-product"
                  label={
                    <label className={classes.TabLabel}>
                      Sản phẩm bắt buộc{` `}
                    </label>
                  }
                />
              }
              {
                <Tab
                  value="voucher-customer"
                  label={
                    <label className={classes.TabLabel}>
                      Khách hàng{` `}
                      {isCustomerChanged ? (
                        <span className={classes.HighlightMark}>*</span>
                      ) : (
                        ''
                      )}
                    </label>
                  }
                />
              }
              <Tab
                value="voucher-sale-channel"
                label={
                  <label className={classes.TabLabel}>
                    Khu vực bán hàng{` `}
                    {isSaleChannelChanged ? (
                      <span className={classes.HighlightMark}>*</span>
                    ) : (
                      ''
                    )}
                  </label>
                }
              />
            </Tabs>
            <div className={classes.TabsPanel}>
              {isEditing ? (
                <div
                  className={
                    this.state.tabActive === 'voucher-unused' &&
                    !isVoucherNotDiscountType
                      ? classes.Wrap
                      : classes.TabHidden
                  }
                >
                  <VoucherUnused
                    voucherDetails={voucherDetails}
                    readOnly={readOnly}
                    isEditing={isEditing}
                  />
                </div>
              ) : (
                ''
              )}
              <div
                className={
                  this.state.tabActive === 'voucher-configuration' &&
                  isVoucherNotDiscountType
                    ? classes.Wrap
                    : classes.TabHidden
                }
              >
                <VoucherConfig
                  isChanged={isChanged}
                  readOnly={readOnly}
                  isEditing={isEditing}
                  voucherType={voucherType}
                />
              </div>
              <div
                className={
                  this.state.tabActive === 'voucher-mandatory-product'
                    ? classes.Wrap
                    : classes.TabHidden
                }
              >
                <VoucherProduct readOnly={readOnly} isEditing={isEditing} />
              </div>
              <div
                className={
                  this.state.tabActive === 'voucher-customer'
                    ? classes.Wrap
                    : classes.TabHidden
                }
              >
                <VoucherCustomer readOnly={readOnly} isEditing={isEditing} />
              </div>
              <div
                className={
                  this.state.tabActive === 'voucher-sale-channel'
                    ? classes.Wrap
                    : classes.TabHidden
                }
              >
                <VoucherSaleChannel readOnly={readOnly} isEditing={isEditing} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectVoucherLoading(),
  voucherDetails: makeSelectVoucherDetails(),
  voucherListQueryString: makeSelectVoucherListQueryString(),
  voucherVendorList: makeSelectVoucherVendorList(),
  oriVoucherDetails: makeSelectOriVoucherDetails(),
  voucherCustomerList: makeSelectVoucherCustomerList(),
  oriVoucherCustomerList: makeSelectOriVoucherCustomerList(),
  voucherSaleChannelList: makeSelectVoucherSaleChannelList(),
  oriVoucherSaleChannelList: makeSelectOriVoucherSaleChannelList(),
  voucherProductList: makeSelectVoucherProductList(),
  oriVoucherProductList: makeSelectOriVoucherProductList(),
  voucherConfigGiftList: makeSelectVoucherConfigGiftList(),
  voucherConfigMoneyList: makeSelectVoucherConfigMoneyList(),
  oriVoucherConfigGiftList: makeSelectOriVoucherConfigGiftList(),
  oriVoucherConfigMoneyList: makeSelectOriVoucherConfigMoneyList(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getVoucher: (payload) => dispatch(getVoucher(payload)),
    resetVoucher: (payload) => dispatch(resetVoucher(payload)),
    updateVoucher: (payload) => dispatch(updateVoucher(payload)),
    createVoucher: (payload) => dispatch(createVoucher(payload)),
    saveVoucherDetails: (payload) => dispatch(saveVoucherDetails(payload)),
    getVoucherVendorList: (payload) => dispatch(getVoucherVendorList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withRouter)(Voucher);
