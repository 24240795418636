import {
  SAVE_LOADING,
  SAVE_BANNER_LIST,
  SAVE_BANNER_LIST_TOTAL,
  SAVE_BANNER_LIST_QUERY_STRING,
  SAVE_BANNER_DETAILS,
  SAVE_BANNER_SIZE,
  SAVE_BANNER_TYPE,
  SAVE_BANNER_LOCATION,
  SAVE_BANNER_CUSTOMER_LIST,
  SAVE_BANNER_SALE_CHANNEL_LIST,
  SAVE_ORI_BANNER_CUSTOMER_LIST,
  SAVE_ORI_BANNER_DETAILS,
  SAVE_ORI_BANNER_SALE_CHANNEL_LIST,
  SAVE_CUSTOMER_LIST,
  SAVE_SALE_CHANNEL_LIST,
  SAVE_CUSTOMER_LIST_ITEM,
  SAVE_SALE_CHANNEL_LIST_ITEM,
  REMOVE_CUSTOMER_LIST_ITEM,
  REMOVE_SALE_CHANNEL_LIST_ITEM,
} from 'redux/constants/tmk/bannerConstants';

const initialState = {
  bannerList: [],
  bannerSize: [],
  bannerType: [],
  bannerLocation: [],

  oriBannerCustomerList: [],
  bannerCustomerList: [],

  bannerSaleChannelList: [],
  oriBannerSaleChannelList: [],

  customerList: [],
  saleChannelList: [],

  bannerListTotal: 0,
  loading: false,
  bannerListQueryString: '',

  bannerDetails: null,
  oriBannerDetails: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case SAVE_BANNER_LIST: {
      const list = action.bannerList;
      return {
        ...state,
        bannerList: list ? [...list] : [],
      };
    }

    case SAVE_BANNER_LIST_TOTAL: {
      return {
        ...state,
        bannerListTotal: action.bannerListTotal,
      };
    }

    case SAVE_BANNER_LIST_QUERY_STRING: {
      return {
        ...state,
        bannerListQueryString: action.bannerListQueryString,
      };
    }

    case SAVE_BANNER_DETAILS: {
      return {
        ...state,
        bannerDetails: action.bannerDetails,
      };
    }

    case SAVE_BANNER_SIZE: {
      return {
        ...state,
        bannerSize: action.bannerSize,
      };
    }

    case SAVE_BANNER_TYPE: {
      return {
        ...state,
        bannerType: action.bannerType,
      };
    }

    case SAVE_BANNER_LOCATION: {
      return {
        ...state,
        bannerLocation: action.bannerLocation,
      };
    }

    case SAVE_BANNER_CUSTOMER_LIST: {
      return {
        ...state,
        bannerCustomerList: action.bannerCustomerList,
      };
    }

    case SAVE_BANNER_SALE_CHANNEL_LIST: {
      return {
        ...state,
        bannerSaleChannelList: action.bannerSaleChannelList,
      };
    }

    case SAVE_ORI_BANNER_DETAILS: {
      return {
        ...state,
        oriBannerDetails: action.oriBannerDetails,
      };
    }

    case SAVE_ORI_BANNER_CUSTOMER_LIST: {
      return {
        ...state,
        oriBannerCustomerList: action.oriBannerCustomerList,
      };
    }

    case SAVE_ORI_BANNER_SALE_CHANNEL_LIST: {
      return {
        ...state,
        oriBannerSaleChannelList: action.oriBannerSaleChannelList,
      };
    }

    case SAVE_CUSTOMER_LIST: {
      return {
        ...state,
        customerList: action.customerList,
      };
    }

    case SAVE_SALE_CHANNEL_LIST: {
      return {
        ...state,
        saleChannelList: action.saleChannelList,
      };
    }

    case SAVE_CUSTOMER_LIST_ITEM: {
      const list = [...state.bannerCustomerList];
      const comparedField = action.customerListItem.getcare_customer.idStr ? 'idStr' : 'id';
      const index = list.findIndex(
        (p) => p.getcare_customer[comparedField] === action.customerListItem.getcare_customer[comparedField]
      );
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.customerListItem };
      } else {
        // Put the new item to the top of the list
        list.unshift({ ...action.customerListItem });
      }
      return {
        ...state,
        bannerCustomerList: list,
      };
    }

    case REMOVE_CUSTOMER_LIST_ITEM: {
      const list = state.bannerCustomerList.filter(
        (p) =>
          !action.customerListItem.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p.getcare_customer[comparedField];
          })
      );
      return {
        ...state,
        bannerCustomerList: list,
      };
    }

    case SAVE_SALE_CHANNEL_LIST_ITEM: {
      const list = [...state.bannerSaleChannelList];
      const comparedField = action.saleChannelListItem.getcare_sales_channel.idStr ? 'idStr' : 'id';
      const index = list.findIndex(
        (p) => p.getcare_sales_channel[comparedField] === action.saleChannelListItem.getcare_sales_channel[comparedField]
      );
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.saleChannelListItem };
      } else {
        // Put the new item to the top of the list
        list.unshift({ ...action.saleChannelListItem });
      }
      return {
        ...state,
        bannerSaleChannelList: list,
      };
    }

    case REMOVE_SALE_CHANNEL_LIST_ITEM: {
      const list = state.bannerSaleChannelList.filter(
        (p) =>
          !action.saleChannelListItem.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p.getcare_sales_channel[comparedField];
          })
      );
      return {
        ...state,
        bannerSaleChannelList: list,
      };
    }

    default:
      return state;
  }
};

export default reducer;
