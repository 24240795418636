export const GET_BANNER_LIST = 'getcare/tmk/banner/GET_BANNER_LIST';
export const GET_BANNER = 'getcare/tmk/banner/GET_BANNER';
export const GET_BANNER_SIZE = 'getcare/tmk/banner/GET_BANNER_SIZE';
export const GET_BANNER_TYPE = 'getcare/tmk/banner/GET_BANNER_TYPE';
export const GET_BANNER_LOCATION = 'getcare/tmk/banner/GET_BANNER_LOCATION';
export const GET_CUSTOMER_LIST = 'getcare/tmk/banner/GET_CUSTOMER_LIST'
export const GET_SALE_CHANNEL_LIST = 'getcare/tmk/banner/GET_SALE_CHANNEL_LIST';
export const UPDATE_BANNER = 'getcare/tmk/banner/UPDATE_BANNER';
export const SAVE_LOADING = 'getcare/tmk/banner/SAVE_LOADING';
export const SAVE_BANNER_LIST = 'getcare/tmk/banner/SAVE_BANNER_LIST';
export const SAVE_BANNER_SIZE = 'getcare/tmk/banner/SAVE_BANNER_SIZE';
export const SAVE_BANNER_TYPE = 'getcare/tmk/banner/SAVE_BANNER_TYPE';
export const SAVE_BANNER_LOCATION = 'getcare/tmk/banner/SAVE_BANNER_LOCATION';
export const SAVE_BANNER_LIST_TOTAL = 'getcare/tmk/banner/SAVE_BANNER_LIST_TOTAL';
export const SAVE_BANNER_LIST_QUERY_STRING = 'getcare/tmk/banner/SAVE_BANNER_LIST_QUERY_STRING';
export const SAVE_BANNER_DETAILS = 'getcare/tmk/banner/SAVE_BANNER_DETAILS';
export const SAVE_ORI_BANNER_DETAILS = 'getcare/tmk/banner/SAVE_ORI_BANNER_DETAILS'
export const SAVE_BANNER_CUSTOMER_LIST = 'getcare/tmk/banner/SAVE_BANNER_CUSTOMER_LIST';
export const SAVE_ORI_BANNER_CUSTOMER_LIST = 'getcare/tmk/banner/SAVE_ORI_BANNER_CUSTOMER_LIST';
export const SAVE_BANNER_SALE_CHANNEL_LIST = 'getcare/tmk/banner/SAVE_BANNER_SALE_CHANNEL_LIST';
export const SAVE_ORI_BANNER_SALE_CHANNEL_LIST = 'getcare/tmk/banner/SAVE_ORI_BANNER_SALE_CHANNEL_LIST';
export const SAVE_CUSTOMER_LIST = 'getcare/tmk/banner/SAVE_CUSTOMER_LIST'
export const SAVE_SALE_CHANNEL_LIST = 'getcare/tmk/banner/SAVE_SALE_CHANNEL_LIST';
export const SAVE_CUSTOMER_LIST_ITEM = 'getcare/tmk/banner/SAVE_CUSTOMER_LIST_ITEM'
export const SAVE_SALE_CHANNEL_LIST_ITEM = 'getcare/tmk/banner/SAVE_SALE_CHANNEL_LIST_ITEM';
export const REMOVE_CUSTOMER_LIST_ITEM = 'getcare/tmk/banner/REMOVE_CUSTOMER_LIST_ITEM';
export const REMOVE_SALE_CHANNEL_LIST_ITEM = 'getcare/tmk/banner/REMOVE_SALE_CHANNEL_LIST_ITEM';
export const RESET_BANNER = 'getcare/tmk/banner/RESET_BANNER';