import promotionApi from 'utils/api/tmk/promotionApi';
import { put } from 'redux-saga/effects';
import { promotionActions } from 'redux/actions';
import { toast } from 'react-toastify';
import { startOfToday, startOfDay, isAfter, addDays, differenceInDays } from 'date-fns';
import { STATUS_ACTIVE } from 'utils/constanst/tmkPromotionConstants';

export function* loadPromotionTypes(payload) {
  try {
    const { data: response } = yield promotionApi.getTypes();
    response.data.push({id: 5 ,name:"Đơn hàng"});
    yield put(
      promotionActions.savePromotionTypes(response.data ? [...response.data] : [])
    );
  } catch (err) {
    console.log(err);
  }
}
export function* loadPromotionOperators(payload) {
  try {
    const { data: response } = yield promotionApi.getOperators();
    yield put(
      promotionActions.savePromotionOperators(response.data ? [...response.data] : [])
    );
  } catch (err) {
    console.log(err);
  }
}
export function* loadPromotionCompares(payload) {
  try {
    const { data: response } = yield promotionApi.getCompares();
    yield put(
      promotionActions.savePromotionCompares(response.data ? [...response.data] : [])
    );
  } catch (err) {
    console.log(err);
  }
}
export function* loadPromotionPriorities(payload) {
  try {
    const { data: response } = yield promotionApi.getPriorities();
    yield put(
      promotionActions.savePromotionPriorities(response.data ? [...response.data] : [])
    );
  } catch (err) {
    console.log(err);
  }
}
export function* loadPromotionApplies(payload) {
  try {
    const { data: response } = yield promotionApi.getApplies();
    yield put(
      promotionActions.savePromotionApplies(response.data ? [...response.data] : [])
    );
  } catch (err) {
    console.log(err);
  }
}
export function* loadPromotionDiscounts(payload) {
  const { params } = payload.payload;
  try {
    const { data: response } = yield promotionApi.getDiscounts({ params });
    yield put(
      promotionActions.savePromotionDiscounts(response.data ? [...response.data] : [])
    );
  } catch (err) {
    console.log(err);
  }
}

export function* loadPromotionList(payload) {
  const { params } = payload.payload;
  try {
    yield put(promotionActions.saveLoading(true));

    const { data: response } = yield promotionApi.getAll({ params });

    yield put(promotionActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      promotionActions.savePromotionList(response.data ? [...response.data] : [])
    );
    yield put(promotionActions.savePromotionListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}

export function* loadPromotionOrderList(payload) {
  const { params } = payload.payload;
  try {
    yield put(promotionActions.saveLoading(true));

    const { data: response } = yield promotionApi.getPromotionParent({ params });

    yield put(promotionActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      promotionActions.savePromotionList(response.data ? [...response.data] : [])
    );
    yield put(promotionActions.savePromotionListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}

export function* loadPromotion(payload) {
  const { id, isDuplicate } = payload.payload;
  try {
    yield put(promotionActions.saveLoading(true));

    yield put(promotionActions.saveOriPromotionDetails(null));
    yield put(promotionActions.savePromotionDetails(null));

    yield put(promotionActions.saveOriPromotionRules([]));
    yield put(promotionActions.savePromotionRules([]));

    yield put(promotionActions.saveOriPromotionProducts([]));
    yield put(promotionActions.saveOriPromotionOrder([]));

    yield put(promotionActions.savePromotionProducts([]));

    yield put(promotionActions.saveOriPromotionMaxProducts([]));
    yield put(promotionActions.savePromotionMaxProducts([]));

    yield put(promotionActions.saveOriPromotionBonuses([]));
    yield put(promotionActions.savePromotionBonuses([]));

    yield put(promotionActions.saveOriPromotionVendors([]));
    yield put(promotionActions.savePromotionVendors([]));

    yield put(promotionActions.saveOriPromotionCustomers([]));
    yield put(promotionActions.savePromotionCustomers([]));

    const { data: response } = yield promotionApi.get(id);

    console.log(response);
    yield put(promotionActions.saveLoading(false));

    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    const oriData = response.data ? {
      ...response.data,
      base_on: '' + response.data.base_on,
      getcare_promotion_type_id: response.data.getcare_promotion_type?.id,
      getcare_promotion_apply_id: response.data.getcare_promotion_apply?.id,
      getcare_promotion_priority_id: response.data.getcare_promotion_priority?.id,

      getcare_promotion_rules: undefined,
      getcare_promotion_products: undefined,
      getcare_promotion_product_bonuss: undefined,
      getcare_promotion_vendors: undefined,
      getcare_promotion_customers: undefined,
    } : null;

    if (!isDuplicate) {
      yield put(promotionActions.saveOriPromotionDetails(oriData ? {...oriData} : null));
      yield put(promotionActions.saveOriPromotionRules(response.data?.getcare_promotion_rules || []));
      yield put(promotionActions.saveOriPromotionProducts(response.data?.getcare_promotion_products || []));
      yield put(promotionActions.saveOriPromotionMaxProducts(response.data?.getcare_promotion_product_maximums || []));
      yield put(promotionActions.saveOriPromotionBonuses(response.data?.getcare_promotion_product_bonuss || []));
      yield put(promotionActions.saveOriPromotionVendors(response.data?.getcare_promotion_vendors || []));
      yield put(promotionActions.saveOriPromotionCustomers(response.data?.getcare_promotion_customers || []));
      
      yield put(promotionActions.savePromotionDetails(oriData ? {...oriData} : null));
      yield put(promotionActions.savePromotionRules(response.data?.getcare_promotion_rules || []));
      yield put(promotionActions.savePromotionProducts(response.data?.getcare_promotion_products || []));
      yield put(promotionActions.savePromotionMaxProducts(response.data?.getcare_promotion_product_maximums || []));
      yield put(promotionActions.savePromotionBonuses(response.data?.getcare_promotion_product_bonuss || []));
      yield put(promotionActions.savePromotionVendors(response.data?.getcare_promotion_vendors || []));
      yield put(promotionActions.savePromotionCustomers(response.data?.getcare_promotion_customers || []));
    }

    if (isDuplicate) {
      const codeSuffixLastIndex = oriData?.code.search(/_\d\d$/);
      const duplicatingStartDate = oriData?.start_date && !isAfter(startOfDay(new Date(oriData.start_date)), startOfToday())
        ? startOfToday().toISOString()
        : oriData?.start_date;

      yield put(promotionActions.savePromotionDetails(oriData ? {
        ...oriData,
        id: undefined,
        code: codeSuffixLastIndex > 0 ? oriData.code.slice(0, codeSuffixLastIndex) : oriData.code,
        start_date: duplicatingStartDate,
        end_date: oriData.end_date
          ? addDays(
              startOfDay(new Date(duplicatingStartDate)),
              differenceInDays(startOfDay(new Date(oriData.end_date)), startOfDay(new Date(oriData.start_date)))
            ).toISOString()
          : oriData.end_date,
        active: STATUS_ACTIVE,
        count_vendor: null,
        vendor_name: null,
      } : null));
      yield put(promotionActions.savePromotionRules(response.data?.getcare_promotion_rules || []));
      yield put(promotionActions.savePromotionProducts(response.data?.getcare_promotion_products || []));
      yield put(promotionActions.savePromotionMaxProducts(response.data?.getcare_promotion_product_maximums || []));
      yield put(promotionActions.savePromotionBonuses(response.data?.getcare_promotion_product_bonuss || []));
      yield put(promotionActions.savePromotionVendors(response.data?.getcare_promotion_vendors || []));
      yield put(promotionActions.savePromotionCustomers(response.data?.getcare_promotion_customers || []));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* postPromotion(payload) {
  const { params } = payload.payload;
  try {
    yield put(promotionActions.saveLoading(true));

    const { data: response } = yield promotionApi.update({ params });

    yield put(promotionActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    toast.success(`Lưu promotion thành công.`);

    const oriData = response.data ? {
      ...response.data,
      base_on: '' + response.data.base_on,
      getcare_promotion_type_id: response.data.getcare_promotion_type?.id,
      getcare_promotion_apply_id: response.data.getcare_promotion_apply?.id,
      getcare_promotion_priority_id: response.data.getcare_promotion_priority?.id,

      getcare_promotion_rules: undefined,
      getcare_promotion_products: undefined,
      getcare_promotion_product_maximums: undefined,
      getcare_promotion_product_bonuss: undefined,
      getcare_promotion_vendors: undefined,
      getcare_promotion_customers: undefined,
    } : null;
    yield put(
      promotionActions.saveOriPromotionDetails(oriData ? {...oriData} : null)
    );
    yield put(
      promotionActions.savePromotionDetails(oriData ? {...oriData} : null)
    );

    if (params.id) {
      // is editing successfull
      yield put(promotionActions.saveOriPromotionRules(response.data?.getcare_promotion_rules || []));
      yield put(promotionActions.savePromotionRules(response.data?.getcare_promotion_rules || []));

      yield put(promotionActions.saveOriPromotionProducts(response.data?.getcare_promotion_products || []));
      yield put(promotionActions.savePromotionProducts(response.data?.getcare_promotion_products || []));

      yield put(promotionActions.saveOriPromotionMaxProducts(response.data?.getcare_promotion_product_maximums || []));
      yield put(promotionActions.savePromotionMaxProducts(response.data?.getcare_promotion_product_maximums || []));

      yield put(promotionActions.saveOriPromotionBonuses(response.data?.getcare_promotion_product_bonuss || []));
      yield put(promotionActions.savePromotionBonuses(response.data?.getcare_promotion_product_bonuss || []));

      yield put(promotionActions.saveOriPromotionVendors(response.data?.getcare_promotion_vendors || []));
      yield put(promotionActions.savePromotionVendors(response.data?.getcare_promotion_vendors || []));

      yield put(promotionActions.saveOriPromotionCustomers(response.data?.getcare_promotion_customers || []));
      yield put(promotionActions.savePromotionCustomers(response.data?.getcare_promotion_customers || []));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* updatePromotionPriority(payload) {
  const { params } = payload.payload;
  try {
    const { data: response } = yield promotionApi.updatePromotionPriority({ params });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(promotionActions.savePromotionListItem(response.data ? response.data[0] : null));
    toast.success(`Cập nhật độ ưu tiên của CTKM ${response.data[0].code} thành công.`);
  } catch (err) {
    console.log(err);
  }
}

export function* loadPromotionHistory(payload) {
  const { params } = payload.payload;
  try {
    yield put(promotionActions.savePromotionHistory([]));

    const { data: response } = yield promotionApi.getPromotionHistory({
      params,
    });

    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      promotionActions.savePromotionHistory(
        response.data ? [...response.data] : []
      )
    );
  } catch (err) {
    console.log(err);
  }
}

export function* exportSaleProductTemplate(payload) {
  try {
    yield put(promotionActions.saveLoading(true));

    const { data: response } = yield promotionApi.exportSaleProductTemplate();
    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    const anchor = document.createElement('a');
    anchor.setAttribute('href', `${response.data ? response.data : ''}`);
    anchor.click();
    anchor.remove();

    yield put(promotionActions.saveLoading(false));
  } catch (err) {
    console.log(err);
  }
}
