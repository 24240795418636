import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import classes from './ConfirmInactiveDialog.module.scss';

class ConfirmInactiveDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      deactiveReason: '',
    };
    this.validator = {
      deactive_reason : {
        maxLength: 255,
      }
    }
  }
  _resetState = () => {
    this.setState({
      deactiveReason: '',
    });
  };
  isFormValid = () => {
    const { deactiveReason } = this.state;
    return !!deactiveReason && deactiveReason.trim() !== '';
  };
  handleInputChange = (e) => {
    const maxLength = this.validator[e.target.name]?.maxLength;
    const newValue = maxLength ? (e.target.value).slice(0, maxLength) : e.target.value;
    this.setState({
      deactiveReason: newValue,
    });
  }
  handleSubmit = () => {
    this.props.onSubmit({
      deactive_reason: this.state.deactiveReason,
    });
  };
  onClose = () => {
    this.props.onClose();
  };
  onEntered = () => {
    this._resetState();
  };

  render() {
    const { isOpen, title, isLoading, promotionCode } = this.props;
    const { deactiveReason } = this.state;
    const isFormValid = this.isFormValid();

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        onEntered={this.onEntered}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <p className={classes.Info}>
              Khi bạn xác nhận dừng hoạt động chương trình khuyến mãi <strong>{promotionCode}</strong>, chương trình này vẫn sẽ có tác dụng với các đơn đặt hàng đã gửi trước lúc dừng hoạt động.
            </p>
            <p className={classes.Info}>
              Để xác nhận, bạn phải nhập lý do ở đây:
            </p>
            <div className={classes.FieldControl}>
              <TextField
                autoFocus
                autoComplete="off"
                className={classes.FieldWrap}
                value={deactiveReason}
                placeholder="Nhập lý do ngừng hoạt động promotion"
                name="deactive_reason"
                maxLength="255"
                error={!isFormValid}
                onChange={this.handleInputChange}
              />
            </div>
          </DialogContent>
          <DialogActions className={classes.DialogActions}>
            <Button variant="outlined" onClick={this.props.onClose}>
              Huỷ bỏ
            </Button>
            <Button
              disabled={!isFormValid}
              variant="contained"
              onClick={this.handleSubmit}
              color="primary"
              autoFocus
            >
              Xác nhận
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

ConfirmInactiveDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  promotionCode: PropTypes.string,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

ConfirmInactiveDialog.defaultProps = {
  isOpen: false,
  title: '',
  promotionCode: '',
  isLoading: false,
};

export default ConfirmInactiveDialog;
