import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  bonusGroupTypes,
  GROUP_TYPE_ID,
  PRODUCT_TYPE_ID,
  BONUS_PROUDCT_TYPE_ID,
} from 'utils/constanst/tmkPromotionConstants';
import promotionApi from 'utils/api/tmk/promotionApi';
import uomApi from 'utils/api/mdm/uomApi';
import { isEqual, debounce } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPromotionRules } from 'redux/selectors';
import { validNumber } from 'utils/helper';

import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';

import classes from './Promotion.module.scss';
import gridClasses from '../PromotionGrid.module.scss';

class Promotiones extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: props.isEditing,
      params: this._getInitParams(props),
      unitOptions: this._initUnitOptions(props),
      groupProductOptions: this._initGroupProductOptions(props),
      groupProductCodeInput: '',
      PromotionNameInput: '',
      groupProductNameInput: '',
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const { groupProductCodeInput, groupProductNameInput, params, unitOptions, isEditing ,PromotionNameInput} = this.state;
    if (groupProductCodeInput && groupProductCodeInput !== prevState.groupProductCodeInput) {
      this._loadSuggestedProducts({
        params: {
          code: groupProductCodeInput,
        },
      });
    }
    if (groupProductNameInput && groupProductNameInput !== prevState.groupProductNameInput) {
      this._loadSuggestedProducts({
        params: {
          name: groupProductNameInput,
        },
      });
    }
    if (PromotionNameInput && PromotionNameInput !== prevState.PromotionNameInput) {
      this._loadSuggestedPromotion({
      });
    }

    if (params.getcare_product && params.getcare_product.id && !isEqual(prevState.params.getcare_product, params.getcare_product)) {
      this._loadSuggestedUnits({
        params: {
          getcare_product_id: params.getcare_product.id,
        },
      });
    }
    
    if (params.getcare_product_bonus && params.getcare_product_bonus.id && !isEqual(prevState.params.getcare_product_bonus, params.getcare_product_bonus)) {
      this.setState({ unitOptions: [{...params.getcare_product_bonus.getcare_product_unit}] });
    }
    if (unitOptions && unitOptions.length && unitOptions !== prevState.unitOptions) {
      this.handleSaveField({target: {name: 'getcare_uom_base'}}, unitOptions[0])
    }
    if (isEditing !== prevState.isEditing && this._isRowValid(params)) {
      this.props.savePromotionBonus({
        ...params,
        idStr: this.props.idStr,
        id: this.props.id,
        isEditing,
      });
    }
  }
  _initUnitOptions = (params) => {
    const unit = params.type_id === PRODUCT_TYPE_ID
      ? params.getcare_uom_base
      : (params.type_id === BONUS_PROUDCT_TYPE_ID
          ? params.getcare_product_bonus?.getcare_product_unit
          : null);
    return unit ? [{...unit}] : [];
  }
  _initGroupProductOptions = (params) => {
    const type = this._getObjectName(params.type_id);
    return params[`getcare_${type}`]
      ? [{...params[`getcare_${type}`]}] : [];
  }
  _getObjectName = (typeId) => {
    switch(typeId) {
      case GROUP_TYPE_ID: {
        return 'erp_group';
      }
      case PRODUCT_TYPE_ID: {
        return 'product';
      }
      case BONUS_PROUDCT_TYPE_ID: {
        return 'product_bonus';
      }
      default: return 'product';
    }
  }
  _loadSuggestedProducts = debounce(async ({ params }) => {
    
    const { data: response } = await promotionApi.getProductBonusSuggestions({
      params: {
        ...params,
        type_id: this.state.params.type_id,
      },
    });
    if (!response?.result) return;
    const productType = this._getObjectName(this.state.params.type_id);
    this.setState({
      groupProductOptions: response.data
        ? (response.data[`${productType}s`]
          ? [...response.data[`${productType}s`]]
          : [])
        : [],
    });


  }, 500);

  _loadSuggestedPromotion = debounce(async ({ params }) => {
    
    const { data: response } = await promotionApi.getProductBonusSuggestionsABC({
      params: {
        ...params,
        type_id: this.state.params.type_id,
      },
    });
    if (!response?.result) return;
    this.setState({
      groupProductOptions2: response.data
        ? (response.data
          ? [...response.data]
          : [])
        : [],
    });
      console.log(this.state.groupProductOptions2);
  }, 500);

  _loadSuggestedUnits = async ({ params }) => {
    const { data: response } = await uomApi.getAllUomBase({ params });
    if (!response?.result) return;
    this.setState({ unitOptions: response.data });
  };
  _getProductOptionLabel = (option) => {
    return `${option.code || option.getcare_id} - ${option.name}`
  }

  _getProductPromotionLabel = (option) => {
    console.log(option);
    return ` ${option.name}`
  }

  _getProductSelectedLabel = (option) => {
    return `${option.code || option.getcare_id} - ${option.name}`
  }
  _getInitParams = (params) => {
    return {
      idStr: params?.idStr,
      id: params?.id,
      type_id: params?.type_id,
      level: params?.level,
      getcare_product: params?.getcare_product ? {...params?.getcare_product} : null,
      getcare_erp_group: params?.getcare_erp_group ? {...params?.getcare_erp_group} : null,
      getcare_product_bonus: params?.getcare_product_bonus ? {...params?.getcare_product_bonus} : null,
      getcare_uom_base: params?.getcare_uom_base
        ? {...params?.getcare_uom_base}
        : ( params?.getcare_product_bonus?.getcare_product_unit
            ? {...params?.getcare_product_bonus?.getcare_product_unit} : null),
      ratio: params?.ratio,
      quantity_number: params?.quantity_number,
    }
  }
  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
      unitOptions: this._initUnitOptions(this.props),
      groupProductOptions: this._initGroupProductOptions(this.props),
      groupProductCodeInput: '',
      groupProductNameInput: '',
    });
  }
  _resetFormAfterChangeTypeId = (typeIdValue) => {
    this.setState({
      isEditing: true,
      params: this._getInitParams({
        ...this.state.params,
        type_id: typeIdValue,
        getcare_product: null,
        getcare_erp_group: null,
        getcare_product_bonus: null,
        getcare_uom_base: null,
        ratio: typeIdValue === GROUP_TYPE_ID ? null : 1,
        quantity_number: this.state.params.quantity_number || 1,
      }),
      unitOptions: [],
      groupProductOptions: [],
      groupProductOptions2 : [],
      groupProductCodeInput: '',
      groupProductNameInput: '',
    });
  }
  _isGroupType = (params) => {
    return params.type_id === GROUP_TYPE_ID;
  }
  _isProductType = (params) => {
    return params.type_id === PRODUCT_TYPE_ID;
  }
  _hasProduct = (params) => {
    return (!!params.getcare_product && !!params.getcare_product.id)
      || (!!params.getcare_erp_group && !!params.getcare_erp_group.id)
      || (!!params.getcare_product_bonus && !!params.getcare_product_bonus.id);
  }
  _hasUnit = (params) => {
    return !!params.getcare_uom_base && !!params.getcare_uom_base.id;
  }
  _hasQuantityNumber = (params) => {
    return !!params.quantity_number && Number(params.quantity_number) > 0;
  }
  _hasLevel = (params) => {
    return !!params.level;
  }
  _isRowValid = (params) => {
    return this._hasLevel(params) && this._hasProduct(params)
      && (this._isGroupType(params) || this._hasUnit(params))
      && this._hasQuantityNumber(params)
  };
  _getLevelOptions = () => {
    const { promotionRules } = this.props;
    return promotionRules.filter(rule => rule.bonus_number && rule.bonus_mode !== 1).map(item => item.level);
  }
  stopPropagation = (e) => {
    e.stopPropagation();
  }
  handleSaveField = (e, value) => {
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: (e.target.type === 'number')  ? validNumber(value) : value,
      },
    });
  }
  handleRowClick = () => {
    if (this.props.readOnly) {
      return;
    }

    if (!this.state.isEditing) {
      this.handleEdit();
    }
  }
  handleEdit = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = (e) => {
    if (e) e.stopPropagation();
    if (this._isRowValid(this.props)) {
      // if it was saved to DB or it was used to save on client successful
      this._resetState();
      return;
    }
    this.props.removePromotionBonus({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  }
  handleRemove = (e) => {
    if (e) e.stopPropagation();
    this.props.removePromotionBonus({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  }
  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: false,
    });
  }
  handleSelectGroupChange = (e) => {
    this._resetFormAfterChangeTypeId(e.target.value);
  }
  handleSelectLevelChange = (e) => {
    this.setState({
      params: {
        ...this.state.params,
        level: Number(e.target.value),
      },
    });
  }
  handleSelectedProductChange = (e, newValue) => {
    console.log(newValue);
    const { params } = this.state;
    this.setState({
      params: {
        ...this.state.params,
        getcare_erp_group: params.type_id === GROUP_TYPE_ID ? newValue : null,
        getcare_product: params.type_id === PRODUCT_TYPE_ID ? newValue : null,
        getcare_product_bonus: params.type_id === BONUS_PROUDCT_TYPE_ID ? newValue : null,
        getcare_uom_base: null,
        ratio: params.type_id === GROUP_TYPE_ID ? null : 1,
        quantity_number: this.state.params.quantity_number || 1,
      },
    });
    console.log(this.state.params);
  };


  handleSelectedProductPromoteChange = (e, newValue) => {
    const { params } = this.state;
    this.setState({
      params: {
        ...this.state.params,
        promotion:  newValue 
      },
    });
  };

  render() {
    const {
      level,
      getcare_product,
      getcare_erp_group,
      quantity_number,
      ratio,
      getcare_uom_base,
      getcare_product_bonus,
      disabledRemoving,
      readOnly,
    } = this.props;
    const {
      isEditing,
      params,
      groupProductOptions,
      groupProductOptions2,
      unitOptions,
    } = this.state;
    const isGroupType = this._isGroupType(params);
    const isProductType = this._isProductType(params);
    const levelOptions = this._getLevelOptions();

    return (
      <>
        <div
          className={`${classes.Item} ${gridClasses.Row}`}
          onClick={this.handleRowClick}
        >
          <div className={gridClasses.Col}>
            {isEditing ? (
              <Select
                className={classes.SelectWrap}
                value={params.level}
                name="level"
                size="small"
                fullWidth
                error={!this._hasLevel(params)}
                onChange={this.handleSelectLevelChange}
              >
                { levelOptions.length > 0
                  ? levelOptions.map(value => <MenuItem key={`type-${value}`} value={value}>{`L${value}`}</MenuItem>)
                  : <MenuItem disabled>No options</MenuItem>
                }
              </Select>
            ) : (
              `L${level}`
            )}
          </div>
          <div className={gridClasses.Col}>
            <Select
              className={classes.SelectWrap}
              value={params.type_id}
              name="type_id"
              size="small"
              disabled={readOnly}
              fullWidth
              onChange={this.handleSelectGroupChange}
            >
              {
                bonusGroupTypes.map(item => <MenuItem key={`type-${item.id}`} value={item.id}>{ item.name }</MenuItem>)
              }
            </Select>
          </div>
        
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={params.promotion?.id}
                onChange={this.handleSelectedProductPromoteChange}
                options={groupProductOptions2 || []}
                filterOptions={(x) => x}
                renderOption={(option) => this._getProductPromotionLabel(option)}
                getOptionLabel={(option) => ((option && option.name) || '')}
                getOptionSelected={(option, value) =>
                  value && value.id && option ? option.id === value.id : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    PromotionNameInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn q2213123 -" />
                )}
              />
            ) : (
              isGroupType ? getcare_erp_group?.name : (isProductType ? getcare_product?.name : getcare_product_bonus?.name)
            )}
          </div>


          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={params.promotion?.code}
                onChange={this.handleSelectedProductPromoteChange}
                options={groupProductOptions2 || []}
                filterOptions={(x) => x}
                renderOption={option => option.name}
                getOptionLabel={(option) => ((option && option.code) || '')}
                getOptionSelected={(option, value) =>
                  value && value.id && option ? option.code === value.code : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    PromotionNameInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn q2213123 -" />
                )}
              />
            ) : (
              isGroupType ? getcare_erp_group?.name : (isProductType ? getcare_product?.name : getcare_product_bonus?.name)
            )}
          </div>

          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={params.promotion?.code}
                onChange={this.handleSelectedProductPromoteChange}
                options={groupProductOptions2 || []}
                filterOptions={(x) => x}
                renderOption={option => option.name}
                getOptionLabel={(option) => ((option && option.code) || '')}
                getOptionSelected={(option, value) =>
                  value && value.id && option ? option.code === value.code : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    PromotionNameInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn q2213123 -" />
                )}
              />
            ) : (
              isGroupType ? getcare_erp_group?.name : (isProductType ? getcare_product?.name : getcare_product_bonus?.name)
            )}
          </div>





          
          
        
          <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
            {isEditing ? (
              <>
                <IconButton
                  disabled={disabledRemoving}
                  onClick={this.handleCancel}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CancelOutlined fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  onClick={this.handleSave}
                  disabled={!this._isRowValid(params)}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={this._isRowValid(params) ? `primary` : `inherit`}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  disabled={readOnly || disabledRemoving}
                  onClick={this.handleRemove}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <DeleteOutline fontSize="small" />
                </IconButton>
                <IconButton
                  disabled={readOnly}
                  onClick={this.handleEdit}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <EditOutlined fontSize="small" color={readOnly ? 'inherit' : "primary"} />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

Promotiones.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentList: PropTypes.array,
  savePromotionBonus: PropTypes.func,
  removePromotionBonus: PropTypes.func,
};

Promotiones.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  promotionRules: makeSelectPromotionRules(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(Promotiones);
