import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPromotionBonuses,
  makeSelectOriPromotionBonuses,
  makeSelectPromotionRules,
} from 'redux/selectors';
import {
  savePromotionBonusItem,
  removePromotionBonusItems,
} from 'redux/actions/tmk/promotionActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  promotionBonusesDisplayFields,
  promotionBonusesParamsMap,
  newBonusItem,
} from 'utils/constanst/tmkPromotionConstants';

import PromotionBonus from './PromotionBonus/PromotionBonus';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';

import gridClasses from './PromotionBonusesGrid.module.scss';
import classes from './PromotionBonuses.module.scss';

class PromotionBonuses extends PureComponent {
  _rerenderPromotionBonuses = () => {
    const { promotionBonuses, promotionRules } = this.props;
    return promotionBonuses.map(item => {
      const isAtleastOne = promotionBonuses.filter(p => p.level === item.level).length <= 1;
      const isInRule = promotionRules.some(r => r.level === item.level && r.bonus_mode !== 1 && parseFloat(r.bonus_number) > 0);
      return {
        ...item,
        disabledRemoving: isAtleastOne && isInRule,
      }
    });
  }
  handleCreateNewRow = () => {
    this.props.savePromotionBonusItem({
      ...newBonusItem,
      idStr: genID(),
    });
  };
  handleSavePromotionBonus = (params) => {
    console.log(params);
    this.props.savePromotionBonusItem({
      ...params,
    });
  };
  handleRemovePromotionBonus = (params) => {
    this.props.removePromotionBonusItems([{ ...params }]);
  };
  handleRemovePromotionBonuses = () => {
    this.props.removePromotionBonusItems([...this.state.selectedItems]);
  };

  render() {
    const { readOnly } = this.props;
    const displayFields = getDisplayFields(
      promotionBonusesParamsMap,
      promotionBonusesDisplayFields
    );
    const list = this._rerenderPromotionBonuses();

    return (
      <>
        <div className={`${classes.ActionContainer}`}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            disabled={readOnly}
            onClick={this.handleCreateNewRow}
          >
            Thêm dòng
          </Button>
        </div>
        <div className={`${classes.List}`}>
        <div className={`${classes.Header}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
              {displayFields.map((item) => (
                <ListHeaderCol
                  key={item.name}
                  className={`${gridClasses.Col} ${gridClasses[item.className]}`}
                  {...item}
                />
              ))}
            </div>
            </div>
          <div className={`${classes.Body}`}>  
            {list.length ? (
              list.map((item) => (
                <PromotionBonus
                  key={`promotion-product-${item.id || item.idStr}`}
                  {...item}
                  readOnly={readOnly}
                  currentList={[...list]}
                  savePromotionBonus={this.handleSavePromotionBonus}
                  removePromotionBonus={this.handleRemovePromotionBonus}
                  onFieldChange={this.props.onFieldChange}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </>
    );
  }
}

PromotionBonuses.propTypes = {
  promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEditing: PropTypes.bool,
};

PromotionBonuses.defaultProps = {
  isEditing: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    savePromotionBonusItem: (payload) => dispatch(savePromotionBonusItem(payload)),
    removePromotionBonusItems: (payload) => dispatch(removePromotionBonusItems(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  promotionBonuses: makeSelectPromotionBonuses(),
  oriPromotionBonuses: makeSelectOriPromotionBonuses(),
  promotionRules: makeSelectPromotionRules(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PromotionBonuses);
