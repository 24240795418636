import React, { PureComponent } from 'react';
import { currencyFormatVN } from 'utils/helper';

import classes from '../OrderListItem/OrderListItem.module.scss';
import gridClasses from '../OrderListGrid.module.scss';

class PromotionDiscountItem extends PureComponent {
  render() {
    const {
      promotion_code,
      promotion_unit_name,
      discount,
      amount,
      vat,
    } = this.props;

    return (<div className={`${gridClasses.Row} ${gridClasses.DiscountRow} ${classes.Item}`}>
      <div className={`${gridClasses.Col}`}>
        <strong>{ promotion_code } - { promotion_unit_name === 'VNĐ' ? currencyFormatVN(discount) : discount + promotion_unit_name }</strong>
      </div>
      <div className={`${gridClasses.Col}`}>
        { ['', null, undefined].includes(amount) ? '' : `(${currencyFormatVN(amount)})` }
      </div>
      <div className={`${gridClasses.Col}`}>
        { ['', null, undefined].includes(vat) ? '' : vat + '%' }
      </div>
      <div className={`${gridClasses.Col}`}></div>
    </div>);
  }
}

export default PromotionDiscountItem;

