import axiosAuth from 'utils/axios/axiosAuth';

const customerApi = {
  getAllCustomers: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_customer/`, {
      params: queryParams,
    });
  },
  getCustomerTypes: () => {
    return axiosAuth.get(`getcare_customer_type/`);
  },
};

export default customerApi;
