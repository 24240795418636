import voucherApi from 'utils/api/tmk/voucherApi';
import { put } from 'redux-saga/effects';
import { voucherActions } from 'redux/actions';
import { toast } from 'react-toastify';
import commonApi from 'utils/api/tmk/commonApi';
import { max } from 'lodash';

export function* loadVoucherList(payload) {
  const { params } = payload.payload;
  try {
    yield put(voucherActions.saveLoading(true));

    const { data: response } = yield voucherApi.getAll({ params });

    yield put(voucherActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      voucherActions.saveVoucherList(response.data ? [...response.data] : [])
    );
    yield put(voucherActions.saveVoucherListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}

export function* loadUsedVoucherList(payload) {
  const { params } = payload.payload;
  try {
    yield put(voucherActions.saveLoading(true));

    const { data: response } = yield voucherApi.getAllUsed({ params });

    yield put(voucherActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      voucherActions.saveUsedVoucherList(
        response.data ? [...response.data] : []
      )
    );
    yield put(voucherActions.saveUsedVoucherListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}

export function* loadVoucher(payload) {
  const { id } = payload.payload;
  try {
    yield put(voucherActions.saveLoading(true));

    yield put(voucherActions.saveVoucherCustomerList([]));
    yield put(voucherActions.saveOriVoucherCustomerList([]));

    yield put(voucherActions.saveVoucherSaleChannelList([]));
    yield put(voucherActions.saveOriVoucherSaleChannelList([]));

    yield put(voucherActions.saveOriVoucherProductList([]));
    yield put(voucherActions.saveVoucherProductList([]));

    yield put(voucherActions.saveOriVoucherConfigGiftList([]));
    yield put(voucherActions.saveVoucherConfigGiftList([]));

    yield put(voucherActions.saveOriVoucherConfigMoneyList([]));
    yield put(voucherActions.saveVoucherConfigMoneyList([]));

    yield put(voucherActions.saveVoucherUnusedList([]));

    yield put(voucherActions.saveOriVoucherDetails(null));
    yield put(voucherActions.saveVoucherDetails(null));

    const { data: response } = yield voucherApi.get(id);
    yield put(voucherActions.saveLoading(false));

    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    const { max_value } = response.data;
    if (max_value) {
      const newRes = {
        ...response.data,
        prev_id: response.data.id - 1,
        next_id: response.data.id + 1,
        has_max_value: true,
      };
      yield put(voucherActions.saveVoucherDetails(newRes));
      yield put(voucherActions.saveOriVoucherDetails(newRes));
    } else {
      const newRes = {
        ...response.data,
        has_max_value: false,
      };
      yield put(voucherActions.saveVoucherDetails(newRes));
      yield put(voucherActions.saveOriVoucherDetails(newRes));
    }

    yield put(
      voucherActions.saveVoucherUnusedList(
        response.data?.getcare_vouchers || []
      )
    );
    if (response.data?.getcare_voucher_type_id !== 1) {
      yield put(
        voucherActions.saveOriVoucherConfigGiftList(
          response.data?.getcare_voucher_gift_codes.map((item) => {
            return { ...item, isChecked: false };
          }) || []
        )
      );

      yield put(
        voucherActions.saveOriVoucherConfigMoneyList(
          response.data?.getcare_voucher_gift_codes.map((item) => {
            return { ...item, isChecked: false };
          }) || []
        )
      );

      yield put(
        voucherActions.saveVoucherConfigGiftList(
          response.data?.getcare_voucher_gift_codes.map((item) => {
            return { ...item, isChecked: false };
          }) || []
        )
      );

      yield put(
        voucherActions.saveVoucherConfigMoneyList(
          response.data?.getcare_voucher_gift_codes.map((item) => {
            return { ...item, isChecked: false };
          }) || []
        )
      );
    }

    yield put(
      voucherActions.saveVoucherProductList(
        response.data?.getcare_voucher_products.map((item) => {
          return { ...item, isChecked: false };
        }) || []
      )
    );

    yield put(
      voucherActions.saveOriVoucherProductList(
        response.data?.getcare_voucher_products.map((item) => {
          return { ...item, isChecked: false };
        }) || []
      )
    );

    yield put(
      voucherActions.saveVoucherCustomerList(
        response.data?.getcare_voucher_customers.map((item) => {
          return { ...item, isChecked: false };
        }) || []
      )
    );

    yield put(
      voucherActions.saveOriVoucherCustomerList(
        response.data?.getcare_voucher_customers.map((item) => {
          return { ...item, isChecked: false };
        }) || []
      )
    );

    yield put(
      voucherActions.saveVoucherSaleChannelList(
        response.data?.getcare_voucher_sales_channel.map((item) => {
          return { ...item, isChecked: false };
        }) || []
      )
    );
    yield put(
      voucherActions.saveOriVoucherSaleChannelList(
        response.data?.getcare_voucher_sales_channel.map((item) => {
          return { ...item, isChecked: false };
        }) || []
      )
    );
  } catch (err) {
    console.log(err);
  }
}

export function* loadVoucherVendor(payload) {
  try {
    const { data: response } = yield voucherApi.getVendorList();
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    const convertedRes = response.data.map((item) => {
      return {
        id: item.id,
        name: item.name,
      };
    });
    yield put(voucherActions.saveVoucherVendorList(convertedRes));
  } catch (error) {
    console.log(error);
  }
}

export function* loadCustomerList(payload) {
  try {
    const { data: response } = yield voucherApi.getCustomerList();

    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    const savedCustomerList = response.data.map((item) => {
      const { name, id, phone, address, code } = item;
      return {
        name,
        id,
        phone,
        address,
        code,
      };
    });
    yield put(voucherActions.saveCustomerList(savedCustomerList));
  } catch (error) {
    console.log(error);
  }
}

export function* loadSaleChannelList(payload) {
  try {
    const { data: response } = yield voucherApi.getSaleChannelList();
    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    yield put(voucherActions.saveSaleChannelList(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* postVoucher(payload) {
  const { params } = payload.payload;
  try {
    yield put(voucherActions.saveLoading(true));

    const { data: response } = yield voucherApi.create({ params });

    yield put(voucherActions.saveLoading(false));
    if (!response?.result) {
      if (response.message.includes('Error 1062')) {
        toast.error('Voucher ID bị trùng');
        return;
      }
      toast.error(response.message);
      return;
    }
    toast.success(`Lưu voucher thành công.`);

    const oriData = response.data
      ? {
          ...response.data,
        }
      : null;
    yield put(
      voucherActions.saveOriVoucherDetails(
        oriData
          ? { ...oriData, has_max_value: oriData.max_value ? true : false }
          : null
      )
    );
    yield put(
      voucherActions.saveVoucherDetails(
        oriData
          ? { ...oriData, has_max_value: oriData.max_value ? true : false }
          : null
      )
    );

    if (response.data.getcare_voucher_type_id !== 1) {
      yield put(
        voucherActions.saveOriVoucherConfigGiftList(
          response.data?.getcare_voucher_gift_codes.map((item) => {
            return { ...item, isChecked: false };
          }) || []
        )
      );

      yield put(
        voucherActions.saveOriVoucherConfigMoneyList(
          response.data?.getcare_voucher_gift_codes.map((item) => {
            return { ...item, isChecked: false };
          }) || []
        )
      );

      yield put(
        voucherActions.saveVoucherConfigGiftList(
          response.data?.getcare_voucher_gift_codes.map((item) => {
            return { ...item, isChecked: false };
          }) || []
        )
      );

      yield put(
        voucherActions.saveVoucherConfigMoneyList(
          response.data?.getcare_voucher_gift_codes.map((item) => {
            return { ...item, isChecked: false };
          }) || []
        )
      );
    }

    yield put(
      voucherActions.saveOriVoucherCustomerList(
        response.data.getcare_voucher_customers.map((item) => {
          return { ...item, isChecked: false };
        }) || []
      )
    );
    yield put(
      voucherActions.saveVoucherCustomerList(
        response.data.getcare_voucher_customers.map((item) => {
          return { ...item, isChecked: false };
        }) || []
      )
    );
    yield put(
      voucherActions.saveOriVoucherProductList(
        response.data.getcare_voucher_products.map((item) => {
          return { ...item, isChecked: false };
        }) || []
      )
    );
    yield put(
      voucherActions.saveVoucherProductList(
        response.data.getcare_voucher_products.map((item) => {
          return { ...item, isChecked: false };
        }) || []
      )
    );
    yield put(
      voucherActions.saveOriVoucherSaleChannelList(
        response.data.getcare_voucher_sales_channel.map((item) => {
          return { ...item, isChecked: false };
        }) || []
      )
    );
    yield put(
      voucherActions.saveVoucherSaleChannelList(
        response.data.getcare_voucher_sales_channel.map((item) => {
          return { ...item, isChecked: false };
        }) || []
      )
    );
    if (params.id) {
    }
  } catch (err) {
    console.log(err);
  }
}

export function* putVoucher(payload) {
  const { params } = payload.payload;
  try {
    yield put(voucherActions.saveLoading(true));

    const { data: response } = yield voucherApi.update({ params });

    yield put(voucherActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    toast.success(`Lưu voucher thành công.`);

    const oriData = response.data
      ? {
          ...response.data,
        }
      : null;
    yield put(
      voucherActions.saveOriVoucherDetails(
        oriData
          ? { ...oriData, has_max_value: oriData.max_value ? true : false }
          : null
      )
    );
    yield put(
      voucherActions.saveVoucherDetails(
        oriData
          ? { ...oriData, has_max_value: oriData.max_value ? true : false }
          : null
      )
    );

    if (response.data.getcare_voucher_type_id !== 1) {
      yield put(
        voucherActions.saveOriVoucherConfigGiftList(
          response.data?.getcare_voucher_gift_codes.map((item) => {
            return { ...item, isChecked: false };
          }) || []
        )
      );

      yield put(
        voucherActions.saveOriVoucherConfigMoneyList(
          response.data?.getcare_voucher_gift_codes.map((item) => {
            return { ...item, isChecked: false };
          }) || []
        )
      );

      yield put(
        voucherActions.saveVoucherConfigGiftList(
          response.data?.getcare_voucher_gift_codes.map((item) => {
            return { ...item, isChecked: false };
          }) || []
        )
      );

      yield put(
        voucherActions.saveVoucherConfigMoneyList(
          response.data?.getcare_voucher_gift_codes.map((item) => {
            return { ...item, isChecked: false };
          }) || []
        )
      );
    }

    yield put(
      voucherActions.saveOriVoucherCustomerList(
        response.data.getcare_voucher_customers.map((item) => {
          return { ...item, isChecked: false };
        }) || []
      )
    );
    yield put(
      voucherActions.saveVoucherCustomerList(
        response.data.getcare_voucher_customers.map((item) => {
          return { ...item, isChecked: false };
        }) || []
      )
    );
    yield put(
      voucherActions.saveOriVoucherProductList(
        response.data.getcare_voucher_products.map((item) => {
          return { ...item, isChecked: false };
        }) || []
      )
    );
    yield put(
      voucherActions.saveVoucherProductList(
        response.data.getcare_voucher_products.map((item) => {
          return { ...item, isChecked: false };
        }) || []
      )
    );
    yield put(
      voucherActions.saveOriVoucherSaleChannelList(
        response.data.getcare_voucher_sales_channel.map((item) => {
          return { ...item, isChecked: false };
        }) || []
      )
    );
    yield put(
      voucherActions.saveVoucherSaleChannelList(
        response.data.getcare_voucher_sales_channel.map((item) => {
          return { ...item, isChecked: false };
        }) || []
      )
    );
    if (params.id) {
    }
  } catch (err) {
    console.log(err);
  }
}


export function* exportVoucherUnusedList(payload) {
  const { id } = payload.payload;
  console.log(id);
  try {
    yield put(voucherActions.saveLoading(true));

    const { data: response } = yield voucherApi.exportVoucherUnusedList(id);
    console.log(response);
    if (!response?.result) {
      yield put(voucherActions.saveLoading(false));
      toast.error(response.message);
      return;
    }

    const anchor = document.createElement('a');
    anchor.setAttribute('href', `${response.data ? response.data : ''}`);
    anchor.click();
    anchor.remove();

    yield put(voucherActions.saveLoading(false));
  } catch (err) {
    console.log(err);
  }
}

export function* exportVoucherGiftCodeUnusedList(payload) {
  const { id } = payload.payload;
  try {
    yield put(voucherActions.saveLoading(true));
    const { data: response } = yield voucherApi.exportVoucherGifCodeUnusedList(
      id
    );
    if (!response?.result) {
      toast.error(response.message);
      yield put(voucherActions.saveLoading(false));
      return;
    }

    const anchor = document.createElement('a');
    anchor.setAttribute('href', `${response.data ? response.data : ''}`);
    anchor.click();
    anchor.remove();

    yield put(voucherActions.saveLoading(false));
  } catch (err) {
    console.log(err);
  }
}
