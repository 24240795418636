import promotionApi from 'utils/api/tmk/promotionApi';
import { put } from 'redux-saga/effects';
import { testPromotionActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* testPromotion(payload) {
  const { params } = payload.payload;
  try {
    yield put(testPromotionActions.saveLoading(true));

    const { data: response } = yield promotionApi.checkPromotion({ params });
    yield put(testPromotionActions.saveLoading(false));
    if (!response?.result || !response?.data) {
      toast.error(response.message);
      return;
    }
    // order items
    yield put(
      testPromotionActions.saveOrderList(response.data.order_items)
    );
    // order discounts (for not-line promtions)
    yield put(
      testPromotionActions.savepromotionDiscounts(response.data.promotion_discounts || [])
    );
    yield put(
      testPromotionActions.savePromotionAvailables(response.data.promotion_availables)
    );
    yield put(
      testPromotionActions.savePromotionActives(response.data.promotion_actives)
    );
    yield put(
    testPromotionActions.saveOrderAmount({
      amount: response.data.amount,
      amount_vat: response.data.amount_vat,
      amount_discount: response.data.amount_discount,
      amount_total: response.data.amount_total,
    })
    );
  } catch (err) {
    console.log(err);
  }
}
