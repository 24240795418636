import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPromotionDiscounts,
  makeSelectPromotionOperators,
  makeSelectPromotionBonuses,
} from 'redux/selectors';
import { currencyFormatVN, validNumber } from 'utils/helper';
import {
  orderModeMap,
  orderModes,
  DISCOUNT_PERCENT,
  DISCOUNT_PER_UNIT,
  isPromotionLine,
  isPromotionGroup,
  isPromotionDocument,
} from 'utils/constanst/tmkPromotionConstants';

import Checkbox from '@material-ui/core/Checkbox';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';
import FieldEditable from 'components/FieldEditable/FieldEditable';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

import gridClasses from '../PromotionRulesGrid.module.scss';
import classes from './PromotionRule.module.scss';

class PromotionRule extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: props.isEditing,
      params: this._getInitParams(props),
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const { isEditing, params } = this.state;
    if (isEditing !== prevState.isEditing && this._isRowValid(params)) {
      this.props.savePromotionRule({
        ...params,
        level: this.props.level,
        idStr: this.props.idStr,
        id: this.props.id,
        isEditing,
      });
    }
    const { index } = this.props;
    if (index !== prevProps.index) {
      this.props.savePromotionRule({
        ...params,
        level: index + 1,
        idStr: this.props.idStr,
        id: this.props.id,
      });
    }
  }
  _getOrderMode = (id) => {
    return orderModes.find(item => item.id === id);
  }
  _getInitParams = (params) => {
    return {
      quantity_number: params.quantity_number,
      order_mode: params.order_mode,
      order_amount: params.order_amount,
      bonus_number: params.bonus_number,
      bonus_mode: params.bonus_mode,
      same_bonus: params.same_bonus,
      getcare_promotion_operator: params.getcare_promotion_operator ? {...params.getcare_promotion_operator} : null,
      getcare_promotion_discount: params.getcare_promotion_discount ? {...params.getcare_promotion_discount} : null,
      discount_amount: params.discount_amount,
      maximum_amount: params.maximum_amount,
      level_name: params.level_name,
    }
  }
  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
    });
  }
  _isOrderAmountValid = (params) => {
    const isDoc = isPromotionDocument(this.props.promotionTypeId);
    const hasAmountNumber = !!params.order_amount && Number(params.order_amount) > 0;
    return isDoc ? hasAmountNumber : (!!params.order_mode ? hasAmountNumber : true);
  }
  _hasOrderAmount = (params) => {
    return this._isQuantityValid(params) && this._isOrderAmountValid(params);
  }
  _hasBonusMode = (params) => {
    return params.bonus_mode === 1;
  }
  _hasBonus = (params) => {
    return !!params.bonus_number && Number(params.bonus_number) > 0;
  }
  _isBonusNumberValid = (params) => {
    if (this._hasBonusMode(params)) return this._hasBonus(params);
    return this._hasBonus(params) || this._hasDiscountMode(params);
  }
  _hasDiscountMode = (params) => {
    return !!params.getcare_promotion_discount?.id;
  }
  _hasDiscountAmount = (params) => {
    return !!params.discount_amount && Number(params.discount_amount) > 0;
  }
  _hasBonusAndDiscount = (params) => {
    return this._hasBonus(params) && this._hasDiscountMode(params);
  }
  _getMinQuantity = (params) => {
    if (this.props.index <= 0) return 0;
    const prevQuantity = this.props.currentList[this.props.index - 1];
    return prevQuantity ? prevQuantity.quantity_number : 0;
  }
  _isQuantityValid = (params) => {
    const isDoc = isPromotionDocument(this.props.promotionTypeId);
    return isDoc ? true : !!params.quantity_number && Number(params.quantity_number) > this._getMinQuantity();
  }
  _isRowValid = (params) => {
    return this._isLevelNameValid(params)
      && this._hasOrderAmount(params)
      && (this._hasDiscountMode(params) ? this._hasDiscountAmount(params) : this._hasBonus(params))
      && (this._hasBonusMode(params) ? this._hasBonus(params) : true);
  };
  _isBonusLevelEnough = (params) => {
    if (!this._hasBonus(params)) return false;
    if (this._hasBonusMode(params)) return true;

    const levelBonusesQuantity = this.props.promotionBonuses.filter(
      item => {
        const isItemNotEditing = item.id || (item.getcare_product?.id || item.getcare_erp_group?.id || item.getcare_product_bonus?.id)
        return (isItemNotEditing && item.level === this.props.level)
      }
    ).reduce((memo, item) => memo += item.quantity_number, 0);
    return (levelBonusesQuantity > 0) && (levelBonusesQuantity <= params.bonus_number);
  }
  _isLevelNameValid = (params) => {
    return params.level_name && params.level_name.trim() !== '';
  }
  _getUnitLabel = () => {
    return this.state.params.getcare_promotion_discount?.id === DISCOUNT_PERCENT ? '%' : 'VNĐ';
  }
  _getDiscountText = () => {
    const { getcare_promotion_discount, discount_amount, maximum_amount } = this.props;
    if (!discount_amount || Number(discount_amount) <= 0) return '';
    return <>
      { getcare_promotion_discount?.name }: {
        getcare_promotion_discount?.id === DISCOUNT_PERCENT
        ? <strong>{ discount_amount } %</strong>
        : <strong>{ currencyFormatVN(discount_amount) }</strong>
      }{ maximum_amount && <>; Tối đa: <strong>{ currencyFormatVN(maximum_amount) }</strong></> }
    </>;
  }
  _getDiscount = (id) => {
    return this.props.promotionDiscounts.find(item => item.id === id);
  }
  _willShowMaximumAmount = () => {
    return [DISCOUNT_PERCENT, DISCOUNT_PER_UNIT].includes(this.state.params.getcare_promotion_discount?.id);
  }
  _canBeRemoved = () => {
    const { index, currentList } = this.props;
    const promotionRulesLength = currentList.length;
    return !this.state.isEditing && (promotionRulesLength !== 1) && (index === promotionRulesLength - 1);
  }

  stopPropagation = (e) => {
    e.stopPropagation();
  }
  handleDiscountCheckboxChange = (e) => {
    const { defaultOperator } = this.props;
    const isChecked = e.target.checked;

    const newParams = isChecked ? {
      getcare_promotion_discount: this.props.promotionDiscounts[0],
      discount_amount: null,
      maximum_amount: null,
    } : {
      getcare_promotion_discount: null,
      discount_amount: null,
      maximum_amount: null,
      getcare_promotion_operator: { ...defaultOperator } ,
    }
    this.setState({
      params: {
        ...this.state.params,
        ...newParams,
      },
    });
  }
  handleSaveField = (e, value) => {
    let newValue = value;
    if (e.target.name === 'order_mode') {
      newValue = value.id;
    }
    if (e.target.type === 'number') {
      newValue = validNumber(value);
    }
    const newParams = (e.target.name === 'order_mode') && !newValue ? {
      ...this.state.params,
      [e.target.name]: newValue,
      order_amount: '',
    } : {
      ...this.state.params,
      [e.target.name]: newValue,
    };
    this.setState({
      params: newParams,
    });
  }
  handleEdit = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = (e) => {
    if (e) e.stopPropagation();
    if (this._isRowValid(this.props)) {
      // if it was saved to DB or it was used to save on client successful
      this._resetState();
      return;
    }
    this.props.removePromotionRule({
      id: this.props.id,
      idStr: this.props.idStr,
      level: this.props.level,
    });
  }
  handleRemove = (e) => {
    if (e) e.stopPropagation();
    this.props.removePromotionRule({
      id: this.props.id,
      idStr: this.props.idStr,
      level: this.props.level,
    });
  }
  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: false,
    });
  }
  handleRowClick = () => {
    if (this.props.readOnly) {
      return;
    }

    if (!this.state.isEditing) {
      this.handleEdit();
    }
  }
  handleBonusModeCheckboxChange = (e) => {
    this.setState({
      params: {
        ...this.state.params,
        bonus_mode: e.target.checked ? 1 : 2,
      },
    });
  }

  render() {
    const {
      level,
      quantity_number,
      order_amount,
      order_mode,
      bonus_number,
      getcare_promotion_operator,
      promotionDiscounts,
      promotionOperators,
      promotionTypeId,
      bonus_mode,
      listClassName,
      level_name,
      readOnly,
    } = this.props;
    const {
      isEditing,
      params,
    } = this.state;
    const hasDiscountMode = this._hasDiscountMode(params);
    const isDoc = isPromotionDocument(promotionTypeId);

    return (
      <>
        <div
          className={`${classes.Item} ${gridClasses.Row} ${gridClasses[listClassName]}`}
          onClick={this.handleRowClick}
        >
          <div className={gridClasses.Col}>{`L${level}`}</div>

          <div className={gridClasses.Col}>
          { isEditing ? <FieldEditable
              autoFocus
              editModeOnly
              fieldName="level_name"
              maxLength={255}
              value={params.level_name || ''}
              className={classes.Field}
              error={!this._isLevelNameValid(params)}
              onChange={(e, newValue) => {
                this.handleSaveField(e, newValue);
              }}
            /> : <>{ level_name }</>
          }
          </div>

          { !isDoc && <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <TextField
                autoComplete="off"
                className={classes.Field}
                value={params.quantity_number || ''}
                name="quantity_number"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                error={!this._isQuantityValid(params)}
                onChange={(e) => {
                  this.handleSaveField(e, e.target.floatValue);
                }}
              />
            ) : (
              <><strong>{ quantity_number }</strong></>
            )}
          </div> }

          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <div className={classes.MultiFieldsWrap}>
                { !isDoc && <Autocomplete
                    className={classes.Field}
                    disableClearable
                    openOnFocus
                    selectOnFocus
                    size="small"
                    name="order_mode"
                    handleHomeEndKeys={false}
                    value={this._getOrderMode(params.order_mode) || null}
                    onChange={(e, newValue) => {
                      this.handleSaveField({target: { name: 'order_mode' } }, newValue);
                    }}
                    options={orderModes}
                    filterOptions={(x) => x}
                    renderOption={(option) => `${option.name}`}
                    getOptionLabel={(option) => (option && option.name) || ''}
                    getOptionSelected={(option, value) => (value && value.id && option) ? option.id === value.id : null}
                    renderInput={(params) => <TextField {...params} placeholder="-" />}
                  />
                }
                <TextField
                  autoComplete="off"
                  disabled={!params.order_mode}
                  value={params.order_amount || ''}
                  name="order_amount"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  error={!this._isOrderAmountValid(params)}
                  onChange={(e) => {
                    this.handleSaveField(e, e.target.floatValue);
                  }}
                />
              </div>
            ) : (
              <>
                { isDoc ? `` : order_mode ? `${orderModeMap[order_mode]} | ` : `` }
                <strong>{ !['', null, undefined].includes(order_amount) ? currencyFormatVN(order_amount) : `` }</strong>
              </>
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <TextField
                autoComplete="off"
                className={`${classes.Field} ${this._isBonusLevelEnough(params) ? '' : classes.Invalid}`}
                value={params.bonus_number || ''}
                name="bonus_number"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                error={!this._isBonusNumberValid(params)}
                onChange={(e) => {
                  this.handleSaveField(e, e.target.floatValue);
                }}
              />
            ) : (
              <strong className={this._isBonusLevelEnough(params) ? '' : classes.Invalid}>{ bonus_number }</strong>
            )}
          </div>
          { (isPromotionGroup(promotionTypeId) || isPromotionLine(promotionTypeId)) && (
            <div className={`${gridClasses.Col} ${gridClasses.Center}`}>
              {isEditing ? (
                <Checkbox
                  size="small"
                  name="bonus_mode"
                  checked={params.bonus_mode === 1}
                  onChange={this.handleBonusModeCheckboxChange}
                />
              ) : (
                bonus_mode === 1 ? <CheckBoxIcon fontSize="small"/> : <CheckBoxOutlineBlankIcon fontSize="small"/>
              )}
            </div>
          )}
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                disabled={!this._hasBonusAndDiscount(params)}
                className={classes.Field}
                disableClearable
                openOnFocus
                selectOnFocus
                size="small"
                handleHomeEndKeys={false}
                name="getcare_promotion_operator"
                value={
                  (params.getcare_promotion_operator && params.getcare_promotion_operator.id) ? params.getcare_promotion_operator : null
                }
                onChange={(e, newValue) => {
                  this.handleSaveField({target: { name: 'getcare_promotion_operator' } }, newValue);
                }}
                options={promotionOperators || []}
                filterOptions={(x) => x}
                renderOption={(option) => `${option.name}`}
                getOptionLabel={(option) => (option && option.name) || ''}
                getOptionSelected={(option, value) => (value && value.id && option) ? option.id === value.id : null}
                renderInput={(params) => <TextField {...params} placeholder="-" />}
              />
            ) : (
              `${this._hasBonusAndDiscount(this.props) ? getcare_promotion_operator?.name : ''}`
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <div className={`${classes.MultiFieldsWrap} ${classes.DiscountWrap}`}>
                <Checkbox
                  size="small"
                  name="is_discount"
                  checked={hasDiscountMode}
                  onChange={this.handleDiscountCheckboxChange}
                  style={{padding: '0'}}
                />
                { hasDiscountMode && <>
                  <Autocomplete
                    className={classes.Field}
                    disableClearable
                    openOnFocus
                    selectOnFocus
                    size="small"
                    handleHomeEndKeys={false}
                    name="getcare_promotion_discount"
                    value={(params.getcare_promotion_discount && params.getcare_promotion_discount.id) ? params.getcare_promotion_discount : null}
                    onChange={(e, newValue) => {
                      this.handleSaveField({target: { name: 'getcare_promotion_discount' } }, newValue);
                    }}
                    options={promotionDiscounts || []}
                    filterOptions={(x) => x}
                    renderOption={(option) => `${option.name}`}
                    getOptionLabel={(option) => (option && option.name) || ''}
                    getOptionSelected={(option, value) => (value && value.id && option) ? option.id === value.id : null}
                    renderInput={(params) => <TextField {...params} placeholder="-" />}
                  />
                  <div className={`${classes.FieldControl} ${classes.LabelAfter}`}>
                    <TextField
                      autoComplete="off"
                      className={classes.Field}
                      value={params.discount_amount || ''}
                      name="discount_amount"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      error={!this._hasDiscountAmount(params)}
                      onChange={(e) => {
                        this.handleSaveField(e, e.target.floatValue);
                      }}
                    />
                    <label>{` (${this._getUnitLabel()})`}</label>
                  </div>
                  { this._willShowMaximumAmount() && <div className={`${classes.FieldControl} ${classes.MaximumField}`}>
                      <label>Tối đa (VNĐ)</label>
                      <TextField
                        autoComplete="off"
                        value={params.maximum_amount || ''}
                        name="maximum_amount"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) => {
                          this.handleSaveField(e, e.target.floatValue);
                        }}
                      />
                    </div>
                  }
                </> }
              </div>
            ) : (
              this._getDiscountText()
            )}
          </div>
          <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
            {isEditing ? (
              <>
                <IconButton
                  onClick={this.handleCancel}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CancelOutlined fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  onClick={this.handleSave}
                  disabled={!this._isRowValid(params)}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={this._isRowValid(params) ? `primary` : `inherit`}
                  />
                </IconButton>
              </>
            ) : (
              <>
                { this._canBeRemoved() &&
                  <IconButton
                    onClick={this.handleRemove}
                    style={{ padding: '0' }}
                    size="small"
                  >
                    <DeleteOutline fontSize="small" />
                  </IconButton>
                }
                <IconButton
                  onClick={this.handleEdit}
                  style={{ padding: '0' }}
                  size="small"
                  disabled={readOnly}
                >
                  <EditOutlined fontSize="small" color={readOnly ? 'inherit' : "primary"} />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

PromotionRule.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.number,
  defaultOperator: PropTypes.object,
  savePromotionRule: PropTypes.func,
  removePromotionRule: PropTypes.func,
};

PromotionRule.defaultProps = {
  defaultOperator: null,
};

const mapStateToProps = createStructuredSelector({
  promotionOperators: makeSelectPromotionOperators(),
  promotionDiscounts: makeSelectPromotionDiscounts(),
  promotionBonuses: makeSelectPromotionBonuses(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(PromotionRule);
