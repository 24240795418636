import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { validDate } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';
import { format, max } from 'date-fns';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { currencyFormatVN } from 'utils/helper';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectOriVoucherDetails,
  makeSelectVoucherDetails,
  makeSelectVoucherVendorList,
} from 'redux/selectors';
import classes from './VoucherDetails.module.scss';
import {
  saveVoucherDetails,
  saveVoucherCustomerList,
  saveVoucherSaleChannelList,
  saveVoucherProductList,
  saveVoucherUnusedList,
  saveVoucherConfigGiftList,
  saveVoucherConfigMoneyList,
} from 'redux/actions/tmk/voucherAction';
import FieldEditable from 'components/FieldEditable/FieldEditable';
import Radio from '@material-ui/core/Radio';
import { Button } from '@material-ui/core';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { debounce } from 'lodash';
import promotionApi from 'utils/api/tmk/promotionApi';
import { toast } from 'react-toastify';
import commonApi from 'utils/api/tmk/commonApi';
import {
  STATUS_INACTIVE,
  APPLY_UNDEFINED,
  APPLY_AWAITING,
  STATUS_EMPTY,
  BASE_ON_PERIOD,
  BASE_ON_CAMPAIGN,
  voucherTypes,
  voucherDiscountUnits,
  voucherStatusMap,
  VOUCHER_DISCOUNT_UNIT_PERCENT,
  APPLY_FOR_DELIVERY_YES,
  APPLY_FOR_DELIVERY_NO,
  MAX_VALUE_UNCHECKED,
  MAX_VALUE_CHECKED,
  VOUCHER_TYPE_DISCOUNT,
  APPLY_IN_PROGRESS,
} from 'utils/constanst/tmkVoucherConstant';
import { ACTIVE, DEACTIVE } from 'utils/constanst/common';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { DISCOUNT_PERCENT } from 'utils/constanst/tmkPromotionConstants';

class VoucherDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isVendorEditing: false,
      isConfirmDialogOpen: false,
      vendorNameInput: '',
      isUploading: false,
      urlImage: '',
      params: {},
      groupVendorOptions: [],
      vendorOptions: this._initVendorOptions(),
      baseOnPeriod: props.voucherDetails
        ? props.voucherDetails.base_on
        : BASE_ON_PERIOD,
    };
  }
  componentDidMount() {
    this.initState();
    if (!this.props.isEditing) {
      this._initCreateForm();
    }
  }

  initState = () => {
    const { voucherDetails } = this.props;
    this.setState({
      baseOnPeriod: voucherDetails
        ? voucherDetails.base_on + ''
        : BASE_ON_PERIOD,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { voucherDetails, isEditing } = this.props;
    const { vendorNameInput } = this.state;
    this.initState();
    if (!voucherDetails && !isEditing) {
      this._initCreateForm();
    }

    if (vendorNameInput && vendorNameInput !== prevState.vendorNameInput) {
      this._loadSuggestedVendors({
        params: {
          name: vendorNameInput,
        },
      });
    }
  }

  _loadSuggestedVendors = debounce(async ({ params }) => {
    const { data: response } = await promotionApi.getVendorSuggestions({
      params: {
        ...params,
      },
    });
    if (!response?.result) return;
    this.setState({
      groupVendorOptions: response.data.vendors || [],
    });
  }, 500);

  _initVendorOptions = () => {
    const { voucherVendorList } = this.props;
    if (!voucherVendorList) return [];
    return voucherVendorList;
  };

  _getVendorNameOptionLabel = (option) => {
    if (option.name) return `${option.name}`;
  };

  _initCreateForm = () => {
    this.handleSaveField({
      start_date: new Date().toISOString(),
      has_max_value: false,
      base_on: BASE_ON_PERIOD,
      getcare_policy_unit_id: DISCOUNT_PERCENT,
      getcare_voucher_type_id: VOUCHER_TYPE_DISCOUNT,
      count_used_per_user: 1,
      count_used_total: 1,
    });
  };

  //end of init state for banner details.
  handleSaveField = (fieldMap) => {
    const params = {
      ...this.props.voucherDetails,
      ...fieldMap,
    };
    this.props.saveVoucherDetails(params);
  };

  handleChangeField = (e, value) => {
    if (!this.props.isEditing) {
      this.handleSaveField({ [e.target.name]: value });
    }
  };

  handleSelectedVendorChange = (e, newValue) => {
    this.setState({
      selectedVendor: {
        id: newValue.id,
        name: newValue.name,
      },
    });
  };

  _isInProgress = () => {
    return this._getActivityStatus() === APPLY_IN_PROGRESS && this._isActive();
  };

  handleCloseConfirmDialog = () => {
    this.setState({
      isConfirmDialogOpen: false,
    });
  };
  handleOpenConfirmDialog = () => {
    this.setState({
      isConfirmDialogOpen: true,
    });
  };

  handleToggleActive = () => {
    // if (this._isInProgress()) {
    //   this.handleOpenConfirmDialog();
    //   return;
    // }
    // if (this._isAwaitingProgress()) {
    //   this.props.onUpdateStatus({
    //     active: DEACTIVE,
    //   });
    //   return;
    // }
    // this.props.onUpdateStatus({
    //   active: ACTIVE,
    // });
    this.handleOpenConfirmDialog();
  };

  handleSubmitConfirmUpdateStatus = () => {
    this.props.onUpdateStatus({
      active: DEACTIVE,
    });
    this.handleCloseConfirmDialog();
  };

  _getVendorName = () => {
    const { voucherDetails, isChanged } = this.props;

    return isChanged
      ? this.state.vendorNameInput
      : voucherDetails?.getcare_vendor?.name || null;
  };

  _isActive = () => {
    return this.props.voucherDetails?.active === ACTIVE;
  };

  _isStoppedProgress = () => {
    return this._getActivityStatus() === STATUS_EMPTY;
  };
  _isAwaitingProgress = () => {
    return this._getActivityStatus() === APPLY_AWAITING;
  };

  _getSelectedType = () => {
    const { voucherDetails } = this.props;
    const applyId =
      voucherDetails?.getcare_voucher_type_id ||
      voucherDetails?.getcare_voucher_type?.id;
    return (
      (voucherDetails && voucherTypes.find((item) => item.id === applyId)) ||
      null
    );
  };

  _handleSelectVendorChange = (e, newValue) => {
    this.setState({
      params: {
        ...this.state.params,
        getcare_vendor: newValue,
      },
    });

    this.handleSaveField({ getcare_vendor_id: newValue.id });
  };

  _getVendorOptionLabel = (option) => {
    return `${option.name}`;
  };

  handleEdit = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isVendorEditing: true,
    });
  };

  handleCancel = (e) => {
    if (e) e.stopPropagation();
    this.setState({ isVendorEditing: false });

    const params = {
      ...this.props.oriVoucherDetails,
    };
    this.props.saveVoucherDetails(params);
  };

  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isVendorEditing: false,
    });
  };

  _handleMaxValue = (e) => {
    this.handleSaveField({
      [e.target.name]: e.target.checked
        ? MAX_VALUE_CHECKED
        : MAX_VALUE_UNCHECKED,
    });
  };

  _getSelectedDiscountUnit = () => {
    const { voucherDetails } = this.props;
    const applyId =
      voucherDetails?.getcare_policy_unit_id ||
      voucherDetails?.getcare_policy_unit?.id;
    return (
      (voucherDetails &&
        voucherDiscountUnits.find((item) => item.id === applyId)) ||
      null
    );
  };

  handleApplyForDelivery = (e) => {
    this.handleSaveField({
      [e.target.name]: e.target.checked
        ? APPLY_FOR_DELIVERY_YES
        : APPLY_FOR_DELIVERY_NO,
    });
  };

  submitFile = async (e) => {
    if (e.target.files.length !== 0) {
      const fileSize = e.target.files[0].size;
      if (fileSize >= 2197152) {
        toast.error('Dung lượng ảnh tối đa là 2MB');
      } else {
        await this.setState({
          currentFile: e.target.files[0],
          previewImage: URL.createObjectURL(e.target.files[0]),
        });
        this.uploadImage();
      }
    }
  };

  uploadImage = async () => {
    const formData = new FormData();
    formData.append('file', this.state.currentFile);
    try {
      this.setState({ isUploading: true });
      const response = await commonApi.uploadMedia(formData);
      const { url } = response.data.data;
      this.setState({ isUploading: false, urlImage: url });
      this.handleSaveField({ thumbnail: url });
    } catch (error) {
      toast.error(error.message);
    }
  };

  handleBaseOnChange = (e) => {
    this.setState({
      baseOnPeriod: e.target.value,
    });
    this.handleSaveField({ [e.target.name]: e.target.value });
  };

  _getActivityStatus = () => {
    const { voucherDetails } = this.props;
    if (!voucherDetails) return APPLY_AWAITING;
    if (
      voucherDetails.active === STATUS_INACTIVE ||
      voucherDetails.apply === APPLY_UNDEFINED
    )
      return STATUS_EMPTY;
    return voucherDetails.apply;
  };

  _isBasedOnPeriod = () => {
    return this.props.voucherDetails?.base_on === BASE_ON_PERIOD;
  };

  render() {
    const {
      isValid,
      voucherDetails,
      isEditing,
      isDuplicating,
      readOnly,
    } = this.props;

    const { groupVendorOptions, params, isVendorEditing } = this.state;
    const isBasedOnPeriod = this._isBasedOnPeriod();

    const minStartDate = isDuplicating ? null : new Date();
    const minEndDate =
      voucherDetails && voucherDetails.start_date
        ? isDuplicating
          ? new Date(voucherDetails.start_date)
          : max([new Date(voucherDetails.start_date), new Date()])
        : isDuplicating
        ? null
        : new Date();

    return (
      <div
        className={`${classes.Details} ${isEditing ? classes.IsEditing : ''}`}
      >
        <div className={classes.DetailsCol}>
          <div className={classes.BasicInfo}>
            <div className={classes.Left}>
              <div className={classes.DetailsRow}>
                <div className={`${classes.FieldControl}`}>
                  <label>Vendor</label>
                  {!isEditing || isVendorEditing ? (
                    <div style={{ display: 'flex', gap: 10 }}>
                      <Autocomplete
                        openOnFocus
                        selectOnFocus
                        disableClearable
                        fullWidth
                        size="small"
                        handleHomeEndKeys={false}
                        value={params.getcare_vendor}
                        onChange={this._handleSelectVendorChange}
                        options={groupVendorOptions || []}
                        filterOptions={(x) => x}
                        renderOption={(option) =>
                          this._getVendorOptionLabel(option)
                        }
                        getOptionLabel={(option) =>
                          (option && option.name) || ''
                        }
                        getOptionSelected={(option, value) =>
                          value && value.id && option
                            ? option.id === value.id
                            : null
                        }
                        onInputChange={(e, newInputValue) => {
                          this.setState({
                            vendorNameInput: newInputValue,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="- Chọn -" />
                        )}
                      />
                      {isEditing ? (
                        <div style={{ display: 'flex' }}>
                          {isVendorEditing ? (
                            <>
                              <IconButton
                                onClick={this.handleCancel}
                                style={{ padding: '0' }}
                                size="small"
                              >
                                <CancelOutlined
                                  fontSize="small"
                                  color="secondary"
                                />
                              </IconButton>
                              <IconButton
                                onClick={this.handleSave}
                                disabled={readOnly}
                                style={{ padding: '0' }}
                                size="small"
                              >
                                <CheckCircleOutlineOutlined
                                  fontSize="small"
                                  color="primary"
                                />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                disabled={readOnly}
                                onClick={this.handleEdit}
                                style={{ padding: '0' }}
                                size="small"
                              >
                                <EditOutlined
                                  fontSize="small"
                                  color={readOnly ? 'inherit' : 'primary'}
                                />
                              </IconButton>
                            </>
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    <div style={{ display: 'flex', gap: 10 }}>
                      <span>{this._getVendorName()}</span>
                      <IconButton
                        disabled={readOnly}
                        onClick={this.handleEdit}
                        style={{ padding: '0' }}
                        size="small"
                      >
                        <EditOutlined
                          fontSize="small"
                          color={readOnly ? 'inherit' : 'primary'}
                        />
                      </IconButton>
                    </div>
                  )}
                </div>
              </div>

              <div className={classes.DetailsRow}>
                <div className={classes.FieldControl}>
                  <label className={classes.Flex}>Voucher program ID</label>
                  {(isEditing || readOnly) && voucherDetails?.code}
                  {!readOnly && !isEditing && (
                    <FieldEditable
                      disabled={isEditing}
                      editModeOnly={!isEditing}
                      fieldName="code"
                      maxLength={11}
                      value={voucherDetails?.code || ''}
                      onSave={this.handleSaveField}
                      onChange={this.handleChangeField}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={`${classes.FieldControlWrap} ${classes.Note}`}>
              <div className={classes.FieldControl}>
                <label>Ghi chú</label>
                {readOnly ? (
                  voucherDetails?.note
                ) : (
                  <FieldEditable
                    error={isEditing ? false : !this.props.isHasNote}
                    editModeOnly={!isEditing}
                    fieldName="note"
                    fieldType="textarea"
                    type="textarea"
                    value={voucherDetails?.note}
                    displayedValue={voucherDetails?.note}
                    onSave={this.handleSaveField}
                    onChange={this.handleChangeField}
                  />
                )}
              </div>
            </div>
          </div>

          <div className={classes.DetailsRow}>
            <div
              className={`${classes.FieldControl} ${classes.VoucherProgramName}`}
            >
              <label className={classes.Flex}>
                Tên voucher program{' '}
                <span className={classes.RequiredMark}>*</span>{' '}
              </label>
              {readOnly && voucherDetails?.name}
              {!readOnly && (
                <FieldEditable
                  editModeOnly={!isEditing}
                  fieldName="name"
                  error={isEditing ? false : !this.props.isHasName}
                  maxLength={55}
                  value={voucherDetails?.name || ''}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                />
              )}
            </div>
          </div>

          <div
            className={`${classes.DetailsRow} ${
              this._getSelectedType()?.id === 2
                ? classes.customDetailRow_2
                : classes.customDetailRow_1
            }`}
          >
            <div className={classes.FieldControl}>
              <label>Loại voucher</label>
              {(isEditing || readOnly) && this._getSelectedType()?.name}
              {!isEditing && (
                <FieldEditable
                  disabled={isEditing}
                  error={isEditing ? false : !this.props.isHasType}
                  fieldType="select"
                  editModeOnly={!isEditing}
                  fieldName="getcare_voucher_type_id"
                  value={this._getSelectedType()?.id}
                  displayedValue={this._getSelectedType()?.name}
                  options={voucherTypes}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                />
              )}
            </div>
            {this._getSelectedType()?.id === 1 && (
              <>
                <div className={classes.FieldControl}>
                  <FormControlLabel
                    size="small"
                    disabled={readOnly || isEditing}
                    control={
                      <Checkbox
                        disabled={readOnly}
                        size="small"
                        name="apply_for_delivery"
                        checked={voucherDetails?.apply_for_delivery}
                        onChange={this.handleApplyForDelivery}
                      />
                    }
                    label="Áp dụng cho vận chuyển"
                  />
                </div>
                <div
                  className={`${classes.FieldControl} ${classes.DiscountValue}`}
                >
                  <label>Giảm giá</label>
                  {(readOnly || isEditing) && (
                    <span>
                      {this._getSelectedDiscountUnit === 1
                        ? currencyFormatVN(voucherDetails.value)
                        : voucherDetails.value}
                    </span>
                  )}
                  {!isEditing && (
                    <FieldEditable
                      editModeOnly={!isEditing}
                      fieldName="value"
                      error={
                        isEditing
                          ? false
                          : !this.props.isHasDiscountValue ||
                            !this.props.isDiscountValueValid
                      }
                      maxLength={55}
                      value={voucherDetails?.value || ''}
                      type="number"
                      onSave={this.handleSaveField}
                      onChange={this.handleChangeField}
                    />
                  )}
                  {(readOnly || isEditing) &&
                    this._getSelectedDiscountUnit()?.name}
                  {!isEditing && (
                    <div>
                      <FieldEditable
                        disableClearable
                        error={
                          isEditing ? false : !this.props.isHasDiscountUnit
                        }
                        fieldType="select"
                        editModeOnly={!isEditing}
                        fieldName="getcare_policy_unit_id"
                        defaultValue
                        value={this._getSelectedDiscountUnit()?.id}
                        displayedValue={this._getSelectedDiscountUnit()?.name}
                        options={voucherDiscountUnits}
                        onSave={this.handleSaveField}
                        onChange={this.handleChangeField}
                      />
                    </div>
                  )}
                </div>

                {this._getSelectedDiscountUnit()?.id ===
                VOUCHER_DISCOUNT_UNIT_PERCENT ? (
                  <>
                    <div className={classes.FieldControl}>
                      <FormControlLabel
                        size="small"
                        disabled={readOnly || isEditing}
                        control={
                          <Checkbox
                            disabled={readOnly || isEditing}
                            size="small"
                            name="has_max_value"
                            checked={voucherDetails?.has_max_value}
                            onChange={this._handleMaxValue}
                          />
                        }
                        label="Mức trần"
                      />
                    </div>
                    {(readOnly ||
                      (isEditing && voucherDetails?.has_max_value)) &&
                      currencyFormatVN(voucherDetails?.max_value)}
                    {!isEditing && voucherDetails?.has_max_value && (
                      <FieldEditable
                        editModeOnly={!isEditing}
                        fieldName="max_value"
                        error={isEditing ? false : !this.props.isHasMaxValue}
                        maxLength={55}
                        type="number"
                        value={voucherDetails?.max_value || ''}
                        onSave={this.handleSaveField}
                        onChange={this.handleChangeField}
                      />
                    )}
                  </>
                ) : (
                  ''
                )}
              </>
            )}
          </div>

          <div className={classes.DetailsRow}>
            <div className={classes.FieldControl}>
              <label className={classes.Flex}>
                Số lượng sản phẩm tối thiểu{' '}
              </label>
              {readOnly ? (
                voucherDetails?.min_order_item
              ) : (
                <FieldEditable
                  editModeOnly={!isEditing}
                  fieldName="min_order_item"
                  maxLength={55}
                  value={voucherDetails?.min_order_item || ''}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                />
              )}
            </div>

            <div className={classes.FieldControl}>
              <label className={classes.Flex}>Giá trị đơn hàng tối thiểu</label>
              {(readOnly || isEditing) &&
                currencyFormatVN(voucherDetails?.min_order_amount)}
              {!isEditing && (
                <FieldEditable
                  editModeOnly={!isEditing}
                  fieldName="min_order_amount"
                  type="number"
                  error={isEditing ? false : !this.props.isHasMinOrderAmount}
                  maxLength={55}
                  value={voucherDetails?.min_order_amount || ''}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                />
              )}
            </div>

            <div className={classes.FieldControl}>
              <label className={classes.Flex}>
                Số lượng voucher tối đa mỗi user{' '}
                <span className={classes.RequiredMark}>*</span>{' '}
              </label>
              {readOnly && voucherDetails?.count_used_per_user}
              {!readOnly && (
                <FieldEditable
                  editModeOnly={!isEditing}
                  fieldName="count_used_per_user"
                  error={isEditing ? false : !this.props.isHasMaxCountPerUser}
                  maxLength={55}
                  value={voucherDetails?.count_used_per_user || ''}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                />
              )}
            </div>
          </div>

          <div className={classes.DetailsRow}>
            <div className={classes.FieldControl}>
              <label className={classes.Flex}>
                Số lượng phát hành{' '}
                <span className={classes.RequiredMark}>*</span>{' '}
              </label>
              {readOnly && voucherDetails?.count_used_total}
              {!readOnly && (
                <FieldEditable
                  editModeOnly={!isEditing}
                  fieldName="count_used_total"
                  error={isEditing ? false : !this.props.isHasCountTotal}
                  maxLength={55}
                  value={voucherDetails?.count_used_total || ''}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                />
              )}
            </div>
          </div>

          <div className={classes.DetailsRowCustom}>
            <div className={classes.FieldControl}>
              <label className={classes.Flex}>
                Thumbnail{' '}
              </label>
              <Button
                size="small"
                className={classes.Upload}
                disabled={this.state.isUploading || readOnly}
                variant="contained"
                color="primary"
                component="label"
              >
                Upload Image
                <input
                  hidden
                  name="file"
                  accept="image/*"
                  type="file"
                  onChange={this.submitFile}
                />
              </Button>
            </div>

            <div className={`${classes.FieldControl}`}>
              {isEditing && (
                <a
                  className={`${classes.ImageLink}`}
                  target="_blank"
                  rel="noreferrer"
                  href={voucherDetails?.thumbnail || ''}
                >
                  {voucherDetails?.thumbnail || ''}
                </a>
              )}
              {!readOnly && !isEditing && (
                <FieldEditable
                  editModeOnly={!isEditing}
                  fieldName="image"
                  value={voucherDetails?.thumbnail || ''}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                />
              )}
            </div>
          </div>

          <RadioGroup
            className={classes.RadioGroupWrap}
            aria-label="Based on"
            name="base_on"
            value={this.state.baseOnPeriod}
            onChange={this.handleBaseOnChange}
          >
            <div className={`${classes.RadioGroupRow}`}>
              <FormControlLabel
                disabled={readOnly}
                value={BASE_ON_PERIOD}
                control={<Radio size="small" />}
                label="Base on period"
              />
              <div className={classes.FieldControl}>
                <label>
                  Ngày bắt đầu <span className={classes.RequiredMark}>*</span>
                </label>
                {isEditing && !this._isAwaitingProgress() ? (
                  validDate(voucherDetails?.start_date) ? (
                    format(validDate(voucherDetails?.start_date), dateFormat)
                  ) : (
                    ''
                  )
                ) : (
                  <FieldEditable
                    disabled={!isBasedOnPeriod}
                    fieldType="date"
                    editModeOnly={!isEditing}
                    displayedValue={
                      validDate(voucherDetails?.start_date)
                        ? format(
                            validDate(voucherDetails?.start_date),
                            dateFormat
                          )
                        : ''
                    }
                    error={isEditing ? false : !this.props.isStartDateValid}
                    minValue={minStartDate?.toISOString() || ''}
                    fieldName="start_date"
                    value={voucherDetails?.start_date || ''}
                    onSave={this.handleSaveField}
                    onChange={this.handleChangeField}
                  />
                )}
              </div>
              <div className={classes.FieldControl}>
                <label>Ngày kết thúc</label>
                {isEditing && this._isStoppedProgress() ? (
                  validDate(voucherDetails?.end_date) ? (
                    format(validDate(voucherDetails?.end_date), dateFormat)
                  ) : (
                    ''
                  )
                ) : (
                  <FieldEditable
                    disabled={!isBasedOnPeriod}
                    fieldType="date"
                    editModeOnly={!isEditing}
                    displayedValue={
                      validDate(voucherDetails?.end_date)
                        ? format(
                            validDate(voucherDetails?.end_date),
                            dateFormat
                          )
                        : ''
                    }
                    fieldName="end_date"
                    minValue={minEndDate?.toISOString() || ''}
                    value={voucherDetails?.end_date || ''}
                    onSave={this.handleSaveField}
                    onChange={this.handleChangeField}
                  />
                )}
              </div>
            </div>
            <div className={`${classes.RadioGroupRow}`}>
              <FormControlLabel
                disabled
                value={BASE_ON_CAMPAIGN}
                control={<Radio size="small" />}
                label="Base on campaign"
              />
            </div>
          </RadioGroup>
        </div>
        <div className="right">
          {isEditing && (
            <div className={classes.DetailsCol}>
              <div
                className={`${classes.FieldControlWrap} ${classes.StatusWrap}`}
              >
                <div className={`${classes.FieldControl}`}>
                  <label>Trạng thái</label>
                  {voucherStatusMap[voucherDetails?.active]}
                </div>
                {!this._isStoppedProgress() && (
                  <Button
                    disabled={!isValid}
                    variant="outlined"
                    size="small"
                    color="secondary"
                    onClick={this.handleToggleActive}
                  >
                    Inactive
                  </Button>
                )}
              </div>
              <div className={`${classes.FieldControl}`}>
                <label>Thời gian tạo</label>
                {validDate(voucherDetails?.created_at)
                  ? format(
                      validDate(voucherDetails?.created_at),
                      dateTimeFormat
                    )
                  : ''}
              </div>
              <div className={`${classes.FieldControl}`}>
                <label>Thời gian sửa</label>
                {validDate(voucherDetails?.updated_at)
                  ? format(
                      validDate(voucherDetails?.updated_at),
                      dateTimeFormat
                    )
                  : ''}
              </div>
              <div className={classes.FieldControl}>
                <label>Người sửa</label>
                {voucherDetails?.user_name}
              </div>
            </div>
          )}
        </div>
        {this.state.isConfirmDialogOpen && (
          <ConfirmationDialog
            isOpen={this.state.isConfirmDialogOpen}
            title={`Xác nhận dừng hoạt động ${this.props.voucherDetails?.code}`}
            message={
              <>
                <p>
                  Khi bạn xác nhận dừng hoạt động voucher{' '}
                  <strong>{this.props.voucherDetails?.code}</strong>, voucher
                  này sẽ mất vài phút để dừng hoàn toàn. Những đơn hàng trong
                  khoảng thời gian đó có thể vẫn được voucher này.
                </p>
                <p>Xin xác nhận hoặc huỷ bỏ hành động này</p>
              </>
            }
            onClose={this.handleCloseConfirmDialog}
            onSubmit={this.handleSubmitConfirmUpdateStatus}
          />
        )}
      </div>
    );
  }
}
VoucherDetails.propTypes = {
  isEditing: PropTypes.bool,
  isValid: PropTypes.bool,
};

VoucherDetails.defaultProps = {
  isEditing: false,
  isValid: true,
};
const mapStateToProps = createStructuredSelector({
  voucherDetails: makeSelectVoucherDetails(),
  oriVoucherDetails: makeSelectOriVoucherDetails(),
  voucherVendorList: makeSelectVoucherVendorList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveVoucherDetails: (payload) => dispatch(saveVoucherDetails(payload)),
    saveVoucherCustomerList: (payload) =>
      dispatch(saveVoucherCustomerList(payload)),
    saveVoucherSaleChannelList: (payload) =>
      dispatch(saveVoucherSaleChannelList(payload)),
    saveVoucherProductList: (payload) =>
      dispatch(saveVoucherProductList(payload)),
    saveVoucherUnusedList: (payload) =>
      dispatch(saveVoucherUnusedList(payload)),
    saveVoucherConfigGiftList: (payload) =>
      dispatch(saveVoucherConfigGiftList(payload)),
    saveVoucherConfigMoneyList: (payload) =>
      dispatch(saveVoucherConfigMoneyList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(VoucherDetails);
