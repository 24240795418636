export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  active: [1],
};

export const BASE_ON_PERIOD = '1';
export const BASE_ON_CAMPAIGN = '2';

export const VOUCHER_CUSTOMER_TYPE = 1;
export const VOUCHER_GROUP_CUSTOMER_TYPE = 2;
export const customerGroupTypes = [
  {
    id: VOUCHER_CUSTOMER_TYPE,
    name: 'Khách hàng',
  },
  {
    id: VOUCHER_GROUP_CUSTOMER_TYPE,
    name: 'Nhóm',
  },
];
export const MIN_VALUE_CHECKED = true;
export const MIN_VALUE_UNCHECKED = false;

export const MAX_VALUE_CHECKED = true;
export const MAX_VALUE_UNCHECKED = false;

export const STATUS_ACTIVE = 1;
export const STATUS_INACTIVE = -1;
export const STATUS_EMPTY = 0;

export const APPLY_FOR_DELIVERY_YES = true;
export const APPLY_FOR_DELIVERY_NO = false;

export const APPLY_AWAITING = -1;
export const APPLY_IN_PROGRESS = 1;
export const APPLY_UNDEFINED = 0;

export const voucherStatusMap = {
  [STATUS_ACTIVE]: 'Hoạt động',
  [STATUS_INACTIVE]: 'Dừng hoạt động',
};

export const voucherStatuses = [
  {
    id: STATUS_ACTIVE,
    name: voucherStatusMap[STATUS_ACTIVE],
  },
  {
    id: STATUS_INACTIVE,
    name: voucherStatusMap[STATUS_INACTIVE],
  },
];

export const VOUCHER_TYPE_DISCOUNT = 1;
export const VOUCHER_TYPE_MONEY = 2;
export const VOUCHER_TYPE_GIFT = 3;

export const voucherTypeMap = {
  [VOUCHER_TYPE_DISCOUNT]: 'Giảm giá',
  [VOUCHER_TYPE_MONEY]: 'Tiền',
  [VOUCHER_TYPE_GIFT]: 'Quà tặng',
};

export const voucherTypes = [
  {
    id: VOUCHER_TYPE_DISCOUNT,
    name: voucherTypeMap[VOUCHER_TYPE_DISCOUNT],
  },
  {
    id: VOUCHER_TYPE_MONEY,
    name: voucherTypeMap[VOUCHER_TYPE_MONEY],
  },
  {
    id: VOUCHER_TYPE_GIFT,
    name: voucherTypeMap[VOUCHER_TYPE_GIFT],
  },
];

export const VOUCHER_DISCOUNT_UNIT_VND = 1;
export const VOUCHER_DISCOUNT_UNIT_PERCENT = 2;

export const voucherDiscountUnitMap = {
  [VOUCHER_DISCOUNT_UNIT_PERCENT]: '%',
  [VOUCHER_DISCOUNT_UNIT_VND]: 'VNĐ',
};

export const voucherDiscountUnits = [
  {
    id: VOUCHER_DISCOUNT_UNIT_PERCENT,
    name: voucherDiscountUnitMap[VOUCHER_DISCOUNT_UNIT_PERCENT],
  },
  {
    id: VOUCHER_DISCOUNT_UNIT_VND,
    name: voucherDiscountUnitMap[VOUCHER_DISCOUNT_UNIT_VND],
  },
];

export const filterFields = [
  {
    name: 'active',
    label: 'Tình trạng',
    value: [STATUS_ACTIVE],
    type: 'custom',
    placeholder: '- Chọn -',
  },
  {
    name: 'getcare_vendor_name',
    label: 'Vendor',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'code',
    label: 'Voucher program ID',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'name',
    label: 'Tên voucher program',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_voucher_type_id',
    label: 'Loại voucher',
    value: '',
    type: 'custom',
    placeholder: 'Nhập...',
  },
  {
    name: 'start_date',
    label: 'Ngày bắt đầu',
    value: '',
    type: 'date',
    placeholder: 'Nhập hoặc chọn...',
  },
  {
    name: 'end_date',
    label: 'Ngày kết thúc',
    value: '',
    type: 'date',
    placeholder: 'Nhập hoặc chọn...',
  },
];

export const listDisplayFields = [
  {
    name: 'vendor',
    label: 'Vendor',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'voucher_program',
    label: 'Voucher program ID',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'voucher_program_name',
    label: 'Tên Voucher program',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'description',
    label: 'Mô tả',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_banner_type_id',
    label: 'Loại voucher',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'start_date',
    label: 'Ngày bắt đầu',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'end_date',
    label: 'Ngày kết thúc',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'campaign',
    label: 'Campaign',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'active',
    label: 'Trạng thái',
    sortable: true,
    sortDir: '',
  },
];

export const listTabVoucherConfigDisplayFields = [
  {
    name: 'gcid',
    label: 'GCID',
  },
  {
    name: 'voucher_product_name',
    label: 'Tên sản phẩm',
  },
  {
    name: 'is_apply',
    label: 'Số lượng giới hạn',
  },
];

export const listTabVoucherConfigMoneyDisplayFields = [
  {
    name: 'voucher_product_limit',
    label: 'Mệnh giá',
  },
  {
    name: 'is_apply',
    label: 'Số lượng giới hạn',
  },
];

export const listTabProductDisplayFields = [
  {
    name: 'voucher_product_id',
    label: 'Mã sản phẩm',
  },
  {
    name: 'voucher_product_name',
    label: 'Tên sản phẩm',
  },
  {
    name: 'voucher_product_umo',
    label: 'UOM',
  },
  {
    name: 'minimum_amount',
    label: 'Số lượng tối thiểu',
  },
];

export const listTabCustomerDisplayFields = [
  { name: 'voucher_customer_group', label: 'Nhóm' },
  {
    name: 'voucher_customer_id',
    label: 'Mã (nhóm) khách hàng',
  },
  {
    name: 'name',
    label: 'Tên (nhóm) khách hàng',
  },
];

export const listTabSaleChannelDisplayFields = [
  {
    name: 'voucher_salechannel_id',
    label: 'Khu vực bán hàng',
  },
  {
    name: 'voucher_salechannel_quantity',
    label: 'Số lượng khách hàng',
  },
];

export const listTabVoucherUnused = [
  {
    name: 'voucher_unused_id',
    label: 'Voucher code',
  },
];
