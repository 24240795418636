import React, { PureComponent } from 'react';
import classes from './BannerImage.module.scss';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectBannerDetails } from 'redux/selectors';
import { saveBannerDetails } from 'redux/actions/tmk/bannerActions';
import bannerApi from 'utils/api/tmk/bannerApi';
import { toast } from 'react-toastify';
import { IMAGE_TYPE, VIDEO_TYPE } from 'utils/constanst/tmkBannerConstants';
import { CircularProgress } from '@material-ui/core';
class BannerImage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isHadUploadVideoAction: false,
      isUploading: false,
      fileName: undefined,
      fileSize: undefined,
      currentFile: undefined,
      previewImage: undefined,
      imageWidth: 0,
      imageHeight: 0,
    };
  }

  handleClearBanner = () => {
    this.setState({
      isUploading: false,
      fileName: undefined,
      fileSize: undefined,
      currentFile: undefined,
      previewImage: undefined,
    });
    this.props.handleSaveField({ ['url']: '' });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.fileType &&
      prevProps.fileType &&
      this.props.fileType !== prevProps.fileType
    ) {
      this.setState({
        ...prevState,
        currentFile: undefined,
        previewImage: undefined,
      });
      this.handleClearBanner();
    }
  }

  uploadVideo = async () => {
    const { bannerDetails } = this.props;
    const formData = new FormData();
    formData.append('file', this.state.currentFile);
    try {
      this.setState({ isUploading: true });
      const response = await bannerApi.uploadBanner(formData);
      const { url, filename, size } = response.data.data;
      this.setState({
        isHadUploadVideoAction: true,
        isUploading: false,
        fileName: filename,
        fileSize: Math.round(size / (1024 * 1024)),
      });
      this.props.saveBannerDetails({ ...bannerDetails, url });
    } catch (error) {
      toast.error(error.message);
    }
  };

  uploadImage = async () => {
    const { bannerDetails } = this.props;
    const formData = new FormData();
    formData.append('file', this.state.currentFile);
    try {
      this.setState({ isUploading: true });
      const response = await bannerApi.uploadBanner(formData);
      const { url } = response.data.data;
      this.setState({ isUploading: false });
      this.props.saveBannerDetails({ ...bannerDetails, url });
    } catch (error) {
      toast.error(error.message);
    }
  };

  submitFile = async (e) => {
    const { fileType } = this.props;
    if (e.target.files.length !== 0) {
      const fileSize = e.target.files[0].size;
      if (fileType === IMAGE_TYPE) {
        if (fileSize >= 2197152) {
          toast.error('Dung lượng ảnh tối đa là 2MB');
        } else {
          await this.setState({
            currentFile: e.target.files[0],
            previewImage: URL.createObjectURL(e.target.files[0]),
          });
          this.uploadImage();
        }
      }
      if (fileType === VIDEO_TYPE) {
        if (fileSize >= 104857600) {
          toast.error('Dung lượng video tối đa là 100MB');
        } else {
        }
        await this.setState({
          currentFile: e.target.files[0],
        });
        this.uploadVideo();
      }
    }
  };

  render() {
    const { bannerDetails, fileType, readOnly } = this.props;
    return (
      <div className={classes.BannerImage}>
        {
          <div className={classes.Action}>
            <Button
              className={classes.Upload}
              disabled={!fileType || this.state.isUploading || readOnly}
              variant="contained"
              color="primary"
              component="label"
            >
              Tải {fileType === 1 ? 'ảnh' : 'video'} lên
              <input
                hidden
                name="file"
                accept={fileType === IMAGE_TYPE ? 'image/*' : 'video/*'}
                type="file"
                onChange={this.submitFile}
              />
            </Button>
            {bannerDetails?.url && (
              <Button
                disabled={readOnly}
                variant="contained"
                color="secondary"
                size="small"
                onClick={this.handleClearBanner}
                className={classes.Clear}
              >
                x
              </Button>
            )}
            {this.state.isUploading && (
              <CircularProgress className={classes.Loading} />
            )}
          </div>
        }
        {!bannerDetails?.url && fileType === IMAGE_TYPE && <p>No image</p>}
        {bannerDetails?.url && fileType === IMAGE_TYPE && (
          <div style={{backgroundImage: `url(${bannerDetails?.url})`}} className={classes.BannerWrapper}>
          </div>
        )}
        {bannerDetails?.url &&
          fileType === VIDEO_TYPE &&
          this.state.isHadUploadVideoAction && (
            <div className={classes.VideoInfo}>
              <div>
                <label>Tên file:</label> {this.state.fileName}
              </div>
              <div>
                <label>Dung lượng file:</label> {this.state.fileSize}MB
              </div>
            </div>
          )}
        {bannerDetails?.url && fileType === VIDEO_TYPE && (
          <video width="640" height="480" controls src={bannerDetails?.url}>
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  bannerDetails: makeSelectBannerDetails(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveBannerDetails: (payload) => dispatch(saveBannerDetails(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(BannerImage);
