import React, { PureComponent } from 'react';
import classes from './BannerSaleChannelItem.module.scss';
import gridClasses from '../BannerBannerSaleChannelListGrid.module.scss';
import { Checkbox } from '@material-ui/core';
import { debounce } from 'lodash';

import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import bannerApi from 'utils/api/tmk/bannerApi';
import { toast } from 'react-toastify';

export default class BannerSaleChannelItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: props.isEditing,
      params: this._getInitParams(props),
      bannerSaleChannelOptions: this._initbannerSaleChannelOptions(props),
      saleChannelCodeInput: '',
    };
  }

  _getInitParams = (params) => {
    return {
      getcare_sales_channel: null,
      idStr: params.idStr ? params.idStr : null,
      id: params.id ? params.id : null,
      code: params.code ? params.code : null,
      count: params.count,
    };
  };

  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
      bannerSaleChannelOptions: this._initbannerSaleChannelOptions(this.props),
      saleChannelCodeInput: '',
    });
  };

  _hadSaleChannel = (params) => {
    return !!params?.id && !!params.getcare_sales_channel?.id;
  };

  _isRowValid = (params) => {
    return this._hadSaleChannel(params);
  };

  async componentDidUpdate(prevProps, prevState) {
    const { saleChannelCodeInput, params, isEditing } = this.state;
    if (
      saleChannelCodeInput &&
      saleChannelCodeInput !== prevState.saleChannelCodeInput
    ) {
      this._loadSuggestedSaleChannel({
        params: {
          code: saleChannelCodeInput,
        },
      });
    }

    if (isEditing !== prevState.isEditing && this._isRowValid(params)) {
      const { currentList } = this.props;
      const isDuplicated = currentList.findIndex((p) => {
        return p.id === params.id && p.code === params.code;
      });
      if (isDuplicated > -1) {
        toast.error('Khu vực bị trùng');
        this.props.removeSaleChannelItem({
          id: this.props.id,
          idStr: this.props.idStr,
        });
      } else {
        this.props.saveSaleChannelItem({
          id: params.id,
          code: params.code,
          idStr: params.idStr,
          count: params.count,
          getcare_sales_channel: { ...params },
          isEditing,
        });
      }
    }
  }

  _loadSuggestedSaleChannel = debounce(async ({ params }) => {
    const { data: response } = await bannerApi.findSaleChannel({
      params: {
        ...params,
      },
    });
    if (!response?.result) return;
    const searchedResults = response.data.map((item) => {
      const { id, count, code } = item;
      return {
        id,
        count,
        code,
      };
    });
    this.setState({
      bannerSaleChannelOptions:
        searchedResults.length > 0 ? searchedResults : [],
    });
  }, 500);

  _getSaleChannelOptionLabel = (option) => {
    if (option.code) return `${option.code}`;
  };

  _initbannerSaleChannelOptions = (params) => {
    return params.currentList ? params.currentList : [];
  };

  handleEdit = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  };

  _hasSaleChannel = (params) => {
    return !!params?.id && !!params.getcare_sales_channel?.id;
  };

  _isRowValid = (params) => {
    return this._hasSaleChannel(params);
  };

  handleCancel = (e) => {
    if (e) e.stopPropagation();
    if (this._isRowValid(this.props) || !this.state.isEditing) {
      this._resetState();
      return;
    }
    this.props.removeSaleChannelItem({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  };

  handleRemove = (e) => {
    if (e) e.stopPropagation();
    this.props.removeSaleChannelItem({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  };
  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: false,
    });
  };

  handleCheckbox = () => {
    this.setState((prevState) => {
      return {
        isChecked: !prevState.isChecked,
      };
    });
  };

  handleSelectedSaleChannelChange = (e, newValue) => {
    this.setState({
      params: {
        ...this.state.params,
        id: newValue.id || null,
        code: newValue.code,
        count: newValue.count,
        getcare_sales_channel: newValue,
      },
    });
  };

  render() {
    const { readOnly } = this.props;
    const { bannerSaleChannelOptions, params, isEditing } = this.state;

    return (
      <div className={`${classes.Item} ${gridClasses.Row}`}>
        <div className={`${gridClasses.Col} ${classes.CodeCol}`}>
          <Checkbox
            size="small"
            name="bonus_mode"
            checked={this.state.isChecked}
            onChange={this.handleCheckbox}
          />
        </div>

        <div className={gridClasses.Col}>
          {' '}
          {isEditing ? (
            <Autocomplete
              openOnFocus
              selectOnFocus
              disableClearable
              fullWidth
              size="small"
              handleHomeEndKeys={false}
              value={params.code}
              onChange={this.handleSelectedSaleChannelChange}
              options={bannerSaleChannelOptions || []}
              filterOptions={(x) => x}
              renderOption={(option) => this._getSaleChannelOptionLabel(option)}
              onInputChange={(e, newInputValue) => {
                this.setState({
                  saleChannelCodeInput: newInputValue,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="- Chọn -" />
              )}
            />
          ) : (
            params.code
          )}
        </div>
        <div className={gridClasses.Col}>{params.count}</div>
        <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
          {isEditing ? (
            <>
              <IconButton
                onClick={this.handleCancel}
                style={{ padding: '0' }}
                size="small"
              >
                <CancelOutlined fontSize="small" color="secondary" />
              </IconButton>
              <IconButton
                onClick={this.handleSave}
                disabled={!this._isRowValid(params)}
                style={{ padding: '0' }}
                size="small"
              >
                <CheckCircleOutlineOutlined
                  fontSize="small"
                  color={this._isRowValid(params) ? `primary` : `inherit`}
                />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                disabled={readOnly}
                onClick={this.handleRemove}
                style={{ padding: '0' }}
                size="small"
              >
                <DeleteOutline
                  fontSize="small"
                  color={readOnly ? 'inherit' : 'action'}
                />
              </IconButton>
              <IconButton
                disabled={readOnly}
                onClick={this.handleEdit}
                style={{ padding: '0' }}
                size="small"
              >
                <EditOutlined
                  fontSize="small"
                  color={readOnly ? 'inherit' : 'primary'}
                />
              </IconButton>
            </>
          )}
        </div>
      </div>
    );
  }
}
