import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPromotionMaxProducts,
  makeSelectOriPromotionMaxProducts,
  makeSelectPromotionProducts,
} from 'redux/selectors';
import {
  savePromotionMaxProductItem,
  removePromotionMaxProductItems,
} from 'redux/actions/tmk/promotionActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  promotionMaxProductsDisplayFields,
  promotionMaxProductsParamsMap,
} from 'utils/constanst/tmkPromotionConstants';

import PromotionMaxProduct from './PromotionMaxProduct/PromotionMaxProduct';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';

import gridClasses from './PromotionMaxProductsGrid.module.scss';
import classes from './PromotionMaxProducts.module.scss';

class PromotionMaxProducts extends PureComponent {
  handleCreateNewRow = () => {
    this.props.savePromotionMaxProductItem({
      idStr: genID(),
      id: 0,
      getcare_product: null,
      quantity_number: null,
      isEditing: true,
    });
  };
  handleSavePromotionMaxProduct = (params) => {
    this.props.savePromotionMaxProductItem({
      ...params,
    });
  };
  handleRemovePromotionMaxProduct = (params) => {
    this.props.removePromotionMaxProductItems([{ ...params }]);
  };
  handleRemovePromotionMaxProducts = () => {
    this.props.removePromotionMaxProductItems([...this.state.selectedItems]);
  };

  render() {
    const { promotionMaxProducts, promotionProducts, readOnly } = this.props;
    const displayFields = getDisplayFields(
      promotionMaxProductsParamsMap,
      promotionMaxProductsDisplayFields
    );

    return (
      <>
        <div className={`${classes.ActionContainer}`}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            disabled={readOnly}
            onClick={this.handleCreateNewRow}
          >
            Thêm dòng
          </Button>
        </div>
        <div className={`${classes.List}`}>
        <div className={`${classes.Header}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
              {displayFields.map((item) => (
                <ListHeaderCol
                  key={item.name}
                  className={`${gridClasses.Col} ${gridClasses[item.className]}`}
                  {...item}
                />
              ))}
            </div>
            </div>
          <div className={`${classes.Body}`}>    
            {promotionMaxProducts.length ? (
              promotionMaxProducts.map((item) => (
                <PromotionMaxProduct
                  key={`promotion-max-product-${item.id || item.idStr}`}
                  {...item}
                  readOnly={readOnly}
                  currentList={[...promotionMaxProducts]}
                  promotionProducts={[...promotionProducts]}
                  savePromotionMaxProduct={this.handleSavePromotionMaxProduct}
                  removePromotionMaxProduct={this.handleRemovePromotionMaxProduct}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </>
    );
  }
}

PromotionMaxProducts.propTypes = {
  promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEditing: PropTypes.bool,
};

PromotionMaxProducts.defaultProps = {
  isEditing: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    savePromotionMaxProductItem: (payload) =>
      dispatch(savePromotionMaxProductItem(payload)),
    removePromotionMaxProductItems: (payload) =>
      dispatch(removePromotionMaxProductItems(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  promotionMaxProducts: makeSelectPromotionMaxProducts(),
  oriPromotionMaxProducts: makeSelectOriPromotionMaxProducts(),
  promotionProducts: makeSelectPromotionProducts(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PromotionMaxProducts);
