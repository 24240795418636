import React, { PureComponent } from 'react';
import classes from './VoucherProductItem.module.scss';
import gridClasses from '../VoucherProductGrid.module.scss';
import { Checkbox } from '@material-ui/core';
import { debounce, isEqual } from 'lodash';
import { validNumber } from 'utils/helper';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';
import voucherApi from 'utils/api/tmk/voucherApi';
import { toast } from 'react-toastify';
export default class VoucherProductItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: props.isChecked,
      isEditing: props.isEditing,
      params: this._getInitParams(props),
      groupProductOptions: this._initgroupProductOptions(props),
      groupProductCodeInput: '',
      groupProductNameInput: '',
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      groupProductCodeInput,
      groupProductNameInput,
      params,
      isChecked,
      isEditing,
    } = this.state;
    const { vendorId } = this.props;
    if (
      groupProductCodeInput &&
      groupProductCodeInput !== prevState.groupProductCodeInput
    ) {
      this._loadSuggestedProducts({
        params: {
          getcare_vendor_id: vendorId,
          code: groupProductCodeInput,
        },
      });
    }
    if (
      groupProductNameInput &&
      groupProductNameInput !== prevState.groupProductNameInput
    ) {
      this._loadSuggestedProducts({
        params: {
          getcare_vendor_id: vendorId,
          name: groupProductNameInput,
        },
      });
    }
    if (isEditing !== prevState.isEditing && this._isRowValid(params)) {
        this.props.saveVoucherProductItem({
          ...params,
          idStr: params.idStr,
          isEditing,
        });
    }
    if (prevState.isChecked !== isChecked) {
      this.props.selectVoucherProductItem({
        id: this.props.id,
        idStr: this.props.idStr,
      });
    }
  }
  _getInitParams = (params) => {
    return {
      getcare_product: params.getcare_product || null,
      idStr: params?.idStr,
      id: params?.id,
      getcare_id: params.getcare_product.getcare_id
        ? params.getcare_product.getcare_id
        : null,
      name: params.getcare_product.name ? params.getcare_product.name : null,
      min_quantity: params.min_quantity,
      getcare_uom_base: params.getcare_uom_base,
    };
  };

  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
      groupProductOptions: this._initgroupProductOptions(this.props),
      customerCodeInput: '',
      customerNameInput: '',
    });
  };

  _initgroupProductOptions = (params) => {
    return params.currentList ? params.currentList : [];
  };

  _loadSuggestedProducts = debounce(async ({ params }) => {
    const { data: response } = await voucherApi.getProductSuggestions({
      params: {
        ...params,
      },
    });
    if (!response?.result) return;
    this.setState({
      groupProductOptions: this.filteredOptions(response.data) || [],
    });
  }, 500);

  filteredOptions = (fullArr) => {
    return fullArr.filter((p) => {
      return (
        p.getcare_id === this.props.getcare_id ||
        !this.props.currentList.some(
          (item) => item.getcare_product?.getcare_id === p.getcare_id
        )
      );
    });
  };

  _hasProduct = (params) => {
    return !!params?.name && !!params?.getcare_id;
  };

  _isRowValid = (params) => {
    return this._hasProduct(params) && this._hasQuantityNumber(params);
  };

  _hasQuantityNumber = (params) => {
    return !!params.min_quantity && Number(params.min_quantity) > 0;
  };

  handleCheckbox = () => {
    this.setState((prevState) => {
      return {
        isChecked: !prevState.isChecked,
      };
    });
  };

  handleRowClick = () => {
    if (this.props.readOnly) {
      return;
    }

    if (!this.state.isEditing) {
      this.handleEdit();
    }
  };

  handleEdit = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  };

  handleCancel = (e) => {
    if (e) e.stopPropagation();
    if (this._isRowValid(this.props) || !this.state.isEditing) {
      this._resetState();
      return;
    }
    this.props.removeVoucherProductItem({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  };

  handleRemove = (e) => {
    if (e) e.stopPropagation();
    this.props.removeVoucherProductItem({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  };

  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: false,
    });
  };

  handleSelectedProductChange = (e, newValue) => {
    const { params } = this.state;
    this.setState({
      params: {
        ...params,
        getcare_id: newValue.getcare_id,
        name: newValue.name,
        min_quantity: 0,
        getcare_uom_base: newValue.getcare_uom.uom_base,
        getcare_product: newValue,
      },
    });
  };

  _getProductOptionLabel = (option) => {
    if ((option.code || option.getcare_id) && option.name) {
      return `${option.code || option.getcare_id} - ${option.name}`;
    } else return '';
  };

  handleSaveField = (e, value) => {
    const parsedValue = parseInt(value);
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]:
          e.target.type === 'number' ? validNumber(parsedValue) : parsedValue,
      },
    });
  };

  render() {
    const { isChecked, readOnly } = this.props;
    const { groupProductOptions, params, isEditing } = this.state;
    return (
      <div className={`${classes.Item} ${gridClasses.Row}`}>
        <div className={`${gridClasses.Col} ${classes.CodeCol}`}>
          <Checkbox
            size="small"
            name="isChecked"
            checked={isChecked}
            onChange={this.handleCheckbox}
          />
        </div>
        <div className={`${gridClasses.Col}`}>
          {isEditing ? (
            <Autocomplete
              openOnFocus
              selectOnFocus
              disableClearable
              fullWidth
              size="small"
              handleHomeEndKeys={false}
              value={params.getcare_product}
              onChange={this.handleSelectedProductChange}
              options={groupProductOptions || []}
              filterOptions={(x) => x}
              renderOption={(option) => this._getProductOptionLabel(option)}
              getOptionLabel={(option) => (option && option.getcare_id) || ''}
              getOptionSelected={(option, value) =>
                value && value.id && option ? option.id === value.id : null
              }
              onInputChange={(e, newInputValue) => {
                this.setState({
                  groupProductCodeInput: newInputValue,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="- Chọn -" />
              )}
            />
          ) : (
            params?.getcare_id
          )}
        </div>
        <div className={`${gridClasses.Col}`}>
          {isEditing ? (
            <Autocomplete
              openOnFocus
              selectOnFocus
              disableClearable
              fullWidth
              size="small"
              handleHomeEndKeys={false}
              value={params.getcare_product}
              onChange={this.handleSelectedProductChange}
              options={groupProductOptions || []}
              filterOptions={(x) => x}
              renderOption={(option) => this._getProductOptionLabel(option)}
              getOptionLabel={(option) => (option && option.name) || ''}
              getOptionSelected={(option, value) =>
                value && value.id && option ? option.id === value.id : null
              }
              onInputChange={(e, newInputValue) => {
                this.setState({
                  groupProductNameInput: newInputValue,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="- Chọn -" />
              )}
            />
          ) : (
            params.name
          )}
        </div>
        <div className={`${gridClasses.Col}`}>
          {params?.getcare_uom_base?.name || ''}
        </div>
        <div className={`${gridClasses.Col}`}>
          {isEditing ? (
            <TextField
              className={classes.Field}
              value={params.min_quantity || 0}
              placeholder="Nhập..."
              name="min_quantity"
              autoComplete="off"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              error={!this._hasQuantityNumber(params)}
              onChange={(e) => {
                this.handleSaveField(e, e.target.value);
              }}
            />
          ) : (
            `${params.min_quantity || 0}`
          )}
        </div>
        <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
          {isEditing ? (
            <>
              <IconButton
                onClick={this.handleCancel}
                style={{ padding: '0' }}
                size="small"
              >
                <CancelOutlined fontSize="small" color="secondary" />
              </IconButton>
              <IconButton
                onClick={this.handleSave}
                disabled={!this._isRowValid(params)}
                style={{ padding: '0' }}
                size="small"
              >
                <CheckCircleOutlineOutlined
                  fontSize="small"
                  color={this._isRowValid(params) ? `primary` : `inherit`}
                />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                disabled={readOnly}
                onClick={this.handleEdit}
                style={{ padding: '0' }}
                size="small"
              >
                <EditOutlined
                  fontSize="small"
                  color={readOnly ? 'inherit' : 'primary'}
                />
              </IconButton>
            </>
          )}
        </div>
      </div>
    );
  }
}
