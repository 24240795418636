export const SAVE_LOADING = 'getcare/tmk/VOUCHER/SAVE_LOADING';

export const GET_VOUCHER_LIST = 'getcare/tmk/VOUCHER/GET_VOUCHER_LIST';
export const SAVE_VOUCHER_LIST = 'getcare/tmk/VOUCHER/SAVE_VOUCHER_LIST';
export const SAVE_VOUCHER_LIST_TOTAL = 'getcare/tmk/VOUCHER/SAVE_VOUCHER_LIST_TOTAL';
export const SAVE_VOUCHER_LIST_QUERY_STRING = 'getcare/tmk/VOUCHER/SAVE_VOUCHER_LIST_QUERY_STRING';
export const RESET_VOUCHER = 'getcare/tmk/VOUCHER/RESET_VOUCHER';

export const GET_USED_VOUCHER_LIST = 'getcare/tmk/VOUCHER/GET_USED_VOUCHER_LIST';
export const SAVE_USED_VOUCHER_LIST = 'getcare/tmk/VOUCHER/SAVE_USED_VOUCHER_LIST';
export const SAVE_USED_VOUCHER_LIST_TOTAL = 'getcare/tmk/VOUCHER/SAVE_USED_VOUCHER_LIST_TOTAL';
export const SAVE_USED_VOUCHER_LIST_QUERY_STRING = 'getcare/tmk/VOUCHER/SAVE_USED_VOUCHER_LIST_QUERY_STRING';

export const GET_VOUCHER_TYPE = 'getcare/tmk/VOUCHER/GET_VOUCHER_TYPE'
export const GET_VOUCHER_VENDOR_LIST = 'getcare/tmk/VOUCHER/GET_VOUCHER_VENDOR_LIST';

export const GET_VOUCHER = 'getcare/tmk/VOUCHER/GET_VOUCHER';
export const UPDATE_VOUCHER = 'getcare/tmk/VOUCHER/UPDATE_VOUCHER';
export const CREATE_VOUCHER = 'getcare/tmk/VOUCHER/CREATE_VOUCHER';
export const SAVE_VOUCHER_DETAILS = 'getcare/tmk/VOUCHER/SAVE_VOUCHER_DETAILS';
export const SAVE_ORI_VOUCHER_DETAILS = 'getcare/tmk/VOUCHER/SAVE_ORI_VOUCHER_DETAILS'

export const SAVE_VOUCHER_TYPE = 'getcare/tmk/VOUCHER/SAVE_VOUCHER_TYPE';
export const SAVE_VOUCHER_VENDOR_LIST = 'getcare/tmk/VOUCHER/SAVE_VOUCHER_VENDOR_LIST';

export const SAVE_VOUCHER_UNUSED_LIST = 'getcare/tmk/VOUCHER/SAVE_VOUCHER_UNUSED_LIST';
export const EXPORT_VOUCHER_UNUSED_LIST = 'getcare/tmk/VOUCHER/EXPORT_VOUCHER_UNUSED_LIST';
export const EXPORT_VOUCHER_GIFTCODES_UNUSED_LIST = 'getcare/tmk/VOUCHER/EXPORT_VOUCHER_GIFTCODES_UNUSED_LIST';

export const SAVE_VOUCHER_CUSTOMER_LIST = 'getcare/tmk/VOUCHER/SAVE_VOUCHER_CUSTOMER_LIST';
export const SAVE_ORI_VOUCHER_CUSTOMER_LIST = 'getcare/tmk/VOUCHER/SAVE_ORI_VOUCHER_CUSTOMER_LIST';
export const SAVE_VOUCHER_CUSTOMER_LIST_ITEM = 'getcare/tmk/VOUCHER/SAVE_VOUCHER_CUSTOMER_LIST_ITEM'
export const REMOVE_VOUCHER_CUSTOMER_LIST_ITEM = 'getcare/tmk/VOUCHER/REMOVE_VOUCHER_CUSTOMER_LIST_ITEM';

export const SAVE_VOUCHER_CONFIG_GIFT_LIST = 'getcare/tmk/VOUCHER/SAVE_VOUCHER_CONFIG_GIFT_LIST';
export const SAVE_VOUCHER_CONFIG_MONEY_LIST = 'getcare/tmk/VOUCHER/SAVE_VOUCHER_CONFIG_MONEY_LIST';
export const SAVE_ORI_VOUCHER_CONFIG_GIFT_LIST = 'getcare/tmk/VOUCHER/SAVE_ORI_VOUCHER_CONFIG_GIFT_LIST';
export const SAVE_ORI_VOUCHER_CONFIG_MONEY_LIST = 'getcare/tmk/VOUCHER/SAVE_ORI_VOUCHER_CONFIG_MONEY_LIST';
export const SAVE_VOUCHER_CONFIG_GIFT_LIST_ITEM = 'getcare/tmk/VOUCHER/SAVE_VOUCHER_CONFIG_GIFT_LIST_ITEM'
export const SAVE_VOUCHER_CONFIG_MONEY_LIST_ITEM = 'getcare/tmk/VOUCHER/SAVE_VOUCHER_CONFIG_MONEY_LIST_ITEM'
export const REMOVE_VOUCHER_CONFIG_GIFT_LIST_ITEM = 'getcare/tmk/VOUCHER/REMOVE_VOUCHER_CONFIG_GIFT_LIST_ITEM';
export const REMOVE_VOUCHER_CONFIG_MONEY_LIST_ITEM = 'getcare/tmk/VOUCHER/REMOVE_VOUCHER_CONFIG_MONEY_LIST_ITEM';

export const SAVE_VOUCHER_SALE_CHANNEL_LIST = 'getcare/tmk/VOUCHER/SAVE_VOUCHER_SALE_CHANNEL_LIST';
export const SAVE_ORI_VOUCHER_SALE_CHANNEL_LIST = 'getcare/tmk/VOUCHER/SAVE_ORI_VOUCHER_SALE_CHANNEL_LIST';
export const SAVE_VOUCHER_SALE_CHANNEL_LIST_ITEM = 'getcare/tmk/VOUCHER/SAVE_VOUCHER_SALE_CHANNEL_LIST_ITEM';
export const REMOVE_VOUCHER_SALE_CHANNEL_LIST_ITEM = 'getcare/tmk/VOUCHER/REMOVE_VOUCHER_SALE_CHANNEL_LIST_ITEM';

export const SAVE_ORI_VOUCHER_PRODUCTS = 'getcare/tmk/VOUCHER/SAVE_ORI_VOUCHER_PRODUCTS';
export const SAVE_VOUCHER_PRODUCTS = 'getcare/tmk/VOUCHER/SAVE_VOUCHER_PRODUCTS';
export const SAVE_VOUCHER_PRODUCT_ITEM = 'getcare/tmk/VOUCHER/SAVE_VOUCHER_PRODUCT_ITEM';
export const REMOVE_VOUCHER_PRODUCT_ITEMS = 'getcare/tmk/VOUCHER/REMOVE_VOUCHER_PRODUCT_ITEMS';

export const GET_CUSTOMER_LIST = 'getcare/tmk/VOUCHER/GET_CUSTOMER_LIST'
export const SAVE_CUSTOMER_LIST = 'getcare/tmk/VOUCHER/SAVE_CUSTOMER_LIST'

export const GET_SALE_CHANNEL_LIST = 'getcare/tmk/VOUCHER/GET_SALE_CHANNEL_LIST';
export const SAVE_SALE_CHANNEL_LIST = 'getcare/tmk/VOUCHER/SAVE_SALE_CHANNEL_LIST';

export const SELECT_ALL_VOUCHER_PRODUCT = 'getcare/tmk/VOUCHER/SELECT_ALL_VOUCHER_PRODUCT';
export const SELECT_ALL_VOUCHER_CUSTOMER = 'getcare/tmk/VOUCHER/SELECT_ALL_VOUCHER_CUSTOMER';
export const SELECT_ALL_VOUCHER_SALE_CHANNEL = 'getcare/tmk/VOUCHER/SELECT_ALL_VOUCHER_SALE_CHANNEL';
export const DESELECT_ALL_VOUCHER_PRODUCT = 'getcare/tmk/VOUCHER/DESELECT_ALL_VOUCHER_PRODUCT';
export const DESELECT_ALL_VOUCHER_CUSTOMER = 'getcare/tmk/VOUCHER/DESELECT_ALL_VOUCHER_CUSTOMER';
export const DESELECT_ALL_VOUCHER_SALE_CHANNEL = 'getcare/tmk/VOUCHER/DESELECT_ALL_VOUCHER_SALE_CHANNEL';
export const SELECT_VOUCHER_PRODUCT_ITEM = 'getcare/tmk/VOUCHER/SELECT_VOUCHER_PRODUCT_ITEM';
export const SELECT_VOUCHER_CUSTOMER_ITEM = 'getcare/tmk/VOUCHER/SELECT_VOUCHER_CUSTOMER_ITEM';
export const SELECT_VOUCHER_SALE_CHANNEL_ITEM = 'getcare/tmk/VOUCHER/SELECT_VOUCHER_SALE_CHANNEL_ITEM';

export const REMOVE_MULTIPLE_VOUCHER_PRODUCT_ITEM = 'getcare/tmk/VOUCHER/REMOVE_MULTIPLE_VOUCHER_PRODUCT_ITEM';
export const REMOVE_MULTIPLE_VOUCHER_CUSTOMER_ITEM = 'getcare/tmk/VOUCHER/REMOVE_MULTIPLE_VOUCHER_CUSTOMER_ITEM';
export const REMOVE_MULTIPLE_VOUCHER_SALE_CHANNEL_ITEM = 'getcare/tmk/VOUCHER/REMOVE_MULTIPLE_VOUCHER_SALE_CHANNEL_ITEM'

export const SELECT_ALL_VOUCHER_CONFIG_MONEY = 'getcare/tmk/VOUCHER/SELECT_ALL_VOUCHER_CONFIG_MONEY';
export const SELECT_VOUCHER_CONFIG_ITEM_MONEY = 'getcare/tmk/VOUCHER/SELECT_VOUCHER_CONFIG_ITEM_MONEY';
export const REMOVE_MULTIPLE_VOUCHER_CONFIG_MONEY_ITEM = 'getcare/tmk/VOUCHER/REMOVE_MULTIPLE_VOUCHER_CONFIG_MONEY_ITEM';
export const DESELECT_ALL_VOUCHER_CONFIG_MONEY = 'getcare/tmk/VOUCHER/DESELECT_ALL_VOUCHER_CONFIG_MONEY';

export const SELECT_ALL_VOUCHER_CONFIG_GIFT = 'getcare/tmk/VOUCHER/SELECT_ALL_VOUCHER_CONFIG_GIFT';
export const SELECT_VOUCHER_CONFIG_ITEM_GIFT = 'getcare/tmk/VOUCHER/SELECT_VOUCHER_CONFIG_ITEM_GIFT';
export const REMOVE_MULTIPLE_VOUCHER_CONFIG_GIFT_ITEM = 'getcare/tmk/VOUCHER/REMOVE_MULTIPLE_VOUCHER_CONFIG_GIFT_ITEM';
export const DESELECT_ALL_VOUCHER_CONFIG_GIFT = 'getcare/tmk/VOUCHER/DESELECT_ALL_VOUCHER_CONFIG_GIFT';
