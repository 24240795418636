import React, { PureComponent } from 'react';
import classes from './BannerListItem.module.scss';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { validDate } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';
import { Checkbox } from '@material-ui/core';
import gridClasses from '../BannerListGrid.module.scss';
import {
  applyStatusMap,
  bannerStatusMap,
} from 'utils/constanst/tmkBannerConstants';
import { TextField } from '@material-ui/core';
class BannerListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
    };
  }

  handleCheckbox = () => {
    this.setState((prevState) => {
      return {
        isChecked: !prevState.isChecked,
      };
    });
  }

  render() {
    const {
      id,
      no,
      name,
      getcare_banner_type,
      getcare_banner_size,
      getcare_banner_location,
      url,
      start_date,
      end_date,
      active,
      apply,
    } = this.props;

    return (
      <Link className={`${classes.Item} ${gridClasses.Row}`} to={`/banner/${id}`}>
        <div className={`${gridClasses.Col} ${classes.CodeCol}`}>
          <Checkbox
            size="small"
            name="bonus_mode"
            checked={this.state.isChecked}
            onChange={this.handleCheckbox}
          />
        </div>

        <div className={`${gridClasses.Col} ${classes.CodeCol}`}>
          <div href={url}>{getcare_banner_type?.id === 1 ? 'Hình ảnh' : 'Thumbnail video'}</div>
        </div>
        <div className={gridClasses.Col}>{name}</div>
        <div className={gridClasses.Col}>{getcare_banner_size?.name}</div>
        <div className={gridClasses.Col}>{getcare_banner_location?.name}</div>
        <div className={gridClasses.Col}>{no}</div>
        <div className={gridClasses.Col}>{getcare_banner_type?.name}</div>
        <div className={gridClasses.Col}>
          {validDate(start_date)
            ? format(validDate(start_date), dateFormat)
            : '-'}
        </div>
        <div className={gridClasses.Col}>
          {validDate(end_date) ? format(validDate(end_date), dateFormat) : '-'}
        </div>
        <div className={gridClasses.Col}>{applyStatusMap[apply]}</div>
        <div className={gridClasses.Col}>{bannerStatusMap[active]}</div>
      </Link>
    );
  }
}

BannerListItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  no: PropTypes.number,
  name: PropTypes.string,
  getcare_banner_type: PropTypes.object,
  getcare_banner_size: PropTypes.object,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  active: PropTypes.number,
  apply: PropTypes.number,
};

export default BannerListItem;
