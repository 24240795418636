import React, { PureComponent } from 'react';
import classes from './VoucherUnusedItem.module.scss';
import gridClasses from '../VoucherUnusedGrid.module.scss';
export default class VoucherUnusedItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { code, index } = this.props;
    return (
      <div
        className={`${classes.Item}  ${gridClasses.Row} ${gridClasses.RowCustom}`}
      >
        <div className={gridClasses.Col}>{`${index}. ${code}`}</div>
      </div>
    );
  }
}
