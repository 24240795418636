import React, { PureComponent } from 'react';
import classes from './Vouchers.module.scss';
import Button from '@material-ui/core/Button';
import ListPagination from 'components/ListPagination/ListPagination';
import Add from '@material-ui/icons/Add';
import { debounce } from 'lodash';
import {
  listParamsMap,
  listDisplayFields,
  filterFields,
} from 'utils/constanst/tmkVoucherConstant';
import {
  getSortsString,
  getDisplayFields,
  getQueryString,
  getQueryParams,
} from 'utils/helper';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectVoucherList,
  makeSelectVoucherListTotal,
  makeSelectVoucherLoading,
  makeSelectWSEvents
} from 'redux/selectors';
import { removeWSEvent } from 'redux/actions/wsActions';
import { getVoucherList, saveVoucherListQueryString  } from 'redux/actions/tmk/voucherAction';
import { connect } from 'react-redux';
import VoucherList from 'components/tmk/VoucherList/VoucherList';
import VoucherListFilter from 'components/tmk/VoucherList/VoucherListFilter/VoucherListFilter';
class Vouchers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
    };
  }
  componentDidMount() {
    this.initUrlSync();
  }

  componentDidUpdate(prevProps, prevState) {
    const { location, events } = this.props;
    if (location.search !== prevProps.location.search) {
      if (['', null, undefined].includes(location.search)) {
        this.initUrlSync();
        return;
      }
      this.reinitListData();
    }
  }

  createNewVoucher = () => {
    this.props.history.push(`/voucher/create`);
  };

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange({ order: newSortString });
  };

  handleFilterChange = (filterParams, forceResetPage: false) => {
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...filterParams,
      page: 1,
    } : {
      ...this.state.listParams,
      ...filterParams,
    };
    this.setState({
      listParams: newParams,
    }, () => {
      this.synUrl(newParams);
    });
  };

  synUrl = debounce((newParams) => {
    this.props.history.push({ search: getQueryString(newParams)});
  }, 500);

  initUrlSync = () => {
    const { location } = this.props;
    const initalQueryStringFromDefaultParams = getQueryString(listParamsMap);
    // check if default listParams is different from inital location search
    if (
      ['', null, undefined].includes(location.search) &&
      initalQueryStringFromDefaultParams !== location.search
    ) {
      this.props.history.replace({
        search: initalQueryStringFromDefaultParams,
      });
      return;
    }
    this.reinitListData();
  };

  reinitListData = () => {
    const { location } = this.props;
    const newParams = getQueryParams(location.search);
    this.setState({
      listParams: newParams,
    });
    this.props.getVoucherList({
      params: newParams,
    });
    this.props.saveVoucherListQueryString(location.search);
  };

  render() {
    const { voucherList, loading, voucherListTotal } = this.props;
    const { listParams } = this.state;
    const isListLoading = loading;
    return (
      <>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách chương trình phát hành Voucher</h1>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={this.createNewVoucher}
            >
              Add
            </Button>
          </div>
          <div className={classes.PageMain}>
            <VoucherListFilter
              filterFields={filterFields}
              queryParamsMap={listParams}
              onFilterChange={this.handleFilterChange}
            />
            <VoucherList
              onSortChange={this.handleSortChange}
              voucherList={voucherList}
              isLoading={isListLoading}
              displayFields={getDisplayFields(listParams, listDisplayFields)}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={Number(listParams.page)}
              pageSize={Number(listParams.page_size)}
              total={voucherListTotal}
              listName="chương trình"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  voucherList: makeSelectVoucherList(),
  voucherListTotal: makeSelectVoucherListTotal(),
  loading: makeSelectVoucherLoading(),
  events: makeSelectWSEvents(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getVoucherList: (payload) => dispatch(getVoucherList(payload)),
    saveVoucherListQueryString: (payload) => dispatch(saveVoucherListQueryString(payload)),
    removeWSEvent: (payload) => dispatch(removeWSEvent(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withRouter)(Vouchers);
