import React, { PureComponent } from 'react';
import classes from './BannerCustomerItem.module.scss';
import gridClasses from '../BannerCustomerGrid.module.scss';
import { Checkbox } from '@material-ui/core';
import { debounce } from 'lodash';

import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

import bannerApi from 'utils/api/tmk/bannerApi';
import { toast } from 'react-toastify';
export default class BannerCustomerItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: props.isChecked,
      isEditing: props.isEditing,
      params: this._getInitParams(props),
      bannerCustomerOptions: this._initbannerCustomerOptions(props),
      customerCodeInput: '',
      customerNameInput: '',
    };
  }

  async componentDidMount() {
    const {isChecked} = this.props;
    this.setState({isChecked: isChecked})
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      customerCodeInput,
      customerNameInput,
      params,
      isEditing,
    } = this.state;
    if (
      customerCodeInput &&
      customerCodeInput !== prevState.customerCodeInput
    ) {
      this._loadSuggestedCustomer({
        params: {
          code: customerCodeInput,
        },
      });
    }
    if (
      customerNameInput &&
      customerNameInput !== prevState.customerNameInput
    ) {
      this._loadSuggestedCustomer({
        params: {
          name: customerNameInput,
        },
      });
    }

    if (isEditing !== prevState.isEditing && this._isRowValid(params)) {
      const { currentList } = this.props;
      const isDuplicated = currentList.findIndex((p) => {
        return p.id === params.id && p.name === params.name;
      });
      if (isDuplicated > -1) {
        toast.error('Khách hàng bị trùng');
        this.props.removeCustomerListItem({
          id: this.props.id,
          idStr: this.props.idStr,
        });
      } else {
        this.props.saveCustomerListItem({
          id: params.id,
          code: params.code,
          name: params.name,
          idStr: params.idStr,
          address: params.address,
          getcare_customer: { ...params },
          isEditing,
        });
      }
    }
  }

  _getInitParams = (params) => {
    return {
      getcare_customer: null,
      idStr: params.idStr ? params.idStr : null,
      id: params.id ? params.id : null,
      name: params.name ? params.name : null,
      code: params.code ? params.code : null,
      phone: params.phone ? params.phone : null,
      address: params.address ? params.address : null,
    };
  };

  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
      bannerCustomerOptions: this._initbannerCustomerOptions(this.props),
      customerCodeInput: '',
      customerNameInput: '',
    });
  };

  _initbannerCustomerOptions = (params) => {
    return params.currentList ? params.currentList : [];
  };

  _loadSuggestedCustomer = debounce(async ({ params }) => {
    const { data: response } = await bannerApi.findCustomer({
      params: {
        ...params,
      },
    });
    if (!response?.result) return;
    const searchedResults = response.data.map((item) => {
      const { id, name, phone, code, address } = item;
      return {
        id,
        name,
        phone,
        code,
        address,
      };
    });
    this.setState({
      bannerCustomerOptions: searchedResults.length > 0 ? searchedResults : [],
    });
  }, 500);

  _filteredOptions = (fullArr) => {
    return fullArr.filter((p) => {
      return (
        p.getcare_id === this.props.getcare_product?.getcare_id ||
        !this.props.currentList.some(
          (item) => item.getcare_product?.getcare_id === p.getcare_id
        )
      );
    });
  };

  _getCustomerNameOptionLabel = (option) => {
    if (option.name) return `${option.name}`;
  };

  _getCustomerCodeOptionLabel = (option) => {
    if (option.code) return `${option.code}`;
  };

  _hasCustomer = (params) => {
    return !!params?.id && !!params.getcare_customer?.id;
  };

  _isRowValid = (params) => {
    return this._hasCustomer(params);
  };

  handleCheckbox = () => {
    this.setState((prevState) => {
      return {
        isChecked: !prevState.isChecked,
      };
    });
  };

  handleRowClick = () => {
    if (this.props.readOnly) {
      return;
    }

    if (!this.state.isEditing) {
      this.handleEdit();
    }
  };

  handleEdit = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  };

  handleCancel = (e) => {
    if (e) e.stopPropagation();
    if (this._isRowValid(this.props) || !this.state.isEditing) {
      this._resetState();
      return;
    }
    this.props.removeCustomerListItem({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  };

  handleRemove = (e) => {
    if (e) e.stopPropagation();
    this.props.removeCustomerListItem({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  };

  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: false,
    });
  };

  handleSelectedCustomerChange = (e, newValue) => {
    this.setState({
      params: {
        ...this.state.params,
        id: newValue.id || null,
        code: newValue.code,
        name: newValue.name,
        phone: newValue.phone,
        address: newValue.address,
        getcare_customer: newValue,
      },
    });
  };

  render() {
    const { readOnly } = this.props;
    const { bannerCustomerOptions, params, isEditing } = this.state;
    return (
      <div
        className={`${classes.Item} ${gridClasses.Row}`}
        // onClick={this.handleRowClick}
      >
        <div className={`${gridClasses.Col} ${classes.CodeCol}`}>
          <Checkbox
            size="small"
            name="bonus_mode"
            checked={this.state.isChecked}
            onChange={this.handleCheckbox}
          />
        </div>

        <div className={gridClasses.Col}>
          {isEditing ? (
            <Autocomplete
              openOnFocus
              selectOnFocus
              disableClearable
              fullWidth
              size="small"
              handleHomeEndKeys={false}
              value={params.name}
              onChange={this.handleSelectedCustomerChange}
              options={bannerCustomerOptions || []}
              filterOptions={(x) => x}
              renderOption={(option) =>
                this._getCustomerNameOptionLabel(option)
              }
              onInputChange={(e, newInputValue) => {
                this.setState({
                  customerNameInput: newInputValue,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="- Chọn -" />
              )}
            />
          ) : (
            params.name
          )}
        </div>
        <div className={gridClasses.Col}>
          {isEditing ? (
            <Autocomplete
              openOnFocus
              selectOnFocus
              disableClearable
              fullWidth
              size="small"
              handleHomeEndKeys={false}
              value={params.code}
              onChange={this.handleSelectedCustomerChange}
              options={bannerCustomerOptions || []}
              filterOptions={(x) => x}
              renderOption={(option) =>
                this._getCustomerCodeOptionLabel(option)
              }
              onInputChange={(e, newInputValue) => {
                this.setState({
                  customerCodeInput: newInputValue,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="- Chọn -" />
              )}
            />
          ) : (
            params.code
          )}
        </div>
        <div className={gridClasses.Col}>{params.phone}</div>
        <div className={gridClasses.Col}>{params.address}</div>
        <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
          {isEditing ? (
            <>
              <IconButton
                onClick={this.handleCancel}
                style={{ padding: '0' }}
                size="small"
              >
                <CancelOutlined fontSize="small" color="secondary" />
              </IconButton>
              <IconButton
                onClick={this.handleSave}
                disabled={!this._isRowValid(params)}
                style={{ padding: '0' }}
                size="small"
              >
                <CheckCircleOutlineOutlined
                  fontSize="small"
                  color={this._isRowValid(params) ? `primary` : `inherit`}
                />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                disabled={readOnly}
                onClick={this.handleRemove}
                style={{ padding: '0' }}
                size="small"
              >
                <DeleteOutline
                  fontSize="small"
                  color={readOnly ? 'inherit' : 'action'}
                />
              </IconButton>
              <IconButton
                disabled={readOnly}
                onClick={this.handleEdit}
                style={{ padding: '0' }}
                size="small"
              >
                <EditOutlined
                  fontSize="small"
                  color={readOnly ? 'inherit' : 'primary'}
                />
              </IconButton>
            </>
          )}
        </div>
      </div>
    );
  }
}
