import React, { PureComponent } from 'react';
import classes from './Banners.module.scss';
import Button from '@material-ui/core/Button';
import ListPagination from 'components/ListPagination/ListPagination';
import Add from '@material-ui/icons/Add';
import { debounce } from 'lodash';
import {
  listParamsMap,
  listDisplayFields,
  filterFields,
} from 'utils/constanst/tmkBannerConstants';
import {
  getSortsString,
  getDisplayFields,
  getQueryString,
  getQueryParams,
} from 'utils/helper';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import BannerListFilter from 'components/tmk/BannerList/BannerListFilter/BannerListFilter';
import BannerList from 'components/tmk/BannerList/BannerList';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectBannerList,
  makeSelectBannerListTotal,
  makeSelectBannerLoading,
  makeSelectWSEvents,
} from 'redux/selectors';
import { removeWSEvent } from 'redux/actions/wsActions';
import { getBannerList, saveBannerListQueryString } from 'redux/actions/tmk/bannerActions';
import { connect } from 'react-redux';

class Banners extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
      isConfirmationOpen: false,
    };
  }
  componentDidMount() {
    this.initUrlSync();
  }

  componentDidUpdate(prevProps, prevState) {
    const { location, events } = this.props;
    if (location.search !== prevProps.location.search) {
      if (['', null, undefined].includes(location.search)) {
        this.initUrlSync();
        return;
      }
      this.reinitListData();
    }
  }

  createNewBanner = () => {
    this.props.history.push(`/banner/create`);
  };

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange({ order: newSortString });
  };

  handleFilterChange = (filterParams, forceResetPage: false) => {
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...filterParams,
      page: 1,
    } : {
      ...this.state.listParams,
      ...filterParams,
    };
    this.setState({
      listParams: newParams,
    }, () => {
      this.synUrl(newParams);
    });
  };

  synUrl = debounce((newParams) => {
    this.props.history.push({ search: getQueryString(newParams)});
  }, 500);

  initUrlSync = () => {
    const { location } = this.props;
    const initalQueryStringFromDefaultParams = getQueryString(listParamsMap);
    // check if default listParams is different from inital location search
    if (
      ['', null, undefined].includes(location.search) &&
      initalQueryStringFromDefaultParams !== location.search
    ) {
      this.props.history.replace({
        search: initalQueryStringFromDefaultParams,
      });
      return;
    }
    this.reinitListData();
  };

  reinitListData = () => {
    const { location } = this.props;
    const newParams = getQueryParams(location.search);
    this.setState({
      listParams: newParams,
    });
    this.props.getBannerList({
      params: newParams,
    });
    this.props.saveBannerListQueryString(location.search);
  };

  render() {
    const { bannerList, loading, bannerListTotal } = this.props;
    const { listParams } = this.state;
    const isListLoading = loading;
    return (
      <>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách Banner</h1>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={this.createNewBanner}
            >
              Tạo mới
            </Button>
          </div>
          <div className={classes.PageMain}>
            <BannerListFilter
              filterFields={filterFields}
              queryParamsMap={listParams}
              onFilterChange={this.handleFilterChange}
            />
            <BannerList
              onSortChange={this.handleSortChange}
              bannerList={bannerList}
              isLoading={isListLoading}
              displayFields={getDisplayFields(listParams, listDisplayFields)}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={Number(listParams.page)}
              pageSize={Number(listParams.page_size)}
              total={bannerListTotal}
              listName="chương trình"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  bannerList: makeSelectBannerList(),
  bannerListTotal: makeSelectBannerListTotal(),
  loading: makeSelectBannerLoading(),
  events: makeSelectWSEvents(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerList: (payload) => dispatch(getBannerList(payload)),
    saveBannerListQueryString: (payload) => dispatch(saveBannerListQueryString(payload)),
    removeWSEvent: (payload) => dispatch(removeWSEvent(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withRouter)(Banners);
