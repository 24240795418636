import React, { PureComponent } from 'react';
import classes from './VoucherList.module.scss';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import PropTypes from 'prop-types';
import VoucherListItem from './VoucherListItem/VoucherListItem';
import gridClasses from './VoucherListGrid.module.scss';
class VoucherList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isHeaderChecked: false,
    };
  }

  render() {
    const { voucherList, isLoading, displayFields } = this.props;
    return (
      <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
        <div className={`${classes.Body}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            {displayFields.map((item) => {
              return (
                <ListHeaderCol
                  key={`voucher-list-header-${item.name}`}
                  className={classes.Col}
                  {...item}
                  onSortChange={this.props.onSortChange}
                />
              );
            })}
          </div>

          {!isLoading && voucherList.length ? (
            voucherList.map((item) => (
              <VoucherListItem key={`Voucher-item-${item.id}`} {...item} />
            ))
          ) : (
            <p className="NoData">Không có data</p>
          )}
        </div>
      </div>
    );
  }
}
VoucherList.propTypes = {
  displayFields: PropTypes.array,
  VoucherList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
};

VoucherList.defaultProps = {
  displayFields: [],
  voucherList: [],
  isLoading: false,
};

export default VoucherList;
