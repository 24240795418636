import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import PromotionListItem from './PromotionListItem/PromotionListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './PromotionListGrid.module.scss';
import classes from './PromotionList.module.scss';

class PromotionList extends PureComponent {
  render() {
    const { promotionList, isLoading, displayFields, promotionPriorities } = this.props;

    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
      <div className={`${classes.Body}`}>
        <div className={`${gridClasses.Row} ${classes.Header}`}>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
      
        { !isLoading && promotionList.length
          ? promotionList.map(item => (
            <PromotionListItem
              key={`Promotion-${item.id}`}
              {...item}
              promotionPriorities={promotionPriorities}
              onPriorityChange={this.props.onPriorityChange}
            />))
          : (<p className="NoData">Không có data</p>)
        }
      </div>
    </div>;
  }
}

PromotionList.propTypes = {
  displayFields: PropTypes.array,
  promotionList: PropTypes.array,
  isLoading: PropTypes.bool,
  promotionPriorities: PropTypes.array,
  onSortChange: PropTypes.func,
  onPriorityChange: PropTypes.func,
};

PromotionList.defaultProps = {
  displayFields: [],
  promotionList: [],
  isLoading: false,
  promotionPriorities: [],
};

export default PromotionList;
