import React, { PureComponent } from 'react';

import Button from '@material-ui/core/Button';
import PromotionLevelsDialog from 'components/tmk/PromotionTest/PromotionLevelsDialog/PromotionLevelsDialog';

import classes from './PromotionAvailable.module.scss';
import gridClasses from '../PromotionAvailablesGrid.module.scss';

class PromotionAvailable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isBonusDialogOpen: false,
    }
  }
  handleCloseBonusDialog = () => {
    this.setState({
      isBonusDialogOpen: false,
    });
  };
  handleOpenBonusDialog = () => {
    this.setState({
      isBonusDialogOpen: true,
    });
  };

  render() {
    const {
      index,
      enable,
      apply,
      items,
      name,
      code,
      count_total,
      count_available,
      priority_name,
      type_name,
      id,
      vendor_name,
    } = this.props;

    return (<>
      <div className={`${gridClasses.Row} ${classes.Item} ${enable ? '' : classes.Disabled}`}>
        <div className={`${gridClasses.Col}`}>
          { index }
        </div>
        <div className={`${gridClasses.Col}`}>
          { `${code}\n` }
          { `${name}\n` }
          { vendor_name && <span className="TextMuted">{ `${vendor_name}` }</span> }
        </div>
        <div className={`${gridClasses.Col}`}>
          { type_name }
        </div>
        <div className={`${gridClasses.Col}`}>
          { priority_name }
        </div>
        <div className={`${gridClasses.Col}`}>
          { items.map(item => (
            `L${item.level} x ${item.count_total} suất\n`
          )) }
        </div>
        <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
          {`${count_total}/${count_available} | `}
          { !apply
            ? <Button color="primary" size="small" onClick={this.handleOpenBonusDialog}>Chọn khuyến mãi</Button>
            : <Button color="primary" size="small" onClick={this.handleOpenBonusDialog}>Chọn lại</Button>
          }
        </div>
      </div>

      { this.state.isBonusDialogOpen &&
        <PromotionLevelsDialog
          isOpen={this.state.isBonusDialogOpen}
          title={<>
            <p>{code} - {name}</p>
            { vendor_name && <p className="TextMuted">{ vendor_name }</p> }
          </>}
          promotionLevels={items}
          promotionId={id}
          onClose={this.handleCloseBonusDialog}
          onSubmit={this.props.onSubmitPromotion}
        />
      }
    </>);
  }
}

export default PromotionAvailable;

