import { createSelector } from 'reselect';

// authentication
const selectAuthState = (state) => {
  return state.authReducer || {};
};
const makeSelectLoginUser = () =>
  createSelector(selectAuthState, (substate) => substate.user);
const makeSelectAuthLoading = () =>
  createSelector(selectAuthState, (substate) => substate.authLoading);
const makeSelectAuthError = () =>
  createSelector(selectAuthState, (substate) => substate.authError);

// websocket
const selectWSState = (state) => {
  return state.wsReducer || {};
};
const makeSelectGlobalWS= () =>
  createSelector(
    selectWSState,
    (substate) => substate.ws
  );
const makeSelectWSEvents= () =>
  createSelector(
    selectWSState,
    (substate) => substate.events
  );

// promotion
const selectPromotionState = (state) => {
  return state.promotionReducer || {};
};

const makeSelectPromotionTypes = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionTypes
  );
const makeSelectPromotionOperators = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionOperators
  );
const makeSelectPromotionCompares = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionCompares
  );
const makeSelectPromotionPriorities = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionPriorities
  );
const makeSelectPromotionApplies = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionApplies
  );
const makeSelectPromotionDiscounts = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionDiscounts
  );

const makeSelectPromotionList = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionList
  );
const makeSelectPromotionListQueryString = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionListQueryString
  );
const makeSelectPromotionListTotal = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionListTotal
  );
const makeSelectPromotionLoading = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.loading
  );

const makeSelectOriPromotionDetails = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.oriPromotionDetails
  );
const makeSelectPromotionDetails = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionDetails
  );

const makeSelectOriPromotionRules = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.oriPromotionRules
  );
const makeSelectPromotionRules = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionRules
  );

const makeSelectOriPromotionProducts = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.oriPromotionProducts
  );
const makeSelectOriPromotionOrder = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.oriPromotionOrder
);  

const makeSelectPromotionProducts = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionProducts
  );
const makeSelectPromotionOrder = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionOrder
  );

const makeSelectOriPromotionMaxProducts = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.oriPromotionMaxProducts
  );
const makeSelectPromotionMaxProducts = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionMaxProducts
  );

const makeSelectOriPromotionBonuses = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.oriPromotionBonuses
  );
const makeSelectPromotionBonuses = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionBonuses
  );

const makeSelectOriPromotionVendors = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.oriPromotionVendors
  );
const makeSelectPromotionVendors = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionVendors
  );

const makeSelectOriPromotionCustomers = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.oriPromotionCustomers
  );
const makeSelectPromotionCustomers = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionCustomers
  );
const makeSelectPromotionHistory = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionHistory
  );

// promotion test
const selectPromotionTestState = (state) => {
  return state.testPromotionReducer || {};
};

const makeSelectTestPromotionLoading = () =>
  createSelector(
    selectPromotionTestState,
    (substate) => substate.loading
  );
const makeSelectTestOrderList = () =>
  createSelector(
    selectPromotionTestState,
    (substate) => substate.orderList
  );
const makeSelectTestPromotionDiscounts = () =>
  createSelector(
    selectPromotionTestState,
    (substate) => substate.promotionDiscounts
  );
const makeSelectTestPromotionAvailables = () =>
  createSelector(
    selectPromotionTestState,
    (substate) => substate.promotionAvailables
  );
const makeSelectTestPromotionActives = () =>
  createSelector(
    selectPromotionTestState,
    (substate) => substate.promotionActives
  );
const makeSelectTestUsedPromotions = () =>
  createSelector(
    selectPromotionTestState,
    (substate) => substate.usedPromotions
  );
const makeSelectTestOrderAmount = () =>
  createSelector(
    selectPromotionTestState,
    (substate) => substate.orderAmount
  );
//banner
const selectBannerState = (state) => {
  return state.bannerReducer || {};
};

const makeSelectBannerLoading = () =>
  createSelector(
    selectBannerState,
    (substate) => substate.loading
  );

const makeSelectBannerList = () =>
  createSelector(
    selectBannerState,
    (substate) => substate.bannerList
  );

const makeSelectBannerListTotal = () =>
  createSelector(
    selectBannerState,
    (substate) => substate.bannerListTotal
  );
  
const makeSelectBannerDetails = () =>
  createSelector(
    selectBannerState,
    (substate) => substate.bannerDetails
  );

const makeSelectOriBannerDetails = () =>
  createSelector(
    selectBannerState,
    (substate) => substate.oriBannerDetails
  );

const makeSelectBannerSize = () => 
  createSelector(
    selectBannerState,
    (substate) => substate.bannerSize
  );

const makeSelectBannerType = () => 
  createSelector(
    selectBannerState,
    (substate) => substate.bannerType
  );

const makeSelectBannerLocation = () => 
  createSelector(
    selectBannerState,
    (substate) => substate.bannerLocation
  );

const makeSelectBannerListQueryString = () =>
  createSelector(
    selectBannerState,
    (substate) => substate.bannerListQueryString
  );

const makeSelectBannerCustomerList = () =>
  createSelector(
    selectBannerState,
    (substate) => substate.bannerCustomerList
  );

const makeSelectBannerSaleChannelList = () =>
  createSelector(
    selectBannerState,
    (substate) => substate.bannerSaleChannelList
  );

const makeSelectOriBannerCustomerList = () =>
  createSelector(
    selectBannerState,
    (substate) => substate.oriBannerCustomerList
  );

const makeSelectOriBannerSaleChannelList = () =>
  createSelector(
    selectBannerState,
    (substate) => substate.oriBannerSaleChannelList
  );

const makeSelectSaleChannelList = () =>
  createSelector(
    selectBannerState,
    (substate) => substate.saleChannelList
  );

const makeSelectCustomerList = () =>
  createSelector(
    selectBannerState,
    (substate) => substate.customerList
  ); 

//voucher
const selectVoucherState = (state) => {
  return state.voucherReducer || {};
};

const makeSelectVoucherLoading = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.loading
  );

const makeSelectVoucherList = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.voucherList
  );

const makeSelectUsedVoucherList = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.usedVoucherList
  );
  

const makeSelectVoucherListTotal = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.voucherListTotal
  );

const makeSelectUsedVoucherListTotal = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.usedVoucherListTotal
  );
  
const makeSelectVoucherDetails = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.voucherDetails
  );

const makeSelectOriVoucherDetails = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.oriVoucherDetails
  );

const makeSelectVoucherListQueryString = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.voucherListQueryString
  );

const makeSelectUsedVoucherListQueryString = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.usedVoucherListQueryString
  );

const makeSelectVoucherCustomerList = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.voucherCustomerList
  );

const makeSelectVoucherConfigGiftList = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.voucherConfigGiftList
  );

const makeSelectVoucherConfigMoneyList = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.voucherConfigMoneyList
  );

const makeSelectOriVoucherConfigGiftList = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.oriVoucherConfigGiftList
  );

const makeSelectOriVoucherConfigMoneyList = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.oriVoucherConfigMoneyList
  );


const makeSelectVoucherSaleChannelList = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.voucherSaleChannelList
  );

const makeSelectOriVoucherCustomerList = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.oriVoucherCustomerList
  );

const makeSelectOriVoucherSaleChannelList = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.oriVoucherSaleChannelList
  );

  const makeSelectVoucherVendorList = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.voucherVendorList
  );

  const makeSelectVoucherProductList = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.voucherProductList
  );

  const makeSelectOriVoucherProductList = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.oriVoucherProductList
  );

  const makeSelectVoucherUnusedList = () =>
  createSelector(
    selectVoucherState,
    (substate) => substate.voucherUnusedList
  );

  export {
  makeSelectLoginUser,
  makeSelectAuthLoading,
  makeSelectAuthError,
  makeSelectGlobalWS,
  makeSelectWSEvents,
  makeSelectPromotionList,
  makeSelectPromotionListQueryString,
  makeSelectPromotionListTotal,
  makeSelectPromotionLoading,
  makeSelectOriPromotionDetails,
  makeSelectPromotionDetails,
  makeSelectPromotionTypes,
  makeSelectPromotionOperators,
  makeSelectPromotionCompares,
  makeSelectPromotionPriorities,
  makeSelectPromotionApplies,
  makeSelectPromotionDiscounts,
  makeSelectOriPromotionRules,
  makeSelectPromotionRules,
  makeSelectOriPromotionBonuses,
  makeSelectPromotionBonuses,
  makeSelectOriPromotionProducts,
  makeSelectPromotionProducts,
  makeSelectPromotionOrder,
  makeSelectOriPromotionOrder,
  makeSelectOriPromotionMaxProducts,
  makeSelectPromotionMaxProducts,
  makeSelectOriPromotionVendors,
  makeSelectPromotionVendors,
  makeSelectOriPromotionCustomers,
  makeSelectPromotionCustomers,
  makeSelectPromotionHistory,
  makeSelectTestOrderList,
  makeSelectTestPromotionAvailables,
  makeSelectTestPromotionActives,
  makeSelectTestUsedPromotions,
  makeSelectTestPromotionLoading,
  makeSelectTestOrderAmount,
  makeSelectTestPromotionDiscounts,
  //banner
  makeSelectBannerList,
  makeSelectBannerListTotal,
  makeSelectBannerLoading,
  makeSelectBannerDetails,
  makeSelectBannerListQueryString,
  makeSelectBannerSize,
  makeSelectBannerType,
  makeSelectBannerLocation,
  makeSelectBannerCustomerList,
  makeSelectBannerSaleChannelList,
  makeSelectOriBannerCustomerList,
  makeSelectOriBannerDetails,
  makeSelectOriBannerSaleChannelList,
  makeSelectSaleChannelList,
  makeSelectCustomerList,
  //voucher
  makeSelectVoucherList,
  makeSelectUsedVoucherList,
  makeSelectVoucherListTotal,
  makeSelectUsedVoucherListTotal,
  makeSelectVoucherLoading,
  makeSelectVoucherDetails,
  makeSelectUsedVoucherListQueryString,
  makeSelectVoucherListQueryString,
  makeSelectVoucherCustomerList,
  makeSelectVoucherSaleChannelList,
  makeSelectOriVoucherCustomerList,
  makeSelectOriVoucherDetails,
  makeSelectOriVoucherSaleChannelList,
  makeSelectVoucherVendorList,
  makeSelectVoucherProductList,
  makeSelectOriVoucherProductList,
  makeSelectVoucherUnusedList,
  makeSelectVoucherConfigGiftList,
  makeSelectVoucherConfigMoneyList,
  makeSelectOriVoucherConfigGiftList,	
  makeSelectOriVoucherConfigMoneyList  
};
