import React, { PureComponent } from 'react';
import classes from './VoucherUsedListItem.module.scss';
import { Link } from 'react-router-dom';
import { validDate } from 'utils/helper';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';
import { Checkbox } from '@material-ui/core';
import gridClasses from '../VoucherUsedListGrid.module.scss';
import {

} from 'utils/constanst/tmkBannerConstants';
class VoucherListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      code,
      getcare_vendor_name,
      getcare_voucher_program_code,
      getcare_voucher_status,
      used_at,
      getcare_order_code,
      getcare_customer_name,
    } = this.props;

    return (
      <div className={`${classes.Item} ${gridClasses.Row}`}>
        <div className={gridClasses.Col}>{getcare_vendor_name || '-'}</div>
        <div className={gridClasses.Col}>{code || '-'}</div>
        <div className={gridClasses.Col}>{getcare_voucher_status?.name || '-'}</div>
        <div className={gridClasses.Col}>{getcare_voucher_program_code}</div>
        <div className={gridClasses.Col}>
          {validDate(used_at)
            ? format(validDate(used_at), dateTimeFormat)
            : '-'}
        </div>
        <div className={gridClasses.Col}>{getcare_order_code}</div>
        <div className={gridClasses.Col}>
            {getcare_customer_name}
        </div>
      </div>
    );
  }
}

VoucherListItem.propTypes = {
};

export default VoucherListItem;
