import React, { PureComponent } from 'react';
import classes from './VoucherConfigItem.module.scss';
import gridClasses from '../VoucherConfigGrid.module.scss';
import { debounce } from 'lodash';

import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';
import { toast } from 'react-toastify';
import { validNumber } from 'utils/helper';
import voucherApi from 'utils/api/tmk/voucherApi';
import { Button } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
export default class VoucherConfigGiftItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isItemChecked: props.isChecked,
      isEditing: props.isEditing,
      params: this._getInitParams(props),
      productBonusOptions: this._initproductBonusOptions(props),
      productCodeInput: '',
      productNameInput: '',
    };
  }

  async componentDidMount() {
    const { isChecked } = this.props;
    this.setState({ isChecked: isChecked });
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      productCodeInput,
      productNameInput,
      params,
      isItemChecked,
      isEditing,
    } = this.state;
    if (productCodeInput && productCodeInput !== prevState.productCodeInput) {
      this._loadSuggestedProduct({
        params: {
          code: productCodeInput,
        },
      });
    }
    if (productNameInput && productNameInput !== prevState.productNameInput) {
      this._loadSuggestedProduct({
        params: {
          name: productNameInput,
        },
      });
    }

    if (isEditing !== prevState.isEditing && this._isRowValid(params)) {
      this.props.saveVoucherConfigGiftItem({
        id: params.id,
        quantity: params.quantity,
        idStr: params.idStr,
        getcare_product_bonus: { ...params.getcare_product_bonus },
        isEditing,
      });
    }

    if (prevState.isItemChecked !== isItemChecked) {
      this.props.selectVoucherConfigGiftItem({
        idStr: this.props.idStr,
        id: this.props.id,
      });
    }
  }

  _getInitParams = (params) => {
    return {
      getcare_product_bonus: params.getcare_product_bonus
        ? params.getcare_product_bonus
        : null,
      idStr: params.idStr ? params.idStr : null,
      id: params.id ? params.id : null,
      quantity: params.quantity ? params.quantity : null,
    };
  };

  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
      productBonusOptions: this._initproductBonusOptions(this.props),
      productCodeInput: '',
      productNameInput: '',
    });
  };

  _initproductBonusOptions = (params) => {
    return params.currentList ? params.currentList : [];
  };

  _loadSuggestedProduct = debounce(async ({ params }) => {
    const { data: response } = await voucherApi.findProductBonus({
      params: {
        ...params,
      },
    });
    if (!response?.result || !response.data) return;
    const searchedResults = response.data.map((item) => {
      const { id, name, code } = item;
      return {
        id,
        code,
        name,
        getcare_product_bonus: { ...item },
      };
    });
    this.setState({
      productBonusOptions: response.data
        ? this._filteredOptions(searchedResults)
        : [],
    });
  }, 500);

  _filteredOptions = (fullArr) => {
    return fullArr.filter((p) => {
      return (
        p.code === this.props.getcare_product_bonus?.code ||
        !this.props.currentList.some(
          (item) => item.getcare_product_bonus?.code === p.code
        )
      );
    });
  };

  _hasQuantityNumber = (params) => {
    return !!params.quantity && Number(params.quantity) > 0;
  };

  _getProductOptionLabel = (option) => {
    if (option.code && option.name) {
      return `${option.code} - ${option.name}`;
    } else return '';
  };

  _hasProduct = (params) => {
    return !!params?.id && !!params?.quantity;
  };

  _isRowValid = (params) => {
    return this._hasProduct(params) && this._hasQuantityNumber(params);
  };

  handleCheckbox = () => {
    this.setState((prevState) => {
      return {
        isItemChecked: !prevState.isItemChecked,
      };
    });
  };

  handleRowClick = () => {
    if (this.props.readOnly) {
      return;
    }

    if (!this.state.isEditing) {
      this.handleEdit();
    }
  };

  handleEdit = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  };

  handleCancel = (e) => {
    if (e) e.stopPropagation();
    if (this._isRowValid(this.props) || !this.state.isEditing) {
      this._resetState();
      return;
    }
    this.props.removeVoucherConfigGiftItem([
      {
        id: this.props.id,
        idStr: this.props.idStr,
      },
    ]);
  };

  handleRemove = (e) => {
    if (e) e.stopPropagation();
    this.props.removeVoucherConfigGiftItem([
      {
        id: this.props.id,
        idStr: this.props.idStr,
      },
    ]);
  };

  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: false,
    });
  };

  handleSaveField = (e, value) => {
    const parsedValue = parseInt(value);
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]:
          e.target.type === 'number' ? validNumber(parsedValue) : parsedValue,
      },
    });
  };

  handleExportCSV = () => {
    this.props.exportVoucher({
      id: this.props.id,
    });
  };

  handleSelectedCustomerChange = (e, newValue) => {
    this.setState({
      params: {
        ...this.state.params,
        id: newValue.id || null,
        quantity: newValue.quantity,
        getcare_product_bonus: newValue.getcare_product_bonus,
      },
    });
  };

  render() {
    const { readOnly, isVoucherEditing, isChecked } = this.props;
    const { productBonusOptions, params, isEditing } = this.state;
    const { getcare_product_bonus } = params;
    return (
      <div
        className={`${classes.Item} ${gridClasses.Row} ${gridClasses.RowGift}`}
      >
        <div className={`${gridClasses.Col} ${classes.CodeCol}`}>
          <Checkbox
            size="small"
            name="bonus_mode"
            checked={isChecked}
            onChange={this.handleCheckbox}
          />
        </div>          
        <div className={gridClasses.Col}>
          {isEditing ? (
            <Autocomplete
              openOnFocus
              selectOnFocus
              disableClearable
              fullWidth
              size="small"
              handleHomeEndKeys={false}
              value={params.getcare_product_bonus}
              onChange={this.handleSelectedCustomerChange}
              options={productBonusOptions || []}
              filterOptions={(x) => x}
              renderOption={(option) => this._getProductOptionLabel(option)}
              getOptionLabel={(option) => (option && option.name) || ''}
              getOptionSelected={(option, value) =>
                value && value.id && option ? option.id === value.id : null
              }
              onInputChange={(e, newInputValue) => {
                this.setState({
                  productCodeInput: newInputValue,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="- Chọn -" />
              )}
            />
          ) : (
            getcare_product_bonus?.code || ''
          )}
        </div>
        <div className={gridClasses.Col}>
          {isEditing ? (
            <Autocomplete
              openOnFocus
              selectOnFocus
              disableClearable
              fullWidth
              size="small"
              handleHomeEndKeys={false}
              value={params.getcare_product_bonus}
              onChange={this.handleSelectedCustomerChange}
              options={productBonusOptions || []}
              filterOptions={(x) => x}
              renderOption={(option) => this._getProductOptionLabel(option)}
              getOptionLabel={(option) => (option && option.name) || ''}
              getOptionSelected={(option, value) =>
                value && value.id && option ? option.id === value.id : null
              }
              onInputChange={(e, newInputValue) => {
                this.setState({
                  productNameInput: newInputValue,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="- Chọn -" />
              )}
            />
          ) : (
            getcare_product_bonus?.name || ''
          )}
        </div>
        <div className={`${gridClasses.Col}`}>
          {isEditing ? (
            <TextField
              className={classes.Field}
              value={params.quantity || 0}
              placeholder="Nhập..."
              name="quantity"
              autoComplete="off"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              error={!this._hasQuantityNumber(params)}
              onChange={(e) => {
                this.handleSaveField(e, e.target.value);
              }}
            />
          ) : (
            `${params.quantity || 0}`
          )}
        </div>
        {this.props.isEditing ? (
          <div className={`${gridClasses.Col} ${gridClasses.Center}`}>
            {isEditing ? (
              <>
                <IconButton
                  onClick={this.handleCancel}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CancelOutlined fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  onClick={this.handleSave}
                  disabled={!this._isRowValid(params)}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={this._isRowValid(params) ? `primary` : `inherit`}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  disabled={readOnly}
                  onClick={this.handleRemove}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <DeleteOutline
                    fontSize="small"
                    color={readOnly ? 'inherit' : 'action'}
                  />
                </IconButton>
                <IconButton
                  disabled={readOnly}
                  onClick={this.handleEdit}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <EditOutlined
                    fontSize="small"
                    color={readOnly ? 'inherit' : 'primary'}
                  />
                </IconButton>
              </>
            )}
          </div>
        ) : (
          <div className={`${gridClasses.Col} ${gridClasses.Center}`}>
            <Button
              disabled={!isVoucherEditing ||  this.props.isEditing !== undefined}
              variant="contained"
              color="primary"
              size="small"
              onClick={this.handleExportCSV}
            >
              Export to CSV
            </Button>
          </div>
        )}
      </div>
    );
  }
}
