export const testPromotionOptions = [
  {
    id: 1,
    name: 'Check khuyến mãi theo khách hàng',
  },
];

export const orderListDisplayFields = [
  {
    name: 'getcare_id',
    label: 'Phahub ID',
  },
  {
    name: 'name',
    label: 'Sản phẩm',
  },
  {
    name: 'code',
    label: 'Mã hàng',
  },
  {
    name: 'vendor_name',
    label: 'Vendor',
  },
  {
    name: 'unit',
    label: 'Đơn vị',
  },
  {
    name: 'price',
    label: 'Đơn giá',
  },
  {
    name: 'quantiy_number',
    label: 'Số lượng',
  },
  {
    name: 'amount',
    label: 'Thành tiền',
  },
  {
    name: 'vat',
    label: 'VAT',
  },
];
export const orderListParamsmap = {
  page_size: 150,
  page: 1,
  order: '',
}
