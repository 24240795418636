import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import {
  getPromotionList,
  getPromotionPriorities,
  updatePromotionPriority,
  savePromotionListQueryString,
} from 'redux/actions/tmk/promotionActions';
import { removeWSEvent } from 'redux/actions/wsActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPromotionLoading,
  makeSelectPromotionList,
  makeSelectPromotionListTotal,
  makeSelectPromotionPriorities,
  makeSelectWSEvents,
} from 'redux/selectors';
import {
  listParamsMap,
  listDisplayFields,
  filterFields,
} from 'utils/constanst/tmkPromotionConstants';
import { FLUSH_CACHE_PROMOTION } from 'utils/constanst/wsConstants';
import { getSortsString, getDisplayFields, getQueryString, getQueryParams } from 'utils/helper';
import { withRouter } from 'react-router-dom';
import promotionApi from 'utils/api/tmk/promotionApi';
import { toast } from 'react-toastify';

import PromotionList from 'components/tmk/PromotionList/PromotionList';
import PromotionListFilters from 'components/tmk/PromotionList/PromotionListFilters/PromotionListFilters';
import ListPagination from 'components/ListPagination/ListPagination';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import classes from './Promotions.module.scss';

class Promotions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },

      isConfirmationOpen: false,
      selectedPromotionParams: undefined,

      flushCacheLoading: false,
      flushCacheEnabled: true,
    };
  }
  componentDidMount() {
    this.initUrlSync();
    this.initOtherData();
  }
  componentDidUpdate(prevProps, prevState) {
    const { location, events } = this.props;
    if (location.search !== prevProps.location.search) {
      if (['', null, undefined].includes(location.search)) {
        this.initUrlSync();
        return;
      }
      this.reinitListData();
    }
    if (events && events.length && events !== prevProps.events) {
      const flushCacheEvent = this.getFlushCachePromotionEvent();
      if (flushCacheEvent && flushCacheEvent.data && flushCacheEvent.data.percent >= 100) {
        this.props.removeWSEvent({
          event: FLUSH_CACHE_PROMOTION,
          indexField: 'event',
        });
        this.setState({ flushCacheEnabled: true });
      }
    }
  }

  initUrlSync = () => {
    const { location } = this.props;
    const initalQueryStringFromDefaultParams = getQueryString(listParamsMap);
    // check if default listParams is different from inital location search
    if (['', null, undefined].includes(location.search) && initalQueryStringFromDefaultParams !== location.search) {
      this.props.history.replace({ search: initalQueryStringFromDefaultParams});
      return;
    }
    this.reinitListData();
  }
  reinitListData = () => {
    const { location } = this.props;
    const newParams = getQueryParams(location.search);
    this.setState({
      listParams: newParams,
    });
    this.props.getPromotionList({
      params: newParams,
    });
    this.props.savePromotionListQueryString(location.search);
  }
  synUrl = debounce((newParams) => {
    this.props.history.push({ search: getQueryString(newParams)});
  }, 500);

  initOtherData = () => {
    this.props.getPromotionPriorities();
  }
  getFlushCachePromotionEvent = () => {
    return this.props.events.find(e => e.event === FLUSH_CACHE_PROMOTION);
  }

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange({ order: newSortString });
  };
  handleFilterChange = (filterParams, forceResetPage: false) => {
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...filterParams,
      page: 1,
    } : {
      ...this.state.listParams,
      ...filterParams,
    };
    this.setState({
      listParams: newParams,
    }, () => {
      this.synUrl(newParams);
    });
  };
  handlePriorityChange = ({ id, code, priority }) => {
    this.setState({
      selectedPromotionParams: { id, code, priority },
    });
    this.handleOpenConfirmation();
  }
  handleUpdatePromotionPriority = () => {
    const { selectedPromotionParams } = this.state;
    this.props.updatePromotionPriority({
      params: {
        items: [{
          id: selectedPromotionParams.id,
          getcare_promotion_priority_id: selectedPromotionParams.priority.id,
        }]
      }
    });
    this.handleCloseConfirmation();
  }

  handleOpenConfirmation = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isConfirmationOpen: true,
    });
  }
  handleCloseConfirmation = () => {
    this.setState({
      isConfirmationOpen: false,
    });
    this.setState({
      selectedPromotionParams: undefined,
    });
  }

  createNewPromotion = () => {
    this.props.history.push(`/promotion/create`);
  }

  handleFlushCache = async () => {
    this.setState({ flushCacheLoading: true, flushCacheEnabled: false });
    try {
      const { data: response } = await promotionApi.flushPromotionsCache();
      if (!response?.result) {
        toast.error(response.message);
        return;
      }
      this.setState({ flushCacheLoading: false });
      this.props.getPromotionList({
        params: this.state.listParams,
      });
    } catch(err) {
      this.setState({ flushCacheLoading: false, flushCacheEnabled: true });
      toast.error(err);
    }
  }

  render() {
    const { promotionList, promotionListTotal, loading, promotionPriorities } = this.props;
    const { listParams, selectedPromotionParams, isConfirmationOpen, flushCacheLoading, flushCacheEnabled } = this.state;
    const isListLoading = loading;
    const flushCachePercentage = this.getFlushCachePromotionEvent()?.data?.percent;

    return (
      <React.Fragment>
        <div className={`${classes.PageWrap} ${flushCacheLoading ? 'OverlayLoading' : ''}`}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách chương trình khuyến mãi</h1>
            <Button
              variant="outlined"
              color="secondary"
              disabled={flushCachePercentage >= 0 || !flushCacheEnabled}
              onClick={this.handleFlushCache}
            >
              Flush cache
              { flushCachePercentage >= 0 && (<>
                ...<span className={classes.FlushCachePercentage}>{ `${parseFloat(flushCachePercentage).toFixed(2)}%` }</span>
              </>)}
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={this.createNewPromotion}
            >Tạo mới</Button>
          </div>
          <div className={classes.PageMain}>
            <PromotionListFilters
              filterFields={filterFields}
              queryParamsMap={listParams}
              onFilterChange={this.handleFilterChange}
            />
            <PromotionList
              displayFields={getDisplayFields(
                listParams,
                listDisplayFields
              )}
              promotionList={promotionList}
              isLoading={isListLoading}
              promotionPriorities={promotionPriorities}
              onSortChange={this.handleSortChange}
              onPriorityChange={this.handlePriorityChange}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={Number(listParams.page)}
              pageSize={Number(listParams.page_size)}
              total={promotionListTotal}
              listName="chương trình"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
        { isConfirmationOpen &&
          <ConfirmationDialog
            isOpen={isConfirmationOpen}
            title="Xác nhận thay đổi độ ưu tiên"
            message={(<>
              <p>{`Bạn đang thay đổi độ ưu tiên của CTMK `} <strong>{selectedPromotionParams?.code}</strong>{` thành `}<strong>{selectedPromotionParams?.priority?.name}</strong>.
                <br/>
                Việc thay đổi có thể ảnh hưởng tới việc áp dụng các CTKM trên đơn hàng.
              </p>
              <p>Bạn có thực sự muốn thay đổi không?</p>
            </>)}
            onClose={this.handleCloseConfirmation}
            onSubmit={this.handleUpdatePromotionPriority}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  promotionList: makeSelectPromotionList(),
  promotionListTotal: makeSelectPromotionListTotal(),
  loading: makeSelectPromotionLoading(),
  promotionPriorities: makeSelectPromotionPriorities(),
  events: makeSelectWSEvents(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getPromotionList: (payload) => dispatch(getPromotionList(payload)),
    getPromotionPriorities: (payload) => dispatch(getPromotionPriorities(payload)),
    updatePromotionPriority: (payload) => dispatch(updatePromotionPriority(payload)),
    savePromotionListQueryString: (payload) => dispatch(savePromotionListQueryString(payload)),
    removeWSEvent: (payload) => dispatch(removeWSEvent(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(Promotions);
