import { takeLatest } from 'redux-saga/effects';

import { CHECK_AUTH, SIGN_OUT } from 'redux/constants/authConstants';
import { checkAuthentication, signMeOut } from 'saga/authSaga';

import {
  GET_PROMOTION_TYPES,
  GET_PROMOTION_OPERATORS,
  GET_PROMOTION_COMPARES,
  GET_PROMOTION_PRIORITIES,
  GET_PROMOTION_APPLIES,
  GET_PROMOTION_DISCOUNTS,
  GET_PROMOTION_LIST,
  GET_PROMOTION_ORDER_LIST,
  GET_PROMOTION,
  UPDATE_PROMOTION,
  GET_PROMOTION_HISTORY,
  EXPORT_SALE_PRODUCT_TEMPLATE,
  UPDATE_PROMOTION_PRIORITY,
} from 'redux/constants/tmk/promotionConstants';
import {
  loadPromotionTypes,
  loadPromotionOperators,
  loadPromotionCompares,
  loadPromotionPriorities,
  loadPromotionApplies,
  loadPromotionDiscounts,
  loadPromotionList,
  loadPromotionOrderList,
  loadPromotion,
  postPromotion,
  loadPromotionHistory,
  exportSaleProductTemplate,
  updatePromotionPriority,
} from 'saga/tmk/promotionSaga';

import {
  GET_BANNER,
  GET_BANNER_SIZE,
  GET_BANNER_TYPE,
  GET_BANNER_LOCATION,
  GET_BANNER_LIST,
  UPDATE_BANNER,
  GET_CUSTOMER_LIST,
  GET_SALE_CHANNEL_LIST
} 
from 'redux/constants/tmk/bannerConstants';
import 
{ 
  GET_VOUCHER, 
  GET_VOUCHER_LIST, 
  UPDATE_VOUCHER, 
  CREATE_VOUCHER, 
  GET_USED_VOUCHER_LIST,
  GET_VOUCHER_VENDOR_LIST,
  EXPORT_VOUCHER_UNUSED_LIST,
  EXPORT_VOUCHER_GIFTCODES_UNUSED_LIST
} 
from 'redux/constants/tmk/voucherConstants';

import 
{ 
  exportVoucherGiftCodeUnusedList,
  exportVoucherUnusedList,
  loadUsedVoucherList,
  loadVoucher, 
  loadVoucherList, 
  loadVoucherVendor, 
  postVoucher,
  putVoucher
} 
from './tmk/voucherSaga';

import 
{
  loadBannerSize,
  loadBannerType,
  loadBannerLocation,
  loadBanner,
  loadBannerList,
  postBanner,
  loadCustomerList,
  loadSaleChannelList
}
from 'saga/tmk/bannerSaga';

import {
  CHECK_PROMOTION,
} from 'redux/constants/tmk/testPromotionConstants';
import {
  testPromotion,
} from 'saga/tmk/testPromotionSaga';

/**
 * Root saga manages watcher lifecycle
 */
export default function* defaultSaga() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount

  // auth
  yield takeLatest(CHECK_AUTH, checkAuthentication);
  yield takeLatest(SIGN_OUT, signMeOut);

  // promotion
  yield takeLatest(GET_PROMOTION_TYPES, loadPromotionTypes);
  yield takeLatest(GET_PROMOTION_OPERATORS, loadPromotionOperators);
  yield takeLatest(GET_PROMOTION_COMPARES, loadPromotionCompares);
  yield takeLatest(GET_PROMOTION_PRIORITIES, loadPromotionPriorities);
  yield takeLatest(GET_PROMOTION_APPLIES, loadPromotionApplies);
  yield takeLatest(GET_PROMOTION_DISCOUNTS, loadPromotionDiscounts);
  yield takeLatest(GET_PROMOTION_LIST, loadPromotionList);
  yield takeLatest(GET_PROMOTION_ORDER_LIST, loadPromotionOrderList);
  yield takeLatest(GET_PROMOTION, loadPromotion);
  yield takeLatest(UPDATE_PROMOTION, postPromotion);
  yield takeLatest(GET_PROMOTION_HISTORY, loadPromotionHistory);
  yield takeLatest(EXPORT_SALE_PRODUCT_TEMPLATE, exportSaleProductTemplate);
  yield takeLatest(UPDATE_PROMOTION_PRIORITY, updatePromotionPriority);

  // test promotion
  yield takeLatest(CHECK_PROMOTION, testPromotion);
  // banner
  yield takeLatest(UPDATE_BANNER, postBanner);
  yield takeLatest(GET_BANNER_LIST, loadBannerList);
  yield takeLatest(GET_BANNER, loadBanner)
  yield takeLatest(GET_BANNER_SIZE, loadBannerSize);
  yield takeLatest(GET_BANNER_TYPE, loadBannerType);
  yield takeLatest(GET_BANNER_LOCATION, loadBannerLocation);
  yield takeLatest(GET_CUSTOMER_LIST, loadCustomerList);
  yield takeLatest(GET_SALE_CHANNEL_LIST, loadSaleChannelList);

  //voucher 
  yield takeLatest(GET_VOUCHER, loadVoucher);
  yield takeLatest(GET_VOUCHER_LIST, loadVoucherList);
  yield takeLatest(CREATE_VOUCHER, postVoucher);
  yield takeLatest(UPDATE_VOUCHER, putVoucher);
  yield takeLatest(EXPORT_VOUCHER_UNUSED_LIST, exportVoucherUnusedList);
  yield takeLatest(EXPORT_VOUCHER_GIFTCODES_UNUSED_LIST, exportVoucherGiftCodeUnusedList);
  yield takeLatest(GET_USED_VOUCHER_LIST, loadUsedVoucherList);
  yield takeLatest(GET_VOUCHER_VENDOR_LIST, loadVoucherVendor);
}
