import {
  SAVE_LOADING,
  GET_BANNER,
  GET_BANNER_LIST,
  UPDATE_BANNER,
  RESET_BANNER,
  SAVE_BANNER_LIST,
  SAVE_BANNER_LIST_TOTAL,
  SAVE_BANNER_LIST_QUERY_STRING,
  SAVE_BANNER_DETAILS,
  GET_BANNER_SIZE,
  GET_BANNER_TYPE,
  GET_BANNER_LOCATION,
  SAVE_BANNER_SIZE,
  SAVE_BANNER_TYPE,
  SAVE_BANNER_LOCATION,
  SAVE_BANNER_CUSTOMER_LIST,
  SAVE_BANNER_SALE_CHANNEL_LIST,
  SAVE_ORI_BANNER_DETAILS,
  SAVE_ORI_BANNER_CUSTOMER_LIST,
  SAVE_ORI_BANNER_SALE_CHANNEL_LIST,
  SAVE_CUSTOMER_LIST,
  SAVE_SALE_CHANNEL_LIST,
  GET_CUSTOMER_LIST,
  GET_SALE_CHANNEL_LIST,
  SAVE_CUSTOMER_LIST_ITEM,
  SAVE_SALE_CHANNEL_LIST_ITEM,
  REMOVE_CUSTOMER_LIST_ITEM,
  REMOVE_SALE_CHANNEL_LIST_ITEM
} from 'redux/constants/tmk/bannerConstants';

export function getBannerList(payload) {
  return {
    type: GET_BANNER_LIST,
    payload,
  };
}

export function getBanner(payload) {
  return {
    type: GET_BANNER,
    payload
  }
}

export function getCustomerList(payload) {
  return {
    type: GET_CUSTOMER_LIST,
    payload
  }
}

export function getSaleChannelList(payload) {
  return {
    type: GET_SALE_CHANNEL_LIST,
    payload
  }
}

export function getBannerSize(payload) {
  return {
    type: GET_BANNER_SIZE,
    payload
  }
}

export function getBannerLocation(payload) {
  return {
    type: GET_BANNER_LOCATION,
    payload
  }
}

export function getBannerType(payload) {
  return {
    type: GET_BANNER_TYPE,
    payload
  }
}

export function updateBanner(payload) {
  return {
    type: UPDATE_BANNER,
    payload,
  };
}

export function resetBanner(payload) {
  return {
    type: RESET_BANNER,
    payload,
  };
}

export function saveBannerDetails(bannerDetails) {
  return {
    type: SAVE_BANNER_DETAILS,
    bannerDetails,
  };
}

export function saveOriBannerDetails(oriBannerDetails) {
  return {
    type: SAVE_ORI_BANNER_DETAILS,
    oriBannerDetails,
  };
}

export function saveBannerSize(bannerSize) {
  return {
    type: SAVE_BANNER_SIZE,
    bannerSize
  }
}

export function saveBannerType(bannerType) {
  return {
    type: SAVE_BANNER_TYPE,
    bannerType
  }
}

export function saveBannerLocation(bannerLocation) {
  return {
    type: SAVE_BANNER_LOCATION,
    bannerLocation
  }
}

export function saveBannerCustomerList(bannerCustomerList) {
  return {
    type: SAVE_BANNER_CUSTOMER_LIST,
    bannerCustomerList
  }
}

export function saveBannerSaleChannelList(bannerSaleChannelList) {
  return {
    type: SAVE_BANNER_SALE_CHANNEL_LIST,
    bannerSaleChannelList
  }
}

export function saveOriBannerCustomerList(oriBannerCustomerList) {
  return {
    type: SAVE_ORI_BANNER_CUSTOMER_LIST,
    oriBannerCustomerList
  }
}

export function saveOriBannerSaleChannelList(oriBannerSaleChannelList) {
  return {
    type: SAVE_ORI_BANNER_SALE_CHANNEL_LIST,
    oriBannerSaleChannelList
  }
}


export function saveBannerList(bannerList) {
  return {
    type: SAVE_BANNER_LIST,
    bannerList,
  };
}

export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading,
  };
}

export function saveBannerListTotal(bannerListTotal) {
  return {
    type: SAVE_BANNER_LIST_TOTAL,
    bannerListTotal,
  };
}

export function saveBannerListQueryString(bannerListQueryString) {
  return {
    type: SAVE_BANNER_LIST_QUERY_STRING,
    bannerListQueryString,
  };
}

export function saveCustomerList(customerList) {
  return {
    type: SAVE_CUSTOMER_LIST,
    customerList,
  };
}

export function saveSaleChannelList(saleChannelList) {
  return {
    type: SAVE_SALE_CHANNEL_LIST,
    saleChannelList,
  };
}

export function saveCustomerListItem(customerListItem) {
  return {
    type: SAVE_CUSTOMER_LIST_ITEM,
    customerListItem,
  };
}

export function saveSaleChannelListItem(saleChannelListItem) {
  return {
    type: SAVE_SALE_CHANNEL_LIST_ITEM,
    saleChannelListItem,
  };
}

export function removeCustomerListItem(customerListItem) {
  return {
    type: REMOVE_CUSTOMER_LIST_ITEM,
    customerListItem
  }
}

export function removeSaleChannelListItem(saleChannelListItem) {
  return {
    type: REMOVE_SALE_CHANNEL_LIST_ITEM,
    saleChannelListItem,
  };
}