import LayoutAdmin from 'layouts/LayoutAdmin/LayoutAdmin';
import Promotions from 'views/tmk/Promotions/Promotions';
import Promotion from 'views/tmk/Promotion/Promotion';
import PromotionOrder from 'views/tmk/PromotionOrder/PromotionOrder';
import PromotionTest from 'views/tmk/PromotionTest/PromotionTest';
import PermissionDenied from 'views/PermissionDenied/PermissionDenied';
import PageNotFound from 'views/PageNotFound/PageNotFound';
import Banners from 'views/tmk/Banners/Banners';
import Banner from 'views/tmk/Banner/Banner';
import Vouchers from 'views/tmk/Vouchers/Vouchers';
import VouchersUsed from 'views/tmk/VouchersUsed/VouchersUsed';
import Voucher from 'views/tmk/Voucher/Voucher';
export const routes = [
  {
    path: '/',
    component: LayoutAdmin,
    routes: [
      {
        path: '/promotion',
        component: Promotions,
        exact: true,
      },
      //Route to banner list
      {
        path: '/banner',
        component: Banners,
        exact: true,
      },
      {
        path: '/voucher',
        component: Vouchers,
        exact: true,
      },
      {
        path: '/voucherused',
        component: VouchersUsed,
        exact: true,
      },
      {
        path: '/voucher/:id',
        component: Voucher,
        exact: true,
      },
      {
        path: '/banner/:id',
        component: Banner,
        exact: true,
      },
      {
        path: '/promotion/:id',
        component: Promotion,
        exact: true,
      },
      {
        path: '/testpromotion',
        component: PromotionTest,
        exact: true,
      },
      // {
      //   path: '/promotion-order/:id',
      //   component: PromotionOrderID,
      //   exact: true,
      // },
      {
        path: '/promotion-order',
        component: PromotionOrder,
        exact: true,
      },
      {
        path: '/403',
        component: PermissionDenied,
        exact: true,
      },
      {
        path: '/*',
        component: PageNotFound,
      },
    ],
  },
];
