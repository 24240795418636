import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import classes from './PromotionLevelsDialog.module.scss';
import PromotionLevel from './PromotionLevel/PromotionLevel';

class PromotionLevelsDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this._initValidMap(),
    }
  }

  _initValidMap = () => {
    return this.props.promotionLevels.reduce((memo, item) => {
      memo[`is_valid_${item.id}`] = false;
      return memo;
    }, {});
  }
  _isFormValid = () => {
    return !Object.keys(this.state).some(key => {
      return key.match(/^is_valid_\d+$/g) && !this.state[key];
    });
  };
  handleLevelValidChange = ({ isValid, usedPromotionItem }) => {
    this.setState({
      [`is_valid_${usedPromotionItem.id}`]: isValid,
    });
  }
  handleSubmit = () => {
    this.props.onSubmit();
    this.onClose();
  };
  onClose = () => {
    this.props.onClose();
  };

  render() {
    const { isOpen, isLoading, title, promotionLevels, promotionId } = this.props;
    const isFormValid = this._isFormValid();

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        onEntered={this.onEntered}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
        maxWidth={false}
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent className={classes.DialogContent}>
            {promotionLevels.map(levelItem => (
              <PromotionLevel
                key={`level-${levelItem.id}`}
                {...levelItem}
                promotionId={promotionId}
                onLevelValidChange={this.handleLevelValidChange}
              />
            ))}
          </DialogContent>
          <DialogActions className={classes.DialogActions}>
            <Button variant="outlined" onClick={this.props.onClose}>
              Huỷ bỏ
            </Button>
            <Button
              disabled={!isFormValid}
              variant="contained"
              onClick={this.handleSubmit}
              color="primary"
              autoFocus
            >
              Xác nhận
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

PromotionLevelsDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  promotionId: PropTypes.number,
  promotionLevels: PropTypes.array,
  onSubmit: PropTypes.func,
};

PromotionLevelsDialog.defaultProps = {
  isOpen: false,
  title: '',
};

export default PromotionLevelsDialog;
