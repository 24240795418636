export const APPLY_TO_ALL = 1;
export const APPLY_TO_CUSTOMER = 2;
export const APPLY_TO_SALE_CHANNEL = 3;

export const bannerCustomerApplyMap = {
  [APPLY_TO_ALL]: 'Tất cả',
  [APPLY_TO_CUSTOMER]: 'Theo danh sách',
  [APPLY_TO_SALE_CHANNEL]: 'Theo khu vực bán hàng',
};

export const bannerCustomerApplyOptions = [
  {
    id: APPLY_TO_ALL,
    name: bannerCustomerApplyMap[APPLY_TO_ALL],
  },
  {
    id: APPLY_TO_CUSTOMER,
    name: bannerCustomerApplyMap[APPLY_TO_CUSTOMER],
  },
  {
    id: APPLY_TO_SALE_CHANNEL,
    name: bannerCustomerApplyMap[APPLY_TO_SALE_CHANNEL],
  },
]

export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  active: [1],
};
export const APPLY_AWAITING = -1;
export const APPLY_IN_PROGRESS = 1;
export const APPLY_UNDEFINED = 0;
export const applyStatusMap = {
  [APPLY_AWAITING]: 'Chưa áp dụng',
  [APPLY_IN_PROGRESS]: 'Đang áp dụng',
  [APPLY_UNDEFINED]: '-',
};

export const STATUS_ACTIVE = 1;
export const STATUS_INACTIVE = -1;
export const STATUS_EMPTY = 0;

export const bannerStatusMap = {
  [STATUS_ACTIVE]: 'Hoạt động',
  [STATUS_INACTIVE]: 'Dừng hoạt động',
  [STATUS_EMPTY]: '-'
};

export const bannerStatuses = [
  {
    id: STATUS_ACTIVE,
    name: bannerStatusMap[STATUS_ACTIVE],
  },
  {
    id: STATUS_INACTIVE,
    name: bannerStatusMap[STATUS_INACTIVE],
  },
];

export const IMAGE_TYPE = 1;
export const VIDEO_TYPE = 2;

export const bannerTypesMap = {
  [IMAGE_TYPE]: 'Hình ảnh',
  [VIDEO_TYPE]: 'Video',
};

export const bannerTypeOptions = [
  {
    id: IMAGE_TYPE,
    name: bannerTypesMap[IMAGE_TYPE],
  },
  {
    id: VIDEO_TYPE,
    name: bannerTypesMap[VIDEO_TYPE],
  },
];

export const HOME_PAGE_ID = 1;
export const SIDE_BAR_ID = 2;
export const HOME_ECOM_ID = 3;

export const bannerLocationMap = {

  [HOME_PAGE_ID]: '001 - Home Page',
  [SIDE_BAR_ID]: '002 - Sidebar',
  [HOME_ECOM_ID]: '003 - Home Ecom',
};

export const bannerLocationOptions = [
  {
    id: HOME_PAGE_ID,
    name: bannerLocationMap[HOME_PAGE_ID],
  },
  {
    id: SIDE_BAR_ID,
    name: bannerLocationMap[SIDE_BAR_ID],
  },
  {
    id: HOME_ECOM_ID,
    name: bannerLocationMap[HOME_ECOM_ID],
  },
];

export const SIZE_320_320 = 1;
export const SIZE_960_320 = 2;
export const bannerSizeMap = {
  [SIZE_320_320]: '320x320',
  [SIZE_960_320]: '960x320',
};
export const bannerSizeOptions = [
  {
    id: SIZE_320_320,
    name: bannerSizeMap[SIZE_320_320],
  },
  {
    id: SIZE_960_320,
    name: bannerSizeMap[SIZE_960_320],
  },
];

export const filterFields = [
  {
    name: 'active',
    label: 'Trạng thái',
    value: [STATUS_ACTIVE],
    type: 'custom',
    placeholder: '- Chọn -',
  },
  {
    name: 'name',
    label: 'Tên',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'size',
    label: 'Size',
    value: '',
    type: 'custom',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_banner_type_id',
    label: 'Loại',
    value: [STATUS_ACTIVE],
    type: 'custom',
    placeholder: '- Chọn -',
  },
  {
    name: 'getcare_banner_location_id',
    label: 'Location ID',
    value: [STATUS_ACTIVE],
    type: 'custom',
    placeholder: '- Chọn -',
  },
];

export const listDisplayFields = [
  {
    name: 'image',
    label: 'Hình ảnh',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Tên',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'size',
    label: 'Size',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'id',
    label: 'Location ID',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'no',
    label: 'Số thứ tự',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_banner_type_id',
    label: 'Loại',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'start_date',
    label: 'Ngày bắt đầu',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'end_date',
    label: 'Ngày kết thúc',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'is_apply',
    label: 'Áp dụng',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'active',
    label: 'Trạng thái',
    sortable: true,
    sortDir: '',
  },
];

export const listBannerCustomerListDisplayFields = [
  {
    name: 'name',
    label: 'Tên',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'customer_id',
    label: 'Mã KH',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'phone_number',
    label: 'Số điện thoại',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'address',
    label: 'Địa chỉ',
    sortable: false,
    sortDir: '',
  },
];

export const listBannerSaleChannelDisplayFields = [
  {
    name: 'name',
    label: 'Mã khu vực',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'customer_id',
    label: 'Số lượng khách hàng',
    sortable: false,
    sortDir: '',
  }
]