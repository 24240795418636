import React, { PureComponent } from 'react';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectCustomerList,
  makeSelectOriVoucherCustomerList,
  makeSelectVoucherCustomerList,
} from 'redux/selectors';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import classes from './VoucherCustomer.module.scss';
import gridClasses from './VoucherCustomerGrid.module.scss';
import {
  listParamsMap,
  listTabCustomerDisplayFields,
  VOUCHER_CUSTOMER_TYPE,
} from 'utils/constanst/tmkVoucherConstant';
import { getDisplayFields, genID } from 'utils/helper';
import PropTypes from 'prop-types';
import VoucherCustomerItem from './VoucherCustomerItem/VoucherCustomerItem';
import { Checkbox } from '@material-ui/core';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import {
  deselectAllVoucherCustomer,
  removeMultipleVoucherCustomerItem,
  removeVoucherCustomerListItem,
  saveVoucherCustomerListItem,
  selectAllVoucherCustomer,
  selectVoucherCustomerItem,
} from 'redux/actions/tmk/voucherAction';
class VoucherCustomer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isHeaderChecked: false,
      listParams: { ...listParamsMap },
    };
  }

  handleCheckbox = () => {
    this.setState((prevState) => {
      return {
        isHeaderChecked: !prevState.isHeaderChecked,
      };
    });

    if (this.state.isHeaderChecked) {
      this.props.deselectAllVoucherCustomer();
      return;
    } else {
      this.props.selectAllVoucherCustomer();
    }
  };

  handleSaveVoucherCustomerItem = (params) => {
    this.props.saveVoucherCustomerListItem({
      ...params,
    });
  };

  handleRemoveBannerCustomerItem = (params) => {
    this.props.removeVoucherCustomerListItem([{ ...params }]);
  };

  handleCreateNewRow = () => {
    const ID = genID();
    this.props.saveVoucherCustomerListItem({
      getcare_customer: null,
      getcare_erp_group: null,
      idStr: ID,
      type_id: VOUCHER_CUSTOMER_TYPE,
      isEditing: true,
      isChecked: false,
    });
  };

  handleDeleteSelectedItem = () => {
    this.props.removeMultipleCustomerItem();
    this.setState({
      isHeaderChecked: false,
    })
  };

  render() {
    const {
      voucherDetails,
      voucherCustomerList,
      readOnly,
      isEditing,
      disabled,
    } = this.props;
    const displayFields = getDisplayFields(
      this.state.listParams,
      listTabCustomerDisplayFields
    );

    const isItemChecked = (element) => element.isChecked;
    const disableDeleteBulk = voucherCustomerList.some(isItemChecked)
    return (
      <div className={classes.VoucherCustomer}>
        <div className={`${classes.List}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            <div className={classes.Col}>
              <Checkbox
                onChange={this.handleCheckbox}
                checked={this.state.isHeaderChecked}
              />
            </div>
            {displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                className={`${gridClasses.Col} ${gridClasses[item.className]}`}
                {...item}
              />
            ))}
            <div className={classes.Col}>
              <Button
                disabled={readOnly || disabled}
                color="primary"
                variant="contained"
                size="small"
                onClick={this.handleCreateNewRow}
              >
                Thêm
              </Button>
            </div>
          </div>
          <div className={classes.ItemContainer}>
            {voucherCustomerList.length > 0 ? (
              voucherCustomerList.map((item, index) => (
                <VoucherCustomerItem
                  key={`voucher-customer-${item.id || item.idStr}`}
                  {...item}
                  index={index}
                  readOnly={readOnly}
                  currentList={[
                    ...voucherCustomerList
                  ]}
                  selectVoucherCustomerItem={
                    this.props.selectVoucherCustomerItem
                  }
                  saveCustomerListItem={this.handleSaveVoucherCustomerItem}
                  removeCustomerListItem={this.handleRemoveBannerCustomerItem}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
        <div className={classes.ActionDelete}>
          <Button
            disabled={voucherCustomerList.length === 0 || !disableDeleteBulk}
            color="primary"
            variant="contained"
            size="small"
            startIcon={<DeleteOutline />}
            onClick={this.handleDeleteSelectedItem}
          >
            Xóa
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  voucherCustomerList: makeSelectVoucherCustomerList(),
  oriVoucherCustomerList: makeSelectOriVoucherCustomerList(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    selectVoucherCustomerItem: (payload) =>
      dispatch(selectVoucherCustomerItem(payload)),
    selectAllVoucherCustomer: (payload) =>
      dispatch(selectAllVoucherCustomer(payload)),
    deselectAllVoucherCustomer: (payload) =>
      dispatch(deselectAllVoucherCustomer(payload)),
    saveVoucherCustomerListItem: (payload) =>
      dispatch(saveVoucherCustomerListItem(payload)),
    removeVoucherCustomerListItem: (payload) =>
      dispatch(removeVoucherCustomerListItem(payload)),
    removeMultipleCustomerItem: (payload) =>
    dispatch(removeMultipleVoucherCustomerItem(payload)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(VoucherCustomer);
VoucherCustomer.propTypes = {
  isEditing: PropTypes.bool,
  onRemoveCustomerItem: PropTypes.func,
  onSaveCustomerItem: PropTypes.func,
};

VoucherCustomer.defaultProps = {
  isEditing: false,
};
