import React, { PureComponent } from 'react';
import classes from './VoucherConfigItem.module.scss';
import gridClasses from '../VoucherConfigGrid.module.scss';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { validNumber } from 'utils/helper';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';
import { Button } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';

export default class VoucherConfigMoneyItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isItemChecked: props.isChecked,
      isEditing: props.isEditing,
      params: this._getInitParams(props),
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const { params, isEditing, isItemChecked } = this.state;

    if (isEditing !== prevState.isEditing && this._isRowValid(params)) {
      this.props.saveVoucherConfigMoneyItem({
        id: params.id,
        amount: params.amount,
        quantity: params.quantity,
        isEditing,
        ...params,
      });
    }

    if (prevState.isItemChecked !== isItemChecked) {
      this.props.selectVoucherConfigMoneyItem({
        idStr: this.props.idStr,
        id: this.props.id,
      });
    }
  }

  _getInitParams = (params) => {
    return {
      idStr: params.idStr ? params.idStr : null,
      id: params.id ? params.id : null,
      quantity: params.quantity ? params.quantity : null,
      amount: params.amount ? params.amount : null,
    };
  };

  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
    });
  };

  _filteredOptions = (fullArr) => {
    return fullArr.filter((p) => {
      return (
        p.getcare_id === this.props.getcare_product?.getcare_id ||
        !this.props.currentList.some(
          (item) => item.getcare_product?.getcare_id === p.getcare_id
        )
      );
    });
  };

  handleCheckbox = () => {
    this.setState((prevState) => {
      return {
        isItemChecked: !prevState.isItemChecked,
      };
    });
  };

  _hasQuantityNumber = (params, key) => {
    return !!params[key] && Number(params[key]) > 0;
  };

  handleRowClick = () => {
    if (this.props.readOnly) {
      return;
    }

    if (!this.state.isEditing) {
      this.handleEdit();
    }
  };

  _isRowValid = (params) => {
    return (
      !!params.quantity &&
      Number(params.quantity) > 0 &&
      !!params.amount &&
      Number(params.amount) > 0
    );
  };

  handleEdit = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  };

  handleCancel = (e) => {
    if (e) e.stopPropagation();
    if (this._isRowValid(this.props) || !this.state.isEditing) {
      this._resetState();
      return;
    }
    this.props.removeVoucherConfigMonneyItem([
      {
        id: this.props.id,
        idStr: this.props.idStr,
      },
    ]);
  };

  handleRemove = (e) => {
    if (e) e.stopPropagation();
    this.props.removeVoucherConfigMonneyItem([
      {
        id: this.props.id,
        idStr: this.props.idStr,
      },
    ]);
  };

  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: false,
    });
  };

  handleSelectedCustomerChange = (e, newValue) => {
    this.setState({
      params: {
        ...this.state.params,
        id: newValue.id || null,
        amount: newValue.amount,
        quantity: newValue.quantity,
      },
    });
  };

  handleSaveField = (e, value) => {
    const parsedValue = parseInt(value);
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]:
          e.target.type === 'number' ? validNumber(parsedValue) : parsedValue,
      },
    });
  };

  handleExportCSV = () => {
    this.props.exportVoucher({
      id: this.state.params.id
    })
  }

  render() {
    const { readOnly, isVoucherEditing, isChecked } = this.props;
    const { params, isEditing } = this.state;
    return (
      <div
        className={`${classes.Item} ${gridClasses.Row} ${gridClasses.RowMoney}`}
      >
        <div className={`${gridClasses.Col} ${classes.CodeCol}`}>
          <Checkbox
            size="small"
            name="bonus_mode"
            checked={isChecked}
            onChange={this.handleCheckbox}
          />
        </div>      
        <div className={`${gridClasses.Col}`}>
          {isEditing ? (
            <TextField
              className={classes.Field}
              value={params.amount || 0}
              placeholder="Nhập..."
              name="amount"
              autoComplete="off"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              error={!this._hasQuantityNumber(params, 'amount')}
              onChange={(e) => {
                this.handleSaveField(e, e.target.value);
              }}
            />
          ) : (
            `${params.amount || 0}`
          )}
        </div>
        <div className={`${gridClasses.Col}`}>
          {isEditing ? (
            <TextField
              className={classes.Field}
              value={params.quantity || 0}
              placeholder="Nhập..."
              name="quantity"
              autoComplete="off"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              error={!this._hasQuantityNumber(params, 'quantity')}
              onChange={(e) => {
                this.handleSaveField(e, e.target.value);
              }}
            />
          ) : (
            `${params.quantity || 0}`
          )}
        </div>
        {this.props.isEditing ? (
          <div className={`${gridClasses.Col} ${gridClasses.Center}`}>
            {isEditing ? (
              <>
                <IconButton
                  onClick={this.handleCancel}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CancelOutlined fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  onClick={this.handleSave}
                  disabled={!this._isRowValid(params)}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={this._isRowValid(params) ? `primary` : `inherit`}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  disabled={readOnly}
                  onClick={this.handleRemove}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <DeleteOutline
                    fontSize="small"
                    color={readOnly ? 'inherit' : 'action'}
                  />
                </IconButton>
                <IconButton
                  disabled={readOnly}
                  onClick={this.handleEdit}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <EditOutlined
                    fontSize="small"
                    color={readOnly ? 'inherit' : 'primary'}
                  />
                </IconButton>
              </>
            )}
          </div>
        ) : (
          <div className={`${gridClasses.Col} ${gridClasses.Center}`}>
            <Button
              disabled={!isVoucherEditing ||  this.props.isEditing !== undefined}
              variant="contained"
              color="primary"
              onClick={this.handleExportCSV}
            >
              Export to CSV
            </Button>
          </div>
        )}
      </div>
    );
  }
}
