import React, { PureComponent } from 'react';
import classes from './VouchersUsed.module.scss';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import { debounce } from 'lodash';
import {
  listParamsMap,
  listDisplayFields,
  filterFields,
} from 'utils/constanst/tmkVoucherUsedConstants';
import {
  getSortsString,
  getDisplayFields,
  getQueryString,
  getQueryParams,
} from 'utils/helper';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectUsedVoucherList,
  makeSelectUsedVoucherListQueryString,
  makeSelectVoucherLoading,
  makeSelectWSEvents,
} from 'redux/selectors';
import { removeWSEvent } from 'redux/actions/wsActions';
import {} from 'redux/actions/tmk/bannerActions';
import { connect } from 'react-redux';
import VoucherUsedList from 'components/tmk/VoucherUsedList/VoucherUsedList';
import VoucherUsedListFilter from 'components/tmk/VoucherUsedList/VoucherUsedListFilter/VoucherUsedListFilter';
import {
  getUsedVoucherList,
  saveUsedVoucherListQueryString,
} from 'redux/actions/tmk/voucherAction';
class VouchersUsed extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
    };
  }
  componentDidMount() {
    this.initUrlSync();
  }

  componentDidUpdate(prevProps, prevState) {
    const { location, events } = this.props;
    if (location.search !== prevProps.location.search) {
      if (['', null, undefined].includes(location.search)) {
        this.initUrlSync();
        return;
      }
      this.reinitListData();
    }
  }

  createNewVoucher = () => {
    this.props.history.push(`/voucher/create`);
  };

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange({ order: newSortString });
  };

  handleFilterChange = (filterParams, forceResetPage: false) => {
    const newParams = forceResetPage
      ? {
          ...this.state.listParams,
          ...filterParams,
          page: 1,
        }
      : {
          ...this.state.listParams,
          ...filterParams,
        };
    this.setState(
      {
        listParams: newParams,
      },
      () => {
        this.synUrl(newParams);
      }
    );
  };

  synUrl = debounce((newParams) => {
    this.props.history.push({ search: getQueryString(newParams) });
  }, 500);

  initUrlSync = () => {
    const { location } = this.props;
    const initalQueryStringFromDefaultParams = getQueryString(listParamsMap);
    // check if default listParams is different from inital location search
    if (
      ['', null, undefined].includes(location.search) &&
      initalQueryStringFromDefaultParams !== location.search
    ) {
      this.props.history.replace({
        search: initalQueryStringFromDefaultParams,
      });
      return;
    }
    this.reinitListData();
  };

  reinitListData = () => {
    const { location } = this.props;
    const newParams = getQueryParams(location.search);
    this.setState({
      listParams: newParams,
    });
    this.props.getUsedVoucherList({
      params: newParams,
    });
    this.props.saveUsedVoucherListQueryString(location.search);
  };

  render() {
    const { usedVoucherList, loading } = this.props;
    const { listParams } = this.state;
    const isListLoading = loading;
    return (
      <>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách voucher đã sử dụng</h1>
          </div>
          <div className={classes.PageMain}>
            <VoucherUsedListFilter
              filterFields={filterFields}
              queryParamsMap={listParams}
              onFilterChange={this.handleFilterChange}
            />
            <VoucherUsedList
              onSortChange={this.handleSortChange}
              usedVoucherList={usedVoucherList}
              isLoading={isListLoading}
              displayFields={getDisplayFields(listParams, listDisplayFields)}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  usedVoucherList: makeSelectUsedVoucherList(),
  loading: makeSelectVoucherLoading(),
  usedVoucherListQueryString: makeSelectUsedVoucherListQueryString(),
  events: makeSelectWSEvents(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getUsedVoucherList: (payload) => dispatch(getUsedVoucherList(payload)),
    saveUsedVoucherListQueryString: (payload) =>
      dispatch(saveUsedVoucherListQueryString(payload)),
    removeWSEvent: (payload) => dispatch(removeWSEvent(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withRouter)(VouchersUsed);
