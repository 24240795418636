import React, { PureComponent } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import UserDropdown from './UserDropdown/UserDropdown';
import ServicesPanel from 'components/PageHeader/ServicesPanel/ServicesPanel';

import classes from './PageHeader.module.scss';

class PageHeader extends PureComponent {
  getNavLinkClass = (pathArr) => {
    return pathArr.some((path) => this.props.location.pathname.split('/').includes(path.replace('/', '')))
      ? classes.Active
      : '';
  };

  render() {
    return (
      <div className={classes.Header}>
        <Link to="/" className={classes.Brand}>
          <img className={classes.Logo} src={`${process.env.REACT_APP_PATH}static/logo/logo-phahub.svg`} height="100" alt="Phahub" />
        </Link>
        <nav className={classes.HeaderNav}>
          <ul>
            <li>
              <NavLink
                disabled
                to="/promotion"
                className={this.getNavLinkClass(['/promotion', '/testpromotion','/promotion-order'])}
              >
                Khuyến mãi
              </NavLink>
              <ul className={classes.SubNav}>
                <li>
                  <NavLink to="/promotion" activeClassName={classes.Active}>
                    Chương trình khuyến mãi
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/promotion-order" activeClassName={classes.Active}>
                    Chương trình khuyến mãi đơn hàng
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/testpromotion"
                    activeClassName={classes.Active}
                  >
                    Test promotion
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                disabled
                to="/commission"
                className={this.getNavLinkClass(['/commission'])}
              >
                Commission
              </NavLink>
            </li>
            <li>
            {/* Banner */}
              <NavLink
                to="/banner"
                className={this.getNavLinkClass([
                  '/banner',
                ])}
              >
                Banner
              </NavLink>
            </li>
            <li>
              <NavLink
                disabled
                to="/voucher"
                className={this.getNavLinkClass(['/voucher', '/voucherused'])}
              >
                Voucher
              </NavLink>
              <ul className={classes.SubNav}>
                <li>
                  <NavLink to="/voucher" activeClassName={classes.Active}>
                    Danh sách chương trình phát hành Voucher
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/voucherused"
                    activeClassName={classes.Active}
                  >
                    Danh sách sách voucher đã sử dụng
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <ul className={classes.RightNav}>
          <li>
            <ServicesPanel iconSize="default" />
          </li>
          <li>
            <UserDropdown />
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(PageHeader);
