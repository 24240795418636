import {
  SAVE_GLOBAL_WS,
  SAVE_WS_EVENT,
  REMOVE_WS_EVENT,
  REMOVE_WS_EVENT_ITEMS,
} from 'redux/constants/wsConstants';

const initialState = {
  ws: undefined,
  events: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_GLOBAL_WS: {
      return {
        ...state,
        ws: action.ws,
      };
    }
    case SAVE_WS_EVENT: {
      const list = [ ...state.events ];
      const result = { ...action.event };
      const indexField = result.indexField;
      const eventIndex = list.findIndex(msg => {
        return msg[indexField] && msg[indexField] === result[indexField];
      });
      if (eventIndex > -1) {
        list[eventIndex] = result;
      } else {
        list.push(result);
      }
      return {
        ...state,
        events: list,
      };
    }
    case REMOVE_WS_EVENT: {
      const list = [ ...state.events ];
      const eventWillRemove = { ...action.event };
      const indexField = eventWillRemove.indexField;
      const eventIndex = list.findIndex(msg => {
        return msg[indexField] && msg[indexField] === eventWillRemove[indexField];
      });
      if (eventIndex > -1) {
        list.splice(eventIndex, 1);
      }
      return {
        ...state,
        events: list,
      };
    }
    case REMOVE_WS_EVENT_ITEMS: {
      const items = [ ...action.items ];
      const list = state.events.filter(
        evt => !items.some(item => {
          const indexField = item.indexField;
          return evt[indexField] && evt[indexField] === item[indexField];
        })
      );
      return {
        ...state,
        events: list,
      };
    }
    default:
      return state;
  }
};

export default reducer;
