import {
  SAVE_LOADING,
  SAVE_ORDER_LIST,
  SAVE_ORDER_LIST_ITEM,
  SAVE_PROMOTION_AVAILABLES,
  SAVE_PROMOTION_ACTIVES,
  SAVE_USED_PROMOTIONS,
  SAVE_USED_PROMOTION_ITEM,
  REMOVE_ORDER_LIST_ITEMS,
  SAVE_ORDER_AMOUNT,
  SAVE_PROMOTION_DISCOUNTS,
} from 'redux/constants/tmk/testPromotionConstants';
import { genID } from 'utils/helper';

const initialState = {
  loading: false,
  orderList: [],
  promotionDiscounts: [],
  promotionAvailables: [],
  promotionActives: [],
  usedPromotions: [],
  orderAmount: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case SAVE_ORDER_LIST: {
      const newList = action.orderList.map(item => ({
        ...item,
        idStr: genID(),
      }));
      return {
        ...state,
        orderList: newList ? [...newList] : [],
      };
    }
    case SAVE_ORDER_LIST_ITEM: {
      const list = [...state.orderList];
      const comparedField = action.orderListItem.idStr
          ? 'idStr'
          : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.orderListItem[comparedField]);
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.orderListItem };
      } else {
        list.push({ ...action.orderListItem });
      }
      return {
        ...state,
        orderList: list,
      };
    }
    case REMOVE_ORDER_LIST_ITEMS: {
      const list = state.orderList.filter(
        (p) =>
          !action.orderListItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        orderList: list,
      };
    }

    case SAVE_PROMOTION_DISCOUNTS: {
      const list = action.promotionDiscounts;
      return {
        ...state,
        promotionDiscounts: list ? [...list] : [],
      };
    }

    case SAVE_PROMOTION_AVAILABLES: {
      const list = action.promotionAvailables;
      return {
        ...state,
        promotionAvailables: list ? [...list] : [],
      };
    }
    case SAVE_PROMOTION_ACTIVES: {
      const list = action.promotionActives;
      return {
        ...state,
        promotionActives: list ? [...list] : [],
      };
    }

    case SAVE_USED_PROMOTIONS: {
      const list = action.usedPromotions;
      return {
        ...state,
        usedPromotions: list ? [...list] : [],
      };
    }
    case SAVE_USED_PROMOTION_ITEM: {
      const list = [...state.usedPromotions];
      const comparedField = action.usedPromotionItem.idStr
          ? 'idStr'
          : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.usedPromotionItem[comparedField]);
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.usedPromotionItem };
      } else {
        list.push({ ...action.usedPromotionItem });
      }
      return {
        ...state,
        usedPromotions: list,
      };
    }

    case SAVE_ORDER_AMOUNT: {
      return {
        ...state,
        orderAmount: {...action.orderAmount},
      };
    }

    default:
      return state;
  }
};

export default reducer;
