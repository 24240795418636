import React, { PureComponent } from 'react';
import { currencyFormatVN } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { makeSelectTestUsedPromotions } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
import { saveUsedPromotionItem } from 'redux/actions/tmk/testPromotionActions';
import { validNumber } from 'utils/helper';

import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';

import { OPERATOR_AND } from 'utils/constanst/tmkPromotionConstants';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import classes from './PromotionLevel.module.scss';
import { isEqual, sortBy } from 'lodash';

class PromotionLevel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this._initParams(props),
    }
  }
  componentDidMount() {
    this._submitLevelData();
  }
  componentDidUpdate(prevProps, prevState) {
    const { isDiscount, isBonus, productBonusQuanties } = this.state;
    if (isDiscount !== prevState.isDiscount || isBonus !== prevState.isBonus || !isEqual(sortBy(productBonusQuanties), sortBy(prevState.productBonusQuanties))) {
      this._submitLevelData();
    }
  }

  _getUsedPromotionItem = () => {
    const { usedPromotions } = this.props;
    if (!usedPromotions || usedPromotions.length <= 0) return undefined;
    return usedPromotions.find(item => item.id === this.props.id);
  }
  _getUsedPromotionItemBonus = ({ bonusesArr, type_id, id }) => {
    return bonusesArr.find(p => p.type_id === type_id && p.id === id);
  }
  _submitLevelData = () => {
    const isValid = this._isFormValid();
    if (isValid) {
      this.props.saveUsedPromotionItem({...this._prepareData()});
    }
    this.props.onLevelValidChange({
      isValid,
      usedPromotionItem: {...this._prepareData()},
    });
  }
  _initParams = (params) => {
    const usedPromotionItem = this._getUsedPromotionItem();
    return {
      isDiscount: usedPromotionItem ? !!usedPromotionItem.discount_amount : !!params.discount_amount,
      isBonus: usedPromotionItem ? !!usedPromotionItem.bonus_number : (this._isMultipleOption() && !!params.bonus_number),
      productBonusQuanties: [...this._getFlatQuantities(params?.getcare_promotion_product_bonuss || [], usedPromotionItem?.getcare_product_bonuss || [])],
    }
  }
  _resetState = () => {
    this.setState({
      ...this._initParams(this.props),
    });
  };
  _getFlatQuantities = (productBonuss, usedPromotionProductBonuss) => {
    const productQuantities = [];
    if (!productBonuss) return;
    productBonuss.forEach(product => {
      const usedBonusItem = this._getUsedPromotionItemBonus({
        bonusesArr: usedPromotionProductBonuss,
        type_id: product.type_id,
        id: product.id,
      })
      productQuantities.push({
        id: product.id,
        name: product.name,
        quantity: usedBonusItem ? usedBonusItem.quantity_number : 0,
        minQuantity: product.quantity_number,
        parentId: undefined,
        hasChildren: product.products && product.products.length > 0 ? true : false,
      });
      if (product.products) {
        product.products.forEach(item => {
          const usedBonusItemChild = this._getUsedPromotionItemBonus({
            bonusesArr: usedBonusItem?.products || [],
            type_id: item.type_id,
            id: item.id,
          });
          productQuantities.push({
            id: item.id,
            name: item.name,
            quantity: usedBonusItemChild ? usedBonusItemChild.quantity_number : 0,
            minQuantity: 0,
            parentId: product.id,
          });
        });
      }
    });
    return productQuantities;
  }
  _getTotalQuantity = () => {
    return this.state.productBonusQuanties.reduce((memo, item) => {
      const num = item.parentId ? 0 : Number(item.quantity);
      return memo += num;
    }, 0);
  }
  _getParentTotalQuantiy = (parentId) => {
    return this.state.productBonusQuanties.filter(item => item.parentId && item.parentId === parentId)
      .reduce((memo, item) => {
        return memo += Number(item.quantity);
      }, 0);
  }
  _isAllQuantityValid = () => {
    return !this.state.productBonusQuanties.some(item => item.minQuantity > Number(item.quantity));
  }
  _isTotalQuantityValid = () => {
    const { bonus_number } = this.props;
    return this._getTotalQuantity() === Number(bonus_number);
  }
  _isFormValid = () => {
    const { isBonus } = this.state;
    if (!isBonus) return true;
    return this._isAllQuantityValid() && this._isTotalQuantityValid();
  };
  _isMultipleOption = () => {
    return this.props.getcare_promotion_operator?.id === OPERATOR_AND;
  }
  _saveItemQuantity = ({ id, parentId, value }) => {
    let list = [...this.state.productBonusQuanties];
    const itemIndex = list.findIndex(item => item.id === id && item.parentId === parentId);
    if (itemIndex > -1) {

      list[itemIndex] = { ...list[itemIndex], quantity: value };

      if (!!parentId) {
        // calculate the total qty for the parent
        const parentIndex = list.findIndex(item => !item.parentId && item.id === parentId);
        if (parentIndex > -1) {
          const parentQty = Number(list[parentIndex].quantity) - Number(this.state.productBonusQuanties[itemIndex].quantity) + Number(value);
          list[parentIndex] = { ...list[parentIndex], quantity: parentQty };
        }
      }

      this.setState({
        productBonusQuanties: list,
      });
    }
  }
  _prepareProductBonuses = () => {
    return this.props.getcare_promotion_product_bonuss.map(product => {
      const qtyItem = this.state.productBonusQuanties.find(item => item.id === product.id && !item.parentId);
      return {
        id: product.id,
        type_id: product.type_id,
        quantity_number: qtyItem?.quantity ? Number(qtyItem?.quantity) : null,
        products: !product.products
          ? null
          : product.products.map(p => {
            const qtyP = this.state.productBonusQuanties.find(item => item.id === p.id && !!item.parentId);
            return {
              id: p.id,
              type_id: p.type_id,
              quantity_number: qtyP?.quantity ? Number(qtyP?.quantity) : null,
            }
          }).filter(p => !!p.quantity_number && p.quantity_number > 0),
      }
    }).filter(product => !!product.quantity_number && product.quantity_number > 0);
  }
  _prepareData = () => {
    return {
      id: this.props.id,
      promotion_id: this.props.promotionId,
      discount_amount: this.state.isDiscount ? this.props.discount_amount : null,
      bonus_number: this.state.isBonus ? this.props.bonus_number : null,
      getcare_product_bonuss: !this.state.isBonus ? null : this._prepareProductBonuses(),
    }
  }

  handleDiscountCheckboxChange = (e) => {
    if (this._isMultipleOption()) return;
    this.setState({
      isDiscount: e.target.checked,
      isBonus: !e.target.checked,
    });
  }
  handleBonusCheckboxChange = (e) => {
    if (this._isMultipleOption()) return;
    this.setState({
      isDiscount: !e.target.checked,
      isBonus: e.target.checked,
    });
  }
  handleQuantityChange = ({ id, parentId, value }) => {
    this._saveItemQuantity({ id, parentId, value: validNumber(value) });
  }

  render() {
    const { discount_amount, bonus_number, level, level_name, count_total } = this.props;
    const { isDiscount, isBonus, productBonusQuanties } = this.state;
    const isTotalQuantityValid = this._isTotalQuantityValid();
    const isMultipleOption = this._isMultipleOption();

    return (<div className={classes.PromotionLevel}>
        <p className={classes.LevelTitle}>{!!level_name ? level_name : `Level ${level}: ${count_total} suất | Còn lại = 0`}</p>
        { !['', null, undefined].includes(discount_amount) &&
          <div className={classes.FormControl}>
            <Checkbox
              size="small"
              disabled={isMultipleOption && isDiscount}
              checked={isDiscount}
              onChange={this.handleDiscountCheckboxChange}
              style={{padding: 0, marginRight: '8px'}}
              icon={isMultipleOption ? <CheckBoxOutlineBlankOutlinedIcon/> : <RadioButtonUncheckedIcon/>}
              checkedIcon={isMultipleOption ? <CheckBoxIcon/> : <RadioButtonCheckedIcon/>}
            />
            Chiết khấu: { currencyFormatVN(discount_amount) }
          </div>
        }
        { !['', null, undefined].includes(bonus_number) &&
          <div className={classes.FormControl}>
            <Checkbox
              size="small"
              disabled={isMultipleOption && isBonus}
              checked={isBonus}
              onChange={this.handleBonusCheckboxChange}
              style={{padding: 0, marginRight: '8px'}}
              icon={isMultipleOption ? <CheckBoxOutlineBlankOutlinedIcon/> : <RadioButtonUncheckedIcon/>}
              checkedIcon={isMultipleOption ? <CheckBoxIcon/> : <RadioButtonCheckedIcon/>}
            />
            Tặng hàng: { bonus_number } | Số lượng còn lại: <span className={`${isTotalQuantityValid ? classes.Valid : classes.Invalid }`}>{ bonus_number - this._getTotalQuantity()}</span>
          </div>
        }
        { productBonusQuanties.length > 0 &&
          <div className={`${classes.QuantityList} ${isBonus ? '' : classes.Disabled}`}>
            <div className={`${classes.Row} ${classes.Header}`}>
              <ListHeaderCol
                className={classes.Col}
                label="Sản phẩm tặng (theo tỉ trọng)"
              />
              <ListHeaderCol
                className={classes.Col}
                label="Số lượng"
              />
            </div>
            <div className={`${classes.Body}`}>
              {productBonusQuanties.length ? (
                productBonusQuanties.map((item, index) => (
                  <div key={`${item.id}-${item.parentId}-${index}`} className={`${classes.Row} ${classes.Item}`}>
                    <div className={`${classes.Col} ${!item.parentId ? classes.Parent : classes.Child}`}>
                      { !!item.parentId ? (<span className={classes.ChildBullet}>{ `>` }</span>) : ``}{ item.name }
                    </div>
                    <div className={classes.Col}>
                      { (!item.parentId && item.hasChildren)
                        ? <>{ item.quantity } <span className={classes.HelpText}>(Min. { item.minQuantity })</span></>
                        : <>
                            <TextField
                              className={classes.Field}
                              value={item.quantity}
                              placeholder="1"
                              name="quantity"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              onChange={(e) => {
                                this.handleQuantityChange({ id: item.id, parentId: item.parentId, value: e.target.floatValue });
                              }}
                            />
                            <span className={classes.HelpText}>(Min. {item.minQuantity})</span>
                          </> }
                    </div>
                  </div>
                ))
              ) : (
                <p className="NoData">Không có data</p>
              )}
            </div>
          </div>
        }
      </div>
    );
  }
}

PromotionLevel.propTypes = {
};

PromotionLevel.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  usedPromotions: makeSelectTestUsedPromotions(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveUsedPromotionItem: (payload) => dispatch(saveUsedPromotionItem(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PromotionLevel);
