import {
    SAVE_LOADING,
    SAVE_VOUCHER_LIST,
    SAVE_VOUCHER_LIST_TOTAL,
    SAVE_USED_VOUCHER_LIST,
    SAVE_USED_VOUCHER_LIST_TOTAL,
    SAVE_USED_VOUCHER_LIST_QUERY_STRING,
    SAVE_VOUCHER_LIST_QUERY_STRING,
    SAVE_VOUCHER_DETAILS,
    SAVE_VOUCHER_CONFIG_GIFT_LIST,
    SAVE_VOUCHER_CONFIG_MONEY_LIST,
    SAVE_ORI_VOUCHER_CONFIG_GIFT_LIST,
    SAVE_ORI_VOUCHER_CONFIG_MONEY_LIST,
    SAVE_VOUCHER_CONFIG_GIFT_LIST_ITEM,
    SAVE_VOUCHER_CONFIG_MONEY_LIST_ITEM,
    REMOVE_VOUCHER_CONFIG_GIFT_LIST_ITEM,
    REMOVE_VOUCHER_CONFIG_MONEY_LIST_ITEM,
    SAVE_VOUCHER_CUSTOMER_LIST,
    SAVE_VOUCHER_SALE_CHANNEL_LIST,
    SAVE_ORI_VOUCHER_CUSTOMER_LIST,
    SAVE_ORI_VOUCHER_DETAILS,
    SAVE_ORI_VOUCHER_SALE_CHANNEL_LIST,
    SAVE_CUSTOMER_LIST,
    SAVE_SALE_CHANNEL_LIST,
    SAVE_VOUCHER_CUSTOMER_LIST_ITEM,
    SAVE_VOUCHER_SALE_CHANNEL_LIST_ITEM,
    REMOVE_VOUCHER_SALE_CHANNEL_LIST_ITEM,
    REMOVE_VOUCHER_CUSTOMER_LIST_ITEM,
    SAVE_VOUCHER_VENDOR_LIST,
    SAVE_ORI_VOUCHER_PRODUCTS,
    SAVE_VOUCHER_PRODUCT_ITEM,
    SAVE_VOUCHER_PRODUCTS,
    REMOVE_VOUCHER_PRODUCT_ITEMS,
    SAVE_VOUCHER_UNUSED_LIST,
    RESET_VOUCHER,
    SELECT_ALL_VOUCHER_PRODUCT,
    SELECT_ALL_VOUCHER_CUSTOMER,
    SELECT_ALL_VOUCHER_SALE_CHANNEL,
    SELECT_ALL_VOUCHER_CONFIG_MONEY,
    SELECT_ALL_VOUCHER_CONFIG_GIFT,
    SELECT_VOUCHER_PRODUCT_ITEM,
    SELECT_VOUCHER_CUSTOMER_ITEM,
    SELECT_VOUCHER_SALE_CHANNEL_ITEM,
    SELECT_VOUCHER_CONFIG_ITEM_MONEY,
    SELECT_VOUCHER_CONFIG_ITEM_GIFT,
    DESELECT_ALL_VOUCHER_CUSTOMER,
    DESELECT_ALL_VOUCHER_SALE_CHANNEL,
    DESELECT_ALL_VOUCHER_PRODUCT,
    DESELECT_ALL_VOUCHER_CONFIG_MONEY,
    DESELECT_ALL_VOUCHER_CONFIG_GIFT,
    REMOVE_MULTIPLE_VOUCHER_CUSTOMER_ITEM,
    REMOVE_MULTIPLE_VOUCHER_PRODUCT_ITEM,
    REMOVE_MULTIPLE_VOUCHER_SALE_CHANNEL_ITEM,
    REMOVE_MULTIPLE_VOUCHER_CONFIG_MONEY_ITEM,
    REMOVE_MULTIPLE_VOUCHER_CONFIG_GIFT_ITEM,
  } from 'redux/constants/tmk/voucherConstants';

  const initialState = {
    voucherList: [],

    usedVoucherList: [],
  
    oriVoucherCustomerList: [],
    voucherCustomerList: [],
  
    voucherSaleChannelList: [],
    oriVoucherSaleChannelList: [],

    oriVoucherProductList: [],
    voucherProductList: [],

    oriVoucherConfigGiftList: [],
    voucherConfigGiftList: [],

    oriVoucherConfigMoneyList: [],
    voucherConfigMoneyList: [],

    voucherUnusedList: [],
  
    customerList: [],
    saleChannelList: [],

    voucherVendorList: [],
  
    voucherListTotal: 0,
    usedVoucherListTotal: 0,

    loading: false,
    voucherListQueryString: '',
    usedVoucherListQueryString: '',
  
    voucherDetails: null,
    oriVoucherDetails: null,
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case SAVE_LOADING: {
        return {
          ...state,
          loading: action.loading,
        };
      }
  
      case SAVE_VOUCHER_LIST: {
        const list = action.voucherList;
        return {
          ...state,
          voucherList: list ? [...list] : [],
        };
      }
  
      case SAVE_VOUCHER_LIST_TOTAL: {
        return {
          ...state,
          voucherListTotal: action.voucherListTotal,
        };
      }
  
      case SAVE_VOUCHER_LIST_QUERY_STRING: {
        return {
          ...state,
          voucherListQueryString: action.voucherListQueryString,
        };
      }

      case SAVE_USED_VOUCHER_LIST: {
        const list = action.usedVoucherList;
        return {
          ...state,
          usedVoucherList: list ? [...list] : [],
        };
      }
  
      case SAVE_USED_VOUCHER_LIST_TOTAL: {
        return {
          ...state,
          usedVoucherListTotal: action.usedVoucherListTotal,
        };
      }
  
      case SAVE_USED_VOUCHER_LIST_QUERY_STRING: {
        return {
          ...state,
          usedVoucherListQueryString: action.usedVoucherListQueryString,
        };
      }
  
      case SAVE_VOUCHER_DETAILS: {
        return {
          ...state,
          voucherDetails: action.voucherDetails,
        };
      }

      case SAVE_VOUCHER_CONFIG_GIFT_LIST: {
        return {
          ...state,
          voucherConfigGiftList: action.voucherConfigGiftList,
        };
      }
      case SAVE_ORI_VOUCHER_CONFIG_GIFT_LIST: {
        return {
          ...state,
          oriVoucherConfigGiftList: action.oriVoucherConfigGiftList,
        };
      }
      case SAVE_VOUCHER_CONFIG_MONEY_LIST: {
        return {
          ...state,
          voucherConfigMoneyList: action.voucherConfigMoneyList,
        };
      }
      case SAVE_ORI_VOUCHER_CONFIG_MONEY_LIST: {
        return {
          ...state,
          oriVoucherConfigMoneyList: action.oriVoucherConfigMoneyList,
        };
      }

      case SAVE_VOUCHER_CONFIG_GIFT_LIST_ITEM: {
        const list = [...state.voucherConfigGiftList];
        const comparedField = action.voucherConfigGiftItem.idStr
            ? 'idStr'
            : 'id';
        const index = list.findIndex((p) => p[comparedField] === action.voucherConfigGiftItem[comparedField]);
        if (index > -1) {
          const tempItem = { ...list[index] };
          list[index] = { ...tempItem, ...action.voucherConfigGiftItem };
        } else {
          // Put the new item to the top of the list
          list.unshift({ ...action.voucherConfigGiftItem });
        }
        return {
          ...state,
          voucherConfigGiftList: list,
        };
      }

      case REMOVE_VOUCHER_CONFIG_GIFT_LIST_ITEM: {
        const list = state.voucherConfigGiftList.filter(
          (p) =>
            !action.voucherConfigGiftItem.some((item) => {
              const comparedField = item.idStr ? 'idStr' : 'id';
              return item[comparedField] === p[comparedField];
            })
        );
        return {
          ...state,
          voucherConfigGiftList: list,
        };
      }

      case SAVE_VOUCHER_CONFIG_MONEY_LIST_ITEM: {
        const list = [...state.voucherConfigMoneyList];
        const comparedField = action.voucherConfigMoneyItem.idStr
            ? 'idStr'
            : 'id';
        const index = list.findIndex((p) => p[comparedField] === action.voucherConfigMoneyItem[comparedField]);
        if (index > -1) {
          const tempItem = { ...list[index] };
          list[index] = { ...tempItem, ...action.voucherConfigMoneyItem };
        } else {
          // Put the new item to the top of the list
          list.unshift({ ...action.voucherConfigMoneyItem });
        }
        return {
          ...state,
          voucherConfigMoneyList: list,
        };
      }

      case REMOVE_VOUCHER_CONFIG_MONEY_LIST_ITEM: {
        const list = state.voucherConfigMoneyList.filter(
          (p) =>
            !action.voucherConfigMoneyItem.some((item) => {
              const comparedField = item.idStr ? 'idStr' : 'id';
              return item[comparedField] === p[comparedField];
            })
        );
        return {
          ...state,
          voucherConfigMoneyList: list,
        };
      }
  
      case SAVE_VOUCHER_CUSTOMER_LIST: {
        return {
          ...state,
          voucherCustomerList: action.voucherCustomerList,
        };
      }
  
      case SAVE_VOUCHER_SALE_CHANNEL_LIST: {
        return {
          ...state,
          voucherSaleChannelList: action.voucherSaleChannelList,
        };
      }

      case SAVE_ORI_VOUCHER_PRODUCTS: {
        return {
          ...state,
          oriVoucherProductList: action.oriVoucherProductList,
        };
      }

      case SAVE_VOUCHER_PRODUCT_ITEM: {
        const list = [...state.voucherProductList];
        const comparedField = action.voucherProductItem.idStr
            ? 'idStr'
            : 'id';
        const index = list.findIndex((p) => p[comparedField] === action.voucherProductItem[comparedField]);
        if (index > -1) {
          const tempItem = { ...list[index] };
          list[index] = { ...tempItem, ...action.voucherProductItem };
        } else {
          // Put the new item to the top of the list
          list.unshift({ ...action.voucherProductItem });
        }
        return {
          ...state,
          voucherProductList: list,
        };
      }

      case SAVE_VOUCHER_PRODUCTS: {
        return {
          ...state,
          voucherProductList: action.voucherProductList,
        };
      }

      case SAVE_VOUCHER_UNUSED_LIST: {
        return {
          ...state,
          voucherUnusedList: action.voucherUnusedList
        }
      }

      case SAVE_VOUCHER_VENDOR_LIST: {
        return {
          ...state,
          voucherVendorList: action.voucherVendorList,
        };
      }
  
      case SAVE_ORI_VOUCHER_DETAILS: {
        return {
          ...state,
          oriVoucherDetails: action.oriVoucherDetails,
        };
      }
  
      case SAVE_ORI_VOUCHER_CUSTOMER_LIST: {
        return {
          ...state,
          oriVoucherCustomerList: action.oriVoucherCustomerList,
        };
      }
  
      case SAVE_ORI_VOUCHER_SALE_CHANNEL_LIST: {
        return {
          ...state,
          oriVoucherSaleChannelList: action.oriVoucherSaleChannelList,
        };
      }
  
      case SAVE_CUSTOMER_LIST: {
        return {
          ...state,
          customerList: action.customerList,
        };
      }
  
      case SAVE_SALE_CHANNEL_LIST: {
        return {
          ...state,
          saleChannelList: action.saleChannelList,
        };
      }
  
      case SAVE_VOUCHER_CUSTOMER_LIST_ITEM: {
        const list = [...state.voucherCustomerList]
        const comparedField = action.customerListItem.idStr ? 'idStr' : 'id';
        const index = list.findIndex(
          (p) => p[comparedField] === action.customerListItem[comparedField]);
        if (index > -1) {
          list[index] = { ...action.customerListItem };
        } else {
          // Put the new item to the top of the list
          list.unshift({ ...action.customerListItem });
        }
        return {
          ...state,
          voucherCustomerList: list,
        };
      }
  
      case REMOVE_VOUCHER_CUSTOMER_LIST_ITEM: {
        const list = [...state.voucherCustomerList]
        
        const newState = list.filter(
          (p) =>
            !action.customerListItem.some((item) => {
              const comparedField = item.idStr ? 'idStr' : 'id';
              return item[comparedField] === p[comparedField];
            })
        );
        return {
          ...state,
          voucherCustomerList: newState,
        };
      }
  
      case SAVE_VOUCHER_SALE_CHANNEL_LIST_ITEM: {
        const list = [...state.voucherSaleChannelList];
        const comparedField = action.saleChannelListItem.getcare_voucher_sales_channel.idStr ? 'idStr' : 'id';
        const index = list.findIndex(
          (p) => p.getcare_voucher_sales_channel[comparedField] === action.saleChannelListItem.getcare_voucher_sales_channel[comparedField]
        );
        if (index > -1) {
          const tempItem = { ...list[index] };
          list[index] = { ...tempItem, ...action.saleChannelListItem };
        } else {
          // Put the new item to the top of the list
          list.unshift({ ...action.saleChannelListItem });
        }
        return {
          ...state,
          voucherSaleChannelList: list,
        };
      }
  
      case REMOVE_VOUCHER_SALE_CHANNEL_LIST_ITEM: {
        const list = state.voucherSaleChannelList.filter(
          (p) =>
            !action.saleChannelListItem.some((item) => {
              const comparedField = item.idStr ? 'idStr' : 'id';
              return item[comparedField] === p[comparedField];
            })
        );
        return {
          ...state,
          voucherSaleChannelList: list,
        };
      }

      case REMOVE_VOUCHER_PRODUCT_ITEMS: {
        const list = state.voucherProductList.filter(
          (p) =>
            !action.voucherProductItems.some((item) => {
              const comparedField = item.idStr ? 'idStr' : 'id';
              return item[comparedField] === p[comparedField];
            })
        );
        return {
          ...state,
          voucherProductList: list,
        };
      }
  
      case RESET_VOUCHER: {
        return {
          ...state,
          oriVoucherDetails: null,
          voucherDetails: null,
          oriVoucherConfigList: [],
          voucherConfigList: [],
          oriVoucherProductList: [],
          voucherProductList: [],
          oriVoucherSaleChannelList: [],
          voucherSaleChannelList: [],
          oriVoucherCustomerList: [],
          voucherCustomerList: [],
          voucherUnusedList: [],
        };
      }

      case SELECT_ALL_VOUCHER_PRODUCT: {
        const list = [...state.voucherProductList]
        const checkedList = list.map((item) => {
          return {...item, isChecked: true}
        })
        return {
          ...state,
          voucherProductList: checkedList
        }
      }

      case DESELECT_ALL_VOUCHER_PRODUCT: {
        const list = [...state.voucherProductList]
        const checkedList = list.map((item) => {
          return {...item, isChecked: false}
        })
        return {
          ...state,
          voucherProductList: checkedList
        }
      }

      case SELECT_ALL_VOUCHER_CUSTOMER: {
        const list = [...state.voucherCustomerList]
        const checkedList = list.map((item) => {
          return {...item, isChecked: true}
        })
        return {
          ...state,
          voucherCustomerList: checkedList
        }
      }

      case DESELECT_ALL_VOUCHER_CUSTOMER: {
        const list = [...state.voucherCustomerList]
        const checkedList = list.map((item) => {
          return {...item, isChecked: false}
        })
        return {
          ...state,
          voucherCustomerList: checkedList
        }
      }

      case DESELECT_ALL_VOUCHER_CONFIG_GIFT: {
        const list = [...state.voucherConfigGiftList]
        const checkedList = list.map((item) => {
          return {...item, isChecked: false}
        })
        return {
          ...state,
          voucherConfigGiftList: checkedList
        }
      }

      case DESELECT_ALL_VOUCHER_CONFIG_MONEY: {
        const list = [...state.voucherConfigMoneyList]
        const checkedList = list.map((item) => {
          return {...item, isChecked: false}
        })
        return {
          ...state,
          voucherConfigMoneyList: checkedList
        }
      }

      case SELECT_ALL_VOUCHER_SALE_CHANNEL: {
        const list = [...state.voucherSaleChannelList]
        const checkedList = list.map((item) => {
          return {...item, isChecked: true}
        })
        return {
          ...state,
          voucherSaleChannelList: checkedList
        }
      }

      case SELECT_ALL_VOUCHER_CONFIG_GIFT: {
        const list = [...state.voucherConfigGiftList]
        const checkedList = list.map((item) => {
          return {...item, isChecked: true}
        })
        return {
          ...state,
          voucherConfigGiftList: checkedList
        }
      }

      case SELECT_ALL_VOUCHER_CONFIG_MONEY: {
        const list = [...state.voucherConfigMoneyList]
        const checkedList = list.map((item) => {
          return {...item, isChecked: true}
        })
        return {
          ...state,
          voucherConfigMoneyList: checkedList
        }
      }

      case DESELECT_ALL_VOUCHER_SALE_CHANNEL: {
        const list = [...state.voucherSaleChannelList]
        const checkedList = list.map((item) => {
          return {...item, isChecked: false}
        })
        return {
          ...state,
          voucherSaleChannelList: checkedList
        }
      }

      case SELECT_VOUCHER_PRODUCT_ITEM: {
        const list = [...state.voucherProductList]
        const comparedField = action.productlistItem.idStr ? 'idStr' : 'id';
        const index = list.findIndex(
          (p) => p[comparedField] === action.productlistItem[comparedField]);
        if (index > -1) {
          const tempItem = { ...list[index] };
          list[index] = { ...tempItem, isChecked: !tempItem.isChecked };
        }
        return {
          ...state,
          voucherProductList: list,
        };
      }

      case SELECT_VOUCHER_CUSTOMER_ITEM: {
        const list = [...state.voucherCustomerList]
        const comparedField = action.customerListItem.idStr ? 'idStr' : 'id';
        const index = list.findIndex(
          (p) => p[comparedField] === action.customerListItem[comparedField]);
        if (index > -1) {
          const tempItem = { ...list[index] };
          list[index] = { ...tempItem, isChecked: !tempItem.isChecked };
        }
        return {
          ...state,
          voucherCustomerList: list,
        };
      }

      case SELECT_VOUCHER_SALE_CHANNEL_ITEM: {
        const list = [...state.voucherSaleChannelList];
        const comparedField = action.saleChannelListItem.idStr ? 'idStr' : 'id';
        const index = list.findIndex(
          (p) => p[comparedField] === action.saleChannelListItem[comparedField]
        );
        if (index > -1) {
          const tempItem = { ...list[index] };
          list[index] = { ...tempItem, isChecked: !tempItem.isChecked };
        }
        return {
          ...state,
          voucherSaleChannelList: list,
        };
      }

      case SELECT_VOUCHER_CONFIG_ITEM_GIFT: {
        const list = [...state.voucherConfigGiftList];
        const comparedField = action.voucherConfigGiftItem.idStr ? 'idStr' : 'id';
        const index = list.findIndex(
          (p) => p[comparedField] === action.voucherConfigGiftItem[comparedField]
        );
        if (index > -1) {
          const tempItem = { ...list[index] };
          list[index] = { ...tempItem, isChecked: !tempItem.isChecked };
        }
        return {
          ...state,
          voucherConfigGiftList: list,
        };
      }

      
      case SELECT_VOUCHER_CONFIG_ITEM_MONEY: {
        const list = [...state.voucherConfigMoneyList];
        const comparedField = action.voucherConfigMoneyItem.idStr ? 'idStr' : 'id';
        const index = list.findIndex(
          (p) => p[comparedField] === action.voucherConfigMoneyItem[comparedField]
        );
        if (index > -1) {
          const tempItem = { ...list[index] };
          list[index] = { ...tempItem, isChecked: !tempItem.isChecked };
        }
        return {
          ...state,
          voucherConfigMoneyList: list,
        };
      }

      case REMOVE_MULTIPLE_VOUCHER_CUSTOMER_ITEM: {
        const newState = state.voucherCustomerList.filter((item => !item.isChecked))
        return {
          ...state,
          voucherCustomerList: newState
        }
      }

      case REMOVE_MULTIPLE_VOUCHER_PRODUCT_ITEM: {
        const newState = state.voucherProductList.filter((item => !item.isChecked))
        return {
          ...state,
          voucherProductList: newState
        }
      }

      case REMOVE_MULTIPLE_VOUCHER_SALE_CHANNEL_ITEM: {
        const newState = state.voucherSaleChannelList.filter((item => !item.isChecked))
        return {
          ...state,
          voucherSaleChannelList: newState
        }
      }

      case REMOVE_MULTIPLE_VOUCHER_CONFIG_MONEY_ITEM: {
        const newState = state.voucherConfigMoneyList.filter((item => !item.isChecked))
        return {
          ...state,
          voucherConfigMoneyList: newState
        }
      }

      case REMOVE_MULTIPLE_VOUCHER_CONFIG_GIFT_ITEM: {
        const newState = state.voucherConfigGiftList.filter((item => !item.isChecked))
        return {
          ...state,
          voucherConfigGiftList: newState
        }
      }

      default:
        return state;
    }
    
  };
  
  export default reducer;
  