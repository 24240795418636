import bannerApi from 'utils/api/tmk/bannerApi';
import { put } from 'redux-saga/effects';
import { bannerActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadBannerList(payload) {
  const { params } = payload.payload;
  try {
    yield put(bannerActions.saveLoading(true));

    const { data: response } = yield bannerApi.getAll({ params });

    yield put(bannerActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      bannerActions.saveBannerList(response.data ? [...response.data] : [])
    );
    yield put(bannerActions.saveBannerListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}

export function* loadBanner(payload) {
  const { id, isDuplicate } = payload.payload;
  try {
    yield put(bannerActions.saveLoading(true));

    yield put(bannerActions.saveBannerCustomerList([]));
    yield put(bannerActions.saveOriBannerCustomerList([]));

    yield put(bannerActions.saveBannerSaleChannelList([]));
    yield put(bannerActions.saveOriBannerSaleChannelList([]));

    yield put(bannerActions.saveOriBannerDetails(null));
    yield put(bannerActions.saveBannerDetails(null));

    const { data: response } = yield bannerApi.get(id);
    yield put(bannerActions.saveLoading(false));

    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(bannerActions.saveBannerDetails(response.data));
    yield put(bannerActions.saveOriBannerDetails(response.data));

    yield put(
      bannerActions.saveBannerCustomerList(
        response.data?.getcare_banner_customer_items || []
      )
    );

    yield put(
      bannerActions.saveBannerSaleChannelList(
        response.data?.getcare_banner_sales_channel_items || []
      )
    );

    yield put(
      bannerActions.saveOriBannerCustomerList(
        response.data?.getcare_banner_customer_items || []
      )
    );

    yield put(
      bannerActions.saveOriBannerSaleChannelList(
        response.data?.getcare_banner_sales_channel_items || []
      )
    );
  } catch (err) {
    console.log(err);
  }
}

export function* loadCustomerList(payload) {
  try {
    const { data: response } = yield bannerApi.getCustomerList();

    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    const savedCustomerList = response.data.map((item) => {
      const { name, id, phone, address, code } = item;
      return {
        name,
        id,
        phone,
        address,
        code,
      };
    });
    yield put(bannerActions.saveCustomerList(savedCustomerList));
  } catch (error) {
    console.log(error);
  }
}

export function* loadSaleChannelList(payload) {
  try {
    const { data: response } = yield bannerApi.getSaleChannelList();
    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    yield put(bannerActions.saveSaleChannelList(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* loadBannerSize(payload) {
  try {
    const { data: response } = yield bannerApi.getSize();
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(bannerActions.saveBannerSize(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* loadBannerType(payload) {
  try {
    const { data: response } = yield bannerApi.getType();
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(bannerActions.saveBannerType(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* loadBannerLocation(payload) {
  try {
    const { data: response } = yield bannerApi.getLocation();
    yield put(bannerActions.saveBannerLocation(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* postBanner(payload) {
  const { params } = payload.payload;
  try {
    yield put(bannerActions.saveLoading(true));

    const { data: response } = yield bannerApi.update({ params });

    yield put(bannerActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    toast.success(`Lưu banner thành công.`);

    const oriData = response.data
      ? {
          ...response.data,
          getcare_banner_size_id: response.data.getcare_banner_size?.id,
          getcare_banner_type_id: response.data.getcare_banner_type?.id,
          getcare_banner_customer_apply_id:
            response.data.getcare_banner_customer_apply?.id,
          getcare_banner_location_id: response.data.getcare_banner_location?.id,
        }
      : null;
    yield put(
      bannerActions.saveOriBannerDetails(oriData ? { ...oriData } : null)
    );
    yield put(bannerActions.saveBannerDetails(oriData ? { ...oriData } : null));
    yield put(
      bannerActions.saveOriBannerCustomerList(
        response.data.getcare_banner_customer_items || []
      )
    );
    yield put(
      bannerActions.saveOriBannerSaleChannelList(
        response.data.getcare_banner_sales_channel_items || []
      )
    );
    if (params.id) {
    }
  } catch (err) {
    console.log(err);
  }
}
