import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  vendorGroupTypes,
  GROUP_TYPE_ID,
  VENDOR_TYPE_ID,
} from 'utils/constanst/tmkPromotionConstants';
import promotionApi from 'utils/api/tmk/promotionApi';
import { debounce } from 'lodash';

import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

import classes from './PromotionVendor.module.scss';
import gridClasses from '../PromotionVendorsGrid.module.scss';
import { validNumber } from 'utils/helper';

class PromotionVendor extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: props.isEditing,
      params: this._getInitParams(props),
      groupVendorOptions: this._initVendorOptions(props),
      groupVendorCodeInput: '',
      groupVendorNameInput: '',
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const { groupVendorCodeInput, groupVendorNameInput, isEditing, params } = this.state;
    if (groupVendorCodeInput && groupVendorCodeInput !== prevState.groupVendorCodeInput) {
      this._loadSuggestedVendors({
        params: {
          code: groupVendorCodeInput,
        },
      });
    }
    if (groupVendorNameInput && groupVendorNameInput !== prevState.groupVendorNameInput) {
      this._loadSuggestedVendors({
        params: {
          name: groupVendorNameInput,
        },
      });
    }
    if (isEditing !== prevState.isEditing && this._isRowValid(params)) {
      this.props.savePromotionVendor({
        ...params,
        idStr: this.props.idStr,
        id: this.props.id,
        isEditing,
      });
    }
  }
  _initVendorOptions = (params) => {
    const type = this._getObjectName(params.type_id);
    return params[`getcare_${type}`] ? [{...params[`getcare_${type}`]}] : [];
  }
  _getObjectName = (typeId) => {
    switch(typeId) {
      case GROUP_TYPE_ID: {
        return 'erp_group';
      }
      case VENDOR_TYPE_ID: {
        return 'vendor';
      }
      default: return 'vendor';
    }
  }
  _loadSuggestedVendors = debounce(async ({ params }) => {
    const { data: response } = await promotionApi.getVendorSuggestions({
      params: {
        ...params,
        type_id: this.state.params.type_id,
      },
    });
    if (!response?.result) return;
    this.setState({
      groupVendorOptions: response.data
        ? this._filteredOptions([...response.data[`${this._getObjectName(this.state.params.type_id)}s`]])
        : [],
    });
  }, 500);
  _filteredOptions = (fullArr) => {
    const { params } = this.state;
    return fullArr.filter((p) => {
      return params.type_id === GROUP_TYPE_ID ? (
          (p.code === this.props.getcare_erp_group?.code) ||
          !this.props.currentList.some(
            (item) => item.getcare_erp_group?.code === p.code
          )
        ) : (p.code === this.props.getcare_vendor?.code) ||
        !this.props.currentList.some(
          (item) => item.getcare_vendor?.code === p.code
        )
    });
  };
  _getVendorOptionLabel = (option) => {
    return `${option.code} - ${option.name}`
  }
  _getInitParams = (params) => {
    const { isPromotionCombo } = this.props;
    return {
      idStr: params?.idStr,
      id: params?.id,
      type_id: isPromotionCombo ? VENDOR_TYPE_ID : params?.type_id,
      getcare_vendor: params?.getcare_vendor ? {...params?.getcare_vendor} : null,
      getcare_erp_group: params?.getcare_erp_group ? {...params?.getcare_erp_group} : null,
    }
  }
  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
      groupVendorOptions: this._initVendorOptions(this.props),
      groupVendorCodeInput: '',
      groupVendorNameInput: '',
    });
  }
  _resetFormAfterChangeTypeId = (typeIdValue) => {
    this.setState({
      isEditing: true,
      params: this._getInitParams({
        ...this.state.params,
        type_id: typeIdValue,
        getcare_vendor: null,
        getcare_erp_group: null,
      }),
      groupVendorOptions: [],
      groupVendorCodeInput: '',
      groupVendorNameInput: '',
    });
  }
  _hasVendor = (params) => {
    return !!params.getcare_vendor?.id || !!params.getcare_erp_group?.id;
  }
  _isRowValid = (params) => {
    return this._hasVendor(params);
  };
  stopPropagation = (e) => {
    e.stopPropagation();
  }
  handleSaveField = (e, value) => {
    let newValue = e.target.type === 'number' ? validNumber(value) : value;
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: newValue,
      },
    });
  }
  handleRowClick = () => {
    if (this.props.readOnly) {
      return;
    }

    if (!this.state.isEditing) {
      this.handleEdit();
    }
  }
  handleEdit = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = (e) => {
    if (e) e.stopPropagation();
    if (this._isRowValid(this.props)) {
      // if it was saved to DB or it was used to save on client successful
      this._resetState();
      return;
    }
    this.props.removePromotionVendor({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  }
  handleRemove = (e) => {
    if (e) e.stopPropagation();
    this.props.removePromotionVendor({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  }
  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: false,
    });
  }
  handleSelectGroupChange = (e) => {
    this._resetFormAfterChangeTypeId(e.target.value);
  }
  handleSelectVendorChange = (e, newValue) => {
    const { params } = this.state;
    this.setState({
      params: {
        ...this.state.params,
        getcare_erp_group: params.type_id === GROUP_TYPE_ID ? newValue : null,
        getcare_vendor: params.type_id === VENDOR_TYPE_ID ? newValue : null,
      },
    });
  };

  render() {
    const {
      type_id,
      getcare_vendor,
      getcare_erp_group,
      isPromotionCombo,
      readOnly,
    } = this.props;
    const {
      isEditing,
      params,
      groupVendorOptions,
    } = this.state;

    return (
      <>
        <div
          className={`${classes.Item} ${gridClasses.Row}`}
          onClick={this.handleRowClick}
        >
          <div className={gridClasses.Col}>
            <Select
              disabled={readOnly || isPromotionCombo}
              className={classes.SelectWrap}
              value={params.type_id}
              name="type_id"
              size="small"
              fullWidth
              onChange={this.handleSelectGroupChange}
            >
              {
                vendorGroupTypes.map(item => <MenuItem key={`type-${item.id}`} value={item.id}>{ item.name }</MenuItem>)
              }
            </Select>
          </div>
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={params.type_id === GROUP_TYPE_ID ? params.getcare_erp_group : params.getcare_vendor}
                onChange={this.handleSelectVendorChange}
                options={groupVendorOptions || []}
                filterOptions={(x) => x}
                renderOption={(option) => this._getVendorOptionLabel(option)}
                getOptionLabel={(option) => ((option && option.code) || '')}
                getOptionSelected={(option, value) =>
                  value && value.id && option ? option.id === value.id : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    groupVendorCodeInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn -" />
                )}
              />
            ) : (
              type_id === GROUP_TYPE_ID ? getcare_erp_group?.code : getcare_vendor?.code
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={params.type_id === GROUP_TYPE_ID ? params.getcare_erp_group : params.getcare_vendor}
                onChange={this.handleSelectVendorChange}
                options={groupVendorOptions || []}
                filterOptions={(x) => x}
                renderOption={(option) => this._getVendorOptionLabel(option)}
                getOptionLabel={(option) => ((option && option.name) || '')}
                getOptionSelected={(option, value) =>
                  value && value.id && option ? option.id === value.id : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    groupVendorNameInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn -" />
                )}
              />
            ) : (
              type_id === GROUP_TYPE_ID ? getcare_erp_group?.name : getcare_vendor?.name
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            {params.type_id === GROUP_TYPE_ID ? params.getcare_erp_group?.description : params.getcare_vendor?.description}
          </div>
          <div className={`${gridClasses.Col}`}>
            {params.type_id === GROUP_TYPE_ID ? params.getcare_erp_group?.count : params.getcare_vendor?.count}
          </div>
          <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
            {isEditing ? (
              <>
                <IconButton
                  onClick={this.handleCancel}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CancelOutlined fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  onClick={this.handleSave}
                  disabled={!this._isRowValid(params)}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={this._isRowValid(params) ? `primary` : `inherit`}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={this.handleRemove}
                  style={{ padding: '0' }}
                  size="small"
                  disabled={readOnly}
                >
                  <DeleteOutline fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={this.handleEdit}
                  style={{ padding: '0' }}
                  size="small"
                  disabled={readOnly}
                >
                  <EditOutlined fontSize="small" color={readOnly ? 'inherit' : "primary"} />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

PromotionVendor.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentList: PropTypes.array,
  isPromotionCombo: PropTypes.bool,
  savePromotionVendor: PropTypes.func,
  removePromotionVendor: PropTypes.func,
};

PromotionVendor.defaultProps = {
};

export default PromotionVendor;
