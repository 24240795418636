import React, { Component } from 'react';

import { Switch } from "react-router-dom";
import RouteWithSubRoutes from 'components/RouteWithSubRoutes';
import PageHeader from 'components/PageHeader/PageHeader';
import isEqual from 'lodash/isEqual';
import PermissionDenied from 'views/PermissionDenied/PermissionDenied';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { checkAuth } from 'redux/actions/authActions';
import { saveGlobalWS, saveWSEvent } from 'redux/actions/wsActions';
import { createStructuredSelector } from "reselect";
import { makeSelectLoginUser } from 'redux/selectors';
import { ToastContainer } from 'react-toastify';
import { WEBSOCKET_ENDPOINT, KEEP_CONNECT_MSG, KEEP_CONNECT_RES } from 'utils/constanst/wsConstants';
import jwtService from 'utils/services/jwtService';
import { isValidJSONString } from 'utils/helper';
import { toast } from 'react-toastify';

import { DEFAULT_PAGE } from 'utils/constanst/common';

import 'react-toastify/dist/ReactToastify.css';
import classes from './LayoutAdmin.module.scss';

class LayoutAdmin extends Component {
  ws = undefined;
  _connectWebsocket = () => {
    this.ws = new WebSocket(`${WEBSOCKET_ENDPOINT}?token=${jwtService.getToken()}`);
    let that = this;
    var connectInterval;
    var connectHoldingInterval;

    this.ws.onopen = () => {
      this.props.saveGlobalWS(this.ws);
      that.timeout = 250;

      clearTimeout(connectInterval);
      clearInterval(connectHoldingInterval);

      connectHoldingInterval = setInterval(this._keepConnectWebsocket, 30000);

      this.ws.onmessage = (e) => {
        if (!e.data || !isValidJSONString(e.data)) return;
        const message = JSON.parse(e.data);
        if (message.result === false) {
          toast.error(message.message);
          return;
        }
        if (![KEEP_CONNECT_MSG, KEEP_CONNECT_RES].includes(message.event)) {
          this.props.saveWSEvent({
            ...message,
            indexField: 'event',
          });
        }
      };
    };
    this.ws.onerror = (err) => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );
      this._closeWebsocket();
    };
    this.ws.onclose = (e) => {
      that.timeout = that.timeout + that.timeout;
      connectInterval = setTimeout(this.check, Math.min(10000, that.timeout));
      clearInterval(connectHoldingInterval);
    };
  };
  _check = () => {
    if (!this.ws || this.ws.readyState === WebSocket.CLOSED)
      this._connectWebsocket();
  };
  _keepConnectWebsocket = () => {
    this.ws &&
      this.ws.send(
        JSON.stringify({
          event: KEEP_CONNECT_MSG,
        })
      );
  };
  _closeWebsocket = () => {
    this.ws && this.ws.close();
  };

  componentDidMount() {
    this.props.checkAuth();
  }
  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (user && user !== prevProps.user && !user.id) {
      // check auth failed
      window.location.href = `${process.env.REACT_APP_PATH_SSO}?continue=${process.env.REACT_APP_PATH_TMK}`;
    }
    if (prevProps.user && prevProps.user.id && (!user || !user.id)) {
      // logout success
      window.location.href = `${process.env.REACT_APP_PATH_SSO}?continue=${process.env.REACT_APP_PATH_TMK}`;
    }
    if (prevProps.user && prevProps.user.id && user && user.id && (prevProps.user.id !== user.id)) {
      // the second user logout success
      window.location.href = DEFAULT_PAGE;
    }
    if (user && user.id && user.token && !isEqual(user, prevProps.user)) {
      this._connectWebsocket();
    }
  }
  componentWillUnmount() {
    this._closeWebsocket();
  }

  render() {
    const { routes, user } = this.props;
    const hasAuth = user && user.id;

    return (<>
      { hasAuth &&
        <div className={classes.Wrap}>
          <PageHeader/>
          { routes && user.token &&
            <Switch>
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          }
          { !user.token && <PermissionDenied/>}
        </div>
      }
      {/* else // todo: redirect to login form */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick
      />
    </>);
  }
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    checkAuth: () => dispatch(checkAuth()),
    saveGlobalWS: (payload) => dispatch(saveGlobalWS(payload)),
    saveWSEvent: (payload) => dispatch(saveWSEvent(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(LayoutAdmin);
