import React, { PureComponent } from 'react';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectOriVoucherProductList,
  makeSelectVoucherDetails,
  makeSelectVoucherProductList,
} from 'redux/selectors';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import classes from './VoucherProduct.module.scss';
import gridClasses from './VoucherProductGrid.module.scss';
import {
  listParamsMap,
  listTabProductDisplayFields,
} from 'utils/constanst/tmkVoucherConstant';
import { getDisplayFields, genID } from 'utils/helper';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import {
  deselectAllVoucherProduct,
  removeMultipleVoucherProductItem,
  removeVoucherProductItem,
  saveVoucherProductItem,
  selectAllVoucherProduct,
  selectVoucherProductlItem,
} from 'redux/actions/tmk/voucherAction';
import VoucherProductItem from './VoucherProductItem/VoucherProductItem';
class VoucherProduct extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isHeaderChecked: false,
      listParams: { ...listParamsMap },
    };
  }

  handleSaveVoucherProduct = (params) => {
    this.props.saveVoucherProductItem({
      ...params,
    });
  };
  handleRemoveVoucherProduct = (params) => {
    this.props.removeVoucherProductItem([{ ...params }]);
  };

  handleCreateNewRow = () => {
    this.props.saveVoucherProductItem({
      getcare_product: {
        getcare_id: null,
        name: null,
        isChecked: false,
      },
      isEditing: true,
      isChecked: false,
      idStr: genID(),
      id: null,
      min_quantity: null,
      getcare_uom_base: null,
    });
  };

  handleDeleteSelectedItem = () => {
    this.props.removeMultipleProduct();
    this.setState({
      isHeaderChecked: false,
    })
  };

  handleCheckbox = () => {
    this.setState((prevState) => {
      return { isHeaderChecked: !prevState.isHeaderChecked };
    });
    if (this.state.isHeaderChecked) {
      this.props.deselectAllVoucherProduct();
    } else {
      this.props.selectAllVoucherProduct();
    }
  };

  render() {
    const {
      voucherProductList,
      readOnly,
      isEditing,
      disabled,
      voucherDetails,
    } = this.props;
    const displayFields = getDisplayFields(
      this.state.listParams,
      listTabProductDisplayFields
    );
    
    const isItemChecked = (element) => element.isChecked;
    const disableDeleteBulk = voucherProductList.some(isItemChecked)
    return (
      <div className={classes.VoucherProduct}>
        <div className={`${classes.List}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            <div className={classes.Col}>
              <Checkbox
                onChange={this.handleCheckbox}
                checked={this.state.isHeaderChecked}
              />
            </div>
            {displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                className={`${gridClasses.Col} ${gridClasses[item.className]}`}
                {...item}
              />
            ))}
            <div className={classes.Col}>
              <Button
                disabled={
                  readOnly || disabled || !voucherDetails?.getcare_vendor_id
                }
                color="primary"
                variant="contained"
                size="small"
                onClick={this.handleCreateNewRow}
              >
                Thêm
              </Button>
            </div>
          </div>
          <div className={classes.ItemContainer}>
            {voucherProductList.length > 0 ? (
              voucherProductList.map((item, index) => (
                <VoucherProductItem
                  key={`voucher-product-${!!item.getcare_product.id ? item.getcare_product.id : item.idStr}`}
                  {...item}
                  index={index}
                  readOnly={readOnly}
                  currentList={[
                    ...voucherProductList
                  ]}
                  selectVoucherProductItem={this.props.selectVoucherProductItem}
                  vendorId={voucherDetails?.getcare_vendor_id}
                  saveVoucherProductItem={this.handleSaveVoucherProduct}
                  removeVoucherProductItem={this.handleRemoveVoucherProduct}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
        <div className={classes.ActionDelete}>
          <Button
            disabled={
              !disableDeleteBulk || voucherProductList.length === 0
            }
            color="primary"
            variant="contained"
            size="small"
            startIcon={<DeleteOutline />}
            onClick={this.handleDeleteSelectedItem}
          >
            Xóa
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  voucherDetails: makeSelectVoucherDetails(),
  voucherProductList: makeSelectVoucherProductList(),
  oriVoucherProductList: makeSelectOriVoucherProductList(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    selectVoucherProductItem: (payload) =>
    dispatch(selectVoucherProductlItem(payload)),
    selectAllVoucherProduct: (payload) =>
      dispatch(selectAllVoucherProduct(payload)),
    deselectAllVoucherProduct: (payload) =>
      dispatch(deselectAllVoucherProduct(payload)),
    saveVoucherProductItem: (payload) =>
      dispatch(saveVoucherProductItem(payload)),
    removeVoucherProductItem: (payload) =>
      dispatch(removeVoucherProductItem(payload)),
    removeMultipleProduct: (payload) =>
    dispatch(removeMultipleVoucherProductItem(payload)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(VoucherProduct);
VoucherProduct.propTypes = {
  isEditing: PropTypes.bool,
  onRemoveCustomerItem: PropTypes.func,
  onSaveCustomerItem: PropTypes.func,
};

VoucherProduct.defaultProps = {
  isEditing: false,
};
