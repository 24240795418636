import * as QueryString from 'query-string';

export function validDate(d) {
  if (!d) return undefined;
  const date = new Date(d);
  return (date instanceof Date && !isNaN(date) && date) || undefined;
}

export function validNumber(d) {
  if (['', null, undefined].includes(d)) {
    return null;
  }
  const num = Number(d);
  return num >= 0 ? num : 0;
}

export function roundCurrency(n) {
  return Math.ceil(n);
}

export function currencyFormatVN(n) {
  if (['', null, undefined].includes(n)) return '-';
  return `${new Intl.NumberFormat().format(n)} VNĐ`;
}
export function numberFormat(n) {
  if (['', null, undefined].includes(n)) return '-';
  return new Intl.NumberFormat().format(n);
}
export function reverseFormatNumber(val, locale){
  var group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
  var decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
  var reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
  reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
  return Number.isNaN(reversedVal) ? 0 : reversedVal;
}

export function removeAccents(str) {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D');
}

// input `name asc,email desc,phone`
// output { name: 'asc', email: 'desc', phone: '' }
export function getSortsMap(sortStr) {
  if (!sortStr) return {};
  const sortArr = sortStr.split(',');
  return sortArr.reduce((memo, item) => {
    const arr = item.split(' ');
    if (arr[0] && arr[0].trim()) {
      memo[arr[0]] = arr[1] ? arr[1] : '';
    }
    return memo;
  }, {});
};

// input { name: 'asc', email: 'desc', phone: '' }
// output `name asc,email desc,phone`
export function getSortsString(sortMap) {
  return Object.keys(sortMap)
    .reduce((memo, key) => {
      memo.push(`${key} ${sortMap[key]}`);
      return memo;
    }, [])
    .join(',');
}

/* ===== input:
  listParams: { page_size: 50, page: 1, order: 'name asc', }
  displayFields: [{
    name: 'getcare_id',
    label: 'Mã khu vực',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Tình trạng',
    sortable: true,
    sortDir: '',
  }]
  ===== output:
  [{
    name: 'getcare_id',
    label: 'Mã khu vực',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Tình trạng',
    sortable: true,
    sortDir: 'asc',
  }]
*/
export function getDisplayFields(listParams, displayFields) {
  const sortsMap = listParams?.order ? getSortsMap(listParams.order) : {};
  return displayFields.map((item) => ({
    ...item,
    sortDir: sortsMap[item.name] || '',
  }));
}

export function genID() {
  return "_" + Math.random().toString(36).substr(2, 9);
};

export function getFullAddressStr(streetStr, ward, district, province, country) {
  let addressArr = [];
  if (streetStr) addressArr.push(streetStr);
  if (ward?.name) addressArr.push(ward.name);
  if (district?.name) addressArr.push(district.name);
  if (province?.name) addressArr.push(province.name);
  if (country?.name) addressArr.push(country.name);
  return addressArr.join(', ');
}

export function isValidJSONString(jsonString) {
  return (/^[\],:{}\s]*$/.test(jsonString.replace(/\\["\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, '')));
}

export function isArrayEmpty(arr) {
  return !arr.some(item => !['', null, undefined].includes(item));
}

export function getQueryString(queryParams) {
  return QueryString.stringify(queryParams, {
    arrayFormat: `bracket`,
    skipNull: true,
  });
}
export function getQueryParams(queryString) {
  return QueryString.parse(queryString, {
    arrayFormat: `bracket`,
    parseBooleans: true,
  });
}

/* allocate route */
export function allocateRoute(errorCode) {
  switch(errorCode) {
    case 401:
      window.location.replace(`${process.env.REACT_APP_PATH_SSO}?continue=${process.env.REACT_APP_PATH_TMK}`);
      break;
    case 403:
      window.location.replace(`/403`);
      break;
    case 404:
      window.location.replace(`/404`);
      break;
    default:
      break;
  }
}
