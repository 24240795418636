import {
    SAVE_LOADING,
    GET_VOUCHER,
    GET_VOUCHER_LIST,
    UPDATE_VOUCHER,
    CREATE_VOUCHER,
    RESET_VOUCHER,
    SAVE_VOUCHER_LIST,
    SAVE_VOUCHER_LIST_TOTAL,
    SAVE_VOUCHER_LIST_QUERY_STRING,
    SAVE_VOUCHER_DETAILS,
    GET_VOUCHER_TYPE,
    SAVE_VOUCHER_VENDOR_LIST,
    SAVE_VOUCHER_CUSTOMER_LIST,
    SAVE_VOUCHER_SALE_CHANNEL_LIST,
    SAVE_ORI_VOUCHER_DETAILS,
    SAVE_ORI_VOUCHER_CUSTOMER_LIST,
    SAVE_ORI_VOUCHER_SALE_CHANNEL_LIST,
    SAVE_CUSTOMER_LIST,
    SAVE_SALE_CHANNEL_LIST,
    GET_CUSTOMER_LIST,
    GET_SALE_CHANNEL_LIST,
    SAVE_VOUCHER_CUSTOMER_LIST_ITEM,
    REMOVE_VOUCHER_SALE_CHANNEL_LIST_ITEM,
    REMOVE_VOUCHER_CUSTOMER_LIST_ITEM,
    GET_USED_VOUCHER_LIST,
    SAVE_USED_VOUCHER_LIST_TOTAL,
    SAVE_USED_VOUCHER_LIST_QUERY_STRING,
    SAVE_USED_VOUCHER_LIST,
    GET_VOUCHER_VENDOR_LIST,
    SAVE_ORI_VOUCHER_PRODUCTS,
    SAVE_VOUCHER_PRODUCTS,
    SAVE_VOUCHER_PRODUCT_ITEM,
    REMOVE_VOUCHER_PRODUCT_ITEMS,
    SAVE_VOUCHER_SALE_CHANNEL_LIST_ITEM,
    SAVE_VOUCHER_UNUSED_LIST,
    EXPORT_VOUCHER_UNUSED_LIST,
    EXPORT_VOUCHER_GIFTCODES_UNUSED_LIST,
    SAVE_VOUCHER_CONFIG_GIFT_LIST,
    SAVE_VOUCHER_CONFIG_MONEY_LIST,
    SAVE_ORI_VOUCHER_CONFIG_MONEY_LIST,
    SAVE_ORI_VOUCHER_CONFIG_GIFT_LIST,
    SAVE_VOUCHER_CONFIG_GIFT_LIST_ITEM,
    SAVE_VOUCHER_CONFIG_MONEY_LIST_ITEM,
    REMOVE_VOUCHER_CONFIG_GIFT_LIST_ITEM,
    REMOVE_VOUCHER_CONFIG_MONEY_LIST_ITEM,
    SELECT_ALL_VOUCHER_PRODUCT,
    SELECT_ALL_VOUCHER_CUSTOMER,
    SELECT_ALL_VOUCHER_SALE_CHANNEL,
    SELECT_VOUCHER_PRODUCT_ITEM,
    SELECT_VOUCHER_CUSTOMER_ITEM,
    SELECT_VOUCHER_SALE_CHANNEL_ITEM,
    DESELECT_ALL_VOUCHER_SALE_CHANNEL,
    DESELECT_ALL_VOUCHER_CUSTOMER,
    DESELECT_ALL_VOUCHER_PRODUCT,
    REMOVE_MULTIPLE_VOUCHER_CUSTOMER_ITEM,
    REMOVE_MULTIPLE_VOUCHER_PRODUCT_ITEM,
    REMOVE_MULTIPLE_VOUCHER_SALE_CHANNEL_ITEM,
    SELECT_ALL_VOUCHER_CONFIG_MONEY,
    SELECT_VOUCHER_CONFIG_ITEM_MONEY,
    REMOVE_MULTIPLE_VOUCHER_CONFIG_MONEY_ITEM ,
    DESELECT_ALL_VOUCHER_CONFIG_MONEY ,
    SELECT_ALL_VOUCHER_CONFIG_GIFT,
    SELECT_VOUCHER_CONFIG_ITEM_GIFT ,
    REMOVE_MULTIPLE_VOUCHER_CONFIG_GIFT_ITEM ,
    DESELECT_ALL_VOUCHER_CONFIG_GIFT,
  } from 'redux/constants/tmk/voucherConstants';
  
  export function getVoucherList(payload) {
    return {
      type: GET_VOUCHER_LIST,
      payload,
    };
  }

  export function getUsedVoucherList(payload) {
    return {
      type: GET_USED_VOUCHER_LIST,
      payload,
    };
  }
  
  export function getVoucher(payload) {
    return {
      type: GET_VOUCHER,
      payload
    }
  }
  
  export function getCustomerList(payload) {
    return {
      type: GET_CUSTOMER_LIST,
      payload
    }
  }
  
  export function getSaleChannelList(payload) {
    return {
      type: GET_SALE_CHANNEL_LIST,
      payload
    }
  }
  
  
  export function getVoucherVendorList(payload) {
    return {
      type: GET_VOUCHER_VENDOR_LIST,
      payload
    }
  }
  
  export function getVoucherType(payload) {
    return {
      type: GET_VOUCHER_TYPE,
      payload
    }
  }
  
  export function updateVoucher(payload) {
    return {
      type: UPDATE_VOUCHER,
      payload,
    };
  }

  export function createVoucher(payload) {
    return {
      type: CREATE_VOUCHER,
      payload,
    };
  }
  
  export function resetVoucher(payload) {
    return {
      type: RESET_VOUCHER,
      payload,
    };
  }
  
  export function saveVoucherDetails(voucherDetails) {
    return {
      type: SAVE_VOUCHER_DETAILS,
      voucherDetails,
    };
  }
  
  export function saveOriVoucherDetails(oriVoucherDetails) {
    return {
      type: SAVE_ORI_VOUCHER_DETAILS,
      oriVoucherDetails,
    };
  }
  
  
  export function saveVoucherCustomerList(voucherCustomerList) {
    return {
      type: SAVE_VOUCHER_CUSTOMER_LIST,
      voucherCustomerList
    }
  }

  export function saveVoucherConfigGiftList(voucherConfigGiftList) {
    return {
      type: SAVE_VOUCHER_CONFIG_GIFT_LIST,
      voucherConfigGiftList
    }
  }

  export function saveVoucherConfigMoneyList(voucherConfigMoneyList) {
    return {
      type: SAVE_VOUCHER_CONFIG_MONEY_LIST,
      voucherConfigMoneyList
    }
  }
  
  
  export function saveVoucherSaleChannelList(voucherSaleChannelList) {
    return {
      type: SAVE_VOUCHER_SALE_CHANNEL_LIST,
      voucherSaleChannelList
    }
  }
  
  export function saveOriVoucherCustomerList(oriVoucherCustomerList) {
    return {
      type: SAVE_ORI_VOUCHER_CUSTOMER_LIST,
      oriVoucherCustomerList
    }
  }

  export function saveOriVoucherConfigGiftList(oriVoucherConfigGiftList) {
    return {
      type: SAVE_ORI_VOUCHER_CONFIG_GIFT_LIST,
      oriVoucherConfigGiftList
    }
  }

  export function saveOriVoucherConfigMoneyList(oriVoucherConfigMoneyList) {
    return {
      type: SAVE_ORI_VOUCHER_CONFIG_MONEY_LIST,
      oriVoucherConfigMoneyList
    }
  }
  
  export function saveOriVoucherSaleChannelList(oriVoucherSaleChannelList) {
    return {
      type: SAVE_ORI_VOUCHER_SALE_CHANNEL_LIST,
      oriVoucherSaleChannelList
    }
  }

  export function saveOriVoucherProductList(oriVoucherProductList) {
    return {
      type: SAVE_ORI_VOUCHER_PRODUCTS,
      oriVoucherProductList
    }
  }

  export function saveVoucherProductList(voucherProductList) {
    return {
      type: SAVE_VOUCHER_PRODUCTS,
      voucherProductList
    }
  }

  export function saveVoucherUnusedList(voucherUnusedList) {
    return {
      type: SAVE_VOUCHER_UNUSED_LIST,
      voucherUnusedList
    }
  }

  export function exportVoucherUnusedList(payload) {
    return {
      type: EXPORT_VOUCHER_UNUSED_LIST,
      payload
    }
  }

  
  export function exportVoucherGiftCodeUnusedList(payload) {
    return {
      type: EXPORT_VOUCHER_GIFTCODES_UNUSED_LIST,
      payload
    }
  }

  
  export function saveVoucherList(voucherList) {
    return {
      type: SAVE_VOUCHER_LIST,
      voucherList,
    };
  }
  
  export function saveUsedVoucherList(usedVoucherList) {
    return {
      type: SAVE_USED_VOUCHER_LIST,
      usedVoucherList,
    };
  }
  
  export function saveLoading(loading) {
    return {
      type: SAVE_LOADING,
      loading,
    };
  }
  
  export function saveVoucherListTotal(voucherListTotal) {
    return {
      type: SAVE_VOUCHER_LIST_TOTAL,
      voucherListTotal,
    };
  }

  export function saveUsedVoucherListTotal(usedVoucherListTotal) {
    return {
      type: SAVE_USED_VOUCHER_LIST_TOTAL,
      usedVoucherListTotal,
    };
  }
  
  export function saveVoucherListQueryString(voucherListQueryString) {
    return {
      type: SAVE_VOUCHER_LIST_QUERY_STRING,
      voucherListQueryString,
    };
  }

  export function saveUsedVoucherListQueryString(usedVoucherListQueryString) {
    return {
      type: SAVE_USED_VOUCHER_LIST_QUERY_STRING,
      usedVoucherListQueryString,
    };
  }
  
  export function saveCustomerList(customerList) {
    return {
      type: SAVE_CUSTOMER_LIST,
      customerList,
    };
  }
  
  export function saveSaleChannelList(saleChannelList) {
    return {
      type: SAVE_SALE_CHANNEL_LIST,
      saleChannelList,
    };
  }

  export function saveVoucherVendorList(voucherVendorList) {
    return {
      type: SAVE_VOUCHER_VENDOR_LIST,
      voucherVendorList,
    };
  }
  
  export function saveVoucherProductItem(voucherProductItem) {
    return {
      type: SAVE_VOUCHER_PRODUCT_ITEM,
      voucherProductItem
    }
  }
  
  export function saveVoucherCustomerListItem(customerListItem) {
    return {
      type: SAVE_VOUCHER_CUSTOMER_LIST_ITEM,
      customerListItem,
    };
  }

  export function saveVoucherConfigGiftItem(voucherConfigGiftItem) {
    return {
      type: SAVE_VOUCHER_CONFIG_GIFT_LIST_ITEM,
      voucherConfigGiftItem,
    };
  }

  export function saveVoucherConfigMoneyItem(voucherConfigMoneyItem) {
    return {
      type: SAVE_VOUCHER_CONFIG_MONEY_LIST_ITEM,
      voucherConfigMoneyItem,
    };
  }
  
  
  export function saveVoucherSaleChannelListItem(saleChannelListItem) {
    return {
      type: SAVE_VOUCHER_SALE_CHANNEL_LIST_ITEM,
      saleChannelListItem,
    };
  }
  
  export function removeVoucherCustomerListItem(customerListItem) {
    return {
      type: REMOVE_VOUCHER_CUSTOMER_LIST_ITEM,
      customerListItem
    }
  }

  export function removeVoucherConfigGiftItem(voucherConfigGiftItem) {
    return {
      type: REMOVE_VOUCHER_CONFIG_GIFT_LIST_ITEM,
      voucherConfigGiftItem
    }
  }

  export function removeVoucherConfigMoneyItem(voucherConfigMoneyItem) {
    return {
      type: REMOVE_VOUCHER_CONFIG_MONEY_LIST_ITEM,
      voucherConfigMoneyItem
    }
  }
  
  export function removeVoucherSaleChannelListItem(saleChannelListItem) {
    return {
      type: REMOVE_VOUCHER_SALE_CHANNEL_LIST_ITEM,
      saleChannelListItem,
    };
  }


  export function removeVoucherProductItem(voucherProductItems) {
    return {
      type: REMOVE_VOUCHER_PRODUCT_ITEMS,
      voucherProductItems,
    };
  }


  export function selectAllVoucherProduct(voucherProductList) {
    return {
      type: SELECT_ALL_VOUCHER_PRODUCT,
      voucherProductList
    }
  }
  
  export function deselectAllVoucherProduct(voucherProductList) {
    return {
      type: DESELECT_ALL_VOUCHER_PRODUCT,
      voucherProductList
    }
  }

  export function selectAllVoucherCustomer(voucherCustomerList) {
    return {
      type: SELECT_ALL_VOUCHER_CUSTOMER,
      voucherCustomerList
    }
  }

  export function deselectAllVoucherCustomer(voucherCustomerList) {
    return {
      type: DESELECT_ALL_VOUCHER_CUSTOMER,
      voucherCustomerList
    }
  }


  export function selectAllVoucherSaleChannel(voucherSaleChannelList) {
    return {
      type: SELECT_ALL_VOUCHER_SALE_CHANNEL,
      voucherSaleChannelList
    }
  }

  export function deselectAllVoucherSaleChannel(voucherSaleChannelList) {
    return {
      type: DESELECT_ALL_VOUCHER_SALE_CHANNEL,
      voucherSaleChannelList
    }
  }

  export function selectAllVoucherConfigGift(voucherConfigGiftList) {
    return {
      type: SELECT_ALL_VOUCHER_CONFIG_GIFT,
      voucherConfigGiftList
    }
  }

  export function deselectAllVoucherConfigGift(voucherConfigGiftList) {
    return {
      type: DESELECT_ALL_VOUCHER_CONFIG_GIFT,
      voucherConfigGiftList
    }
  }

  export function selectAllVoucherConfigMoney(voucherConfigMoneyList) {
    return {
      type: SELECT_ALL_VOUCHER_CONFIG_MONEY,
      voucherConfigMoneyList
    }
  }

  export function deselectAllVoucherConfigMoney(voucherConfigMoneyList) {
    return {
      type: DESELECT_ALL_VOUCHER_CONFIG_MONEY,
      voucherConfigMoneyList
    }
  }

  export function selectVoucherConfigMoneyItem(voucherConfigMoneyItem) {
    return {
      type: SELECT_VOUCHER_CONFIG_ITEM_MONEY,
      voucherConfigMoneyItem
    }
  }


  export function selectVoucherConfigGiftItem(voucherConfigGiftItem) {
    return {
      type: SELECT_VOUCHER_CONFIG_ITEM_GIFT,
      voucherConfigGiftItem
    }
  }

  export function selectVoucherCustomerItem(customerListItem) {
    return {
      type: SELECT_VOUCHER_CUSTOMER_ITEM,
      customerListItem
    }
  }

  export function selectVoucherSaleChannelItem(saleChannelListItem) {
    return {
      type: SELECT_VOUCHER_SALE_CHANNEL_ITEM,
      saleChannelListItem
    }
  }

  export function selectVoucherProductlItem(productlistItem) {
    return {
      type: SELECT_VOUCHER_PRODUCT_ITEM,
      productlistItem
    }
  }

  export function removeMultipleVoucherProductItem(payload) {
    return {
      type: REMOVE_MULTIPLE_VOUCHER_PRODUCT_ITEM,
      payload
    }
  }

  export function removeMultipleVoucherCustomerItem(payload) {
    return {
      type: REMOVE_MULTIPLE_VOUCHER_CUSTOMER_ITEM,
      payload
    }
  }

  export function removeMultipleVoucherSaleChannelItem(payload) {
    return {
      type: REMOVE_MULTIPLE_VOUCHER_SALE_CHANNEL_ITEM,
      payload
    }
  }

  
  export function removeMultipleVoucherConfigMoneyItem(payload) {
    return {
      type: REMOVE_MULTIPLE_VOUCHER_CONFIG_MONEY_ITEM,
      payload
    }
  }

  export function removeMultipleVoucherConfigGiftItem(payload) {
    return {
      type: REMOVE_MULTIPLE_VOUCHER_CONFIG_GIFT_ITEM,
      payload
    }
  }

  