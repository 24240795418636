import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { validDate, validNumber } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';
import { isEqual, sortBy } from 'lodash';

import EditOutlined from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';

import classes from './FieldEditable.module.scss';

class FieldEditable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: !!props.editModeOnly || props.isEditing,
      params: {
        [props.fieldName]: props.value,
      },
      autocompleteValue: null,
    };
  }
  componentDidMount() {
    if (this.props.fieldType === 'select') {
      const selectedOption = this._findSelectedOptionById(this.props.value);
      this.setState({
        autocompleteValue: selectedOption,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { fieldName, value, fieldType, options } = this.props;
    if (value !== prevProps.value) {
      this.setState({
        isEditing: false,
        params: {
          ...this.state.params,
          [fieldName]: value,
        },
      });
    }
    if (
      fieldType === 'select' &&
      (value !== prevProps.value ||
        !isEqual(sortBy(options), sortBy(prevProps.options)))
    ) {
      const selectedOption = this._findSelectedOptionById(value);
      this.setState({
        autocompleteValue: selectedOption,
      });
    }
  }
  _findSelectedOptionById = (id) => {
    return this.props.options.find((item) => item.id === id);
  };
  _sliceText = (text) => {
    return text.slice(0, this.props.maxLength);
  };
  isEditing = () => {
    return this.state.isEditing || this.props.editModeOnly;
  };
  handleEdit = (fieldName) => {
    this.setState({
      isEditing: true,
    });
  };
  handleChange = (e) => {
    const newValue = this.props.maxLength
      ? this._sliceText(e.target.value)
      : e.target.value;
    const populatedValue =
      this.props.type === 'number'
        ? (this.props.isInteger && validNumber(e.target.floatValue)
          ? Math.ceil(validNumber(e.target.floatValue))
          : validNumber(e.target.floatValue))
        : newValue;
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: populatedValue,
      },
    });
    if (this.props.onChange) {
      this.props.onChange(e, populatedValue);
    }
  };
  handleAutocompleteChange = (e, newValue) => {
    const newValueId = newValue ? newValue.id : null;
    this.setState({
      autocompleteValue: newValue,
      params: {
        ...this.state.params,
        [this.props.fieldName]: newValueId,
      },
    });
    if (this.props.onChange) {
      this.props.onChange(
        { target: { name: this.props.fieldName } },
        newValueId,
      );
    }
  };
  handleDateChange = (date) => {
    const value = validDate(date) ? date.toISOString() : '';
    this.setState({
      params: {
        ...this.state.params,
        [this.props.fieldName]: value,
      },
    });
    if (this.props.onChange) {
      this.props.onChange({ target: { name: this.props.fieldName } }, value);
    }
  };
  handleSave = () => {
    const { fieldName } = this.props;
    this.setState({
      isEditing: false,
    });
    this.props.onSave({
      [fieldName]:
        this.props.type === 'number'
          ? validNumber(this.state.params[fieldName])
          : this.state.params[fieldName],
    });
  };
  handleCancel = () => {
    const initParams = {
      [this.props.fieldName]: this.props.value,
    }
    this.setState({
      isEditing: false,
      params: {...initParams},
    });
    if (this.props.onCancel) {
      this.props.onCancel({...initParams});
    }
  };
  handleRowBlur = () => {};
  handleInputBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur([this.props.fieldName], this.props.value);
    }
  };

  render() {
    const {
      value,
      fieldName,
      type,
      fieldType,
      displayedValue,
      error,
      minValue,
      disableClearable,
      disabled,
      inputProps,
    } = this.props;
    const { params, autocompleteValue } = this.state;

    return (
      <ClickAwayListener onClickAway={this.handleRowBlur}>
        {!this.isEditing() ? (
          <div className={classes.EditInlineCol}>
            <label onClick={this.handleEdit}>
              {displayedValue ? displayedValue : value}{' '}
            </label>
            <IconButton onClick={this.handleEdit} style={{ padding: '3px' }}>
              <EditOutlined fontSize="small" color="primary" />
            </IconButton>
          </div>
        ) : (
          <div className={classes.EditInlineCol}>
            {fieldType === 'textarea' && (
              <div className={classes.FieldWrap}>
                <TextField
                  autoComplete="off"
                  rowsMax={this.props.rowsMax}
                  fullWidth
                  multiline
                  value={params[fieldName]}
                  placeholder="Nhập..."
                  autoFocus={this.props.autoFocus}
                  disabled={disabled}
                  name={fieldName}
                  error={error}
                  onChange={this.handleChange}
                  onBlur={this.handleInputBlur}
                />
              </div>
            )}
            {fieldType === 'select' && (
              <Autocomplete
                className={classes.AutocompleteField}
                disableClearable={disableClearable}
                openOnFocus
                selectOnFocus
                size="small"
                value={autocompleteValue || null}
                name={fieldName}
                onChange={this.handleAutocompleteChange}
                disabled={disabled}
                handleHomeEndKeys={false}
                options={this.props.options || []}
                getOptionLabel={(option) => option?.name || ''}
                getOptionSelected={(option, value) =>
                  value && ![null, undefined, ''].includes(value.id) && option
                    ? option.id === value.id
                    : null
                }
                renderInput={(params) => (
                  <TextField {...params} error={error} placeholder="- Chọn -" />
                )}
              />
            )}
            {fieldType === 'text' && type !== 'number' && (
              <TextField
                autoComplete="off"
                className={classes.FieldWrap}
                value={
                  [null, undefined].includes(params[fieldName])
                    ? ``
                    : params[fieldName]
                }
                placeholder="Nhập..."
                autoFocus={this.props.autoFocus}
                disabled={disabled}
                name={fieldName}
                type={type}
                error={error}
                InputProps={{ ...inputProps }}
                onChange={this.handleChange}
                onBlur={this.handleInputBlur}
              />
            )}
            {fieldType === 'text' && type === 'number' && (
              <TextField
                autoComplete="off"
                disabled={disabled}
                className={classes.FieldWrap}
                value={
                  [null, undefined].includes(params[fieldName])
                    ? ``
                    : params[fieldName]
                }
                placeholder="Nhập..."
                autoFocus={this.props.autoFocus}
                name={fieldName}
                error={error}
                InputProps={{
                  ...inputProps,
                  inputComponent: NumberFormatCustom,
                }}
                onChange={this.handleChange}
                onBlur={this.handleInputBlur}
              />
            )}

            {fieldType === 'date' && (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  disabled={disabled}
                  variant="inline"
                  placeholder="Nhập hoặc chọn..."
                  format={dateFormat}
                  minDate={validDate(minValue)}
                  error={error}
                  value={
                    validDate(params[fieldName])
                      ? validDate(params[fieldName])
                      : null
                  }
                  onChange={this.handleDateChange}
                  className={classes.DatePickerField}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            )}
            {this.isEditing() && !this.props.editModeOnly && (
              <>
                <IconButton
                  onClick={this.handleCancel}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CancelOutlined fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  disabled={error}
                  onClick={this.handleSave}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={!error ? `primary` : `inherit`}
                  />
                </IconButton>
              </>
            )}
          </div>
        )}
      </ClickAwayListener>
    );
  }
}

FieldEditable.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  options: PropTypes.array,
  displayedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fieldType: PropTypes.oneOf(['text', 'textarea', 'select', 'date']),
  rowsMax: PropTypes.number,
  editModeOnly: PropTypes.bool,
  minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.bool,
  isEditing: PropTypes.bool,
  maxLength: PropTypes.number,
  inputProps: PropTypes.object,
  disabled: PropTypes.bool,
  isInteger: PropTypes.bool,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
  onInputAutocompleteChange: PropTypes.func,
  onBlur: PropTypes.func,
};

FieldEditable.defaultProps = {
  value: '',
  type: '',
  fieldType: 'text',
  options: [],
  editModeOnly: false,
  error: false,
  isEditing: false,
  maxLength: undefined,
  disabled: false,
  inputProps: {},
  rowsMax: 3,
  isInteger: false,
};

export default FieldEditable;
