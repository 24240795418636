export const MAX_FILE_SIZE = 5*1024*1024;

export const listPageSizes = [50, 100, 150];
export const suggestionPageSize = 1000;
export const suggestionPageSize2 = 20;

export const DEFAULT_PAGE = `/promotion`;

export const ACTIVE = 1;
export const DEACTIVE = -1;
export const activeStatusMap = {
  [ACTIVE]: 'Đang hoạt động',
  [DEACTIVE]: 'Ngừng hoạt động',
};
