import React, { PureComponent } from 'react';
import { testPromotionOptions } from 'utils/constanst/tmkPromotionTestConstants';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  checkPromotion,
  savePromotionAvailables,
  saveUsedPromotions,
  savePromotionActives,
  saveOrderAmount,
} from 'redux/actions/tmk/testPromotionActions';
import {
  makeSelectTestOrderList,
  makeSelectTestPromotionAvailables,
  makeSelectTestPromotionActives,
  makeSelectTestUsedPromotions,
  makeSelectTestPromotionLoading,
  makeSelectTestOrderAmount,
} from 'redux/selectors';
import { groupBy, orderBy, isEqual } from 'lodash';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CustomerAndDateSelector from 'components/tmk/PromotionTest/CustomerAndDateSelector/CustomerAndDateSelector';
import OrderList from 'components/tmk/PromotionTest/OrderList/OrderList';
import Button from '@material-ui/core/Button';
import PromotionAvailables from 'components/tmk/PromotionTest/PromotionAvailables/PromotionAvailables';
import PromotionActives from 'components/tmk/PromotionTest/PromotionActives/PromotionActives';

import classes from './PromotionTest.module.scss';
import { currencyFormatVN } from 'utils/helper';

class PromotionTest extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedTestId: 1,
      orderDate: new Date(),
      selectedCustomer: null,

      testPromotionOptions: [...testPromotionOptions],
      hasChecked: false,
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { selectedCustomer, orderDate } = this.state;
    if (!isEqual(selectedCustomer, prevState.selectedCustomer)
      || !isEqual(orderDate, prevState.orderDate)
    ) {
      this._resetPromotionData();
      this.setState({
        hasChecked: false,
      });
    }
  }

  _isAbleToCheck = () => {
    const { orderDate, selectedCustomer } = this.state;
    return !!this._getValidOrderItems(this.props.orderList).length && !!orderDate && !!selectedCustomer;
  }
  _getValidOrderItems = (orderListItems) => {
    return orderListItems.filter(
      item => this._isOrderItemValid(item)
    );
  }
  _isOrderItemValid = (order) => {
    return order.getcare_product?.id
      && order.getcare_vendor?.id
      && order.getcare_uom_base?.id
      && !['', null, undefined].includes(order.price_sales_total)
      && order.quantity_number > 0
  }
  _getOrderListData = () => {
    const { orderList } = this.props;
    const orderListData = orderList.filter(
      item => this._isOrderItemValid(item)
    ).map(item => ({
      getcare_product_id: item.getcare_product.id,
      getcare_vendor_id: item.getcare_vendor.id,
      getcare_uom_base_id: item.getcare_uom_base.id,
      quantity_number: Number(item.quantity_number),
    }));

    const quantitiesMap = orderListData.reduce((memo, item) => {
      const key = `${item.getcare_product_id}-${item.getcare_vendor_id}-${item.getcare_uom_base_id}`;
      memo[key] = [null, undefined].includes(memo[key]) ? item.quantity_number : (memo[key] + item.quantity_number);
      return memo;
    }, {});

    return Object.keys(quantitiesMap).map(key => {
      const keyArr = key.split('-');
      return {
        getcare_product_id: Number(keyArr[0]),
        getcare_vendor_id: Number(keyArr[1]),
        getcare_uom_base_id: Number(keyArr[2]),
        quantity_number: quantitiesMap[key],
      }
    })
  }
  _getOrderSubTotal = () => {
    const { orderList } = this.props;
    return orderList.filter(product =>
      product.getcare_product?.id && product.getcare_vendor?.id && product.getcare_uom_base?.id
    ).reduce((memo, item) => {
        memo += (Number(item.price_sales_total || 0) * Number(item.quantity_number || 0));
        return memo;
      }, 0);
  }
  _prepareUsedPromotionsData = () => {
    const { usedPromotions } = this.props;
    const groupByPromotionIdMap = groupBy(
      usedPromotions.map((item, index) => ({ ...item, index })),
      'promotion_id'
    );
    // group the array by promotion id and sort by the order of promotion selection
    return orderBy(Object.keys(groupByPromotionIdMap).map(key => ({
      id: parseFloat(key),
      items: groupByPromotionIdMap[key],
      index: groupByPromotionIdMap[key][0]?.index,
    })), 'index');
  }
  _prepareData = ({ hasResetData }) => {
    if (hasResetData) {
      this._resetPromotionData();
    }
    const { orderDate, selectedCustomer } = this.state;
    return {
      getcare_customer_id: selectedCustomer?.id,
      date: orderDate.toISOString(),

      order_items: this._getOrderListData(),

      promotion_actives: hasResetData ? [] : this._prepareUsedPromotionsData(),
    }
  }
  _resetPromotionData = () => {
    this.props.savePromotionAvailables([]);
    this.props.savePromotionActives([]);
    this.props.saveUsedPromotions([]);
    this.props.saveOrderAmount(null);
  }

  handleChangeCustomer = (newValue) => {
    this.setState({
      selectedCustomer: { ...newValue },
    });
  }
  handleChangeOrderDate = (newValue) => {
    this.setState({
      orderDate: newValue,
    });
  }
  handleCheckPromotion = () => {
    this.setState({
      hasChecked: true,
    });
    this.props.checkPromotion({
      params: this._prepareData({ hasResetData : true }),
    });
  }
  handleSubmitPromotion = () => {
    this.props.checkPromotion({
      params: this._prepareData({ hasResetData : false }),
    });
  }
  handleOrderManuallyChange = () => {
    if (this.state.hasChecked) {
      this._resetPromotionData();
      this.setState({
        hasChecked: false,
      });
    }
  }

  render() {
    const { selectedTestId, testPromotionOptions, selectedCustomer, hasChecked } = this.state;
    const { promotionActives, promotionAvailables, loading, orderAmount } = this.props;
    const subTotal = this._getOrderSubTotal();

    return (<>
      <div className={classes.PageWrap}>
        <div className={classes.PageHeader}>
          <div className={classes.PageHeaderInner}>
            <h1 className={classes.PageTitle}>Test promotion</h1>
            <div className={classes.PageActions}>
              <Select
                value={selectedTestId}
                fullWidth
                onChange={this.handleChangeTest}
              >
                {
                  testPromotionOptions.map(item => <MenuItem key={`type-${item.id}`} value={item.id}>{ item.name }</MenuItem>)
                }
              </Select>
            </div>
          </div>
        </div>
        <div className={`${classes.PageMain} ${loading ? 'OverlayLoading' : ''}`}>
          <CustomerAndDateSelector
            orderDate={this.state.orderDate}
            selectedCustomer={selectedCustomer}
            onChangeCustomer={this.handleChangeCustomer}
            onChangeOrderDate={this.handleChangeOrderDate}
          />

          { selectedCustomer?.id &&
            <OrderList
              orderDate={this.state.orderDate}
              selectedCustomerId={selectedCustomer?.id}
              onManuallyChange={this.handleOrderManuallyChange}
            />
          }

          { !!orderAmount && <div className={classes.OrderAmountWrap}><div className={classes.OrderAmount}>
            <p className={classes.OrderAmountLine}>
              <label>Tiền hàng</label>
              <span className={classes.OrderAmountValue}>{![null, undefined, ''].includes(subTotal) ? currencyFormatVN(subTotal) : `-`}</span>
            </p>
            <span className={`${classes.HelpText}`}>(Đã bao gồm VAT nếu có)</span>
            <p className={classes.OrderAmountLine}>
              <label>Giảm giá</label>
              <span className={classes.OrderAmountValue}>{![null, undefined, ''].includes(orderAmount.amount_discount) ? currencyFormatVN(orderAmount.amount_discount) : `-`}</span>
            </p>
            <p className={`${classes.OrderAmountLine} ${classes.TotalLine}`}>
              <label>Phải thanh toán</label>
              <span className={classes.OrderAmountValue}>{![null, undefined, ''].includes(orderAmount.amount_total) ? currencyFormatVN(orderAmount.amount_total) : `-`}</span>
            </p>
          </div></div> }

          <div className={classes.CheckControl}>
            <Button
              color="primary"
              variant="contained"
              disabled={!this._isAbleToCheck()}
              onClick={this.handleCheckPromotion}
            >
              Check khuyến mãi
            </Button>
          </div>

          { this._isAbleToCheck() &&
            <div className={classes.PromotionActivesWrap}>
              { promotionAvailables && promotionAvailables.length > 0
                ? <>
                    <PromotionAvailables onSubmitPromotion={this.handleSubmitPromotion}/>
                    { promotionActives && promotionActives.length > 0 && <PromotionActives/> }
                  </>
                : hasChecked ? 'Không có khuyến mãi nào' : ''
              }
            </div>
          }
        </div>
      </div>
    </>);
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkPromotion: (payload) => dispatch(checkPromotion(payload)),
    savePromotionActives: (payload) => dispatch(savePromotionActives(payload)),
    saveUsedPromotions: (payload) => dispatch(saveUsedPromotions(payload)),
    savePromotionAvailables: (payload) => dispatch(savePromotionAvailables(payload)),
    saveOrderAmount: (payload) => dispatch(saveOrderAmount(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  loading: makeSelectTestPromotionLoading(),
  orderList: makeSelectTestOrderList(),
  promotionAvailables: makeSelectTestPromotionAvailables(),
  promotionActives: makeSelectTestPromotionActives(),
  usedPromotions: makeSelectTestUsedPromotions(),
  orderAmount: makeSelectTestOrderAmount(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PromotionTest);
