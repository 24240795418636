import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  bonusGroupTypes,
  GROUP_TYPE_ID,
  PRODUCT_TYPE_ID,
  BONUS_PROUDCT_TYPE_ID,
} from 'utils/constanst/tmkPromotionConstants';
import promotionApi from 'utils/api/tmk/promotionApi';
import uomApi from 'utils/api/mdm/uomApi';
import { isEqual, debounce } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPromotionRules } from 'redux/selectors';
import { validNumber } from 'utils/helper';

import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {
  listParamsMap,
} from 'utils/constanst/tmkPromotionConstants';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

import classes from './PromotionOrders.module.scss';
import gridClasses from '../PromotionOrderGrid.module.scss';

class PromotionOrder extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: props.isEditing,
      params: this._getInitParams(props),
      groupProductOptions: [],
      selectedPromotion: '',
      listParams: { ...listParamsMap },
      PromotionCodeInput: '',
      PromotionNameInput: ''
    };
  }

  componentDidMount () {
    this.setState({
      selectedPromotion: this.state.params
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { PromotionCodeInput, groupProductNameInput, params, unitOptions, isEditing ,PromotionNameInput} = this.state;
    if (PromotionCodeInput && PromotionCodeInput !== prevState.PromotionCodeInput) {
      this._loadSuggestedPromotion({
        params: {
          code: PromotionCodeInput,
        },
      });
    }
    if (PromotionNameInput && PromotionNameInput !== prevState.PromotionNameInput) {
      this._loadSuggestedPromotion({
        params: {
          name: PromotionNameInput,
        },
      });
    }
    
    if (params.getcare_product && params.getcare_product.id && !isEqual(prevState.params.getcare_product, params.getcare_product)) {
      this._loadSuggestedUnits({
        params: {
          getcare_product_id: params.getcare_product.id,
        },
      });
    }
    
    if (params.getcare_product_bonus && params.getcare_product_bonus.id && !isEqual(prevState.params.getcare_product_bonus, params.getcare_product_bonus)) {
      this.setState({ unitOptions: [{...params.getcare_product_bonus.getcare_product_unit}] });
    }
    if (unitOptions && unitOptions.length && unitOptions !== prevState.unitOptions) {
      this.handleSaveField({target: {name: 'getcare_uom_base'}}, unitOptions[0])
    }
    if (isEditing !== prevState.isEditing && this._isRowValid(params)) {
      this.props.savePromotionProduct({
        ...params,
        idStr: this.props.idStr,
        id: this.props.id,
        isEditing,
      });
    }
  }


  _loadSuggestedPromotion = debounce(async ({ params }) => {
    const { data: response } = await promotionApi.getAll({
      params: {
        ...this.state.listParams,
        ...params,
      },
    });
    if (!response?.result) return;
    this.setState({
      groupProductOptions: response.data
        ? (response.data
          ? [...response.data] 
          : [])
        : [],
    });
  }, 500);

  _loadSuggestedUnits = async ({ params }) => {
    const { data: response } = await uomApi.getAllUomBase({ params });
    if (!response?.result) return;
    this.setState({ unitOptions: response.data });
  };

  _getInitParams = (params) => {
    return {
      idStr: params?.idStr,
      id: params?.id,
      idPromotion: params?.id,
      name: params?.name,
      code: params?.code,
    }
  }
  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
      groupProductOptions: [],
      groupProductCodeInput: '',
      groupProductNameInput: '',
    });
  }
  _resetFormAfterChangeTypeId = (typeIdValue) => {
    this.setState({
      isEditing: true,
      params: this._getInitParams({
        ...this.state.params,
      }),
    });
  }

  _hasProductName = (params) => {
    return !!params?.name
  }
  _hasProduct = (params) => {
    return !!params?.code
  }
  
  _isRowValid = (params) => {
    return this._hasProduct(params) || this._hasProductName(params)
  };
  _getLevelOptions = () => {
    const { promotionRules } = this.props;
    return promotionRules.filter(rule => rule.bonus_number && rule.bonus_mode !== 1).map(item => item.level);
  }
  stopPropagation = (e) => {
    e.stopPropagation();
  }
  handleSaveField = (e, value) => {
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: (e.target.type === 'number')  ? validNumber(value) : value,
      },
    });
  }
  handleRowClick = () => {
    if (this.props.readOnly) {
      return;
    }

    if (!this.state.isEditing) {
      this.handleEdit();
    }
  }
  handleEdit = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = (e) => {
    if (e) e.stopPropagation();
    if (this._isRowValid(this.props)) {
      // if it was saved to DB or it was used to save on client successful
      this._resetState();
      return;
    }
    this.props.removePromotionProduct({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  }
  handleRemove = (e) => {
    if (e) e.stopPropagation();
    this.props.removePromotionProduct({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  }
  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: false,
    });
  }
  handleSelectGroupChange = (e) => {
    this._resetFormAfterChangeTypeId(e.target.value);
  }
  handleSelectLevelChange = (e) => {
    this.setState({
      params: {
        ...this.state.params,
        level: Number(e.target.value),
      },
    });
  }



  handleSelectedProductPromoteChange = (e, newValue) => {
    this.setState({
      params: {
        ...this.state.params,
        code: newValue?.code,
        name: newValue?.name,
        idPromotion: newValue?.id,
      },
      selectedPromotion: newValue
    });
    
  };

  render() {
    const {
      disabledRemoving,
      readOnly,
    } = this.props;
    const {
      isEditing,
      params,
      groupProductOptions,
      selectedPromotion,
    } = this.state;
 

    return (
      <>
        <div
          className={`${classes.Item} ${gridClasses.Row}`}
          onClick={this.handleRowClick}
        >
      

          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={selectedPromotion}
                onChange={this.handleSelectedProductPromoteChange}
                options={groupProductOptions || []}
                filterOptions={(x) => x}
                renderOption={option => option.code}
                getOptionLabel={(option) => ((option && option.code) || '')}
                getOptionSelected={(option) =>
                  option.code
               } 
                onInputChange={(e, newInputValue,reason) => { 
                  if (["input","clear"].includes(reason)){
                    this.setState({
                      PromotionCodeInput: newInputValue,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn -" />
                )}
              />
            ) : (
              params?.code ? params?.code : ''
            )}
          </div>

          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={selectedPromotion}
                 onChange={this.handleSelectedProductPromoteChange}
                options={groupProductOptions || []}
                filterOptions={(x) => x}
                renderOption={option => option.name}
                getOptionLabel={(option) => ((option && option.name) || '')}
                getOptionSelected={(option) =>
                    option.name
                } 
                onInputChange={(e, newInputValue,reason) => {
                  if (["input","clear"].includes(reason)){
                    this.setState({
                      PromotionNameInput: newInputValue,                
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn -" />
                )}
              />
            ) : (
              params?.name ? params?.name : ''
            )}
          </div>

          <div className={`${gridClasses.Col}`}>
              {isEditing  ? (
                <TextField
                  className={classes.Field}
                  disabled={true}
                  value={params?.description ? params?.description : ''}
                  placeholder="Nhập..."
                  name="quantity_number"
                  autoComplete="off"
        
                  // error={!this._hasQuantityNumber(params)}
                  onChange={(e) => {
                    this.handleSaveField(e, e.target.floatValue);
                  }}
                />
              ) : (
                params? params?.description : ''
              )}
            </div>
            <div className={`${gridClasses.Col}`}>
              {isEditing  ? (
                <TextField
                  className={classes.Field}
                  disabled={true}
                  value={selectedPromotion?.getcare_promotion_type?.name ? selectedPromotion?.getcare_promotion_type?.name  : ''}
                  placeholder="Nhập..."
                  name="quantity_number"
                  autoComplete="off"
        
                  // error={!this._hasQuantityNumber(params)}
                  onChange={(e) => {
                    this.handleSaveField(e, e.target.floatValue);
                  }}
                />
              ) : (
                params? params?.getcare_promotion_type?.name : ''
              )}
            </div>

          <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
            {isEditing ? (
              <>
                <IconButton
                  disabled={disabledRemoving}
                  onClick={this.handleCancel}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CancelOutlined fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  onClick={this.handleSave}
                  disabled={!this._isRowValid(params)}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={this._isRowValid(params) ? `primary` : `inherit`}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  disabled={readOnly || disabledRemoving}
                  onClick={this.handleRemove}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <DeleteOutline fontSize="small" />
                </IconButton>
                <IconButton
                  disabled={readOnly}
                  onClick={this.handleEdit}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <EditOutlined fontSize="small" color={readOnly ? 'inherit' : "primary"} />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

PromotionOrder.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentList: PropTypes.array,
  savePromotionProduct: PropTypes.func,
  removePromotionBonus: PropTypes.func,
};

PromotionOrder.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  promotionRules: makeSelectPromotionRules(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(PromotionOrder);