import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from 'routes/routes';
import RouteWithSubRoutes from 'components/RouteWithSubRoutes';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { defaultTheme } from 'assets/js/themes';

import './App.scss';

const theme = createMuiTheme(defaultTheme);

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <Switch>
            <Route exact path="/">
              <Redirect to="/promotion" />
            </Route>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
