import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPromotionProducts,
  makeSelectPromotionOrder,
  makeSelectOriPromotionOrder,
} from 'redux/selectors';
import {
  savePromotionOrder,
  savePromotionProductOrder,
  removePromotionProductOrder,
} from 'redux/actions/tmk/promotionActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  promotionOrderDisplayFields,
  PROMOTION_TYPE_COMBO,
} from 'utils/constanst/tmkPromotionConstants';

import PromotionOrders from './PromotionOrder/PromotionOrders';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';

import gridClasses from './PromotionOrderGrid.module.scss';
import classes from './PromotionOrder.module.scss';

class PromotionOrder extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isImportDialogOpen: false,
    }
  }

  handleCreateNewRow = () => {
    this.props.savePromotionProductOrder({
      idStr: genID(),
      id: 0,
      code: null,
      name:  null,
      isEditing: true,
    });
  };
  handleSavePromotionProduct = (params) => {
    this.props.savePromotionProductOrder({
      ...params,
    });
  };
  handleRemovePromotionProduct = (params) => {
    console.log(params);
    this.props.removePromotionProductOrder([{ ...params }]);
  };
  handleRemovePromotionProducts = () => {
    this.props.removePromotionProductOrder([...this.state.selectedItems]);
  };


  renderActions = () => {
    const { readOnly } = this.props;
    return (
      <>
        {/* TODO: Group promotions is currently not supported */}
        <Button
          color="primary"
          variant="contained"
          size="small"
          disabled={readOnly}
          onClick={this.handleCreateNewRow}>
          Thêm dòng
        </Button>
      </>
    )
  }

  render() {
    const { promotionOrder, promotionTypeId, readOnly } = this.props;

    return (
      <>
        <div className={`${classes.ActionContainer}`}>
          {this.renderActions()}
        </div>
        <div className={`${classes.List}`}>
          <div className={`${classes.Body}`}>
            <div className={`${gridClasses.Row} ${classes.Header} ${[PROMOTION_TYPE_COMBO].includes(promotionTypeId) ? gridClasses.HasFocusProduct : ''}`}>
              {promotionOrderDisplayFields.map((item) => (
                <ListHeaderCol
                  key={item.name}
                  className={`${gridClasses.Col} ${gridClasses[item.className]}`}
                  {...item}
                />
              ))}
            </div>
          
            {promotionOrder.length ? (
              promotionOrder.map((item) => (
                <PromotionOrders
                  key={`promotion-product-${item.id || item.idStr}`}
                  {...item}
                  readOnly={readOnly}
                  currentList={[...promotionOrder]}
                  promotionTypeId={promotionTypeId}
                  savePromotionProduct={this.handleSavePromotionProduct}
                  removePromotionProduct={this.handleRemovePromotionProduct}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </>
    );
  }
}

PromotionOrder.propTypes = {
  promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEditing: PropTypes.bool,
  readOnly: PropTypes.bool,
};

PromotionOrder.defaultProps = {
  isEditing: false,
  readOnly: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    savePromotionProductOrder: (payload) =>
      dispatch(savePromotionProductOrder(payload)),
    removePromotionProductOrder: (payload) =>
      dispatch(removePromotionProductOrder(payload)),
    savePromotionOrder: (payload) => dispatch(savePromotionOrder(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  promotionProducts: makeSelectPromotionProducts(),
  promotionOrder: makeSelectPromotionOrder(),
  oriPromotionOrder: makeSelectOriPromotionOrder(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PromotionOrder);
