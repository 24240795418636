import axiosAuth from 'utils/axios/axiosAuth';

const commonApi = {
  uploadMedia: (formData) => {
    return axiosAuth.post('upload/single', formData);
  },
  findVendor: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get('getcare_vendor', {
      params: queryParams,
    });
  }
};

export default commonApi;
