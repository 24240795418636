export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
};

export const filterFields = [
  {
    name: 'getcare_vendor_name',
    label: 'Vendor',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'code',
    label: 'Mã voucher',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_voucher_program_code',
    label: 'Voucher program ID',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'used_at',
    label: 'Ngày sử dụng',
    value: '',
    type: 'date',
    placeholder: 'Nhập hoặc chọn...',
  },
  {
    name: 'getcare_order_code',
    label: 'Đơn hàng sử dụng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
];

export const listDisplayFields = [
  {
    name: 'vendor',
    label: 'Vendor',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'getcare_voucher_id',
    label: 'Mã voucher',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'status',
    label: 'Status',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'voucher_program_name',
    label: 'Voucher program',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'used_date_time',
    label: 'Thời gian sử dụng',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'used_invoice',
    label: 'Đơn hàng sử dụng',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'customer',
    label: 'Khách hàng',
    sortable: false,
    sortDir: '',
  },
];
