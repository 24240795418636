import React, { PureComponent } from 'react';
import classes from './VoucherListItem.module.scss';
import { Link } from 'react-router-dom';
import { validDate } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';
import { voucherStatusMap } from 'utils/constanst/tmkVoucherConstant';
import gridClasses from '../VoucherListGrid.module.scss';
import {} from 'utils/constanst/tmkBannerConstants';
class VoucherListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      id,
      code,
      name,
      getcare_vendor,
      getcare_voucher_type,
      note,
      start_date,
      end_date,
      active,
    } = this.props;

    return (
      <Link className={`${classes.Item} ${gridClasses.Row}`} to={`/voucher/${id}`}>
        <div className={`${gridClasses.Col}`}>
          {getcare_vendor?.name}
        </div>
        <div className={`${gridClasses.Col} ${classes.CodeCol}`}>
          {code}
        </div>
        <div className={gridClasses.Col}>{name}</div>
        <div className={gridClasses.Col}>{note}</div>
        <div className={gridClasses.Col}>{getcare_voucher_type?.name}</div>
        <div className={gridClasses.Col}>
          {validDate(start_date)
            ? format(validDate(start_date), dateFormat)
            : '-'}
        </div>
        <div className={gridClasses.Col}>
          {validDate(end_date) ? format(validDate(end_date), dateFormat) : '-'}
        </div>
        <div className={gridClasses.Col}>-</div>
        <div className={gridClasses.Col}>{voucherStatusMap[active] || '-'}</div>
      </Link>
    );
  }
}

VoucherListItem.propTypes = {};

export default VoucherListItem;
