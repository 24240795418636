import React, { PureComponent } from 'react';
import classes from './BannerList.module.scss';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import PropTypes from 'prop-types';
import BannerListItem from './BannerListItem/BannerListItem';
import gridClasses from './BannerListGrid.module.scss';
import { Checkbox } from '@material-ui/core';
class BannerList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isHeaderChecked: false,
    };
  }

  handleCheckbox = () => {
    this.setState((prevState) => {
      return {
        isHeaderChecked: !prevState.isHeaderChecked,
      };
    });
  };
  render() {
    const { bannerList, isLoading, displayFields } = this.props;

    return (
      <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
        <div className={`${classes.Body}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            <div className={classes.Col}>
              <Checkbox
                onChange={this.handleCheckbox}
                checked={this.state.isHeaderChecked}
              />
            </div>
            {displayFields.map((item) => {
              return (
                <ListHeaderCol
                  key={`banner-list-header-${item.name}`}
                  className={classes.Col}
                  {...item}
                  onSortChange={this.props.onSortChange}
                />
              );
            })}
          </div>

          {!isLoading && bannerList.length ? (
            bannerList.map((item) => (
              <BannerListItem key={`Banner-item-${item.id}`} {...item} />
            ))
          ) : (
            <p className="NoData">Không có data</p>
          )}
        </div>
      </div>
    );
  }
}
BannerList.propTypes = {
  displayFields: PropTypes.array,
  bannerList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
};

BannerList.defaultProps = {
  displayFields: [],
  bannerList: [],
  isLoading: false,
};

export default BannerList;
