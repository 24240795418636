import React, { PureComponent } from 'react';
import classes from './VoucherCustomerItem.module.scss';
import gridClasses from '../VoucherCustomerGrid.module.scss';
import { Checkbox } from '@material-ui/core';
import { debounce } from 'lodash';

import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  customerGroupTypes,
  VOUCHER_CUSTOMER_TYPE,
  VOUCHER_GROUP_CUSTOMER_TYPE,
} from 'utils/constanst/tmkVoucherConstant';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';
import { validNumber } from 'utils/helper';

import { toast } from 'react-toastify';
import voucherApi from 'utils/api/tmk/voucherApi';
export default class VoucherCustomerItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isItemChecked: props.isChecked,
      isEditing: props.isEditing,
      params: this._getInitParams(props),
      voucherCustomerOptions: this._initVoucherCustomerOptions(props),
      customerCodeInput: '',
      customerNameInput: '',
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      customerCodeInput,
      customerNameInput,
      params,
      isItemChecked,
      isEditing,
    } = this.state;
    if (
      customerCodeInput &&
      customerCodeInput !== prevState.customerCodeInput
    ) {
      this._loadSuggestedCustomer({
        params: {
          code: customerCodeInput,
        },
      });
    }
    if (
      customerNameInput &&
      customerNameInput !== prevState.customerNameInput
    ) {
      this._loadSuggestedCustomer({
        params: {
          name: customerNameInput,
        },
      });
    }

    if (isEditing !== prevState.isEditing && this._isRowValid(params)) {
        this.props.saveCustomerListItem({
          ...params,
          id: this.props.id,
          idStr: this.props.idStr,
        });
    }

    if (prevState.isItemChecked !== isItemChecked) {
      this.props.selectVoucherCustomerItem({
        idStr: this.props.idStr,
        id: this.props.id,
      });
    }
  }

  _getInitParams = (params) => {
    return {
      idStr: params?.idStr,
      id: params?.id,
      type_id: params?.type_id,
      getcare_customer: params?.getcare_customer
        ? { ...params?.getcare_customer }
        : null,
      getcare_erp_group: params?.getcare_erp_group
        ? { ...params?.getcare_erp_group }
        : null,
    };
  };

  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
      voucherCustomerOptions: this._initVoucherCustomerOptions(this.props),
      customerCodeInput: '',
      customerNameInput: '',
    });
  };

  handleSaveField = (e, value) => {
    const parsedValue = parseFloat(value);
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]:
          e.target.type === 'number' ? validNumber(parsedValue) : parsedValue,
      },
    });
  };

  _initVoucherCustomerOptions = (params) => {
    return params.currentList ? params.currentList : [];
  };

  _loadSuggestedCustomer = debounce(async ({ params }) => {
    const { data: response } = await voucherApi.findCustomer({
      params: {
        ...params,
        type_id: this.state.params.type_id,
      },
    });
    if (!response?.result) return;

    const fullList =
      this.state.params.type_id === VOUCHER_CUSTOMER_TYPE
        ? response.data.customers || []
        : response.data.erp_groups || [];
    this.setState({
      voucherCustomerOptions: response.data
        ? this.filteredOptions(fullList)
        : [],
    });
  }, 500);

  filteredOptions = (fullArr) => {
    const { params } = this.state;
    return fullArr.filter((p) => {
      return params.type_id === VOUCHER_CUSTOMER_TYPE
        ? p.code === this.props.getcare_customer?.code ||
            !this.props.currentList.some(
              (item) => item.getcare_customer?.code === p.code
            )
        : p.code === this.props.getcare_erp_group?.code ||
            !this.props.currentList.some(
              (item) => item.getcare_erp_group?.code === p.code
            );
    });
  };

  _getCustomerOptionLabel = (option) => {
    if ((option.code || option.getcare_id) && option.name)
      return `${option.code || option.getcare_id} - ${option.name}`;
    else return '';
  };

  _hasCustomer = (params) => {
    return !!params.getcare_customer?.id || !!params.getcare_erp_group?.id;
  };

  _isRowValid = (params) => {
    return this._hasCustomer(params);
  };

  handleCheckbox = () => {
    this.setState((prevState) => {
      return {
        isItemChecked: !prevState.isItemChecked,
      };
    });
  };

  handleRowClick = () => {
    if (this.props.readOnly) {
      return;
    }

    if (!this.state.isEditing) {
      this.handleEdit();
    }
  };

  handleEdit = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  };

  handleCancel = (e) => {
    if (e) e.stopPropagation();
    if (this._isRowValid(this.props) || !this.state.isEditing) {
      this._resetState();
      return;
    }
    this.props.removeCustomerListItem({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  };

  handleRemove = (e) => {
    if (e) e.stopPropagation();
    this.props.removeCustomerListItem({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  };

  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: false,
    });
  };

  handleSelectGroupChange = (e, newValue) => {
    this._resetFormAfterChangeTypeId(e.target.value);
  };

  _resetFormAfterChangeTypeId = (typeIdValue) => {
    this.setState({
      isEditing: true,
      params: this._getInitParams({
        ...this.state.params,
        type_id: typeIdValue,
        getcare_customer: null,
        getcare_erp_group: null,
      }),
      voucherCustomerOptions: [],
      codeInput: '',
      nameInput: '',
    });
  };

  handleSelectedCustomerChange = (e, newValue) => {
    this.setState({
      params: {
        ...this.state.params,
        id: newValue.id || null,
        code: newValue.code,
        name: newValue.name,
        getcare_customer: newValue.type_id === VOUCHER_CUSTOMER_TYPE ? newValue : null,
        getcare_erp_group: newValue.type_id === VOUCHER_GROUP_CUSTOMER_TYPE ? newValue : null,
        getcare_customer_type_id: newValue.getcare_customer_type_id,
      },
    });
  };

  render() {
    const {
      readOnly,
      isChecked,
      type_id,
      getcare_erp_group,
      getcare_customer,
    } = this.props;
    const { voucherCustomerOptions, params, isEditing } = this.state;
    return (
      <div
        className={`${classes.Item} ${gridClasses.Row}`}
        // onClick={this.handleRowClick}
      >
        <div className={`${gridClasses.Col} ${classes.CodeCol}`}>
          <Checkbox
            size="small"
            name="bonus_mode"
            checked={isChecked}
            onChange={this.handleCheckbox}
          />
        </div>
        <div className={gridClasses.Col}>
          <Select
            disabled={readOnly}
            value={params.type_id || ''}
            name="type_id"
            size="small"
            fullWidth
            onChange={this.handleSelectGroupChange}
          >
            {customerGroupTypes.map((item) => (
              <MenuItem key={`type-${item.id}`} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={gridClasses.Col}>
          {isEditing ? (
            <Autocomplete
              openOnFocus
              selectOnFocus
              disableClearable
              fullWidth
              size="small"
              handleHomeEndKeys={false}
              value={
                params.type_id === VOUCHER_CUSTOMER_TYPE
                  ? params.getcare_customer
                  : params.getcare_erp_group
              }
              onChange={this.handleSelectedCustomerChange}
              options={voucherCustomerOptions || []}
              filterOptions={(x) => x}
              getOptionLabel={(option) => (option && option.code) || ''}
              renderOption={(option) => this._getCustomerOptionLabel(option)}
              getOptionSelected={(option, value) =>
                value && value.id && option ? option.id === value.id : null
              }
              onInputChange={(e, newInputValue) => {
                this.setState({
                  customerCodeInput: newInputValue,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="- Chọn -" />
              )}
            />
          ) : type_id === VOUCHER_CUSTOMER_TYPE ? (
            getcare_customer?.code
          ) : (
            getcare_erp_group?.code
          )}
        </div>

        <div className={gridClasses.Col}>
          {isEditing ? (
            <Autocomplete
              openOnFocus
              selectOnFocus
              disableClearable
              fullWidth
              size="small"
              handleHomeEndKeys={false}
              value={
                params.type_id === VOUCHER_CUSTOMER_TYPE
                  ? params.getcare_customer
                  : params.getcare_erp_group
              }
              onChange={this.handleSelectedCustomerChange}
              options={voucherCustomerOptions || []}
              filterOptions={(x) => x}
              getOptionLabel={(option) => (option && option.name) || ''}
              renderOption={(option) => this._getCustomerOptionLabel(option)}
              getOptionSelected={(option, value) =>
                value && value.id && option ? option.id === value.id : null
              }
              onInputChange={(e, newInputValue) => {
                this.setState({
                  customerNameInput: newInputValue,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="- Chọn -" />
              )}
            />
          ) : type_id === VOUCHER_CUSTOMER_TYPE ? (
            getcare_customer?.name
          ) : (
            getcare_erp_group?.name
          )}
        </div>
        <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
          {isEditing ? (
            <>
              <IconButton
                onClick={this.handleCancel}
                style={{ padding: '0' }}
                size="small"
              >
                <CancelOutlined fontSize="small" color="secondary" />
              </IconButton>
              <IconButton
                onClick={this.handleSave}
                disabled={!this._isRowValid(params)}
                style={{ padding: '0' }}
                size="small"
              >
                <CheckCircleOutlineOutlined
                  fontSize="small"
                  color={this._isRowValid(params) ? `primary` : `inherit`}
                />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                disabled={readOnly}
                onClick={this.handleEdit}
                style={{ padding: '0' }}
                size="small"
              >
                <EditOutlined
                  fontSize="small"
                  color={readOnly ? 'inherit' : 'primary'}
                />
              </IconButton>
            </>
          )}
        </div>
      </div>
    );
  }
}
