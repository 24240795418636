import React, { PureComponent } from 'react';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectOriVoucherSaleChannelList,
  makeSelectVoucherSaleChannelList,
} from 'redux/selectors';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import classes from './VoucherSaleChannel.module.scss';
import gridClasses from './VoucherSaleChannelGrid.module.scss';
import {
  listParamsMap,
  listTabSaleChannelDisplayFields,
} from 'utils/constanst/tmkVoucherConstant';
import { getDisplayFields, genID } from 'utils/helper';
import PropTypes from 'prop-types';
import VoucherSaleChannelItem from './VoucherSaleChannelItem/VoucherSaleChannelItem';
import { Checkbox } from '@material-ui/core';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import {
  deselectAllVoucherSaleChannel,
  removeMultipleVoucherSaleChannelItem,
  removeVoucherSaleChannelListItem,
  saveVoucherSaleChannelListItem,
  selectAllVoucherSaleChannel,
  selectVoucherSaleChannelItem,
} from 'redux/actions/tmk/voucherAction';
class VoucherSaleChannel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isHeaderChecked: false,
      listParams: { ...listParamsMap },
    };
  }

  componentDidMount() {
    this._initData();
  }

  _initData = () => {
    // this.props.getCustomerList();
  };

  handleSaveVoucherSaleChannelItem = (params) => {
    this.props.saveVoucherSaleChannelListItem({
      ...params,
    });
  };

  handleRemoveVoucherSaleChannelItem = (params) => {
    this.props.removeVoucherSaleChannelListItem([{ ...params }]);
  };

  handleCreateNewRow = () => {
    const ID = genID();
    this.props.saveVoucherSaleChannelListItem({
      getcare_voucher_sales_channel: {
        isEditing: true,
        idStr: ID,
        id: null,
        getcare_sales_channel_id: null,
        count: null,
        code: null,
        isChecked: false,
      },
      isEditing: true,
      isChecked: false,
      idStr: ID,
      id: null,
      getcare_sales_channel_id: null,
      count: null,
      code: null,
    });
  };

  handleDeleteSelectedItem = () => {
    this.props.removeMultipleSaleChannel();
    this.setState({
      isHeaderChecked: false,
    })
  };

  handleCheckbox = () => {
    this.setState((prevState) => {
      return { isHeaderChecked: !prevState.isHeaderChecked };
    });
    if (this.state.isHeaderChecked) {
      this.props.deselectAllVoucherSaleChannel();
    } else {
      this.props.selectAllVoucherSaleChannel();
    }
  };

  render() {
    const {
      voucherSaleChannelList,
      readOnly,
      isEditing,
      disabled,
      customerList,
    } = this.props;
    const displayFields = getDisplayFields(
      this.state.listParams,
      listTabSaleChannelDisplayFields
    );

    const isItemChecked = (element) => element.isChecked;
    const disableDeleteBulk = voucherSaleChannelList.some(isItemChecked)
    return (
      <div className={classes.VoucherSaleChannel}>
        <div className={`${classes.List}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            <div className={classes.Col}>
              <Checkbox
                onChange={this.handleCheckbox}
                checked={this.state.isHeaderChecked}
              />
            </div>
            {displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                className={`${gridClasses.Col} ${gridClasses[item.className]}`}
                {...item}
              />
            ))}
            <div className={classes.Col}>
              <Button
                disabled={readOnly || disabled}
                color="primary"
                variant="contained"
                size="small"
                onClick={this.handleCreateNewRow}
              >
                Thêm
              </Button>
            </div>
          </div>
          <div className={classes.ItemContainer}>
            {voucherSaleChannelList.length > 0 ? (
              voucherSaleChannelList.map((item, index) => (
                <VoucherSaleChannelItem
                  key={`voucher-sale-channel-${
                    !!item.getcare_sales_channel_id
                      ? item.getcare_sales_channel_id
                      : item.idStr
                  }`}
                  {...item.getcare_voucher_sales_channel}
                  {...item}
                  index={index}
                  readOnly={readOnly}
                  currentList={[
                    ...voucherSaleChannelList.map((item) => {
                      return { ...item.getcare_voucher_sales_channel };
                    }),
                  ]}
                  customerList={customerList}
                  selectVoucherSaleChannelItem={
                    this.props.selectVoucherSaleChannelItem
                  }
                  saveSaleChannelItem={this.handleSaveVoucherSaleChannelItem}
                  removeSaleChannelItem={
                    this.handleRemoveVoucherSaleChannelItem
                  }
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
        <div className={classes.ActionDelete}>
          <Button
            disabled={voucherSaleChannelList.length === 0 || !disableDeleteBulk}
            color="primary"
            variant="contained"
            size="small"
            startIcon={<DeleteOutline />}
            onClick={this.handleDeleteSelectedItem}
          >
            Xóa
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  voucherSaleChannelList: makeSelectVoucherSaleChannelList(),
  oriVoucherSaleChannel: makeSelectOriVoucherSaleChannelList(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    selectVoucherSaleChannelItem: (payload) =>
      dispatch(selectVoucherSaleChannelItem(payload)),
    selectAllVoucherSaleChannel: (payload) =>
      dispatch(selectAllVoucherSaleChannel(payload)),
    deselectAllVoucherSaleChannel: (payload) =>
      dispatch(deselectAllVoucherSaleChannel(payload)),
    saveVoucherSaleChannelListItem: (payload) =>
      dispatch(saveVoucherSaleChannelListItem(payload)),
    removeVoucherSaleChannelListItem: (payload) =>
      dispatch(removeVoucherSaleChannelListItem(payload)),
    removeMultipleSaleChannel: (payload) =>
    dispatch(removeMultipleVoucherSaleChannelItem(payload)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(VoucherSaleChannel);
VoucherSaleChannel.propTypes = {
  // promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEditing: PropTypes.bool,
  onRemoveCustomerItem: PropTypes.func,
  onSaveCustomerItem: PropTypes.func,
};

VoucherSaleChannel.defaultProps = {
  isEditing: false,
};
