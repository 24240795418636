import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPromotionVendors,
  makeSelectOriPromotionVendors,
  makeSelectPromotionDetails,
} from 'redux/selectors';
import {
  savePromotionVendorItem,
  removePromotionVendorItems,
} from 'redux/actions/tmk/promotionActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  promotionVendorsDisplayFields,
  promotionVendorsParamsMap,
  VENDOR_TYPE_ID,
  isPromotionCombo,
} from 'utils/constanst/tmkPromotionConstants';

import PromotionVendor from './PromotionVendor/PromotionVendor';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';

import gridClasses from './PromotionVendorsGrid.module.scss';
import classes from './PromotionVendors.module.scss';

class PromotionVendors extends PureComponent {
  _isPromotionCombo = () => {
    const { promotionDetails } = this.props;
    return isPromotionCombo(promotionDetails?.getcare_promotion_type_id || promotionDetails?.getcare_promotion_type?.id);
  }
  _isDisabledAddingVendor = () => {
    const { promotionVendors } = this.props;
    return promotionVendors.length === 1 && this._isPromotionCombo();
  }

  handleCreateNewRow = () => {
    this.props.savePromotionVendorItem({
      idStr: genID(),
      id: 0,
      type_id: VENDOR_TYPE_ID,
      getcare_vendor: null,
      getcare_erp_group: null,
      isEditing: true,
    });
  };
  handleSavePromotionVendor = (params) => {
    this.props.savePromotionVendorItem({
      ...params,
    });
  };
  handleRemovePromotionVendor = (params) => {
    this.props.removePromotionVendorItems([{ ...params }]);
  };
  handleRemovePromotionVendors = () => {
    this.props.removePromotionVendorItems([...this.state.selectedItems]);
  };

  render() {
    const { promotionVendors, readOnly } = this.props;
    const displayFields = getDisplayFields(
      promotionVendorsParamsMap,
      promotionVendorsDisplayFields
    );

    return (
      <>
        <div className={`${classes.ActionContainer}`}>
          <Button
            disabled={readOnly || this._isDisabledAddingVendor()}
            color="primary"
            variant="contained"
            size="small"
            onClick={this.handleCreateNewRow}
          >
            Thêm dòng
          </Button>
        </div>
        <div className={`${classes.List}`}>
        <div className={`${classes.Header}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
              {displayFields.map((item) => (
                <ListHeaderCol
                  key={item.name}
                  className={`${gridClasses.Col} ${gridClasses[item.className]}`}
                  {...item}
                />
              ))}
            </div>
            </div>
          <div className={`${classes.Body}`}>  
          
            {promotionVendors.length ? (
              promotionVendors.map((item) => (
                <PromotionVendor
                  key={`promotion-vendor-${item.id || item.idStr}`}
                  {...item}
                  readOnly={readOnly}
                  currentList={[...promotionVendors]}
                  isPromotionCombo={this._isPromotionCombo()}
                  savePromotionVendor={this.handleSavePromotionVendor}
                  removePromotionVendor={this.handleRemovePromotionVendor}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </>
    );
  }
}

PromotionVendors.propTypes = {
  promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEditing: PropTypes.bool,
};

PromotionVendors.defaultProps = {
  isEditing: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    savePromotionVendorItem: (payload) =>
      dispatch(savePromotionVendorItem(payload)),
    removePromotionVendorItems: (payload) =>
      dispatch(removePromotionVendorItems(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  promotionVendors: makeSelectPromotionVendors(),
  promotionDetails: makeSelectPromotionDetails(),
  oriPromotionVendors: makeSelectOriPromotionVendors(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PromotionVendors);
