import { Height } from '@material-ui/icons';
import React, { PureComponent } from 'react';
import { TextField } from '@material-ui/core';
import { FormGroup, debounce } from '@material-ui/core';
import classFilter from '../BannerListFilter.module.scss';

class BannerListFilterSize extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      width: '',
      height: '',
    };
  }
  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    this.handleSizeFilter();
  }

  handleSizeFilter = debounce(() => {
    const { width, height } = this.state;
    if (width !== '' && height !== '') {
      this.props.handleFieldChange({
        name: this.props.name,
        value: `${width}x${height}`,
      });
    }
  }, 500);

  render() {
    const { name } = this.props;
    return (
      <FormGroup
        name={name}
        className={`${classFilter.FormGroup}`}
      >
        <TextField
          name="width"
          onChange={this.handleInputChange}
          placeholder="Width"
          autoFocus={true}
          value={this.state.width}
        />
        x
        <TextField
          name="height"
          onChange={this.handleInputChange}
          placeholder="Height"
          autoFocus={true}
          value={this.state.height}
        />
      </FormGroup>
    );
  }
}

export default BannerListFilterSize;
