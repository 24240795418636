import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { validDate } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';
import { format, max } from 'date-fns';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectBannerLocation,
  makeSelectBannerSize,
  makeSelectBannerType,
  makeSelectBannerDetails,
} from 'redux/selectors';
import classes from './BannerDetails.module.scss';
import {
  getBannerSize,
  getBannerType,
  getBannerLocation,
  saveBannerSize,
  saveBannerType,
  saveBannerLocation,
  saveBannerDetails,
  saveBannerCustomerList,
  saveBannerSaleChannelList,
} from 'redux/actions/tmk/bannerActions';
import Button from '@material-ui/core/Button';
import FieldEditable from 'components/FieldEditable/FieldEditable';
import Radio from '@material-ui/core/Radio';
import {
  bannerCustomerApplyOptions,
  applyStatusMap,
  bannerStatuses,
  STATUS_INACTIVE,
  APPLY_UNDEFINED,
  APPLY_AWAITING,
  STATUS_EMPTY,
  APPLY_TO_ALL,
  APPLY_TO_CUSTOMER,
  APPLY_TO_SALE_CHANNEL,
} from 'utils/constanst/tmkBannerConstants';
import BannerCustomerList from 'components/tmk/Banner/BannerCustomerList/BannerCustomerList';
import BannerSaleChannelList from 'components/tmk/Banner/BannerSaleChannelList/BannerSaleChannelList';
import BannerImage from '../BannerImage/BannerImage';
import { STATUS_ACTIVE } from 'utils/constanst/tmkPromotionConstants';
class BannerDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      customerApply: !!props.bannerDetails?.getcare_banner_customer_apply?.id
        ? props.bannerDetails.getcare_banner_customer_apply?.id
        : APPLY_TO_ALL,
    };
  }
  //start of init state for banner details
  componentDidMount() {
    this.initState();
    if (!this.props.isEditing && !this.props.isDuplicating) {
      this._initCreateForm();
    }
  }

  componentDidUpdate() {}

  initState = () => {
    const { bannerDetails } = this.props;
    this.setState({
      customerApply: bannerDetails
        ? bannerDetails.getcare_banner_customer_apply?.id
        : APPLY_TO_ALL,
    });
  };

  _initCreateForm = () => {
    this.props.saveBannerDetails(null);
    this.props.saveBannerCustomerList([]);
    this.props.saveBannerSaleChannelList([]);
  };
  //end of init state for banner details.
  handleSaveField = (fieldMap) => {
    const params = {
      ...this.props.bannerDetails,
      ...fieldMap,
    };
    this.props.saveBannerDetails(params);
  };

  handleChangeField = (e, value) => {
    if (!this.props.isEditing) {
      this.handleSaveField({ [e.target.name]: value });
    }
  };

  _getBannerSize = () => {
    const { bannerDetails, bannerSize } = this.props;
    const applyId =
      bannerDetails?.getcare_banner_size_id ||
      bannerDetails?.getcare_banner_size?.id;
    return (
      (bannerDetails && bannerSize.find((item) => item.id === applyId)) || null
    );
  };
  _getBannerType = () => {
    const { bannerDetails, bannerType } = this.props;
    const applyId =
      bannerDetails?.getcare_banner_type_id ||
      bannerDetails?.getcare_banner_type?.id;
    return (
      (bannerDetails && bannerType.find((item) => item.id === applyId)) || null
    );
  };

  _getBannerLocation = () => {
    const { bannerDetails, bannerLocation } = this.props;
    const applyId =
      bannerDetails?.getcare_banner_location_id ||
      bannerDetails?.getcare_banner_location?.id;
    return (
      (bannerDetails && bannerLocation.find((item) => item.id === applyId)) ||
      null
    );
  };

  _getBannerApply = () => {
    const { bannerDetails } = this.props;
    const applyId = bannerDetails?.active;
    return bannerStatuses.find((item) => {
      return item.id === applyId;
    });
  };

  handleBaseOnChange = (e) => {
    const valueParsed = parseInt(e.target.value);
    this.handleSaveField({ [e.target.name]: valueParsed });
  };

  _getBannerCustomerApply = () => {
    const { bannerDetails } = this.props;
    if (!bannerDetails) return APPLY_TO_ALL;
    const applyId =
      bannerDetails?.getcare_banner_customer_apply_id ||
      bannerDetails?.getcare_banner_customer_apply?.id;
    return (
      bannerDetails &&
      bannerCustomerApplyOptions.find((item) => item.id === applyId)
    );
  };

  _isStoppedProgress = () => {
    return this._getActivityStatus() === STATUS_EMPTY;
  };
  _isAwaitingProgress = () => {
    return this._getActivityStatus() === APPLY_AWAITING;
  };

  _getActivityStatus = () => {
    const { bannerDetails } = this.props;
    if (!bannerDetails) return APPLY_AWAITING;
    if (
      bannerDetails.active === STATUS_INACTIVE ||
      bannerDetails.apply === APPLY_UNDEFINED
    )
      return STATUS_EMPTY;
    return bannerDetails.apply;
  };

  _getCustomerListAddActionAllow = () => {
    const { bannerDetails } = this.props;
    if (
      !bannerDetails?.getcare_banner_customer_apply &&
      !bannerDetails?.getcare_banner_customer_apply_id
    )
      return true;
    return bannerDetails?.getcare_banner_customer_apply_id
      ? bannerDetails?.getcare_banner_customer_apply_id !== APPLY_TO_CUSTOMER
      : bannerDetails?.getcare_banner_customer_apply.id !== APPLY_TO_CUSTOMER;
  };
  _getSaleChannelListAddActionAllow = () => {
    const { bannerDetails } = this.props;
    if (
      !bannerDetails?.getcare_banner_customer_apply &&
      !bannerDetails?.getcare_banner_customer_apply_id
    )
      return true;
    return bannerDetails?.getcare_banner_customer_apply_id
      ? bannerDetails?.getcare_banner_customer_apply_id !==
          APPLY_TO_SALE_CHANNEL
      : bannerDetails?.getcare_banner_customer_apply.id !==
          APPLY_TO_SALE_CHANNEL;
  };

  _toggleStatus = () => {
    const { bannerDetails } = this.props;
    if (bannerDetails.active === STATUS_INACTIVE) {
      this.handleSaveField({['active']: STATUS_ACTIVE});
    } else {
      this.handleSaveField({['active']: STATUS_INACTIVE});
    }
  };

  render() {
    const {
      bannerDetails,
      isEditing,
      isDuplicating,
      bannerLocation,
      bannerSize,
      bannerType,
      readOnly,
    } = this.props;

    const minStartDate = isDuplicating ? null : new Date();
    const minEndDate =
      bannerDetails && bannerDetails.start_date
        ? isDuplicating
          ? new Date(bannerDetails.start_date)
          : max([new Date(bannerDetails.start_date), new Date()])
        : isDuplicating
        ? null
        : new Date();
    const disableAddCustomer = this._getCustomerListAddActionAllow();
    const disableAddSaleChannel = this._getSaleChannelListAddActionAllow();

    return (
      <div className={classes.PageMain}>
        <div className={classes.PageLeft}>
          <div
            className={`${classes.FieldControlWrap} ${classes.DocumentWrap}`}
          >
            <div className={classes.FieldControl}>
              <label>
                Location ID <span className={classes.RequiredMark}>*</span>{' '}
                {` `}
              </label>
              {readOnly && this._getBannerLocation()?.name}
              {!readOnly && (
                <FieldEditable
                  disableClearable
                  fieldType="select"
                  editModeOnly={!isEditing}
                  error={isEditing ? false : !this.props.isLocationValid}
                  fieldName="getcare_banner_location_id"
                  value={this._getBannerLocation()?.id}
                  displayedValue={this._getBannerLocation()?.name}
                  options={bannerLocation}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                />
              )}
            </div>
          </div>

          <div
            className={`${classes.FieldControlWrap} ${classes.DocumentWrap}`}
          >
            <div className={classes.FieldControl}>
              <label>
                Tên <span className={classes.RequiredMark}>*</span> {` `}
              </label>
              {readOnly && bannerDetails?.name}
              {!readOnly && (
                <FieldEditable
                  disableClearable
                  editModeOnly={!isEditing}
                  autoFocus
                  fieldName="name"
                  error={isEditing ? false : !this.props.isNameValid}
                  maxLength={100}
                  value={bannerDetails?.name || ''}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                  onBlur={this.handleBlurCodeField}
                />
              )}
            </div>
          </div>
          <div
            className={`${classes.FieldControlWrap} ${classes.DocumentWrap}`}
          >
            <div className={classes.FieldControl}>
              <label>
                Số thứ tự <span className={classes.RequiredMark}>*</span> {` `}
              </label>
              {readOnly && bannerDetails?.no}
              {!readOnly && (
                <FieldEditable
                  editModeOnly={!isEditing}
                  autoFocus
                  fieldName="no"
                  error={isEditing ? false : !this.props.isOrderValid}
                  maxLength={20}
                  value={bannerDetails?.no || ''}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                  onBlur={this.handleBlurCodeField}
                />
              )}
            </div>
          </div>

          <div
            className={`${classes.FieldControlWrap} ${classes.DocumentWrap}`}
          >
            <div className={classes.FieldControl}>
              <label>
                Ngày bắt đầu <span className={classes.RequiredMark}>*</span>{' '}
                {` `}
              </label>
              {isEditing && !this._isAwaitingProgress() ? (
                validDate(bannerDetails?.start_date) ? (
                  format(validDate(bannerDetails?.start_date), dateFormat)
                ) : (
                  ''
                )
              ) : (
                <FieldEditable
                  fieldType="date"
                  editModeOnly={!isEditing}
                  displayedValue={
                    validDate(bannerDetails?.start_date)
                      ? format(validDate(bannerDetails?.start_date), dateFormat)
                      : ''
                  }
                  error={isEditing ? false : !this.props.isStartDateValid}
                  minValue={minStartDate?.toISOString() || ''}
                  fieldName="start_date"
                  value={bannerDetails?.start_date || ''}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                />
              )}
            </div>
          </div>

          <div
            className={`${classes.FieldControlWrap} ${classes.DocumentWrap}`}
          >
            <div className={classes.FieldControl}>
              <label>
                Ngày kết thúc <span className={classes.RequiredMark}>*</span>{' '}
                {` `}
              </label>
              {isEditing && this._isStoppedProgress() ? (
                validDate(bannerDetails?.end_date) ? (
                  format(validDate(bannerDetails?.end_date), dateFormat)
                ) : (
                  ''
                )
              ) : (
                <FieldEditable
                  fieldType="date"
                  editModeOnly={!isEditing}
                  displayedValue={
                    validDate(bannerDetails?.end_date)
                      ? format(validDate(bannerDetails?.end_date), dateFormat)
                      : ''
                  }
                  error={isEditing ? false : !this.props.isEndDateValid}
                  fieldName="end_date"
                  minValue={minEndDate?.toISOString() || ''}
                  value={bannerDetails?.end_date || ''}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                />
              )}
            </div>
          </div>
          <div
            className={`${classes.FieldControlWrap} ${classes.DocumentWrap}`}
          >
            <div className={classes.FieldControl}>
              <label>
                URL khi click vào
                {` `}
              </label>
              {readOnly && bannerDetails?.url}
              {!readOnly && (
                <FieldEditable
                  editModeOnly={!isEditing}
                  autoFocus
                  fieldName="href"
                  value={bannerDetails?.href || ''}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                  onBlur={this.handleBlurCodeField}
                />
              )}
            </div>
          </div>
          <div
            className={`${classes.FieldControlWrap} ${classes.DocumentWrap}`}
          >
            <label className={classes.RadioGroupLabel}>
              Khách hàng áp dụng <span className={classes.RequiredMark}>*</span>{' '}
              {` `}
            </label>
            <div className={`${classes.DetailsRow} ${classes.RadioGroupRow}`}>
              <Radio
                disabled={readOnly}
                className={classes.Radio}
                checked={this._getBannerCustomerApply()?.id === APPLY_TO_ALL}
                size="small"
                onChange={this.handleBaseOnChange}
                value={APPLY_TO_ALL}
                name="getcare_banner_customer_apply_id"
              />
              <span>Tất cả</span>
            </div>
            <div className={`${classes.DetailsRow} ${classes.RadioGroupRow}`}>
              <Radio
                disabled={readOnly}
                size="small"
                className={classes.Radio}
                checked={
                  this._getBannerCustomerApply()?.id === APPLY_TO_CUSTOMER
                }
                onChange={this.handleBaseOnChange}
                value={APPLY_TO_CUSTOMER}
                name="getcare_banner_customer_apply_id"
              />
              <span>Theo danh sách</span>
            </div>
            <div className={`${classes.DetailsRow} ${classes.RadioGroupRow}`}>
              <Radio
                disabled={readOnly}
                size="small"
                className={classes.Radio}
                checked={
                  this._getBannerCustomerApply()?.id === APPLY_TO_SALE_CHANNEL
                }
                onChange={this.handleBaseOnChange}
                value={APPLY_TO_SALE_CHANNEL}
                name="getcare_banner_customer_apply_id"
              />
              <span>Theo khu vực bán hàng</span>
            </div>
          </div>

          <BannerCustomerList
            disabled={disableAddCustomer}
            readOnly={readOnly}
            isEditing={isEditing}
            bannerId={bannerDetails?.id}
          />
          <BannerSaleChannelList
            disabled={disableAddSaleChannel}
            readOnly={readOnly}
            isEditing={isEditing}
            bannerId={bannerDetails?.id}
          />
        </div>
        <div className={classes.PageRight}>
          <div className={classes.PageRightUpper}>
            <div
              className={`${classes.FieldControlWrap} ${classes.DocumentWrap}`}
            >
              <div className={classes.FieldControl}>
                <label>
                  Loại banner <span className={classes.RequiredMark}>*</span>{' '}
                  {` `}
                </label>
                {readOnly && this._getBannerType()?.name}
                {!readOnly && (
                  <FieldEditable
                    disableClearable
                    fieldType="select"
                    editModeOnly={!isEditing}
                    fieldName="getcare_banner_type_id"
                    error={isEditing ? false : !this.props.isTypeValid}
                    value={this._getBannerType()?.id}
                    displayedValue={this._getBannerType()?.name}
                    options={bannerType}
                    onSave={this.handleSaveField}
                    onChange={this.handleChangeField}
                  />
                )}
              </div>
            </div>

            <div
              className={`${classes.FieldControlWrap} ${classes.DocumentWrap}`}
            >
              <div className={classes.FieldControl}>
                <label>
                  Size <span className={classes.RequiredMark}>*</span> {` `}
                </label>
                {readOnly && this._getBannerSize()?.name}
                {!readOnly && (
                  <FieldEditable
                    disableClearable
                    fieldType="select"
                    error={isEditing ? false : !this.props.isSizeValid}
                    editModeOnly={!isEditing}
                    fieldName="getcare_banner_size_id"
                    value={this._getBannerSize()?.id}
                    displayedValue={this._getBannerSize()?.name}
                    options={bannerSize}
                    onSave={this.handleSaveField}
                    onChange={this.handleChangeField}
                  />
                )}
              </div>
            </div>

            {isEditing && (
              <div
                className={`${classes.FieldControlWrap} ${classes.DocumentWrap}`}
              >
                <div className={classes.FieldControl}>
                  <label>
                    Trạng thái <span className={classes.RequiredMark}>*</span>{' '}
                    {` `}
                  </label>
                  <div className={`${classes.FieldControl} ${classes.Status}`}>
                    {this._getBannerApply()?.name || '-'}
                    <Button
                      className={classes.ToggleStatus}
                      disabled={readOnly && !isEditing}
                      variant="outlined"
                      size="small"
                      color={
                        this._getActivityStatus() === STATUS_ACTIVE
                          ? 'secondary'
                          : 'primary'
                      }
                      onClick={this._toggleStatus}
                    >
                      {this._getActivityStatus() === STATUS_ACTIVE
                        ? 'Inactive'
                        : 'Active'}
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {isEditing && (
              <div
                className={`${classes.FieldControlWrap} ${classes.DocumentWrap}`}
              >
                <div className={classes.FieldControl}>
                  <label>
                    Áp dụng <span className={classes.RequiredMark}>*</span>{' '}
                    {` `}
                  </label>
                  <div className={`${classes.FieldControl}`}>
                    {applyStatusMap[bannerDetails?.apply]}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className={`${classes.FieldControlWrap} ${classes.DocumentWrap} ${classes.PageRightLower}`}
          >
            <div className={`${classes.FieldControl} ${classes.BannerLabel}`}>
              <label>
                Hình ảnh / Video <span className={classes.RequiredMark}>*</span>{' '}
                {` `}
              </label>
            </div>
            <div className={classes.BannerContent}>
              <BannerImage
                readOnly={readOnly}
                isEditing={isEditing}
                fileType={this._getBannerType()?.id}
                handleSaveField={this.handleSaveField}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
BannerDetails.propTypes = {
  isEditing: PropTypes.bool,
  isValid: PropTypes.bool,
};

BannerDetails.defaultProps = {
  isEditing: false,
  isValid: true,
};
const mapStateToProps = createStructuredSelector({
  bannerSize: makeSelectBannerSize(),
  bannerType: makeSelectBannerType(),
  bannerLocation: makeSelectBannerLocation(),
  bannerDetails: makeSelectBannerDetails(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getBannerSize: (payload) => dispatch(getBannerSize(payload)),
    getBannerType: (payload) => dispatch(getBannerType(payload)),
    getBannerLocation: (payload) => dispatch(getBannerLocation(payload)),
    saveBannerSize: (payload) => dispatch(saveBannerSize(payload)),
    saveBannerType: (payload) => dispatch(saveBannerType(payload)),
    saveBannerLocation: (payload) => dispatch(saveBannerLocation(payload)),
    saveBannerDetails: (payload) => dispatch(saveBannerDetails(payload)),
    saveBannerCustomerList: (payload) =>
      dispatch(saveBannerCustomerList(payload)),
    saveBannerSaleChannelList: (payload) =>
      dispatch(saveBannerSaleChannelList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(BannerDetails);
