import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { promotionStatuses, STATUS_EMPTY } from 'utils/constanst/tmkPromotionConstants';
import { isEqual } from 'lodash';

import FilterField from 'components/FilterField/FilterField';
import Checkboxes from 'components/FilterField/Checkboxes';

import classes from './PromotionListFilters.module.scss';

class PromotionListFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: {
        ...props.queryParamsMap,
        ...this.getInputActiveAndIsEmptyParams(props.queryParamsMap),
      },
    };
  }
  componentDidMount() {
    const { queryParamsMap } = this.props;
    this.setState({
      queryParams: { ...queryParamsMap },
    });
  }
  componentDidUpdate(prevProps) {
    const { queryParamsMap } = this.props;
    if (!isEqual(prevProps.queryParamsMap, queryParamsMap)) {
      this.setState({
        queryParams: {
          ...queryParamsMap ,
          ...this.getInputActiveAndIsEmptyParams(queryParamsMap),
        },
      });
    }
  }

  getInputActiveAndIsEmptyParams = (queryParams) => {
    const initActiveArr = queryParams.active ? queryParams.active : [];
    return {
      active: queryParams.is_empty ? [...initActiveArr, STATUS_EMPTY + ``] : initActiveArr,
      is_empty: undefined,
    }
  }
  getOutputActiveAndIsEmptyParams = (queryParams) => {
    return {
      active: queryParams.active ? queryParams.active.filter(status => ![STATUS_EMPTY, STATUS_EMPTY + ``].includes(status)) : undefined,
      is_empty: queryParams.active && queryParams.active.some(value => [STATUS_EMPTY, STATUS_EMPTY + ``].includes(value)) ? true : undefined,
    }
  }
  handleFieldChange = ({ name, value }) => {
    const newQueryParams = {
      ...this.state.queryParams,
      [name]: value,
    }
    this.setState({
      queryParams: newQueryParams,
    });

    this.props.onFilterChange({
      ...newQueryParams,
      ...this.getOutputActiveAndIsEmptyParams(newQueryParams),
    }, true);
  };

  render() {
    const { queryParams } = this.state;
    const { filterFields } = this.props;

    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {filterFields.map((field) => (
            <FilterField
              key={field.name}
              {...field}
              value={queryParams[field.name]}
              defaultValue={['active'].includes(field.name) ? [] : ''}
              customField={
                field.name === 'active' ? (
                  <Checkboxes
                    name={field.name}
                    value={queryParams[field.name]}
                    multiple
                    values={[...promotionStatuses]}
                    handleFieldChange={this.handleFieldChange}
                  />
                ) : ''
              }
              handleFieldChange={this.handleFieldChange}
            />
          ))}
        </div>
      </div>
    );
  }
}

PromotionListFilters.propTypes = {
  filterFields: PropTypes.array,
  queryParamsMap: PropTypes.object,
  onFilterChange: PropTypes.func,
};

export default PromotionListFilters;
