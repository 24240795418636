import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { MAX_FILE_SIZE } from 'utils/constanst/common';
import { toast } from 'react-toastify';
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';

import classes from './FileUpload.module.scss';

class FileUpload extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      hightlight: false,
    }
    this.fileInputRef = React.createRef();
  }
  isFileSizeExceed = (file) => {
    const isValid = file && file.size <= MAX_FILE_SIZE;
    if (!isValid) {
      toast.error('File tối đa 5MB');
    }
    return isValid;
  }
  openFileDialog = () => {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }
  onFilesAdded = (evt) => {
    if (this.props.disabled) return;
    const files = evt.target.files;
    Array.from(files).forEach(file => {
      if (!this.isFileSizeExceed(file)) return;
    });
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  }
  onDragOver = (evt) => {
    evt.preventDefault()
    if (this.props.disabled) return
    this.setState({ hightlight: true })
  }
  onDragLeave = () => {
    this.setState({ hightlight: false })
  }
  onDrop = (event) => {
    event.preventDefault();
    if (this.props.disabled) return
    const files = event.dataTransfer.files
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files)
      this.props.onFilesAdded(array)
    }
    this.setState({ hightlight: false });
  }
  fileListToArray = (list) => {
    const array = []
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i))
    }
    return array;
  }

  render() {
    return (
      <div
        className={`${classes.FileUpload} ${this.state.hightlight ? classes.Highlight : ''}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: this.props.disabled ? 'default' : 'pointer' }}
      >
        <input
          ref={this.fileInputRef}
          className={classes.FileInput}
          type="file"
          multiple={this.props.multiple}
          accept={this.props.accept}
          onChange={this.onFilesAdded}
        />
        <div className={classes.HelpBlock}>
          <CloudUploadOutlined className={classes.Icon}/>
          <span className={classes.HelpText}>Tải file từ máy tính hoặc kéo/thả file vào cửa sổ này</span>
        </div>
      </div>
    )
  }
}

FileUpload.propTypes = {
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  onFilesAdded: PropTypes.func,
};

FileUpload.defaultProps = {
  disabled: false,
  multiple: false,
  // accept: '.csv'
};

export default FileUpload;
