import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import promotionApi from 'utils/api/tmk/promotionApi';
import { debounce } from 'lodash';
import { validNumber } from 'utils/helper';

import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

import classes from './PromotionCustomer.module.scss';
import gridClasses from '../PromotionCustomersGrid.module.scss';

class PromotionCustomer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: props.isEditing,
      params: this._getInitParams(props),
      groupCustomerOptions: this._initCustomerOptions(props),
      groupCustomerCodeInput: '',
      groupCustomerNameInput: '',
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const { groupCustomerCodeInput, groupCustomerNameInput, isEditing, params } = this.state;
    if (groupCustomerCodeInput && groupCustomerCodeInput !== prevState.groupCustomerCodeInput) {
      this._loadSuggestedCustomers({
        params: {
          code: groupCustomerCodeInput,
        },
      });
    }
    if (groupCustomerNameInput && groupCustomerNameInput !== prevState.groupCustomerNameInput) {
      this._loadSuggestedCustomers({
        params: {
          name: groupCustomerNameInput,
        },
      });
    }
    if (isEditing !== prevState.isEditing && this._isRowValid(params)) {
      this.props.savePromotionCustomer({
        ...params,
        idStr: this.props.idStr,
        id: this.props.id,
        isEditing,
      });
    }
  }
  _initCustomerOptions = (params) =>{
    return params.getcare_erp_group ? [{...params.getcare_erp_group}] : [];
  }
  _loadSuggestedCustomers = debounce(async ({ params }) => {
    const { data: response } = await promotionApi.getCustomerSuggestions({
      params: {
        ...params,
        type_id: this.state.params.type_id,
      },
    });
    if (!response?.result) return;
    this.setState({
      groupCustomerOptions: response.data
        ? this._filteredOptions([...response.data.erp_groups])
        : [],
    });
  }, 500);
  _filteredOptions = (fullArr) => {
    return fullArr.filter(p =>
      (p.code === this.props.getcare_erp_group?.code) ||
      !this.props.currentList.some(
        (item) => item.getcare_erp_group?.code === p.code
      )
    );
  };
  _getCustomerOptionLabel = (option) => {
    return `${option.code} - ${option.name}`
  }
  _getInitParams = (params) => {
    return {
      idStr: params?.idStr,
      id: params?.id,
      type_id: params?.type_id,
      getcare_customer: params?.getcare_customer ? {...params?.getcare_customer} : null,
      getcare_erp_group: params?.getcare_erp_group ? {...params?.getcare_erp_group} : null,
    }
  }
  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
      groupCustomerOptions: this._initCustomerOptions(this.props),
      groupCustomerCodeInput: '',
      groupCustomerNameInput: '',
    });
  }
  _hasCustomer = (params) => {
    return !!params.getcare_customer?.id || !!params.getcare_erp_group?.id;
  }
  _isRowValid = (params) => {
    return this._hasCustomer(params);
  };
  stopPropagation = (e) => {
    e.stopPropagation();
  }
  handleSaveField = (e, value) => {
    let newValue = e.target.type === 'number' ? validNumber(value) : value;
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: newValue,
      },
    });
  }
  handleRowClick = () => {
    if (this.props.readOnly) {
      return;
    }

    if (!this.state.isEditing) {
      this.handleEdit();
    }
  }
  handleEdit = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = (e) => {
    if (e) e.stopPropagation();
    if (this._isRowValid(this.props)) {
      // if it was saved to DB or it was used to save on client successful
      this._resetState();
      return;
    }
    this.props.removePromotionCustomer({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  }
  handleRemove = (e) => {
    if (e) e.stopPropagation();
    this.props.removePromotionCustomer({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  }
  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: false,
    });
  }
  handleSelectCustomerChange = (e, newValue) => {
    this.setState({
      params: {
        ...this.state.params,
        getcare_erp_group: newValue,
        getcare_customer: null,
      },
    });
  };

  render() {
    const { getcare_erp_group, readOnly } = this.props;
    const {
      isEditing,
      params,
      groupCustomerOptions,
    } = this.state;

    return (
      <>
        <div
          className={`${classes.Item} ${gridClasses.Row}`}
          onClick={this.handleRowClick}
        >
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={params.getcare_erp_group}
                onChange={this.handleSelectCustomerChange}
                options={groupCustomerOptions || []}
                filterOptions={(x) => x}
                renderOption={(option) => this._getCustomerOptionLabel(option)}
                getOptionLabel={(option) => ((option && option.code) || '')}
                getOptionSelected={(option, value) =>
                  value && value.id && option ? option.id === value.id : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    groupCustomerCodeInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn -" />
                )}
              />
            ) : (
              getcare_erp_group?.code
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={params.getcare_erp_group}
                onChange={this.handleSelectCustomerChange}
                options={groupCustomerOptions || []}
                filterOptions={(x) => x}
                renderOption={(option) => this._getCustomerOptionLabel(option)}
                getOptionLabel={(option) => ((option && option.name) || '')}
                getOptionSelected={(option, value) =>
                  value && value.id && option ? option.id === value.id : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    groupCustomerNameInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn -" />
                )}
              />
            ) : (
              getcare_erp_group?.name
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            { params.getcare_erp_group?.description }
          </div>
          <div className={`${gridClasses.Col}`}>
            { params.getcare_erp_group?.count }
          </div>
          <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
            {isEditing ? (
              <>
                <IconButton
                  onClick={this.handleCancel}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CancelOutlined fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  onClick={this.handleSave}
                  disabled={!this._isRowValid(params)}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={this._isRowValid(params) ? `primary` : `inherit`}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={this.handleRemove}
                  style={{ padding: '0' }}
                  size="small"
                  disabled={readOnly}
                >
                  <DeleteOutline fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={this.handleEdit}
                  style={{ padding: '0' }}
                  size="small"
                  disabled={readOnly}
                >
                  <EditOutlined fontSize="small" color={readOnly ? 'inherit' : "primary"} />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

PromotionCustomer.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentList: PropTypes.array,
  savePromotionCustomer: PropTypes.func,
  removePromotionCustomer: PropTypes.func,
};

PromotionCustomer.defaultProps = {
};

export default PromotionCustomer;
