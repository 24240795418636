import React, { PureComponent } from 'react';
import productApi from 'utils/api/mdm/productApi';
import vendorApi from 'utils/api/mdm/vendorApi';
import uomApi from 'utils/api/mdm/uomApi';
import { isEqual, debounce } from 'lodash';
import { currencyFormatVN, validNumber } from 'utils/helper';
import priceTestApi from 'utils/api/mdm/priceTestApi';

import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';

import classes from './OrderListItem.module.scss';
import gridClasses from '../OrderListGrid.module.scss';

class OrderListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: props.isEditing,
      productOptions: this._initProductOptions(props),
      vendorOptions: this._initVendorOptions(props),
      unitOptions: this._initUnitOptions(props),
      productKeywordInput: '',
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const { productKeywordInput } = this.state;
    const { orderDate, selectedCustomerId, getcare_product, getcare_vendor, getcare_uom_base } = this.props;
    if (productKeywordInput && productKeywordInput !== prevState.productKeywordInput) {
      this._loadSuggestedProducts({
        params: {
          keyword: productKeywordInput,
          date: orderDate.toISOString(),
          getcare_customer_id: selectedCustomerId,
          mode: 'INCLUDE_VENDORS',
        },
      });
    }
    if (getcare_product && getcare_product.id && !isEqual(prevProps.getcare_product, getcare_product)) {
      this._loadSuggestedVendors({ params: {
        getcare_product_id: getcare_product.id,
        date: orderDate.toISOString(),
        getcare_customer_id: selectedCustomerId,
      }});
    }
    // get units once having product and vendor
    if (getcare_product && getcare_product.id
      && getcare_vendor && getcare_vendor.id
      && (!isEqual(prevProps.getcare_product, getcare_product)
        || !isEqual(prevProps.getcare_vendor, getcare_vendor))
    ) {
      this._loadSuggestedUnits({
        params: {
          getcare_product_id: getcare_product.id,
          getcare_vendor_id: getcare_vendor.id,
          date: orderDate.toISOString(),
          getcare_customer_id: selectedCustomerId,
        }
      })
    }
    // get price once having product, vendor and unit
    if (getcare_product && getcare_product.id
      && getcare_vendor && getcare_vendor.id
      && getcare_uom_base && getcare_uom_base.id
      && (!isEqual(prevProps.getcare_product, getcare_product)
        || !isEqual(prevProps.getcare_vendor, getcare_vendor)
        || !isEqual(prevProps.getcare_uom_base, getcare_uom_base))
    ) {
      this._loadPrice({
        params: {
          getcare_product_id: getcare_product.id,
          getcare_vendor_id: getcare_vendor.id,
          getcare_uom_base_id: getcare_uom_base.id,
          date: orderDate.toISOString(),
          getcare_customer_id: selectedCustomerId,
        }
      });
    }
  }
  _initProductOptions = (params) => {
    return params.getcare_product ? [{...params.getcare_product}] : [];
  }
  _initVendorOptions = (params) => {
    return params.getcare_vendor ? [{...params.getcare_vendor}] : [];
  }
  _initUnitOptions = (params) => {
    return params.getcare_uom_base ? [{...params.getcare_uom_base}] : [];
  }
  _loadSuggestedProducts = debounce(async ({ params }) => {
    const { data: response } = await productApi.getAll({ params });
    if (!response?.result) return;
    this.setState({ productOptions: response.data ? response.data : [] })
  }, 500);
  _loadSuggestedVendors = debounce(async ({ params }) => {
    const { data: response } = await vendorApi.getAll({ params });
    if (!response?.result) return;
    this.setState({ vendorOptions: response.data ? response.data : [] })
  }, 500);
  _loadSuggestedUnits = async ({ params }) => {
    const { data: response } = await uomApi.getAllUomBase({ params });
    if (!response?.result) return;
    this.setState({ unitOptions: response.data ? response.data : [] });
  };
  _loadPrice = async ({ params }) => {
    const { data: response } = await priceTestApi.getProductPrice({ params });
    if (!response?.result) {
      this.handleSavePriceItem({ price_sales_total: null, vat: null });
      return;
    };

    this.handleSavePriceItem({ price_sales_total: response.data?.price_sales_total, vat: response.data?.vat });
  };
  _getInitParams = (params) => {
    return {
      idStr: params?.idStr,
      id: params?.id,
      getcare_product: params.getcare_product ? {...params.getcare_product} : null,
      getcare_vendor: params.getcare_vendor ? {...params.getcare_vendor} : null,
      getcare_uom_base: params.getcare_uom_base ? {...params.getcare_uom_base} : null,
      quantity_number: params.quantity_number,
      price_sales_total: params.price_sales_total,
      vat: params.vat,
    }
  }
  _resetState = () => {
    this.setState({
      isEditing: false,
      productOptions: this._initProductOptions(this.props),
      vendorOptions: this._initVendorOptions(this.props),
      unitOptions: this._initUnitOptions(this.props),
      productKeywordInput: '',
    });
  }
  _hasProduct = () => {
    const { getcare_product } = this.props;
    return !!getcare_product && !!getcare_product.id;
  }
  _hasVendor = () => {
    const { getcare_vendor } = this.props;
    return !!getcare_vendor && !!getcare_vendor.id;
  }
  _hasUnit = () => {
    const { getcare_uom_base } = this.props;
    return !!getcare_uom_base && !!getcare_uom_base.id;
  }
  _isRowValid = () => {
    return this._hasProduct() && this._hasVendor() && this._hasUnit();
  };

  handleSaveField = (e, value) => {
    const newParams = {
      ...this._getInitParams(this.props),
      [e.target.name]: (e.target.type === 'number')  ? validNumber(value) : value,
    }
    this.props.saveOrderListItem({
      ...newParams,
      idStr: this.props.idStr,
      id: this.props.id,
    });
  }
  handleSavePriceItem = ({ price_sales_total, vat }) => {
    const newParams = {
      ...this._getInitParams(this.props),
      price_sales_total,
      vat,
    }
    this.props.saveOrderListItem({
      ...newParams,
      idStr: this.props.idStr,
      id: this.props.id,
    });
  }
  handleCancel = (e) => {
    this.props.removeOrderListItem({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  }
  handleRemove = () => {
    this.props.removeOrderListItem({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  }
  handleSave = (e) => {
    this.props.saveOrderListItem({
      ...this._getInitParams(this.props),
      idStr: this.props.idStr,
      id: this.props.id,
    });
    this.setState({
      isEditing: false,
    });
  }

  render() {
    const {
      getcare_product,
      getcare_vendor,
      getcare_uom_base,
      quantity_number,
      price_sales_total,
      vat,
      discounts,
    } = this.props;
    const {
      productOptions,
      unitOptions,
      vendorOptions,
    } = this.state;

    return (<>
      <div className={`${gridClasses.Row} ${classes.Item}`}>
        <div className={`${gridClasses.Col}`}>
          <Autocomplete
            openOnFocus
            selectOnFocus
            disableClearable
            fullWidth
            size="small"
            handleHomeEndKeys={false}
            value={getcare_product}
            onChange={(e, newValue) => this.handleSaveField({target: {name: 'getcare_product'}}, newValue)}
            options={productOptions || []}
            filterOptions={(x) => x}
            renderOption={(option) => `${option.getcare_id} - ${option.name}`}
            getOptionLabel={(option) => `${option.getcare_id}`}
            getOptionSelected={(option, value) =>
              value?.id && option ? option.id === value.id : null
            }
            onInputChange={(e, newInputValue) => {
              this.setState({
                productKeywordInput: newInputValue,
              });
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="- Chọn -" />
            )}
          />
        </div>
        <div className={`${gridClasses.Col}`}>
          <Autocomplete
            openOnFocus
            selectOnFocus
            disableClearable
            fullWidth
            size="small"
            handleHomeEndKeys={false}
            value={getcare_product}
            onChange={(e, newValue) => this.handleSaveField({target: {name: 'getcare_product'}}, newValue)}
            options={productOptions || []}
            filterOptions={(x) => x}
            renderOption={(option) => `${option.getcare_id} - ${option.name}`}
            getOptionLabel={(option) => `${option.name}`}
            getOptionSelected={(option, value) =>
              value?.id && option ? option.id === value.id : null
            }
            onInputChange={(e, newInputValue) => {
              this.setState({
                productKeywordInput: newInputValue,
              });
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="- Chọn -" />
            )}
          />
        </div>
        <div className={`${gridClasses.Col}`}>
          { getcare_product?.code }
        </div>
        <div className={`${gridClasses.Col}`}>
          <Autocomplete
            openOnFocus
            selectOnFocus
            fullWidth
            size="small"
            handleHomeEndKeys={false}
            value={getcare_vendor}
            onChange={(e, newValue) => this.handleSaveField({target: {name: 'getcare_vendor'}}, newValue)}
            options={vendorOptions || []}
            filterOptions={(x) => x}
            renderOption={(option) => `${option.code} - ${option.name}`}
            getOptionLabel={(option) => `${option.name}`}
            getOptionSelected={(option, value) =>
              value?.id && option ? option.id === value.id : null
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="- Chọn -" />
            )}
          />
        </div>
        <div className={`${gridClasses.Col}`}>
          <Autocomplete
            openOnFocus
            selectOnFocus
            fullWidth
            size="small"
            handleHomeEndKeys={false}
            value={getcare_uom_base}
            onChange={(e, newValue) => this.handleSaveField({target: {name: 'getcare_uom_base'}}, newValue)}
            options={unitOptions || []}
            renderOption={(option) => `${option.name}`}
            getOptionLabel={(option) => (option && option.name) || ''}
            getOptionSelected={(option, value) =>
              value && value.name && option ? option.name === value.name : null
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="- Chọn -" />
            )}
          />
        </div>
        <div className={`${gridClasses.Col}`}>
          { ![null, undefined, ''].includes(price_sales_total) ? currencyFormatVN(price_sales_total) : ``}
        </div>
        <div className={`${gridClasses.Col}`}>
          <TextField
            autoComplete="off"
            className={classes.Field}
            value={quantity_number}
            placeholder="1"
            name="quantity_number"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={(e) => {
              this.handleSaveField(e, e.target.floatValue);
            }}
          />
        </div>
        <div className={`${gridClasses.Col}`}>
          { [null, undefined, ''].includes(quantity_number) || [null, undefined, ''].includes(price_sales_total) ? `` : currencyFormatVN(price_sales_total * Number(quantity_number)) }
        </div>
        <div className={`${gridClasses.Col}`}>
          { ![null, undefined, ''].includes(vat) ? vat + '%' : '' }
        </div>
        <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
          <IconButton
            onClick={this.handleRemove}
            style={{ padding: '0' }}
            size="small"
          >
            <DeleteOutline fontSize="small" />
          </IconButton>
        </div>
      </div>
      { discounts && discounts.length > 0 && discounts.map((discount, index) => (
        <div key={`discount-${index}`} className={`${gridClasses.Row} ${gridClasses.DiscountLineRow} ${classes.Item}`}>
          <div className={`${gridClasses.Col}`}></div>
          <div className={`${gridClasses.Col}`}></div>
          <div className={`${gridClasses.Col}`}></div>
          <div className={`${gridClasses.Col}`}>
            <strong>{ discount.promotion_code } - { discount.promotion_unit_name === 'VNĐ' ? currencyFormatVN(discount.discount) : discount.discount + discount.promotion_unit_name }</strong>
          </div>
          <div className={`${gridClasses.Col}`}>
            { ['', null, undefined].includes(discount.amount) ? '' : `(${currencyFormatVN(discount.amount)})` }
          </div>
          <div className={`${gridClasses.Col}`}>
            { ['', null, undefined].includes(discount.vat) ? '' : discount.vat + '%' }
          </div>
          <div className={`${gridClasses.Col}`}></div>
        </div>
      ))
      }
    </>);
  }
}

export default OrderListItem;

