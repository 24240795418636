import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { validDate, removeAccents, genID } from 'utils/helper';
import { suggestionPageSize } from 'utils/constanst/common';
import { dateFormat, dateTimeFormat } from 'utils/constanst/dateConstants';
import { format, max } from 'date-fns';
import promotionApi from 'utils/api/tmk/promotionApi';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import commonApi from 'utils/api/tmk/commonApi';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPromotionDetails,
  makeSelectPromotionTypes,
  makeSelectPromotionApplies,
  makeSelectPromotionPriorities,
  makeSelectPromotionVendors,
} from 'redux/selectors';
import {
  savePromotionDetails,
  savePromotionVendors,
} from 'redux/actions/tmk/promotionActions';
import {
  BASE_ON_PERIOD,
  BASE_ON_CAMPAIGN,
  vendorModes,
  customerModes,
  documentModes,
  IS_STOCK_FINISHED_YES,
  IS_STOCK_FINISHED_NO,
  PRIORITY_LOW,
  VENDOR_MODE_ALL,
  VENDOR_MODE_OPTION,
  CUSTOMER_MODE_ALL,
  MANY_TIMES_BY_DESC_QUANTITY,
  APPLY_AWAITING,
  STATUS_INACTIVE, APPLY_UNDEFINED, ACTIVITY_STOP,
  promotionStatusMap,
  applyStatusMap,
  isPromotionCombo,
  VENDOR_TYPE_ID,
  IS_TREND_PROMOTION_YES,
  IS_TREND_PROMOTION_NO,
} from 'utils/constanst/tmkPromotionConstants';

import ErrorOutline from '@material-ui/icons/ErrorOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import FieldEditable from 'components/FieldEditable/FieldEditable';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import classes from './PromotionDetails.module.scss';

class PromotionDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      baseOnPeriod: props.promotionDetails ? props.promotionDetails.base_on : BASE_ON_PERIOD,
      availablePromotions: [],
      isUploading: false,
      urlImage: '',
      vendorOptions: this._initVendorOptions(),
    }
  }
  componentDidMount() {
    this.initState();
    if (!this.props.isEditing && !this.props.isDuplicating) {
      this._initCreateForm();
    }
    this.loadSuggestedVendors({
      params: {
        page_size: suggestionPageSize,
      },
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { promotionDetails, isEditing, isDuplicating } = this.props;
    this.initState();
    if (!promotionDetails && !isEditing && !isDuplicating) {
      this._initCreateForm();
    }
    if (promotionDetails
      && promotionDetails?.name
      && promotionDetails?.name.trim().length > 0
      && promotionDetails?.name !== prevProps.promotionDetails?.name
    ) {
      this.loadAvailablePromotions({
        params: {
          name: promotionDetails?.name,
          page_size: 1,
        },
      });
    }
  }
  initState = () => {
    const { promotionDetails } = this.props;
    this.setState({
      baseOnPeriod: promotionDetails ? promotionDetails.base_on : BASE_ON_PERIOD,
      urlImage: promotionDetails?.image ? promotionDetails?.image : ''
    });
  }
  _initCreateForm = () => {
    this.handleSaveField({
      start_date: new Date().toISOString(),
      getcare_promotion_priority_id: PRIORITY_LOW,
      vendor_mode: this._isPromotionCombo() ? VENDOR_MODE_OPTION : VENDOR_MODE_ALL,
      customer_mode: CUSTOMER_MODE_ALL,
      base_on: BASE_ON_PERIOD,
      customer_mode: CUSTOMER_MODE_ALL,
      number_of_times_applied: null,
      promotion_vendors:null,
      number_of_product: null,
      order_amount_from: null,  
      getcare_promotion_apply_id: MANY_TIMES_BY_DESC_QUANTITY,
    });
  }
  _getSelectedType = () => {
    const { promotionDetails, promotionTypes } = this.props;
    const typeId = (promotionDetails?.getcare_promotion_type_id || promotionDetails?.getcare_promotion_type?.id);
    return (promotionDetails && promotionTypes.find(item => item.id === typeId)) || null;
  };
  _getSelectedDocumentMode = () => {
    const { promotionDetails } = this.props;
    return (documentModes && documentModes.find(item => item.id === promotionDetails?.document_mode)) || null;
  };
  _getSelectedApply = () => {
    const { promotionDetails, promotionApplies } = this.props;
    const applyId = (promotionDetails?.getcare_promotion_apply_id || promotionDetails?.getcare_promotion_apply?.id);
    return (promotionDetails && promotionApplies.find(item => item.id === applyId)) || null;
  };
  _getSelectedPriority = () => {
    const { promotionDetails, promotionPriorities } = this.props;
    const applyId = (promotionDetails?.getcare_promotion_priority_id || promotionDetails?.getcare_promotion_priority?.id);
    return (promotionDetails && promotionPriorities.find(item => item.id === applyId)) || null;
  };

  _initVendorOptions = () => {
    const { promotionVendors } = this.props;
    if (!promotionVendors) return [];
    return promotionVendors.filter(vendor => !!vendor.getcare_vendor).map(vendor => ({...vendor.getcare_vendor}));
  }
  loadSuggestedVendors = debounce(async ({ params }) => {
    const { data: response } = await promotionApi.getVendorSuggestions({
      params: {
        ...params,
        type_id: VENDOR_TYPE_ID,
      },
    });
    if (!response?.result) return;
    this.setState({
      vendorOptions: response.data
        ? [...response.data.vendors]
        : [],
    });
  }, 500);
  _getSelectedVendorMode = () => {
    const { promotionDetails } = this.props;
    return (vendorModes && vendorModes.find(item => item.id === promotionDetails?.vendor_mode)) || null;
  };
  _getComboSelectedVendor = () => {
    const { promotionVendors } = this.props;
    return promotionVendors ? promotionVendors[0]?.getcare_vendor : null;
  }
  _getSelectedVendor = (vendorId) => {
    const { vendorOptions } = this.state;
    return (vendorId && vendorOptions.find(item => item.id === vendorId)) || null;
  };

  _getSelectedCustomerMode = () => {
    const { promotionDetails } = this.props;
    return (customerModes && customerModes.find(item => item.id === promotionDetails?.customer_mode)) || null;
  };
  _isBasedOnPeriod = () => {
    return this.props.promotionDetails?.base_on === BASE_ON_PERIOD;
  }
  _preprocessCodeValue = (value) => {
    return removeAccents(value).toUpperCase();
  };
  loadAvailablePromotions = debounce(async ({ params }) => {
    const { data: response } = await promotionApi.getAll({ params });
    const { promotionDetails } = this.props;
    if (!response?.result) return;
    this.setState({
      availablePromotions: response.data.filter(item =>
        item.id !== promotionDetails?.id
        && item.name.toLowerCase() === promotionDetails?.name.toLowerCase()
      )
    });
  }, 500);
  _isPromotionNameDuplicated = () => {
    const { availablePromotions } = this.state;
    return availablePromotions && availablePromotions.length > 0;
  }
  _isPromotionNotAppliedYet = () => {
    const { promotionDetails, isEditing } = this.props;
    return !isEditing || promotionDetails?.apply === APPLY_AWAITING;
  }
  _isAwaitingProgress = () => {
    return this._getActivityStatus() === APPLY_AWAITING;
  }
  _isStoppedProgress = () => {
    return this._getActivityStatus() === ACTIVITY_STOP;
  }
  _getActivityStatus = () => {
    const { promotionDetails } = this.props;
    if (!promotionDetails) return APPLY_AWAITING;
    if (promotionDetails.active === STATUS_INACTIVE || promotionDetails.apply === APPLY_UNDEFINED) return ACTIVITY_STOP;
    return promotionDetails.apply;
  }
  isPromotionOrder(promotionTypeId) {
    return promotionTypeId === 5;
  }
  _isPromotionCombo = () => {
    const { promotionDetails } = this.props;
    return isPromotionCombo(promotionDetails?.getcare_promotion_type_id || promotionDetails?.getcare_promotion_type?.id)
  }

  _isPromotionOrder = () => {
    const { promotionDetails } = this.props;
    return this.isPromotionOrder(promotionDetails?.getcare_promotion_type_id || promotionDetails?.getcare_promotion_type?.id)
  }

  getPromotionVendorCount = () => {
    return this.props.promotionDetails?.count_vendor;
  }
  getPromotionVendorString = () => {
    const vendorCount = this.getPromotionVendorCount();
    if (vendorCount > 1) return <div><span className={`${classes.VendorCountBadge} Badge BlueGrey Text`}>{ vendorCount }</span></div>;

    return this.props.promotionDetails?.vendor_name;
  }

  handleBaseOnChange = (e) => {
    this.setState({
      baseOnPeriod: e.target.value,
    });
    this.handleSaveField({ [e.target.name]: e.target.value });
  }
  handleIsStockFinishedChange = (e) => {
    this.handleSaveField({ [e.target.name]: e.target.checked ? IS_STOCK_FINISHED_YES : IS_STOCK_FINISHED_NO });
  }

  handleIsPromotionPaid = (e) => {
    const {promotionDetails} = this.props;
    this.handleSaveField({ 
      [e.target.name]: e.target.checked ? IS_TREND_PROMOTION_YES :  IS_TREND_PROMOTION_NO,
      image: e.target.checked ? this.state.urlImage : (promotionDetails.image ? promotionDetails.image : '')
    });
  }

  submitFile = async (e) => {
    if (e.target.files.length !== 0) {
      const fileSize = e.target.files[0].size;
        if (fileSize >= 2197152) {
          toast.error('Dung lượng ảnh tối đa là 2MB');
        } else {
          await this.setState({
            currentFile: e.target.files[0],
            previewImage: URL.createObjectURL(e.target.files[0]),
          });
          this.uploadImage();
        }
    }
  };

  uploadImage = async () => {
    const formData = new FormData();
    formData.append('file', this.state.currentFile);
    try {
      this.setState({ isUploading: true });
      const response = await commonApi.uploadMedia(formData);
      const { url } = response.data.data;
      this.setState({ isUploading: false, urlImage: url });
      this.handleSaveField({image: url});
    } catch (error) {
      toast.error(error.message);
    }
  };

  handleChangeField = (e, value) => {
    if (!this.props.isEditing) {
      this.handleSaveField({ [e.target.name]: value });
    }
  };
  handleSaveField = (fieldMap) => {
    if (fieldMap.promotion_vendors && !this.props.isOrder) {
      fieldMap.promotion_vendors = this._getComboSelectedVendor();
      const selectedVendor = this._getComboSelectedVendor();
      this.props.savePromotionVendors([{
        idStr: selectedVendor?.idStr || genID(),
        id: selectedVendor?.id || 0,
        type_id: VENDOR_TYPE_ID,
        getcare_vendor: this._getSelectedVendor(fieldMap.promotion_vendors),
        getcare_erp_group: null,
        isEditing: false,
      }]);
      return;
    }
    if (fieldMap.code && this.props.isEditing) {
      fieldMap.code = this._preprocessCodeValue(fieldMap.code);
    }
    
    const params = {
      ...this.props.promotionDetails,
      ...fieldMap,
    };
    this.props.savePromotionDetails(params);
  };
  handleBlurCodeField = (e, value) => {
    this.handleSaveField({code: this._preprocessCodeValue(value)});
  }
  handleChangeSelectedCode = (e, newValue) => {
    if (!newValue) return;
    this.props.history.push(`/promotion/${newValue.id}`);
  }

  render() {
    const {
      promotionDetails,
      isEditing,
      promotionTypes,
      promotionPriorities,
      promotionApplies,
      isValid,
      isDocumentType,
      isDuplicating,
      isOrder,
      readOnly,
    } = this.props;

    const vendorCount = this.getPromotionVendorCount();
    const isBasedOnPeriod = this._isBasedOnPeriod();
    const minStartDate = isDuplicating ? null : new Date();
    const minEndDate = (promotionDetails && promotionDetails.start_date)
      ? (isDuplicating ? new Date(promotionDetails.start_date) : max([new Date(promotionDetails.start_date), new Date()]))
      : (isDuplicating ? null : new Date());

    return (
      <div className={`${classes.Details} ${isEditing ? classes.IsEditing : ''}`}>
        <div className={classes.DetailsCol}>
          <div className={classes.DetailsRow}>
            <div className={classes.FieldControl}>
              <label className={classes.Flex}>
                Mã promotion <span className={classes.RequiredMark}>*</span> {` `}
                { isEditing && promotionDetails?.is_empty && (<Tooltip title={`Chương trình rỗng`} arrow placement="bottom">
                    <span className="TextDanger"><ErrorOutline fontSize="small"/></span>
                  </Tooltip>)
                }
              </label>
              {(readOnly || (isEditing && !promotionDetails?.is_editable))
                ? promotionDetails?.code
                : <FieldEditable
                    editModeOnly={!isEditing}
                    autoFocus
                    fieldName="code"
                    error={isEditing ? false : !this.props.isCodeValid}
                    maxLength={20}
                    value={promotionDetails?.code || ''}
                    onSave={this.handleSaveField}
                    onChange={this.handleChangeField}
                    onBlur={this.handleBlurCodeField}
                  />
              }
            </div>
            <div className={classes.FieldControl}>
              <label>Mô tả promotion</label>
              {readOnly && promotionDetails?.description}
              {!readOnly && (
                <FieldEditable
                  editModeOnly={!isEditing}
                  fieldName="description"
                  maxLength={255}
                  value={promotionDetails?.description || ''}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                />
              )}
            </div>
          </div>

          <div className={classes.DetailsRow}>
            <div className={classes.FieldControl}>
              <label className={classes.Flex}>
                Tên promotion <span className={classes.RequiredMark}>*</span>
              </label>
              {readOnly && promotionDetails?.name}
              {!readOnly && (
                <FieldEditable
                  editModeOnly={!isEditing}
                  fieldName="name"
                  error={isEditing ? false : !this.props.isNameValid}
                  maxLength={55}
                  value={promotionDetails?.name || ''}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                />
              )}
            </div>
            <div className={`${classes.FieldControlWrap} ${classes.DocumentWrap}`}>
              <div className={classes.FieldControl}>
                <label>Loại promotion</label>
                {isEditing ? (
                  promotionDetails?.getcare_promotion_type?.name
                ) : (
                  <FieldEditable
                    error={isEditing ? false : !this.props.isTypeValid}
                    fieldType="select"
                    editModeOnly={!isEditing}
                    fieldName="getcare_promotion_type_id"
                    value={this._getSelectedType()?.id}
                    displayedValue={this._getSelectedType()?.name}
                    options={promotionTypes}
                    onSave={this.handleSaveField}
                    onChange={this.handleChangeField}
                  />
                )}
              </div>
              { isDocumentType && (
                <div className={classes.FieldControl}>
                  <label>trên</label>
                  {isEditing ? (
                    this._getSelectedDocumentMode()?.name
                  ) : (
                    <FieldEditable
                      error={isEditing ? false : !this.props.isTypeValid}
                      fieldType="select"
                      editModeOnly={!isEditing}
                      fieldName="document_mode"
                      value={this._getSelectedDocumentMode()?.id}
                      displayedValue={this._getSelectedDocumentMode()?.name}
                      options={documentModes}
                      onSave={this.handleSaveField}
                      onChange={this.handleChangeField}
                    />
                  )}
                </div>
              )}
            </div>
          </div>

          <div className={classes.DetailsRowCustom}>
            <div className={classes.FieldControl}>
              <FormControlLabel
                  size="small"
                  disabled={readOnly}
                  control={
                    <Checkbox
                      size="small"
                      name="is_trend_promotion"
                      checked={promotionDetails?.is_trend_promotion === IS_TREND_PROMOTION_YES}
                      onChange={this.handleIsPromotionPaid}
                    />
                  }
                  label="Paid Promotion"
                />
              <Button
                size="small"
                className={classes.Upload}
                disabled={ this.state.isUploading || readOnly || promotionDetails?.is_trend_promotion !== IS_TREND_PROMOTION_YES}
                variant="contained"
                color="primary"
                component="label"
              >
                Upload Image
                <input
                  hidden
                  name="file"
                  accept='image/*' 
                  type="file"
                  onChange={this.submitFile}
                />
            </Button>
              </div>
            { promotionDetails?.is_trend_promotion === IS_TREND_PROMOTION_YES && 
              <div className={`${classes.FieldControl}`}>
                {isEditing &&
                  <a className={`${classes.ImageLink}`} target="_blank" rel="noreferrer" href={promotionDetails?.image || ''}>
                    {promotionDetails?.image || ''}
                  </a>
                }
                {!readOnly && !isEditing && (
                  <FieldEditable
                    editModeOnly={!isEditing}
                    fieldName="image"
                    value={promotionDetails?.image || ''}
                    onSave={this.handleSaveField}
                    onChange={this.handleChangeField}
                  />
                )}
              </div> 
            }
          </div>

          <div className={classes.DetailsRow}>
            <div className={classes.FieldControl}>
              <label>{ vendorCount > 1 ? `Số nhà cung cấp` : `Nhà cung cấp` }</label>
              {readOnly && this.getPromotionVendorString()}
              {
                 this._isPromotionOrder() ? 
                 !readOnly && this._isPromotionOrder() 
                  ? <FieldEditable
                      disableClearable
                      error={isEditing ? false :!this.props.isVendorPromotionValid}
                      fieldType="select"
                      editModeOnly={!isEditing}
                      fieldName="promotion_vendors"
                      value={this._getComboSelectedVendor()?.id}
                      displayedValue={this._getComboSelectedVendor()?.name}
                      options={this.state.vendorOptions || []}
                      renderOption={(option) => `${option.code} - ${option.name}`}
                      getOptionLabel={(option) => ((option && option.name) || '')}
                      onSave={this.handleSaveField}
                      onChange={this.handleChangeField}
                    />
                  : !readOnly && isEditing ? (
                      this.getPromotionVendorString()
                    ) : !readOnly && (
                      <FieldEditable
                        error={isEditing ? false : !this.props.isVendorModeValid }
                        fieldType="select"
                        editModeOnly={!isEditing}
                        fieldName="vendor_mode"
                        value={this._getSelectedVendorMode()?.id}
                        displayedValue={this._getSelectedVendorMode()?.name}
                        options={vendorModes}
                        onSave={this.handleSaveField}
                        onChange={this.handleChangeField}
                      />
                    )
                
                 :!readOnly && this._isPromotionCombo()
                 ? <FieldEditable
                     disableClearable
                     error={isEditing ? false :!this.props.hasVendors}
                     fieldType="select"
                     editModeOnly={!isEditing}
                     fieldName="promotion_vendors"
                     value={this._getComboSelectedVendor()?.id}
                     displayedValue={this._getComboSelectedVendor()?.name}
                     options={this.state.vendorOptions || []}
                     renderOption={(option) => `${option.code} - ${option.name}`}
                     getOptionLabel={(option) => ((option && option.name) || '')}
                     onSave={this.handleSaveField}
                     onChange={this.handleChangeField}
                   />
                 : !readOnly && isEditing ? (
                     this.getPromotionVendorString()
                   ) : !readOnly && (
                     <FieldEditable
                       error={isEditing ? false : !this.props.isVendorModeValid }
                       fieldType="select"
                       editModeOnly={!isEditing}
                       fieldName="vendor_mode"
                       value={this._getSelectedVendorMode()?.id}
                       displayedValue={this._getSelectedVendorMode()?.name}
                       options={vendorModes}
                       onSave={this.handleSaveField}
                       onChange={this.handleChangeField}
                     />
                   )
              }   
            </div>
            <div className={classes.FieldControl}>
              <label>Khách hàng</label>
              {isEditing ? (
                this._getSelectedCustomerMode()?.name
              ) : (
                <FieldEditable
                  error={isEditing ? false : !this.props.isCustomerModeValid}
                  fieldType="select"
                  editModeOnly={!isEditing}
                  fieldName="customer_mode"
                  value={this._getSelectedCustomerMode()?.id}
                  displayedValue={this._getSelectedCustomerMode()?.name}
                  options={customerModes}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                />
              )}
            </div>
          </div>

          <RadioGroup
            className={classes.RadioGroupWrap}
            aria-label="Based on"
            name="base_on"
            value={this.state.baseOnPeriod}
            onChange={this.handleBaseOnChange}
          >
            <div className={`${classes.DetailsRow} ${classes.RadioGroupRow}`}>
              <FormControlLabel disabled={readOnly} value={BASE_ON_PERIOD} control={<Radio size="small" />} label="Base on period" />
              <div className={classes.FieldControl}>
                <label>
                  Ngày bắt đầu <span className={classes.RequiredMark}>*</span>
                </label>
                {isEditing && !this._isAwaitingProgress() ? (
                  validDate(promotionDetails?.start_date) ? (
                    format(validDate(promotionDetails?.start_date), dateFormat)
                  ) : ('')
                ) : (
                  <FieldEditable
                    disabled={!isBasedOnPeriod}
                    fieldType="date"
                    editModeOnly={!isEditing}
                    displayedValue={
                      validDate(promotionDetails?.start_date)
                        ? format(validDate(promotionDetails?.start_date), dateFormat)
                        : ''
                    }
                    error={isEditing ? false : !this.props.isStartDateValid}
                    minValue={minStartDate?.toISOString() || ''}
                    fieldName="start_date"
                    value={promotionDetails?.start_date || ''}
                    onSave={this.handleSaveField}
                    onChange={this.handleChangeField}
                  />
                )}
              </div>
              <div className={classes.FieldControl}>
                <label>Ngày kết thúc</label>
                {isEditing && this._isStoppedProgress() ? (
                  validDate(promotionDetails?.end_date) ? (
                    format(validDate(promotionDetails?.end_date), dateFormat)
                  ) : ('')
                ) : (
                  <FieldEditable
                    disabled={!isBasedOnPeriod}
                    fieldType="date"
                    editModeOnly={!isEditing}
                    displayedValue={
                      validDate(promotionDetails?.end_date)
                        ? format(validDate(promotionDetails?.end_date), dateFormat)
                        : ''
                    }
                    error={isEditing ? false : !this.props.isEndDateValid}
                    fieldName="end_date"
                    minValue={minEndDate?.toISOString() || ''}
                    value={promotionDetails?.end_date || ''}
                    onSave={this.handleSaveField}
                    onChange={this.handleChangeField}
                  />
                )}
              </div>
              <FormControlLabel
                size="small"
                disabled={readOnly}
                control={
                  <Checkbox
                    disabled={!isBasedOnPeriod}
                    size="small"
                    name="is_stock_finished"
                    checked={promotionDetails?.is_stock_finished === IS_STOCK_FINISHED_YES}
                    onChange={this.handleIsStockFinishedChange}
                  />
                }
                label="Tự động kết thúc khi hết hàng khuyến mãi"
              />
            </div>
            <div className={`${classes.DetailsRow} ${classes.RadioGroupRow}`}>
              <FormControlLabel disabled value={BASE_ON_CAMPAIGN} control={<Radio size="small" />} label="Base on campaign" />
              <label>{promotionDetails?.getcare_campaign_id}</label>
            </div>
          </RadioGroup>





          <div className={classes.DetailsRow}>
            <div className={classes.FieldControl}>
              <label>Áp dụng trong đơn hàng</label>
              {readOnly && this._getSelectedApply()?.name}
              {!readOnly && (
                <FieldEditable
                  disableClearable
                  error={isEditing ? false : !this.props.isApplyValid}
                  fieldType="select"
                  editModeOnly={!isEditing}
                  fieldName="getcare_promotion_apply_id"
                  value={this._getSelectedApply()?.id}
                  displayedValue={this._getSelectedApply()?.name}
                  options={promotionApplies}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                />
              )}
            </div>
            <div className={classes.FieldControl}>
              <label>Ưu tiên</label>
              {readOnly && this._getSelectedPriority()?.name}
              {!readOnly && (
                <FieldEditable
                  disableClearable
                  error={isEditing ? false : !this.props.isPriorityValid}
                  fieldType="select"
                  editModeOnly={!isEditing}
                  fieldName="getcare_promotion_priority_id"
                  value={this._getSelectedPriority()?.id}
                  displayedValue={this._getSelectedPriority()?.name}
                  options={promotionPriorities}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                />
              )}
            </div>
          </div>
          {
            isOrder ? 
            <div className={classes.DetailsRow}>
          <div className={classes.FieldControl}>
              <label>Giá trị đơn hàng từ </label>
              {readOnly && promotionDetails?.order_amount_from}
              {!readOnly && (
                <FieldEditable
                  editModeOnly={!isEditing}
                  error={isEditing ? false : !this.props.isOrderValid}
                  fieldName="order_amount_from"
                  maxLength={255}
                  value={promotionDetails?.order_amount_from || ''}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                />
              )}
            </div>
            <div className={classes.FieldControl}>
              <label>Số lần áp dụng</label>
              {readOnly && promotionDetails?.number_of_times_applied}
              {!readOnly && (
                <FieldEditable
                  editModeOnly={!isEditing}
                  error={isEditing ? false : !this.props.isOrderApplied}
                  fieldName="number_of_times_applied"
                  maxLength={255}
                  value={promotionDetails?.number_of_times_applied || ''}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                />
              )}
            </div>
            <div className={classes.FieldControl}>
              <label>Số lượng sản phẩm đạt</label>
              {readOnly && promotionDetails?.number_of_product}
              {!readOnly && (
                <FieldEditable
                  editModeOnly={!isEditing}
                  fieldName="number_of_product"
                  error={isEditing ? false : !this.props.isOrderNumber}
                  maxLength={255}
                  value={promotionDetails?.number_of_product || ''}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                />
              )}
            </div>
            </div>
            : null
          }
          
        </div>
          
          
        { isEditing && <div className={classes.DetailsCol}>
          <div className={`${classes.FieldControlWrap} ${classes.StatusWrap}`}>
            <div className={`${classes.FieldControl}`}>
              <label>Trạng thái</label>
              {promotionStatusMap[promotionDetails?.active]}
            </div>
            {!this._isStoppedProgress() && (
              <Button
                disabled={!isValid}
                variant="outlined"
                size="small"
                color="secondary"
                onClick={this.props.onDeactivePromotion}
              >
                Inactive
              </Button>
            )}
          </div>
          <div className={`${classes.FieldControl}`}>
            <label>Áp dụng</label>
            {applyStatusMap[promotionDetails?.apply]}
          </div>
          <div className={`${classes.FieldControl}`}>
            <label>Thời gian tạo</label>
            {validDate(promotionDetails?.created_at)
              ? format(
                  validDate(promotionDetails?.created_at),
                  dateTimeFormat
                )
              : ''}
          </div>
          <div className={`${classes.FieldControl}`}>
            <label>Thời gian sửa</label>
            {validDate(promotionDetails?.updated_at)
              ? format(
                  validDate(promotionDetails?.updated_at),
                  dateTimeFormat
                )
              : ''}
          </div>
          <div className={classes.FieldControl}>
            <label>Người sửa</label>
            { promotionDetails?.getcare_user?.name }
          </div>
        </div> }
      </div>
    );
  }
}

PromotionDetails.propTypes = {
  isEditing: PropTypes.bool,
  isValid: PropTypes.bool,
  onDeactivePromotion: PropTypes.func,
};

PromotionDetails.defaultProps = {
  isEditing: false,
  isValid: true,
};

const mapStateToProps = createStructuredSelector({
  promotionDetails: makeSelectPromotionDetails(),
  promotionTypes: makeSelectPromotionTypes(),
  promotionApplies: makeSelectPromotionApplies(),
  promotionPriorities: makeSelectPromotionPriorities(),
  promotionVendors: makeSelectPromotionVendors(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    savePromotionDetails: (payload) => dispatch(savePromotionDetails(payload)),
    savePromotionVendors: (payload) => dispatch(savePromotionVendors(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(PromotionDetails);
