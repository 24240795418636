export const STATUS_ACTIVE = 1;
export const STATUS_INACTIVE = -1;
export const STATUS_EMPTY = 0;
export const promotionStatusMap = {
  [STATUS_ACTIVE]: 'Đang hoạt động',
  [STATUS_INACTIVE]: 'Dừng hoạt động',
  [STATUS_EMPTY]: 'Chương trình rỗng',
};
export const promotionStatuses = [
  {
    id: STATUS_ACTIVE,
    name: promotionStatusMap[STATUS_ACTIVE],
  },
  {
    id: STATUS_INACTIVE,
    name: promotionStatusMap[STATUS_INACTIVE],
  },
  {
    id: STATUS_EMPTY,
    name: promotionStatusMap[STATUS_EMPTY],
  },
];

export const APPLY_AWAITING = -1;
export const APPLY_IN_PROGRESS = 1;
export const APPLY_UNDEFINED = 0;
export const applyStatusMap = {
  [APPLY_AWAITING]: 'Chưa áp dụng',
  [APPLY_IN_PROGRESS]: 'Đang áp dụng',
  [APPLY_UNDEFINED]: '-',
}

export const ACTIVITY_AWAITING = -1;
export const ACTIVITY_IN_PROGRESS = 1;
export const ACTIVITY_STOP = 0;
export const activityMap = {
  [ACTIVITY_AWAITING]: 'Đang chờ',
  [ACTIVITY_IN_PROGRESS]: 'Đang hoạt động',
  [ACTIVITY_STOP]: '',
};

export const listDisplayFields = [
  {
    name: 'code',
    label: 'Mã promotion',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Tên promotion',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_promotion_type_name',
    label: 'Loại promotion',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'description',
    label: 'Mô tả',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'start_date',
    label: 'Ngày bắt đầu',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'end_date',
    label: 'Ngày kết thúc',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_promotion_priority_name',
    label: 'Ưu tiên',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'apply',
    label: 'Áp dụng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'active',
    label: 'Trạng thái',
    sortable: true,
    sortDir: '',
  },
];
export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  active: [1],
};
export const filterFields = [
  {
    name: 'active',
    label: 'Tình trạng',
    value: [STATUS_ACTIVE],
    type: 'custom',
    placeholder: '- Chọn -',
  },
  {
    name: 'code',
    label: 'Mã promotion',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'name',
    label: 'Tên promotion',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_promotion_type_name',
    label: 'Loại promotion',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'start_date',
    label: 'Ngày bắt đầu',
    value: '',
    type: 'date',
    placeholder: 'Nhập hoặc chọn...',
  },
  {
    name: 'end_date',
    label: 'Ngày kết thúc',
    value: '',
    type: 'date',
    placeholder: 'Nhập hoặc chọn...',
  },
];

// details
export const PROMOTION_TYPE_COMBO = 1;
export const PROMOTION_TYPE_GROUP = 2;
export const PROMOTION_TYPE_LINE = 3;
export const PROMOTION_TYPE_DOCUMENT = 4;

export const BASE_ON_PERIOD = "1";
export const BASE_ON_CAMPAIGN = "2";

export const IS_STOCK_FINISHED_YES = 1;
export const IS_STOCK_FINISHED_NO = 2;

export const IS_TREND_PROMOTION_YES = 1;
export const IS_TREND_PROMOTION_NO = 0;

export const MANY_TIMES_BY_DESC_QUANTITY = 1;
export const ONCE_BY_HIGHEST_LEVEL = 2;
export const MANY_TIMES_BY_LOWEST_LEVEL = 3;

export const VENDOR_MODE_ALL = 1;
export const VENDOR_MODE_OPTION = 2;
export const vendorModesMap = {
  [VENDOR_MODE_ALL]: 'Tất cả',
  [VENDOR_MODE_OPTION]: 'Nhóm nhà cung cấp',
}
export const vendorModes = [
  {
    id: VENDOR_MODE_ALL,
    name: vendorModesMap[VENDOR_MODE_ALL],
  },
  {
    id: VENDOR_MODE_OPTION,
    name: vendorModesMap[VENDOR_MODE_OPTION],
  },
]

export const CUSTOMER_MODE_ALL = 1;
export const CUSTOMER_MODE_OPTION = 2;
export const customerModesMap = {
  [CUSTOMER_MODE_ALL]: 'Tất cả',
  [CUSTOMER_MODE_OPTION]: 'Nhóm khách hàng',
}
export const customerModes = [
  {
    id: CUSTOMER_MODE_ALL,
    name: customerModesMap[CUSTOMER_MODE_ALL],
  },
  {
    id: CUSTOMER_MODE_OPTION,
    name: customerModesMap[CUSTOMER_MODE_OPTION],
  },
]

export const DOCUMENT_MODE_ALL = 1;
export const DOCUMENT_MODE_OPTION = 2;
export const documentModesMap = {
  [DOCUMENT_MODE_ALL]: 'Tất cả',
  [DOCUMENT_MODE_OPTION]: 'Trừ hàng đã khuyến mãi',
}
export const documentModes = [
  {
    id: DOCUMENT_MODE_ALL,
    name: documentModesMap[DOCUMENT_MODE_ALL],
  },
  {
    id: DOCUMENT_MODE_OPTION,
    name: documentModesMap[DOCUMENT_MODE_OPTION],
  },
]

export const PRIORITY_LOW = 1;
export const PRIORITY_MEDIUM = 2;
export const PRIORITY_HIGH = 3;

// rules
export const MAX_LEVEL_NUM = 5;
export const COMPARE_EQUAL = 3;
export const ORDER_MODE_NONE = null;
export const ORDER_MODE_INCLUSIVE = 1;
export const ORDER_MODE_EXCLUSIVE = 2;
export const orderModeMap = {
  [ORDER_MODE_NONE]: 'None',
  [ORDER_MODE_INCLUSIVE]: 'Inclusive',
  [ORDER_MODE_EXCLUSIVE]: 'Exclusive',
}
export const orderModes = [
  {
    id: ORDER_MODE_NONE,
    name: orderModeMap[ORDER_MODE_NONE],
  },
  {
    id: ORDER_MODE_INCLUSIVE,
    name: orderModeMap[ORDER_MODE_INCLUSIVE],
  },
  {
    id: ORDER_MODE_EXCLUSIVE,
    name: orderModeMap[ORDER_MODE_EXCLUSIVE],
  },
]

export const OPERATOR_AND = 1;
export const DISCOUNT_YES = 1;
export const DISCOUNT_NO = 2;
export const DISCOUNT_PERCENT = 1;
export const DISCOUNT_PER_UNIT = 2;

export const promotionRulesDisplayFields = [
  {
    name: 'level',
    label: 'Level',
  },
  {
    name: 'level_name',
    label: 'Tên level',
  },
  {
    name: 'quantity_number',
    label: 'Số lượng mua',
  },
  {
    name: 'order_amount',
    label: 'Giá trị đơn hàng',
  },
  {
    name: 'bonus_number',
    label: 'Số lượng tặng',
  },
  {
    name: 'getcare_promotion_operator_name',
    label: 'AND/OR',
  },
  {
    name: 'discount',
    label: 'Discount',
  },
]
export const promotionRulesGroupDisplayFields = [
  {
    name: 'level',
    label: 'Level',
  },
  {
    name: 'level_name',
    label: 'Tên level',
  },
  {
    name: 'quantity_number',
    label: 'Số lượng mua',
  },
  {
    name: 'order_amount',
    label: 'Giá trị đơn hàng',
  },
  {
    name: 'bonus_number',
    label: 'Số lượng tặng',
  },
  {
    name: 'bonus_mode',
    label: 'Tặng nhóm tương tự',
    className: 'Center',
  },
  {
    name: 'getcare_promotion_operator_name',
    label: 'AND/OR',
  },
  {
    name: 'discount',
    label: 'Discount',
  },
]
export const promotionRulesLineDisplayFields = [
  {
    name: 'level',
    label: 'Level',
  },
  {
    name: 'level_name',
    label: 'Tên level',
  },
  {
    name: 'quantity_number',
    label: 'Số lượng mua',
  },
  {
    name: 'order_amount',
    label: 'Giá trị đơn hàng',
  },
  {
    name: 'bonus_number',
    label: 'Số lượng tặng',
  },
  {
    name: 'bonus_mode',
    label: 'Tặng hàng tương tự',
  },
  {
    name: 'getcare_promotion_operator_name',
    label: 'AND/OR',
  },
  {
    name: 'discount',
    label: 'Discount',
  },
]
export const promotionRulesDocumentDisplayFields = [
  {
    name: 'level',
    label: 'Level',
  },
  {
    name: 'level_name',
    label: 'Tên level',
  },
  {
    name: 'order_amount',
    label: 'Giá trị đơn hàng',
  },
  {
    name: 'bonus_number',
    label: 'Số lượng tặng',
  },
  {
    name: 'getcare_promotion_operator_name',
    label: 'AND/OR',
  },
  {
    name: 'discount',
    label: 'Discount',
  },
]
export const promotionRulesParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
}

// products
export const PRODUCT_TYPE_ID = 1;
export const GROUP_TYPE_ID = 2;
export const BONUS_PROUDCT_TYPE_ID = 3;
export const groupTypes = [
  {
    id: GROUP_TYPE_ID,
    name: 'Nhóm',
  },
  {
    id: PRODUCT_TYPE_ID,
    name: 'Sản phẩm',
  }
]
export const promotionProductsDisplayFields = [
  {
    name: 'type_id',
    label: 'Nhóm',
  },
  {
    name: 'code',
    label: 'ID',
  },
  {
    name: 'name',
    label: 'Tên',
  },
  {
    name: 'getcare_uom_base',
    label: 'Đơn vị tính',
  },
  {
    name: 'ratio',
    label: 'Ratio',
  },
]

export const promotionOrderDisplayFields = [
  {
    name: 'code',
    label: 'Mã sản phẩm',
  },
  {
    name: 'name',
    label: 'Tên',
  },
  {
    name: 'description',
    label: 'Miêu tả',
  },
  {
    name: 'line',
    label: 'Kiểu khuyến mại',
  },
]

export const promotionProductsComboDisplayFields = [
  {
    name: 'type_id',
    label: 'Nhóm',
  },
  {
    name: 'code',
    label: 'ID',
  },
  {
    name: 'name',
    label: 'Tên',
  },
  {
    name: 'getcare_uom_base',
    label: 'Đơn vị tính',
  },
  {
    name: 'ratio',
    label: 'Ratio',
  },
  {
    name: 'focus',
    label: 'Sản phẩm trọng tâm',
    className: 'Center',
  },
  {
    name: 'quantity_number',
    label: 'Tối thiểu',
  },
]


export const promotionOrderComboDisplayFields = [
  {
    name: 'code',
    label: 'Mã CTKM',
  },
  {
    name: 'name',
    label: 'Tên CTKM*',
  },
  {
    name: 'description',
    label: 'Mô tả',
  },
  {
    name: 'line',
    label: 'Loại chương trình khuyến mại',
  },
]
export const promotionProductsParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
}

// max products
export const promotionMaxProductsDisplayFields = [
  {
    name: 'code',
    label: 'ID',
  },
  {
    name: 'name',
    label: 'Tên',
  },
  {
    name: 'quantity_number',
    label: 'Số lượng tối đa',
  },
]
export const promotionMaxProductsParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
}

// bonuses
export const bonusGroupTypes = [
  {
    id: GROUP_TYPE_ID,
    name: 'Nhóm',
  },
  {
    id: PRODUCT_TYPE_ID,
    name: 'Sản phẩm',
  },
  {
    id: BONUS_PROUDCT_TYPE_ID,
    name: 'Hàng khuyến mãi',
  },
]
export const promotionBonusesDisplayFields = [
  {
    name: 'level',
    label: 'Level',
  },
  {
    name: 'type_id',
    label: 'Nhóm',
  },
  {
    name: 'code',
    label: 'ID',
  },
  {
    name: 'name',
    label: 'Tên',
  },
  {
    name: 'getcare_uom_base',
    label: 'Đơn vị tính',
  },
  {
    name: 'ratio',
    label: 'Ratio',
  },
  {
    name: 'quantity_number',
    label: 'Số lượng',
  },
]
export const promotionBonusesParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
}
export const newBonusItem = {
  id: 0,
  level: '',
  type_id: PRODUCT_TYPE_ID,
  getcare_product: null,
  getcare_erp_group: null,
  getcare_product_bonus: null,
  getcare_uom_base: null,
  ratio: null,
  quantity_number: 1,
  isEditing: true,
}

// vendors
export const VENDOR_TYPE_ID = 1;
export const vendorGroupTypes = [
  {
    id: GROUP_TYPE_ID,
    name: 'Nhóm',
  },
  {
    id: VENDOR_TYPE_ID,
    name: 'Nhà cung cấp',
  },
]
export const promotionVendorsDisplayFields = [
  {
    name: 'type_id',
    label: 'Nhóm',
  },
  {
    name: 'code',
    label: 'Mã (nhóm) nhà cung cấp (NCC)',
  },
  {
    name: 'name',
    label: 'Tên',
  },
  {
    name: 'description',
    label: 'Mô tả',
  },
  {
    name: 'count',
    label: 'Số lượng',
  },
]
export const promotionVendorsParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
}

// customers
export const promotionCustomersDisplayFields = [
  {
    name: 'code',
    label: 'Mã nhóm khách hàng',
  },
  {
    name: 'name',
    label: 'Tên nhóm',
  },
  {
    name: 'description',
    label: 'Mô tả',
  },
  {
    name: 'count',
    label: 'Số lượng',
  },
]
export const promotionCustomersParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
}

export function getPromotionClassName(promotionTypeId) {
  switch(promotionTypeId) {
    case PROMOTION_TYPE_COMBO: {
      return 'Combo';
    }
    case PROMOTION_TYPE_GROUP: {
      return 'Group';
    }
    case PROMOTION_TYPE_DOCUMENT: {
      return 'Document';
    }
    case PROMOTION_TYPE_LINE: {
      return 'Line';
    }
    default: break;
  }
}
export function isPromotionDocument(promotionTypeId) {
  return promotionTypeId === PROMOTION_TYPE_DOCUMENT;
}
export function isPromotionCombo(promotionTypeId) {
  return promotionTypeId === PROMOTION_TYPE_COMBO;
}
export function isPromotionLine(promotionTypeId) {
  return promotionTypeId === PROMOTION_TYPE_LINE;
}
export function isPromotionGroup(promotionTypeId) {
  return promotionTypeId === PROMOTION_TYPE_GROUP;
}
export function isOrderGroup(promotionTypeId) {
  return promotionTypeId === 5;
}
