import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { validDate } from 'utils/helper';
import { format, isSameDay, isAfter } from 'date-fns';
import { dateFormat } from 'utils/constanst/dateConstants';
import * as QueryString from 'query-string';
import {
  makeSelectOriPromotionDetails,
  makeSelectPromotionDetails,
  makeSelectPromotionLoading,
  makeSelectOriPromotionRules,
  makeSelectPromotionRules,
  makeSelectOriPromotionProducts,
  makeSelectPromotionProducts,
  makeSelectOriPromotionMaxProducts,
  makeSelectPromotionMaxProducts,
  makeSelectOriPromotionBonuses,
  makeSelectPromotionBonuses,
  makeSelectOriPromotionVendors,
  makeSelectPromotionVendors,
  makeSelectOriPromotionCustomers,
  makeSelectPromotionCustomers,
  makeSelectPromotionListQueryString,
  makeSelectPromotionOrder,
  makeSelectOriPromotionOrder
} from 'redux/selectors';
import {
  getPromotion,
  savePromotionProductOrder,
  resetPromotion,
  updatePromotion,
  savePromotionOrder,
  savePromotionDetails,
  getPromotionTypes,
  getPromotionApplies,
  getPromotionPriorities,
  getPromotionOperators,
  getPromotionDiscounts,
  savePromotionRules,
  savePromotionProducts,
  savePromotionMaxProducts,
  savePromotionBonuses,
  savePromotionVendors,
  savePromotionCustomers,
} from 'redux/actions/tmk/promotionActions';
import { isEqual } from 'lodash';
import {
  VENDOR_MODE_ALL,
  VENDOR_MODE_OPTION,
  CUSTOMER_MODE_ALL,
  BASE_ON_PERIOD,
  APPLY_AWAITING,
  STATUS_INACTIVE,
  APPLY_UNDEFINED,
  ACTIVITY_STOP,
  PROMOTION_TYPE_DOCUMENT,
  PROMOTION_TYPE_GROUP,
  PROMOTION_TYPE_LINE,
  COMPARE_EQUAL,
  isPromotionCombo,
  isOrderGroup,
} from 'utils/constanst/tmkPromotionConstants';
import { toast } from 'react-toastify';
import { ACTIVE, DEACTIVE } from 'utils/constanst/common';
import promotionApi from 'utils/api/tmk/promotionApi';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import { Tabs, Tab } from '@material-ui/core';
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons';
import PromotionDetails from 'components/tmk/PromotionDetails/PromotionDetails';
import PromotionRules from 'components/tmk/PromotionRules/PromotionRules';
import PromotionProducts from 'components/tmk/PromotionProducts/PromotionProducts';
import PromotionOrder from 'components/tmk/PromotionOrder/PromotionOrder';
import PromotionBonuses from 'components/tmk/PromotionBonuses/PromotionBonuses';

import Promotions from 'components/tmk/Promotion/Promotion';


import PromotionVendors from 'components/tmk/PromotionVendors/PromotionVendors';
import PromotionCustomers from 'components/tmk/PromotionCustomers/PromotionCustomers';
import PromotionMaxProducts from 'components/tmk/PromotionMaxProducts/PromotionMaxProducts';
import PromotionHistory from 'components/tmk/PromotionHistory/PromotionHistory';
import ConfirmInactiveDialog from 'components/tmk/PromotionDetails/ConfirmInactiveDialog/ConfirmInactiveDialog';
import PromotionCodeSearch from 'components/tmk/PromotionDetails/PromotionCodeSearch/PromotionCodeSearch';

import classes from './Promotion.module.scss';

class Promotion extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: 'promotion-rule',
      isConfirmDialogOpen: false,
    }
    this.validate = {
      code: {
        template: /^[a-zA-Z0-9\-_\s]*$/,
        required: true,
        maxLength: 20,
      },
      name: {
        required: true,
        maxLength: 55,
      },
      description: {
        maxLength: 255,
      },
      start_date: {
        required: true,
      },
    };
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    this._loadData(id);
  }
  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;
    if (id !== prevProps.match.params.id) {
      this._loadData(id);
    }
    const { promotionDetails } = this.props;
    if (promotionDetails && promotionDetails.id) {
      if (!prevProps.promotionDetails || promotionDetails.id !== prevProps.promotionDetails.id) {
        if (
          !prevProps.promotionDetails ||
          !prevProps.promotionDetails.id ||
          isNaN(prevProps.promotionDetails.id)
        ) {
          // created successfull
          this.props.history.replace(`/promotion/${promotionDetails.id}`);
          return;
        }
      }
    }
    if (promotionDetails && prevProps.promotionDetails
      && promotionDetails.getcare_promotion_type_id !== prevProps.promotionDetails.getcare_promotion_type_id) {
        this._resetVendorModeOption();
        this._resetTabsData();
    }

    const { tabActive } = this.state;
    const isOrder = this._isOrderType();

    if (tabActive === 'promotion-vendor' && prevProps.promotionDetails && promotionDetails
      && promotionDetails.vendor_mode === VENDOR_MODE_ALL
      && promotionDetails.vendor_mode !== prevProps.promotionDetails.vendor_mode) {
      }
    if (tabActive === 'promotion-customer' && prevProps.promotionDetails && promotionDetails
      && promotionDetails.customer_mode === CUSTOMER_MODE_ALL
      && promotionDetails.customer_mode !== prevProps.promotionDetails.customer_mode) {
        this.setState({
          tabActive: 'promotion-rule',
        });
      }
      if (isOrder && tabActive === 'promotion-rule' && prevProps.promotionDetails  && promotionDetails
      && promotionDetails.getcare_promotion_type_id === 5) {
        this.setState({
          tabActive: 'promotion-order',
        });
      }
      if (!isOrder && tabActive === 'promotion-order' && prevProps.promotionDetails  && promotionDetails
      && promotionDetails.getcare_promotion_type_id !== 5) {
        this.setState({
          tabActive: 'promotion-rule',
        });
      }
    if (tabActive === 'promotion-product' && prevProps.promotionDetails && promotionDetails
      && promotionDetails.getcare_promotion_type_id === PROMOTION_TYPE_DOCUMENT) {        
        this.setState({
          tabActive: 'promotion-rule',
        });
      }
  }

  goToItem = (id) => {
    this.props.history.push(`/promotion/${id}`);
  }

  isCodeValid = () => {
    const code = this.props.promotionDetails?.code || '';
    return (
      code.trim() !== '' &&
      this.validate.code.template.test(code)
    );
  };
  isNameValid = () => {
    const name = this.props.promotionDetails?.name || '';
    return name.trim() !== '';
  };

  isPromotionOrderValid = () => {
    const isOrder = this._isOrderType();
    if (isOrder){
      const order = this.props.promotionDetails?.order_amount_from || '';
      return order.trim() !== '';
    }else{
      return true
    }
  };

  isOrderApplied = () => {
    const isOrder = this._isOrderType();
    if (isOrder){
      const order = this.props.promotionDetails?.number_of_times_applied || '';
      return order.trim() !== '';
    }else{
      return true
    }

  };

  isOrderNumber = () => {
    const isOrder = this._isOrderType();
    if (isOrder){
      const order = this.props.promotionDetails?.number_of_product || '';
    return order.trim() !== '';
    }else{
      return true
    }
  };

  isVendorPromotionValid = () => {
    const isOrder = this._isOrderType();
    if (isOrder){
      const order = this.props.promotionDetails?.promotion_vendors || '';
    return order
    }else{
      return true
    }
  };

  isTypeValid = () => {
    const { promotionDetails } = this.props;
    return !!(promotionDetails?.getcare_promotion_type_id);
  };
  isVendorModeValid = () => {
    return !!this.props.promotionDetails?.vendor_mode;
  };

  isCustomerModeValid = () => {
    return !!this.props.promotionDetails?.customer_mode;
  };
  isApplyValid = () => {
    const { promotionDetails } = this.props;
    return !!(promotionDetails?.getcare_promotion_apply_id || promotionDetails?.getcare_promotion_apply?.id);
  };
  isPriorityValid = () => {
    const { promotionDetails } = this.props;
    return !!(promotionDetails?.getcare_promotion_priority_id || promotionDetails?.getcare_promotion_priority?.id);
  };
  isStartDateValid = () => {
    if (!this.props.promotionDetails) return false;
    if (this._isEditing() && !this._isAwaitingProgress()) return true;
    const { start_date } = this.props.promotionDetails;
    return !!start_date
      && validDate(start_date)
      && (this._isDuplicating() ? true : isSameDay(new Date(start_date), new Date()) || isAfter(new Date(start_date), new Date()));
  }
  isEndDateValid = () => {
    if (!this.props.promotionDetails) return false;
    const { end_date, start_date } = this.props.promotionDetails;
    return !end_date
      || (start_date && validDate(start_date)
          && validDate(end_date)
          && (isSameDay(new Date(end_date), new Date(start_date)) || isAfter(new Date(end_date), new Date(start_date)))
      );
  }
  hasRules = () => {
    const { promotionRules } = this.props;
    return promotionRules && promotionRules.length > 0;
  }

  hasProducts = () => {
    const { promotionProducts, promotionDetails } = this.props;
    if (promotionDetails && promotionDetails.getcare_promotion_type_id === PROMOTION_TYPE_DOCUMENT) return true;
    return promotionProducts && promotionProducts.length > 0;
  }
  hasProductsBonus = () => {
   const { promotionBonuses } = this.props;
    return promotionBonuses && promotionBonuses.length > 0;
  }

  hasOrder = () => {
    const { promotionOrder, promotionDetails } = this.props;
    if (promotionDetails && promotionDetails.getcare_promotion_type_id === 5) return true;
    return promotionOrder && promotionOrder.length > 0;
  }
  hasVendors = () => {
    const { promotionDetails } = this.props;
    if (promotionDetails && promotionDetails.vendor_mode === VENDOR_MODE_ALL) return true;
    const { promotionVendors } = this.props;
    return promotionVendors && promotionVendors.length > 0;
  }
  hasCustomers = () => {
    const { promotionDetails } = this.props;
    if (promotionDetails && promotionDetails.customer_mode === CUSTOMER_MODE_ALL) return true;
    const { promotionCustomers } = this.props;
    return promotionCustomers && promotionCustomers.length > 0;
  }
  _isValid = () => {
    const isOrder = this._isOrderType();
    let vadilates = [
      this.isCodeValid,
      this.isPromotionOrderValid,
      this.isOrderApplied,
      this.isOrderNumber,
      this.isNameValid,
      this.isTypeValid,
      this.isVendorModeValid,
      this.isCustomerModeValid,
      this.isApplyValid,
      this.isPriorityValid,
      this.isStartDateValid,
      this.isEndDateValid,
      this.hasCustomers
    ]
    if (!isOrder ){
      vadilates = vadilates.concat([this.hasRules,this.hasProducts,this.hasVendors]);
    }else{
      vadilates = vadilates.concat([this.hasOrder,this.hasProductsBonus]);
    }
    return vadilates.every(vadilate => vadilate())
  
    
  }
  _isAwaitingProgress = () => {
    return this._getActivityStatus() === APPLY_AWAITING;
  }
  _getActivityStatus = () => {
    const { promotionDetails } = this.props;
    if (!promotionDetails) return APPLY_AWAITING;
    if (promotionDetails.active === STATUS_INACTIVE || promotionDetails.apply === APPLY_UNDEFINED) return ACTIVITY_STOP;
    return promotionDetails.apply;
  }

  _getPromotionDetailsContrains = (params) => {
    return {
      code: params.code,
      name: params.name,
      description: params.description,
      number_of_product: params.number_of_product,
      number_of_times_applied: params.number_of_times_applied,
      promotion_vendors: params.promotion_vendors,
      vendor_id: params.vendor_id,
      isTrendPromotion: params.is_trend_promotion,
      image: params.image,
      baseOn: params.base_on,
      startDate: validDate(params.start_date) ? format(validDate(params.start_date), dateFormat) : '',
      endDate: validDate(params.end_date) ? format(validDate(params.end_date), dateFormat) : '',
      isStockFinished: params.is_stock_finished,

      applyId: params.getcare_promotion_apply_id || params.getcare_promotion_apply?.id,
      priorityId: params.getcare_promotion_priority_id || params.getcare_promotion_priority?.id,
    }
  }
  _isDetailsChanged = () => {
    if (!this._isEditing()) return false;
    const { oriPromotionDetails, promotionDetails } = this.props;
    if (!oriPromotionDetails || !promotionDetails) return false;
    return !isEqual(this._getPromotionDetailsContrains(promotionDetails), this._getPromotionDetailsContrains(oriPromotionDetails));
  }

  _getRuleContrains = (params) => {
    return {
      id: params.id,
      level: params.level,
      level_name: params.level_name,
      quantity_number: params.quantity_number,
      order_mode: params.order_mode,
      order_amount: params.order_amount,
      bonus_number: params.bonus_number,
      bonus_mode: params.bonus_mode,
      getcare_promotion_operator_id: params.getcare_promotion_operator?.id,
      getcare_promotion_discount_id: params.getcare_promotion_discount?.id,
      discount_amount: params.discount_amount,
      maximum_amount: params.maximum_amount,
    }
  }
  _getProductContrains = (params) => {
    return {
      id: params.id,
      type_id: params.type_id,
      getcare_product_id: params.getcare_product?.id,
      getcare_erp_group_id: params.getcare_erp_group?.id,
      getcare_uom_base_id: params.getcare_uom_base?.id,
      ratio: params.ratio,
      focus: params.focus,
      quantity_number: params.quantity_number,
    }
  }

  _getProductOrderContrains = (params) => {
    return {
      id: params.id,
      idPromotion: params?.id,
      name: params?.name,
      code: params?.code,
    }
  }

  _getMaxProductContrains = (params) => {
    return {
      id: params.id,
      getcare_product_id: params.getcare_product?.id,
      quantity_number: params.quantity_number,
    }
  }
  _getBonusContrains = (params) => {
    return {
      id: params.id,
      level: params.level,
      type_id: params.type_id,
      getcare_product_id: params.getcare_product?.id,
      getcare_erp_group_id: params.getcare_erp_group?.id,
      getcare_product_bonus_id: params.getcare_product_bonus?.id,
      getcare_uom_base_id: params.getcare_uom_base?.id,
      ratio: params.ratio,
      quantity_number: params.quantity_number,
    }
  }
  _getVendorContrains = (params) => {
    return {
      id: params.id,
      type_id: params.type_id,
      getcare_vendor_id: params.getcare_vendor?.id,
      getcare_erp_group_id: params.getcare_erp_group?.id,
    }
  }
  _getCustomerContrains = (params) => {
    return {
      id: params.id,
      type_id: params.type_id,
      getcare_customer_id: params.getcare_customer?.id,
      getcare_erp_group_id: params.getcare_erp_group?.id,
    }
  }

  _isRulesChanged = () => {
    if (!this._isEditing()) return false;

    const { promotionRules, oriPromotionRules } = this.props;
    if (!promotionRules || !oriPromotionRules) return false;

    const hasDeletedItem = oriPromotionRules.some(item => item.id && !promotionRules.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;
    return promotionRules.some(tempItem => {
      const tempItemMap = this._getRuleContrains(tempItem);
      return !oriPromotionRules.some(item => isEqual(
        tempItemMap,
        this._getRuleContrains(item)
      ))
    });
  }
  _isProductsChanged = () => {
    if (!this._isEditing()) return false;

    const { promotionProducts, oriPromotionProducts } = this.props;
    if (!promotionProducts || !oriPromotionProducts) return false;

    const hasDeletedItem = oriPromotionProducts.some(item => item.id && !promotionProducts.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return promotionProducts.some(tempItem => {
      const tempItemMap = this._getProductContrains(tempItem);
      return !oriPromotionProducts.some(item => isEqual(
        tempItemMap,
        this._getProductContrains(item)
      ))
    });
  }

  _isProductsOrderChanged  = () => {
    if (!this._isEditing()) return false;

    const { promotionOrder, oriPromotionOrder} = this.props;
    if (!promotionOrder || !oriPromotionOrder) return false;

    const hasDeletedItem = oriPromotionOrder.some(item => item.id && !promotionOrder.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return promotionOrder.some(tempItem => {
      const tempItemMap = this._getProductContrains(tempItem);
      return !oriPromotionOrder.some(item => isEqual(
        tempItemMap,
        this._getProductOrderContrains(item)
      ))
    });
  }

  _isMaxProductsChanged = () => {
    if (!this._isEditing()) return false;

    const { promotionMaxProducts, oriPromotionMaxProducts } = this.props;
    if (!promotionMaxProducts || !oriPromotionMaxProducts) return false;

    const hasDeletedItem = oriPromotionMaxProducts.some(item => item.id && !promotionMaxProducts.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return promotionMaxProducts.some(tempItem => {
      const tempItemMap = this._getMaxProductContrains(tempItem);
      return !oriPromotionMaxProducts.some(item => isEqual(
        tempItemMap,
        this._getMaxProductContrains(item)
      ))
    });
  }
  _isBonusesChanged = () => {
    if (!this._isEditing()) return false;

    const { promotionBonuses, oriPromotionBonuses } = this.props;
    if (!promotionBonuses || !oriPromotionBonuses) return false;

    const hasDeletedItem = oriPromotionBonuses.some(item => item.id && !promotionBonuses.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return promotionBonuses.some(tempItem => {
      const tempItemMap = this._getBonusContrains(tempItem);
      return !oriPromotionBonuses.some(item => isEqual(
        tempItemMap,
        this._getBonusContrains(item)
      ))
    });
  }
  _isVendorsChanged = () => {
    if (!this._isEditing()) return false;

    const { promotionVendors, oriPromotionVendors } = this.props;
    if (!promotionVendors || !oriPromotionVendors) return false;

    const hasDeletedItem = oriPromotionVendors.some(item => item.id && !promotionVendors.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return promotionVendors.some(tempItem => {
      const tempItemMap = this._getVendorContrains(tempItem);
      return !oriPromotionVendors.some(item => isEqual(
        tempItemMap,
        this._getVendorContrains(item)
      ))
    });
  }
  _isCustomersChanged = () => {
    if (!this._isEditing()) return false;

    const { promotionCustomers, oriPromotionCustomers } = this.props;
    if (!promotionCustomers || !oriPromotionCustomers) return false;

    const hasDeletedItem = oriPromotionCustomers.some(item => item.id && !promotionCustomers.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return promotionCustomers.some(tempItem => {
      const tempItemMap = this._getCustomerContrains(tempItem);
      return !oriPromotionCustomers.some(item => isEqual(
        tempItemMap,
        this._getCustomerContrains(item)
      ))
    });
  }
  _resetVendorModeOption = () => {
    const { promotionDetails } = this.props;
    const params = {
      ...promotionDetails,
      vendor_mode: isOrderGroup(promotionDetails?.getcare_promotion_type_id || promotionDetails?.getcare_promotion_type?.id) || isPromotionCombo(promotionDetails?.getcare_promotion_type_id || promotionDetails?.getcare_promotion_type?.id)
        ? VENDOR_MODE_OPTION
        : VENDOR_MODE_ALL,
    };
    this.props.savePromotionDetails(params);
  }
  _resetTabsData = () => {
    this._loadRuleData();
    this.props.savePromotionOrder([]);
    this.props.savePromotionRules([]);
    this.props.savePromotionProducts([]);
    this.props.savePromotionMaxProducts([]);
    this.props.savePromotionBonuses([]);
    this.props.savePromotionVendors([]);
    this.props.savePromotionCustomers([]);
  }
  _loadRuleData = () => {
    this.props.getPromotionDiscounts({
      params: {
        getcare_promotion_type_id: this.props.promotionDetails?.getcare_promotion_type_id,
      },
    });
    this.props.getPromotionOperators();
  }
  _resetState = () => {
    this.setState({
      tabActive: 'promotion-rule',
      isConfirmDialogOpen: false,
    });
  }
  _loadData = (id) => {
    this._resetState();
    this.props.getPromotionTypes();
    this.props.getPromotionApplies();
    this.props.getPromotionPriorities();

    if (this._isEditing()) {
      this.props.getPromotion({
        id,
        isDuplicate: false,
      });
      this._loadRuleData();
      return;
    }
    if (this._isDuplicating()) {
      const duplicatedId = this._getDuplicatedId();
      this.props.getPromotion({
        id: duplicatedId,
        isDuplicate: true,
      });
      return;
    }
    this.props.resetPromotion();
  };

  _backToList = () => {
    this.props.history.push(`/promotion${this.props.promotionListQueryString}`);
  };
  _isEditing = () => {
    const { id } = this.props.match.params;
    return id && !isNaN(id) && Number(id) > 0;
  };
  _getDuplicatedId = () => {
    if (this._isEditing()) return null;
    const { location } = this.props;
    const params = QueryString.parse(location.search);
    return params.dup;
  }
  _isDuplicating = () => {
    const duplicatedId = this._getDuplicatedId();
    return duplicatedId && !isNaN(duplicatedId) && Number(duplicatedId) > 0;
  }

  _isVendorModeAll = () => {
    const { promotionDetails } = this.props;
    return promotionDetails?.vendor_mode === VENDOR_MODE_ALL;
  }
  _isCustomerModeAll = () => {
    return this.props.promotionDetails?.customer_mode === CUSTOMER_MODE_ALL;
  }
  _hasPromotionType = () => {
    const { promotionDetails } = this.props;
    return !!promotionDetails?.getcare_promotion_type_id || !!promotionDetails?.getcare_promotion_type;
  }
  _hasProductBonuses = () => {
    const { promotionRules } = this.props;
    return promotionRules.filter(rule => rule.bonus_number && rule.bonus_mode !== 1).length > 0 || this.props.promotionDetails?.getcare_promotion_type_id === 5;
  }
  _isBaseOnPeriod = () => {
    return this.props.promotionDetails?.base_on === BASE_ON_PERIOD;
  }
  _isRulesNotCompleted = () => {
    return this.props.promotionRules.some(item => item.isEditing);
  }
  _isProductsNotCompleted = () => {
    return this.props.promotionProducts.some(item => item.isEditing);
  }
  _isProductsOrderNotCompleted = () => {
    return this.props.promotionOrder.some(item => item.isEditing);
  }
  _isProductMaximumsNotCompleted = () => {
    return this.props.promotionMaxProducts.some(item => !item.disabled && item.isEditing);
  }
  _isBonusesNotCompleted = () => {
    return this.props.promotionBonuses.some(item => item.isEditing);
  }
  _isVendorsNotCompleted = () => {
    return this.props.promotionVendors.some(item => item.isEditing);
  }
  _isCustomersNotCompleted = () => {
    return this.props.promotionCustomers.some(item => item.isEditing);
  }
  _isOrderNotCompleted = () => {
    return this.props.promotionOrder.some(item => item.isEditing);
  }
  _isDocumentType = () => {
    return this.props.promotionDetails?.getcare_promotion_type_id === PROMOTION_TYPE_DOCUMENT;
  }

  _isGroupType = () => {
    return this.props.promotionDetails?.getcare_promotion_type_id === PROMOTION_TYPE_GROUP;
  }

  _isLineType = () => {
    return this.props.promotionDetails?.getcare_promotion_type_id === PROMOTION_TYPE_LINE;
  }

  _isOrderType = () => {
    return this.props.promotionDetails?.getcare_promotion_type_id === 5;
  }

  hasNotCompletedForm = () => {
    return this._isRulesNotCompleted()
      || this._isProductsNotCompleted()
      || this._isProductsOrderNotCompleted()
      || this._isProductMaximumsNotCompleted()
      || this._isBonusesNotCompleted()
      || this._isVendorsNotCompleted()
      || this._isCustomersNotCompleted()
  }
  isRulesValid = () => {
    let messages = [];
    if (!this.hasRules() || this._isDocumentType()) return messages;
    const { promotionRules } = this.props;
    for(let i = 1; i < promotionRules.length; i++) {
      if (promotionRules[i].quantity_number <= promotionRules[i - 1].quantity_number) {
        messages.push('• Số lượng mua level sau phải lớn hơn level trước');
        break;
      }
    }
    return messages;
  }
  
  isOrderValid = () => {
    let messages = [];
    if (!this.hasOrder() ) return messages;
    return messages;
  }
  isProductsValid = () => {
    let messages = [];
    if (!this.hasProducts()) return messages;
    const dupplicatedProducts = this.props.promotionProducts.reduce((memo, product, index) => {
      const idStr = `${product.type_id}${product.getcare_product?.id}${product.getcare_erp_group?.id}`;
      memo[idStr] = ++memo[idStr] || 0;
      return memo;
    }, {});
    if (Object.keys(dupplicatedProducts).some(key => dupplicatedProducts[key] > 0)) {
      messages.push('• Nhóm/sản phẩm hàng bán không được trùng nhau');
    }
    return messages;
  }
  _isBonusesDuplicated = () => {
    const dupplicatedProducts = this.props.promotionBonuses.reduce((memo, product, index) => {
      const idStr = `${product.type_id}${product.getcare_product?.id}${product.getcare_erp_group?.id}${product.getcare_product_bonus?.id}`;
      memo[idStr] = ++memo[idStr] || 0;
      return memo;
    }, {});
    return Object.keys(dupplicatedProducts).some(key => dupplicatedProducts[key] > 0);
  }
  isBonusesValid = () => {
    const isOrder = this._isOrderType();

    let messages = [];
    const { promotionRules, promotionBonuses } = this.props;
    const promotionRulesNeedBonus = promotionRules.filter(item => item.bonus_mode !== 1);
    const quantityRulesMap = promotionRulesNeedBonus.reduce((memo, item) => {
      if (!!item.bonus_number) memo[item.level] = item.bonus_number;
      return memo;
    }, {});
    const quantityBonusesMap = promotionBonuses.reduce((memo, item) => {
      if (!memo[item.level]) {
        memo[item.level] = item.quantity_number;
      } else {
        memo[item.level] = item.quantity_number;
      }
      return memo;
    }, {});
    if (!isOrder){
      const hasNoNeedBonuses = Object.keys(quantityBonusesMap).some(key => quantityRulesMap[key] === undefined);
      if (hasNoNeedBonuses) {
        messages.push('• Bạn đang setup sai hàng tặng khác cho level chỉ tặng hàng tương tự');
      }
    }

    const isBonusesQuantityEnough = !Object.keys(quantityRulesMap).some(key => {
      const bonusNumber = quantityBonusesMap[key];
      const rulesNumber = quantityRulesMap[key];
      return [null, undefined, 0].includes(bonusNumber) || Number(bonusNumber) > Number(rulesNumber);
    });
    if (!isBonusesQuantityEnough) {
      messages.push('• Hàng tặng theo từng level phải đủ số lượng cơ cấu');
    }
    return messages;
  }
  isVendorsValid = () => {
    let messages = [];
    if (!this.hasVendors()) return false;
    const dupplicatedItems = this.props.promotionVendors.reduce((memo, item, index) => {
      const idStr = `${item.type_id}${item.getcare_vendor?.id}${item.getcare_erp_group?.id}`;
      memo[idStr] = ++memo[idStr] || 0;
      return memo;
    }, {});
    if (Object.keys(dupplicatedItems).some(key => dupplicatedItems[key] > 0)) {
      messages.push('• Nhóm/nhà cung cấp không được trùng nhau');
    }
    return messages;
  }
  isCustomersValid = () => {
    let messages = [];
    if (!this.hasCustomers()) return false;
    const dupplicatedItems = this.props.promotionCustomers.reduce((memo, item, index) => {
      const idStr = `${item.type_id}${item.getcare_customer?.id}${item.getcare_erp_group?.id}`;
      memo[idStr] = ++memo[idStr] || 0;
      return memo;
    }, {});
    if (Object.keys(dupplicatedItems).some(key => dupplicatedItems[key] > 0)) {
      messages.push('• Nhóm/khách hàng không được trùng nhau');
    }
    return messages;
  }
  isCodeDuplicated = async (codeStr) => {
    let messages = [];
    const value = codeStr.trim();
    const { data: response } = await promotionApi.checkPromotionCodeDuplicated({
      params: {
        code: value,
        id: this.props.promotionDetails?.id,
      }
    });
    if (!response?.result) {
      messages.push('• Mã promotion bị trùng');
    }
    return messages;
  }
  _prepareData = () => {
    const isEditing = this._isEditing();
    const isOrder = this._isOrderType();
    const isBaseOnPeriod = this._isBaseOnPeriod();
    if (isOrder) {
      const {
        promotionDetails,
        promotionOrder,
      } = this.props;
      const data = {
        id: promotionDetails?.id || undefined,
        code: promotionDetails?.code,
        name: promotionDetails?.name,
        description: promotionDetails?.description,
        // image: promotionDetails.image,
        base_on: Number(promotionDetails.base_on),
        start_date: isBaseOnPeriod && !!promotionDetails?.start_date ? promotionDetails?.start_date : null,
        end_date: isBaseOnPeriod && promotionDetails?.end_date ? promotionDetails?.end_date : null,
        active: !isEditing ? ACTIVE : promotionDetails.active,
        deactivate_reason: null,
        getcare_promotion_parent_type_id: 1,
        getcare_vendor_id: promotionDetails.promotion_vendors,
        order_amount_from: promotionDetails?.order_amount_from ? parseInt(promotionDetails?.order_amount_from): null,
        number_of_times_applied: promotionDetails?.number_of_times_applied ? parseInt(promotionDetails?.number_of_times_applied): null,
        number_of_product:promotionDetails?.number_of_times_applied ?  parseInt(promotionDetails?.number_of_product): null,
        getcare_promotion_parent_product_items:this.props.promotionBonuses.map((item) => ({
          getcare_product_id: item.getcare_product?.id || null,
          getcare_uom_base_id: item.getcare_uom_base?.id || null,
          quantity_number:  ['', null, undefined].includes(item.quantity_number) ? null : Number(item.quantity_number),
        })),
          getcare_promotion_ids : isOrder ? promotionOrder.map((item) => item.idPromotion) : [],
      }
      return data
    
    }else{
      const {
        promotionDetails,
      } = this.props;
      return {
        id: promotionDetails?.id || undefined,
        code: promotionDetails.code,
        name: promotionDetails.name,
        description: promotionDetails.description,
        image: promotionDetails.image,
        is_trend_promotion: promotionDetails.is_trend_promotion,
        base_on: Number(promotionDetails.base_on),
        start_date: isBaseOnPeriod && !!promotionDetails.start_date ? promotionDetails.start_date : null,
        end_date: isBaseOnPeriod && promotionDetails.end_date ? promotionDetails.end_date : null,
        is_stock_finished: isBaseOnPeriod && promotionDetails.is_stock_finished ? promotionDetails.is_stock_finished : null,
  
        active: !isEditing ? ACTIVE : promotionDetails.active,
  
        getcare_promotion_type_id: promotionDetails.getcare_promotion_type_id,
        getcare_promotion_apply_id: promotionDetails.getcare_promotion_apply_id,
        getcare_promotion_priority_id: promotionDetails.getcare_promotion_priority_id,
  
        vendor_mode: promotionDetails.vendor_mode,
        customer_mode: promotionDetails.customer_mode,
        document_mode: promotionDetails.document_mode,
  
        promotion_rules: this.props.promotionRules.map((item) => ({
          id: isEditing && item.id ? item.id : undefined,
          level: item.level,
          level_name: item.level_name,
          getcare_promotion_compare_id: COMPARE_EQUAL, // will remove
          quantity_number: ['', null, undefined].includes(item.quantity_number) ? null : Number(item.quantity_number),
          order_mode: item.order_mode,
          order_amount: ['', null, undefined].includes(item.order_amount) ? null : Number(item.order_amount),
          bonus_number: ['', null, undefined].includes(item.bonus_number) ? null : Number(item.bonus_number),
          bonus_mode: item.bonus_mode,
          getcare_promotion_operator_id: item.getcare_promotion_operator?.id || null,
          getcare_promotion_discount_id: item.getcare_promotion_discount?.id || null,
          discount_amount: ['', null, undefined].includes(item.discount_amount) ? null : Number(item.discount_amount),
          maximum_amount: ['', null, undefined].includes(item.maximum_amount) ? null : Number(item.maximum_amount),
        })),
          promotion_products: this.props.promotionProducts.map((item) => ({
          id: isEditing && item.id ? item.id : undefined,
          type_id: item.type_id,
          getcare_product_id: item.getcare_product?.id || null,
          getcare_erp_group_id: item.getcare_erp_group?.id || null,
          getcare_uom_base_id: item.getcare_uom_base?.id || null,
          ratio: ['', null, undefined].includes(item.ratio) ? null : Number(item.ratio),
          focus: item.focus,
          quantity_number:  ['', null, undefined].includes(item.quantity_number) ? null : Number(item.quantity_number),
        })),
  

        promotion_product_maximums: this.props.promotionMaxProducts.filter(
            item => !item.disabled
          ).map((item) => ({
            id: isEditing && item.id ? item.id : undefined,
            getcare_product_id: item.getcare_product?.id || null,
            quantity_number:  ['', null, undefined].includes(item.quantity_number) ? null : Number(item.quantity_number),
        })),
  
        promotion_product_bonuss: this.props.promotionBonuses.map((item) => ({
          id: isEditing && item.id ? item.id : undefined,
          level: item.level,
          type_id: item.type_id,
          getcare_product_id: item.getcare_product?.id || null,
          getcare_erp_group_id: item.getcare_erp_group?.id || null,
          getcare_product_bonus_id: item.getcare_product_bonus?.id || null,
          getcare_uom_base_id: item.getcare_uom_base?.id || null,
          ratio:  ['', null, undefined].includes(item.ratio) ? null : Number(item.ratio),
          quantity_number:  ['', null, undefined].includes(item.quantity_number) ? null : Number(item.quantity_number),
        })),
        
        promotion_vendors: this._isVendorModeAll() ? [] : this.props.promotionVendors.map((item) => ({
          id: isEditing && item.id ? item.id : undefined,
          type_id: item.type_id,
          getcare_vendor_id: item.getcare_vendor?.id || null,
          getcare_erp_group_id: item.getcare_erp_group?.id || null,
        })),
  
        promotion_customers: this._isCustomerModeAll() ? [] : this.props.promotionCustomers.map((item) => ({
          id: isEditing && item.id ? item.id : undefined,
          type_id: item.type_id,
          getcare_customer_id: item.getcare_customer?.id || null,
          getcare_erp_group_id: item.getcare_erp_group?.id || null,
        })),
      };
    }

  };
  handleTabChange = (e, value) => {
    this.setState({
      tabActive: value,
    });
  };
  hasWarningMessages = async () => {
    if (this.hasNotCompletedForm()) {
      toast.error('Có một dòng bạn đang làm dang dở. Hãy hoàn thành để tiếp tục.');
      return true;
    }
    const codeDuplicatedMsgs = await this.isCodeDuplicated(this.props.promotionDetails?.code);
    const isOrder = this._isOrderType();
    if (isOrder) {
      var messages = [
        ...this.isRulesValid(),
        ...this.isOrderValid(),
        ...this.isProductsValid(),
        ...this.isBonusesValid(),
        // ...this.isVendorsValid(),
        // ...this.isCustomersValid(),
        ...codeDuplicatedMsgs,
      ];
    }else{
      var messages = [
        ...this.isRulesValid(),
        ...this.isOrderValid(),
        ...this.isProductsValid(),
        ...this.isBonusesValid(),
        ...this.isVendorsValid(),
        ...this.isCustomersValid(),
        ...codeDuplicatedMsgs,
      ];
    }
   
    if (messages.length) {
      toast.error(messages.join('\n'));
      return true;
    }
    return false;
  }
  handleCancelPromotion = () => {
    this._loadData(this.props.match.params.id);
  }
  handleSubmitPromotion = async () => {
    const hasErrors = await this.hasWarningMessages();
    if (hasErrors) return;
    const isOrder = this._isOrderType();
    if (isOrder) {  
      
      const { data: response } = await promotionApi.PromotionParent({
           params: {
        ...this._prepareData(),
      }, 
      });
      if (!response?.result) {
        toast.error(response.message);
        return;
      }else{
        this.props.history.push(`/promotion-order`)
      }
    }else{
      this.props.updatePromotion({
        params: {
          ...this._prepareData(),
        },
      });
    }
  }
  handleDeactivePromotion = async () => {
    const hasErrors = await this.hasWarningMessages();
    if (hasErrors) return;
    this.handleOpenConfirmDialog();
  };
  handleSubmitConfirmUpdateStatus = ({ deactive_reason }) => {
    this.props.savePromotionDetails({
      ...this.props.promotionDetails,
      active: DEACTIVE,
      deactive_reason,
    });
    this.props.updatePromotion({
      params: {
        ...this._prepareData(),
        active: DEACTIVE,
        deactive_reason,
      },
    });
    this.handleCloseConfirmDialog();
  };
  handleCloseConfirmDialog = () => {
    this.setState({
      isConfirmDialogOpen: false,
    });
  };
  handleOpenConfirmDialog = () => {
    this.setState({
      isConfirmDialogOpen: true,
    });
  };
  handleClickDuplicate = () => {
    this.props.history.push(`/promotion/new?dup=${this.props.promotionDetails?.id}`);
  };
  handleSelectedCodeChange = (newValue) => {
    this.props.history.push(`/promotion/${newValue.id}`)
  }

  render() {
    const { promotionDetails } = this.props;

    const isEditing = this._isEditing();
    const isDuplicating = this._isDuplicating();
    const isDetailsChanged = this._isDetailsChanged();
    const isRulesChanged = this._isRulesChanged();
    const isProductsChanged = this._isProductsChanged();
    const isProductsOrderChanged = this._isProductsOrderChanged();
    const isMaxProductsChanged = this._isMaxProductsChanged();
    const isBonusesChanged = this._isBonusesChanged();
    const isVendorsChanged = this._isVendorsChanged();
    const isCustomersChanged = this._isCustomersChanged();
    const isOrder = this._isOrderType();
    const isChanged = isDetailsChanged
      || isRulesChanged
      || isProductsChanged
      || isProductsOrderChanged
      || isMaxProductsChanged
      || isBonusesChanged
      || isVendorsChanged
      || isCustomersChanged;
    const isValid = this._isValid();
    const promotionTypeId = promotionDetails?.getcare_promotion_type_id || promotionDetails?.getcare_promotion_type?.id;
    const isDocumentType = this._isDocumentType();
    const isComboType = isPromotionCombo(promotionTypeId);
    const readOnly = !isDuplicating && (this._getActivityStatus() === ACTIVITY_STOP);

    return (
      <div
        className={`${classes.PageWrap} ${
          this.props.loading ? 'OverlayLoading' : ''
        }`}
      >
        <div className={classes.PageHeader}>
          { isEditing && <>
            <IconButton disabled={!promotionDetails?.prev_id} size="small" onClick={() => this.goToItem(promotionDetails?.prev_id)}>
              <ArrowBackIosOutlined fontSize="small"/>
            </IconButton>
            <IconButton disabled={!promotionDetails?.next_id} size="small" onClick={() => this.goToItem(promotionDetails?.next_id)}>
              <ArrowForwardIosOutlined fontSize="small"/>
            </IconButton>
          </>}
          <h1 className={classes.PageTitle}>
            {isEditing ? `Xem promotion` : `Tạo mới promotion`}
          </h1>

          {!isEditing && (
            <Tooltip title="Huỷ và Trở về" arrow placement="top">
              <Button
                variant="outlined"
                style={{ minWidth: 0 }}
                onClick={this._backToList}
              ><ArrowBack/></Button>
            </Tooltip>
          )}

          {isEditing && (
            <>
              <PromotionCodeSearch
                promotionId={promotionDetails?.id}
                promotionCode={promotionDetails?.code}
                onSelectedCodeChange={this.handleSelectedCodeChange}
              />
              <Tooltip title="Trở về" arrow placement="top">
                <Button
                  variant="outlined"
                  style={{ minWidth: 0 }}
                  onClick={this._backToList}
                ><ArrowBack/></Button>
              </Tooltip>
              <Tooltip title="Duplicate" arrow placement="top">
                <Button
                  variant="outlined"
                  style={{ minWidth: 0 }}
                  color="primary"
                  onClick={this.handleClickDuplicate}
                ><FileCopyIcon/></Button>
              </Tooltip>
              <Button
                disabled={(!isEditing && !isValid) || (isEditing && (!isValid || !isChanged))}
                variant="outlined"
                color="secondary"
                onClick={this.handleCancelPromotion}
              >
                Huỷ thay đổi
              </Button>
            </>
          )}

          <Button
            variant="contained"
            color="primary"
            disabled={(!isEditing && !isValid) || (isEditing && (!isValid || !isChanged))}
            startIcon={<CheckOutlined />}
            onClick={this.handleSubmitPromotion}
          >
            {isEditing ? `Xác nhận thay đổi` : `Tạo promotion`}
          </Button>
        </div>
        <div className={classes.PageMain}>
          <PromotionDetails
            key={`policy-${promotionDetails?.id || `new`}`}
            isEditing={isEditing}
            isDuplicating={isDuplicating}
            isValid={isValid}
            isStartDateValid={this.isStartDateValid()}
            isEndDateValid={this.isEndDateValid()}
            isCodeValid={this.isCodeValid()}
            isOrderValid={this.isPromotionOrderValid()}
            isOrderApplied={this.isOrderApplied()}
            isOrderNumber={this.isOrderNumber()}
            isNameValid={this.isNameValid()}
            isTypeValid={this.isTypeValid()}
            isVendorPromotionValid={this.isVendorPromotionValid()}
            isOrder={this._isOrderType()}
            isVendorModeValid={this.isVendorModeValid()}
            hasVendors={this.hasVendors()}
            isCustomerModeValid={this.isCustomerModeValid()}
            isApplyValid={this.isApplyValid()}
            isPriorityValid={this.isPriorityValid()}
            isDocumentType={isDocumentType}
            onDeactivePromotion={this.handleDeactivePromotion}
            readOnly={readOnly}
          />
          { this._hasPromotionType() && <>
            <Tabs
              className={classes.TabsWrap}
              value={this.state.tabActive}
              onChange={this.handleTabChange}
            >
             

             { isOrder &&  <Tab value="promotion-order" label={<label className={classes.TabLabel}>
                Chương trình khuyến mại{` `}
                { isRulesChanged ? <span className={classes.HighlightMark}>*</span> : '' }
              </label>} />}

              {!isOrder &&  <Tab value="promotion-rule" label={<label className={classes.TabLabel}>
                Cơ cấu{` `}
                { isRulesChanged ? <span className={classes.HighlightMark}>*</span> : '' }
              </label>} />}

          
              {  !isDocumentType && !isOrder &&  <Tab value="promotion-product" label={<label className={classes.TabLabel}>
                Hàng bán{` `}
                { isProductsChanged ? <span className={classes.HighlightMark}>*</span> : '' }
              </label>} /> }
              { !isDocumentType && !isOrder &&  <Tab value="promotion-max-product" label={<label className={classes.TabLabel}>
                Tối đa{` `}
                { isMaxProductsChanged ? <span className={classes.HighlightMark}>*</span> : '' }
              </label>} /> }
              <Tab value="promotion-bonus" disabled={!this._hasProductBonuses()} label={<label className={classes.TabLabel}>
                Hàng tặng{` `}
                { isBonusesChanged ? <span className={classes.HighlightMark}>*</span> : '' }
              </label>} />
            
              { !this._isVendorModeAll() && !isOrder  && !isComboType && <Tab value="promotion-vendor" label={<label className={classes.TabLabel}>
                Nhà cung cấp{` `}
                { isVendorsChanged ? <span className={classes.HighlightMark}>*</span> : '' }
              </label>} />}
              { !this._isCustomerModeAll() && !isOrder && <Tab value="promotion-customer" label={<label className={classes.TabLabel}>
                Khách hàng{` `}
                { isCustomersChanged ? <span className={classes.HighlightMark}>*</span> : '' }
              </label>} />}
              { isEditing && <Tab value="promotion-history" label="Lịch sử" />}
            </Tabs>
            
            <div className={classes.TabsPanel}>
            <div className={this.state.tabActive === 'promotion-order' && isOrder ? classes.Wrap : classes.TabHidden}>
                 <PromotionOrder
                  readOnly={readOnly}
                  isEditing={isEditing}
                  promotionId={promotionDetails?.id}
                  promotionTypeId={promotionTypeId}
                />
                </div>
              <div className={this.state.tabActive === 'promotion-rule' && !isOrder  ? classes.Wrap : classes.TabHidden}>
                <PromotionRules
                  readOnly={readOnly}
                  isEditing={isEditing}
                  promotionId={promotionDetails?.id}
                  promotionTypeId={promotionTypeId}
                />
              </div>
            
              <div className={this.state.tabActive === 'promotion-product' && !isDocumentType ? classes.Wrap : classes.TabHidden}>
                <PromotionProducts
                  readOnly={readOnly}
                  isEditing={isEditing}
                  promotionId={promotionDetails?.id}
                  promotionTypeId={promotionTypeId}
                />
              </div>
              <div className={this.state.tabActive === 'promotion-max-product' && !isDocumentType ? classes.Wrap : classes.TabHidden}>
                <PromotionMaxProducts
                  readOnly={readOnly}
                  isEditing={isEditing}
                  promotionId={promotionDetails?.id}
                  promotionTypeId={promotionTypeId}
                />
              </div>
              <div className={this.state.tabActive === 'promotion-bonus' ? classes.Wrap : classes.TabHidden}>
                <PromotionBonuses
                  readOnly={readOnly}
                  isEditing={isEditing}
                  promotionId={promotionDetails?.id}
                  promotionTypeId={promotionTypeId}
                />
              </div>
          
              <div className={this.state.tabActive === 'promotion-vendor' && !this._isVendorModeAll() && !isComboType ? classes.Wrap : classes.TabHidden}>
                <PromotionVendors
                  readOnly={readOnly}
                  isEditing={isEditing}
                  promotionId={promotionDetails?.id}
                />
              </div>
              <div className={this.state.tabActive === 'promotion-customer' && !this._isCustomerModeAll() ? classes.Wrap : classes.TabHidden}>
                <PromotionCustomers
                  readOnly={readOnly}
                  isEditing={isEditing}
                  promotionId={promotionDetails?.id}
                />
              </div>
              {isEditing && this.state.tabActive === 'promotion-history' && (
                <PromotionHistory
                  promotionId={promotionDetails?.id}
                />
              )}
            </div>
          </> }
        </div>
        {this.state.isConfirmDialogOpen && (
          <ConfirmInactiveDialog
            isOpen={this.state.isConfirmDialogOpen}
            title={`Xác nhận dừng hoạt động ${promotionDetails?.code}`}
            promotionCode={promotionDetails?.code}
            onClose={this.handleCloseConfirmDialog}
            onSubmit={this.handleSubmitConfirmUpdateStatus}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  oriPromotionDetails: makeSelectOriPromotionDetails(),
  promotionDetails: makeSelectPromotionDetails(),
  oriPromotionRules: makeSelectOriPromotionRules(),
  promotionRules: makeSelectPromotionRules(),
  oriPromotionProducts: makeSelectOriPromotionProducts(),
  promotionProducts: makeSelectPromotionProducts(),
  promotionOrder: makeSelectPromotionOrder(),
  oriPromotionOrder: makeSelectOriPromotionOrder(),
  oriPromotionMaxProducts: makeSelectOriPromotionMaxProducts(),
  promotionMaxProducts: makeSelectPromotionMaxProducts(),
  oriPromotionBonuses: makeSelectOriPromotionBonuses(),
  promotionBonuses: makeSelectPromotionBonuses(),
  oriPromotionVendors: makeSelectOriPromotionVendors(),
  promotionVendors: makeSelectPromotionVendors(),
  oriPromotionCustomers: makeSelectOriPromotionCustomers(),
  promotionCustomers: makeSelectPromotionCustomers(),
  loading: makeSelectPromotionLoading(),
  promotionListQueryString: makeSelectPromotionListQueryString(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getPromotion: (payload) => dispatch(getPromotion(payload)),
    savePromotionProductOrder: (payload) =>
    dispatch(savePromotionProductOrder(payload)),
    resetPromotion: (payload) => dispatch(resetPromotion(payload)),
    savePromotionOrder: (payload) => dispatch(savePromotionOrder(payload)),
    updatePromotion: (payload) => dispatch(updatePromotion(payload)),
    savePromotionDetails: (payload) => dispatch(savePromotionDetails(payload)),
    getPromotionTypes: (payload) => dispatch(getPromotionTypes(payload)),
    getPromotionApplies: (payload) => dispatch(getPromotionApplies(payload)),
    getPromotionPriorities: (payload) => dispatch(getPromotionPriorities(payload)),
    getPromotionDiscounts: (payload) => dispatch(getPromotionDiscounts(payload)),
    getPromotionOperators: (payload) => dispatch(getPromotionOperators(payload)),
    savePromotionRules: (payload) => dispatch(savePromotionRules(payload)),
    savePromotionProducts: (payload) => dispatch(savePromotionProducts(payload)),
    savePromotionMaxProducts: (payload) => dispatch(savePromotionMaxProducts(payload)),
    savePromotionBonuses: (payload) => dispatch(savePromotionBonuses(payload)),
    savePromotionVendors: (payload) => dispatch(savePromotionVendors(payload)),
    savePromotionCustomers: (payload) => dispatch(savePromotionCustomers(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(Promotion);
