import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { isEqual } from 'lodash';
import {
  makeSelectBannerSize,
  makeSelectBannerType,
  makeSelectBannerLocation,
  makeSelectBannerDetails,
  makeSelectBannerListQueryString,
  makeSelectBannerLoading,
  makeSelectBannerCustomerList,
  makeSelectBannerSaleChannelList,
  makeSelectOriBannerCustomerList,
  makeSelectOriBannerDetails,
  makeSelectOriBannerSaleChannelList,
} from 'redux/selectors';
import {
  getBanner,
  getBannerSize,
  getBannerLocation,
  getBannerType,
  saveBannerSize,
  saveBannerType,
  saveBannerLocation,
  updateBanner,
  resetBanner,
  saveBannerDetails,
  saveBannerCustomerList,
  saveBannerSaleChannelList,
} from 'redux/actions/tmk/bannerActions';
import classes from './Banner.module.scss';
import Button from '@material-ui/core/Button';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import { validDate } from 'utils/helper';
import { format, isSameDay, isAfter } from 'date-fns';
import { dateFormat } from 'utils/constanst/dateConstants';
import * as QueryString from 'query-string';
import {
  STATUS_INACTIVE,
  APPLY_UNDEFINED,
  APPLY_AWAITING,
  STATUS_EMPTY,
  APPLY_TO_CUSTOMER,
  APPLY_TO_SALE_CHANNEL,
} from 'utils/constanst/tmkBannerConstants';
import { ACTIVE } from 'utils/constanst/common';

import BannerDetails from 'components/tmk/Banner/BannerDetails/BannerDetails';
class Banner extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      start_date: new Date(),
      end_date: new Date(),
      no: '',
    };
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    this._loadData(id);
  }

  _loadData = (id) => {
    // this._resetState();
    this.props.getBannerSize();
    this.props.getBannerType();
    this.props.getBannerLocation();

    if (this._isEditing()) {
      this.props.getBanner({
        id,
        isDuplicate: false,
      });
      return;
    }

    if (this._isDuplicating()) {
      const duplicatedId = this._getDuplicatedId();
      this.props.getBanner({
        id: duplicatedId,
        isDuplicate: true,
      });
      return;
    }
    this.props.resetBanner();
  };

  _getDuplicatedId = () => {
    if (this._isEditing()) return null;
    const { location } = this.props;
    const params = QueryString.parse(location.search);
    return params.dup;
  };

  _isDuplicating = () => {
    const duplicatedId = this._getDuplicatedId();
    return duplicatedId && !isNaN(duplicatedId) && Number(duplicatedId) > 0;
  };

  _isEditing = () => {
    const { id } = this.props.match.params;
    return id && !isNaN(id) && Number(id) > 0;
  };

  _backToList = () => {
    this.props.history.push(`/banner${this.props.bannerListQueryString}`);
  };

  _getActivityStatus = () => {
    const { bannerDetails } = this.props;
    if (!bannerDetails) return APPLY_AWAITING;
    if (
      bannerDetails.active === STATUS_INACTIVE ||
      bannerDetails.apply === APPLY_UNDEFINED
    )
      return STATUS_EMPTY;
    return bannerDetails.apply;
  };

  handleSubmitBanner = async () => {
    // const hasErrors = await this.hasWarningMessages();
    // if (hasErrors) return;
    this.props.updateBanner({
      params: {
        ...this._prepareData(),
      },
    });
    if (!this._isEditing()) {
      this._backToList();
    }
  };

  _prepareBannerList = () => {
    const { bannerCustomerList, bannerDetails } = this.props;
    if (bannerDetails.getcare_banner_customer_apply_id !== APPLY_TO_CUSTOMER) {
      this.props.saveBannerCustomerList([]);
      return null;
    }
    return bannerCustomerList.map((item) => {
      return {
        getcare_customer_id: item.getcare_customer.id,
      };
    });
  };

  _prepareSaleChannelList = () => {
    const { bannerSaleChannelList, bannerDetails } = this.props;
    if (
      bannerDetails.getcare_banner_customer_apply_id !== APPLY_TO_SALE_CHANNEL
    ) {
      this.props.saveBannerSaleChannelList([]);
      return null;
    }
    return bannerSaleChannelList.map((item) => {
      return {
        getcare_sales_channel_id: item.getcare_sales_channel.id,
      };
    });
  };

  _prepareData = () => {
    const isEditing = this._isEditing();
    const { bannerDetails } = this.props;

    return {
      id: bannerDetails?.id || undefined,
      name: bannerDetails.name,
      no: parseInt(bannerDetails.no),
      href: bannerDetails.href,
      start_date: !!bannerDetails.start_date ? bannerDetails.start_date : null,
      end_date: bannerDetails.end_date ? bannerDetails.end_date : null,
      active: !isEditing ? ACTIVE : bannerDetails.active,
      url: bannerDetails.url,
      getcare_banner_customer_apply_id:
        bannerDetails.getcare_banner_customer_apply_id ||
        bannerDetails?.getcare_banner_customer_apply.id,
      getcare_banner_location_id:
        bannerDetails.getcare_banner_location_id ||
        bannerDetails?.getcare_banner_location.id,
      getcare_banner_size_id:
        bannerDetails.getcare_banner_size_id ||
        bannerDetails?.getcare_banner_size.id,
      getcare_banner_type_id:
        bannerDetails.getcare_banner_type_id ||
        bannerDetails?.getcare_banner_type.id,
      getcare_banner_customer_items: this._prepareBannerList(),
      getcare_banner_sales_channel_items: this._prepareSaleChannelList(),
    };
  };

  // Start of form validation
  isNameValid = () => {
    const { name } = this.props.bannerDetails || '';
    return name?.trim() !== '' && name?.length <= 100;
  };

  isApplyCustomerValid = () => {
    if (!this.props.bannerDetails) return false;
    const { getcare_banner_customer_apply_id } = this.props.bannerDetails;
    return (
      !!getcare_banner_customer_apply_id ||
      !!this.props.bannerDetails?.getcare_banner_customer_apply.id
    );
  };

  isSizeValid = () => {
    if (!this.props.bannerDetails) return false;
    const {
      getcare_banner_size_id,
      getcare_banner_size,
    } = this.props.bannerDetails;
    return !!getcare_banner_size_id || !!getcare_banner_size?.id;
  };

  isCustomerApplyValid = () => {
    if (
      !this.props.bannerDetails ||
      (!this.props.bannerDetails?.getcare_banner_customer_apply_id &&
        !this.props.bannerDetails.getcare_banner_customer_apply?.id)
    )
      return false;
    const { bannerDetails } = this.props;
    return (
      !!bannerDetails?.getcare_banner_customer_apply_id ||
      !!bannerDetails?.getcare_banner_customer_apply.id
    );
  };

  isStartDateValid = () => {
    if (!this.props.bannerDetails) return false;
    if (this._isEditing()) return true;
    const { start_date } = this.props.bannerDetails;
    return (
      !!start_date &&
      validDate(start_date) &&
      (this._isDuplicating()
        ? true
        : isSameDay(new Date(start_date), new Date()) ||
          isAfter(new Date(start_date), new Date()))
    );
  };

  isEndDateValid = () => {
    if (!this.props.bannerDetails) return false;
    const { end_date, start_date } = this.props.bannerDetails;
    return (
      !end_date ||
      (start_date &&
        validDate(start_date) &&
        validDate(end_date) &&
        (isSameDay(new Date(end_date), new Date(start_date)) ||
          isAfter(new Date(end_date), new Date(start_date))))
    );
  };

  isTypeValid = () => {
    if (
      !this.props.bannerDetails ||
      (!this.props.bannerDetails?.getcare_banner_type_id &&
        !this.props.bannerDetails.getcare_banner_type?.id)
    )
      return false;
    const { bannerDetails } = this.props;
    return (
      !!bannerDetails?.getcare_banner_type_id ||
      !!bannerDetails?.getcare_banner_type.id
    );
  };

  isMediaValid = () => {
    if (!this.props.bannerDetails || !this.props.bannerDetails?.url)
      return false;
    const { bannerDetails } = this.props;
    return !!bannerDetails?.url;
  };

  isOrderValid = () => {
    if (!this.props.bannerDetails) return false;
    const { no } = this.props.bannerDetails;
    return !!no;
  };

  isUrlValid = () => {
    if (!this.props.bannerDetails) return false;
    const { href } = this.props.bannerDetails;
    return href?.trim() !== '';
  };

  isLocationValid = () => {
    if (!this.props.bannerDetails) return false;
    const { bannerDetails } = this.props;
    const { getcare_banner_location_id } = bannerDetails;
    return (
      !!getcare_banner_location_id ||
      !!bannerDetails?.getcare_banner_location?.id
    );
  };

  _isAwaitingProgress = () => {
    return this._getActivityStatus() === APPLY_AWAITING;
  };

  isBannerCustomerListHadNull = () => {
    if (!this.props.bannerDetails) return false;
    const { bannerCustomerList } = this.props;
    return bannerCustomerList.some((item) => {
      return (item.getcare_customer?.id === null)
    });
  };

  isBannerSaleChannelListHadNull = () => {
    if (!this.props.bannerDetails) return false;
    const { bannerSaleChannelList } = this.props;
    return bannerSaleChannelList.some((item) => {
      return (item.getcare_sales_channel?.id === null)
    });
  };
  //end of form validation

  _getBannerDetailsContrains = (params) => {
    return {
      no: params.no,
      name: params.name,
      description: params.description,
      href: params.href,
      url: params.url,
      startDate: validDate(params.start_date)
        ? format(validDate(params.start_date), dateFormat)
        : '',
      endDate: validDate(params.end_date)
        ? format(validDate(params.end_date), dateFormat)
        : '',
      size: params.getcare_banner_size_id || params.getcare_banner_size?.id,
      type: params.getcare_banner_type_id || params.getcare_banner_type?.id,
      active: params.active,
      applyId:
        params.getcare_banner_customer_apply_id ||
        params.getcare_banner_customer_apply?.id,
      locationId:
        params.getcare_banner_location_id || params.getcare_banner_location?.id,
    };
  };

  _getListLength = (params) => {
    if (params?.length && params.length > -1) return params.length;
  };

  //start of check change

  _isDetailsChanged = () => {
    if (!this._isEditing()) return false;
    const { oriBannerDetails, bannerDetails } = this.props;
    if (!oriBannerDetails || !bannerDetails) return false;
    return !isEqual(
      this._getBannerDetailsContrains(bannerDetails),
      this._getBannerDetailsContrains(oriBannerDetails)
    );
  };

  _isCustomerListChanged = () => {
    if (!this._isEditing()) return false;
    const { oriBannerCustomerList, bannerCustomerList } = this.props;
    if (!oriBannerCustomerList && !bannerCustomerList) return false;
    return !isEqual(
      this._getListLength(bannerCustomerList),
      this._getListLength(oriBannerCustomerList)
    );
  };

  _isSaleChannelChanged = () => {
    if (!this._isEditing()) return false;
    const { oriBannerSaleChannelList, bannerSaleChannelList } = this.props;
    if (!oriBannerSaleChannelList && !bannerSaleChannelList) return false;
    return !isEqual(
      this._getListLength(bannerSaleChannelList),
      this._getListLength(oriBannerSaleChannelList)
    );
  };

  _isValid = () => {
    return (
      this.isStartDateValid() &&
      this.isEndDateValid() &&
      this.isNameValid() &&
      this.isTypeValid() &&
      this.isSizeValid() &&
      this.isOrderValid() &&
      this.isLocationValid() &&
      this.isCustomerApplyValid() &&
      this.isMediaValid() &&
      !this.isBannerCustomerListHadNull() &&
      !this.isBannerSaleChannelListHadNull()
    );
  };

  render() {
    const isEditing = this._isEditing();
    const isDuplicating = this._isDuplicating();
    const isDetailsChanged = this._isDetailsChanged();
    const isCustomerListChanged = this._isCustomerListChanged();
    const isSaleChannelChanged = this._isSaleChannelChanged();
    const readOnly =
      !isDuplicating && this._getActivityStatus() === STATUS_EMPTY;
    const isChanged =
      isDetailsChanged || isCustomerListChanged || isSaleChannelChanged;
    const isValid = this._isValid();
    return (
      <div className="Banner">
        <div
          className={`${classes.PageWrap} ${
            this.props.loading ? 'OverlayLoading' : ''
          }`}
        >
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>
              {isEditing ? `Xem Banner` : `Tạo mới Banner`}
            </h1>
            <Button
              variant="contained"
              color="default"
              onClick={this._backToList}
            >
              Hủy & Trở Về
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                (!isEditing && !isValid) ||
                (isEditing && (!isValid || !isChanged))
              }
              startIcon={<CheckOutlined />}
              onClick={this.handleSubmitBanner}
            >
              {isEditing ? `Xác nhận thay đổi` : `Tạo promotion`}
            </Button>
          </div>
          <BannerDetails
            readOnly={readOnly}
            isEditing={isEditing}
            isDuplicating={isDuplicating}
            isValid={isValid}
            isNameValid={this.isNameValid()}
            isTypeValid={this.isTypeValid()}
            isOrderValid={this.isOrderValid()}
            isSizeValid={this.isSizeValid()}
            isLocationValid={this.isLocationValid()}
            isStartDateValid={this.isStartDateValid()}
            isEndDateValid={this.isEndDateValid()}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectBannerLoading(),
  bannerDetails: makeSelectBannerDetails(),
  bannerListQueryString: makeSelectBannerListQueryString(),
  bannerSize: makeSelectBannerSize(),
  bannerType: makeSelectBannerType(),
  bannerLocation: makeSelectBannerLocation(),
  bannerCustomerList: makeSelectBannerCustomerList(),
  bannerSaleChannelList: makeSelectBannerSaleChannelList(),
  oriBannerDetails: makeSelectOriBannerDetails(),
  oriBannerCustomerList: makeSelectOriBannerCustomerList(),
  oriBannerSaleChannelList: makeSelectOriBannerSaleChannelList(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBanner: (payload) => dispatch(getBanner(payload)),
    resetBanner: (payload) => dispatch(resetBanner(payload)),
    updateBanner: (payload) => dispatch(updateBanner(payload)),
    saveBannerDetails: (payload) => dispatch(saveBannerDetails(payload)),
    getBannerSize: (payload) => dispatch(getBannerSize(payload)),
    getBannerType: (payload) => dispatch(getBannerType(payload)),
    getBannerLocation: (payload) => dispatch(getBannerLocation(payload)),
    saveBannerSize: (payload) => dispatch(saveBannerSize(payload)),
    saveBannerType: (payload) => dispatch(saveBannerType(payload)),
    saveBannerLocation: (payload) => dispatch(saveBannerLocation(payload)),
    saveBannerCustomerList: (payload) =>
      dispatch(saveBannerCustomerList(payload)),
    saveBannerSaleChannelList: (payload) =>
      dispatch(saveBannerSaleChannelList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withRouter)(Banner);
