import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectTestPromotionAvailables } from 'redux/selectors';

import PromotionAvailable from './PromotionAvailable/PromotionAvailable';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './PromotionAvailablesGrid.module.scss';
import classes from './PromotionAvailables.module.scss';

class PromotionAvailables extends PureComponent {
  render() {
    const { promotionAvailables } = this.props;

    return (
      <div className={`${classes.Wrap}`}>
        <div className={`${classes.List}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            <ListHeaderCol
              className={gridClasses.Col}
              label="#"
            />
            <ListHeaderCol
              className={gridClasses.Col}
              label="Chương trình khuyến mãi"
            />
            <ListHeaderCol
              className={gridClasses.Col}
              label="Loại"
            />
            <ListHeaderCol
              className={gridClasses.Col}
              label="Ưu tiên"
            />
            <ListHeaderCol
              className={gridClasses.Col}
              label="Đạt"
            />
            <ListHeaderCol
              className={gridClasses.Col}
              label="Tác vụ"
            />
          </div>
          <div className={`${classes.Body}`}>
            {promotionAvailables.length ? (
              promotionAvailables.map((item) => (
                <PromotionAvailable
                  key={`promotion-active-${item.id}`}
                  {...item}
                  onSubmitPromotion={this.props.onSubmitPromotion}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PromotionAvailables.propTypes = {
};

PromotionAvailables.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  promotionAvailables: makeSelectTestPromotionAvailables(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(PromotionAvailables);
