import React, { PureComponent } from 'react';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectBannerSaleChannelList,
  makeSelectSaleChannelList,
} from 'redux/selectors';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import classes from './BannerSaleChannelList.module.scss';
import gridClasses from './BannerBannerSaleChannelListGrid.module.scss';
import {
  listParamsMap,
  listBannerSaleChannelDisplayFields,
} from 'utils/constanst/tmkBannerConstants';
import { getDisplayFields, genID } from 'utils/helper';
import BannerSaleChannelItem from './BannerSaleChannelItem/BannerSaleChannelItem';
import { Checkbox } from '@material-ui/core';
import {
  getSaleChannelList,
  removeSaleChannelListItem,
  saveSaleChannelListItem,
} from 'redux/actions/tmk/bannerActions';
class BannerSaleChannelList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isHeaderChecked: false,
      listParams: { ...listParamsMap },
    };
  }
  handleCheckbox = () => {
    this.setState((prevState) => {
      return {
        isHeaderChecked: !prevState.isHeaderChecked,
      };
    });
  };

  handleCreateNewRow = () => {
    this.props.saveSaleChannelListItem({
      getcare_sales_channel: {
        isEditing: true,
        idStr: genID(),
        id: null,
        name: null,
        count: null,
        code: null,
      },
    });
  };

  componentDidMount() {
    this._initData();
  }
  _initData = () => {
    this.props.getSaleChannelList();
  };

  handleSaveSaleChannelItem = (params) => {
    this.props.saveSaleChannelListItem({
      ...params,
    });
  };

  handleRemoveSaleChannelItem = (params) => {
    this.props.removeSaleChannelListItem([{ ...params }]);
  };

  render() {
    const { bannerSaleChannelList, readOnly, disabled } = this.props;
    const displayFields = getDisplayFields(
      this.state.listParams,
      listBannerSaleChannelDisplayFields
    );
    return (
      <div className={classes.BannerSaleChannelList}>
        <div className={`${classes.ActionContainer}`}>
          <Button
            disabled={readOnly || disabled}
            color="primary"
            variant="contained"
            size="small"
            onClick={this.handleCreateNewRow}
          >
            Thêm
          </Button>
        </div>
        <div className={`${classes.List}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            <div className={classes.Col}>
              <Checkbox
                onChange={this.handleCheckbox}
                checked={this.state.isHeaderChecked}
              />
            </div>
            {displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                className={`${gridClasses.Col} ${gridClasses[item.className]}`}
                {...item}
              />
            ))}
          </div>
          <div className={classes.ItemContainer}>
            {bannerSaleChannelList.length > 0 ? (
              bannerSaleChannelList.map((item, index) => (
                <BannerSaleChannelItem
                  key={`banner-sale-channel-${
                    !!item.id ? item.id : item.getcare_sales_channel.idStr
                  }`}
                  {...item.getcare_sales_channel}
                  index={index}
                  readOnly={readOnly}
                  currentList={[
                    ...bannerSaleChannelList.map((item) => {
                      return { ...item.getcare_sales_channel };
                    }),
                  ]}
                  saveSaleChannelItem={this.handleSaveSaleChannelItem}
                  removeSaleChannelItem={this.handleRemoveSaleChannelItem}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  bannerSaleChannelList: makeSelectBannerSaleChannelList(),
  saleChannelList: makeSelectSaleChannelList(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    saveSaleChannelListItem: (payload) =>
      dispatch(saveSaleChannelListItem(payload)),
    removeSaleChannelListItem: (payload) =>
      dispatch(removeSaleChannelListItem(payload)),
    getSaleChannelList: (payload) => dispatch(getSaleChannelList(payload)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(BannerSaleChannelList);
