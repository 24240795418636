export const GET_PROMOTION_TYPES = 'getcare/tmk/promotion/GET_PROMOTION_TYPES';
export const GET_PROMOTION_OPERATORS = 'getcare/tmk/promotion/GET_PROMOTION_OPERATORS';
export const GET_PROMOTION_COMPARES = 'getcare/tmk/promotion/GET_PROMOTION_COMPARES';
export const GET_PROMOTION_PRIORITIES = 'getcare/tmk/promotion/GET_PROMOTION_PRIORITIES';
export const GET_PROMOTION_APPLIES = 'getcare/tmk/promotion/GET_PROMOTION_APPLIES';
export const GET_PROMOTION_DISCOUNTS = 'getcare/tmk/promotion/GET_PROMOTION_DISCOUNTS';

export const GET_PROMOTION_LIST = 'getcare/tmk/promotion/GET_PROMOTION_LIST';
export const GET_PROMOTION = 'getcare/tmk/promotion/GET_PROMOTION';


export const GET_PROMOTION_ORDER_LIST = 'getcare/tmk/promotion/GET_PROMOTION_ORDER_LIST';


export const UPDATE_PROMOTION = 'getcare/tmk/promotion/UPDATE_PROMOTION';
export const UPDATE_PROMOTION_PRIORITY = 'getcare/tmk/promotion/UPDATE_PROMOTION_PRIORITY';

export const GET_PROMOTION_HISTORY = 'getcare/tmk/promotion/GET_PROMOTION_HISTORY';

// mutation
export const SAVE_PROMOTION_TYPES = 'getcare/tmk/promotion/SAVE_PROMOTION_TYPES';
export const SAVE_PROMOTION_OPERATORS = 'getcare/tmk/promotion/SAVE_PROMOTION_OPERATORS';
export const SAVE_PROMOTION_COMPARES = 'getcare/tmk/promotion/SAVE_PROMOTION_COMPARES';
export const SAVE_PROMOTION_PRIORITIES = 'getcare/tmk/promotion/SAVE_PROMOTION_PRIORITIES';
export const SAVE_PROMOTION_APPLIES = 'getcare/tmk/promotion/SAVE_PROMOTION_APPLIES';
export const SAVE_PROMOTION_DISCOUNTS = 'getcare/tmk/promotion/SAVE_PROMOTION_DISCOUNTS';

export const SAVE_LOADING = 'getcare/tmk/promotion/SAVE_LOADING';

export const SAVE_PROMOTION_LIST = 'getcare/tmk/promotion/SAVE_PROMOTION_LIST';
export const SAVE_PROMOTION_LIST_QUERY_STRING = 'getcare/tmk/promotion/SAVE_PROMOTION_LIST_QUERY_STRING';
export const SAVE_PROMOTION_LIST_TOTAL = 'getcare/tmk/promotion/SAVE_PROMOTION_LIST_TOTAL';
export const SAVE_PROMOTION_LIST_ITEM = 'getcare/tmk/promotion/SAVE_PROMOTION_LIST_ITEM';

export const RESET_PROMOTION = 'getcare/tmk/promotion/RESET_PROMOTION';

export const SAVE_ORI_PROMOTION_DETAILS = 'getcare/tmk/promotion/SAVE_ORI_PROMOTION_DETAILS';
export const SAVE_PROMOTION_DETAILS = 'getcare/tmk/promotion/SAVE_PROMOTION_DETAILS';

export const SAVE_ORI_PROMOTION_RULES = 'getcare/tmk/promotion/SAVE_ORI_PROMOTION_RULES';
export const SAVE_PROMOTION_RULES = 'getcare/tmk/promotion/SAVE_PROMOTION_RULES';
export const SAVE_PROMOTION_RULE_ITEM = 'getcare/tmk/promotion/SAVE_PROMOTION_RULE_ITEM';
export const REMOVE_PROMOTION_RULE_ITEMS = 'getcare/tmk/promotion/REMOVE_PROMOTION_RULE_ITEMS';

export const SAVE_ORI_PROMOTION_PRODUCTS = 'getcare/tmk/promotion/SAVE_ORI_PROMOTION_PRODUCTS';
export const SAVE_PROMOTION_PRODUCTS = 'getcare/tmk/promotion/SAVE_PROMOTION_PRODUCTS';
export const SAVE_PROMOTION_PRODUCT_ITEM = 'getcare/tmk/promotion/SAVE_PROMOTION_PRODUCT_ITEM';
export const REMOVE_PROMOTION_PRODUCT_ITEMS = 'getcare/tmk/promotion/REMOVE_PROMOTION_PRODUCT_ITEMS';

export const SAVE_PROMOTION_ORDER = 'getcare/tmk/promotion/SAVE_PROMOTION_ORDER';
export const SAVE_ORI_PROMOTION_ORDER = 'getcare/tmk/promotion/SAVE_ORI_PROMOTION_ORDER';
export const SAVE_PROMOTION_PRODUCT_ORDER = 'getcare/tmk/promotion/SAVE_PROMOTION_PRODUCT_ORDER';
export const REMOVE_PROMOTION_PRODUCT_ORDER = 'getcare/tmk/promotion/REMOVE_PROMOTION_PRODUCT_ORDER';


export const SAVE_ORI_PROMOTION_MAX_PRODUCTS = 'getcare/tmk/promotion/SAVE_ORI_PROMOTION_MAX_PRODUCTS';
export const SAVE_PROMOTION_MAX_PRODUCTS = 'getcare/tmk/promotion/SAVE_PROMOTION_MAX_PRODUCTS';
export const SAVE_PROMOTION_MAX_PRODUCT_ITEM = 'getcare/tmk/promotion/SAVE_PROMOTION_MAX_PRODUCT_ITEM';
export const REMOVE_PROMOTION_MAX_PRODUCT_ITEMS = 'getcare/tmk/promotion/REMOVE_PROMOTION_MAX_PRODUCT_ITEMS';

export const SAVE_ORI_PROMOTION_BONUSES = 'getcare/tmk/promotion/SAVE_ORI_PROMOTION_BONUSES';
export const SAVE_PROMOTION_BONUSES = 'getcare/tmk/promotion/SAVE_PROMOTION_BONUSES';
export const SAVE_PROMOTION_BONUS_ITEM = 'getcare/tmk/promotion/SAVE_PROMOTION_BONUS_ITEM';
export const REMOVE_PROMOTION_BONUS_ITEMS = 'getcare/tmk/promotion/REMOVE_PROMOTION_BONUS_ITEMS';

export const SAVE_ORI_PROMOTION_VENDORS = 'getcare/tmk/promotion/SAVE_ORI_PROMOTION_VENDORS';
export const SAVE_PROMOTION_VENDORS = 'getcare/tmk/promotion/SAVE_PROMOTION_VENDORS';
export const SAVE_PROMOTION_VENDOR_ITEM = 'getcare/tmk/promotion/SAVE_PROMOTION_VENDOR_ITEM';
export const REMOVE_PROMOTION_VENDOR_ITEMS = 'getcare/tmk/promotion/REMOVE_PROMOTION_VENDOR_ITEMS';

export const SAVE_ORI_PROMOTION_CUSTOMERS = 'getcare/tmk/promotion/SAVE_ORI_PROMOTION_CUSTOMERS';
export const SAVE_PROMOTION_CUSTOMERS = 'getcare/tmk/promotion/SAVE_PROMOTION_CUSTOMERS';
export const SAVE_PROMOTION_CUSTOMER_ITEM = 'getcare/tmk/promotion/SAVE_PROMOTION_CUSTOMER_ITEM';
export const REMOVE_PROMOTION_CUSTOMER_ITEMS = 'getcare/tmk/promotion/REMOVE_PROMOTION_CUSTOMER_ITEMS';

export const SAVE_PROMOTION_HISTORY = 'getcare/tmk/promotion/SAVE_PROMOTION_HISTORY';

export const EXPORT_SALE_PRODUCT_TEMPLATE = 'getcare/tmk/promotion/EXPORT_SALE_PRODUCT_TEMPLATE';