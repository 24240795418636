import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import classes from './ConfirmationDialog.module.scss';

class ConfirmationDialog extends PureComponent {
  render() {
    const { isOpen, isLoading, title, message } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={this.props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <DialogTitle className={classes.DialogTitle}>
            { title }
          </DialogTitle>
          <DialogContent className={classes.DialogContent}>
            { message }
          </DialogContent>
          <DialogActions className={classes.DialogFooter}>
            <Button onClick={this.props.onClose}>
              Huỷ bỏ
            </Button>
            <Button
              onClick={this.props.onSubmit}
              variant="contained"
              color="primary"
              autoFocus
            >
              Xác nhận
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

ConfirmationDialog.defaultProps = {
  isOpen: false,
  isLoading: false,
  title: '',
  message: '',
};

export default ConfirmationDialog;
