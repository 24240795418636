import React, { PureComponent } from 'react';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectVoucherDetails,
  makeSelectVoucherUnusedList,
} from 'redux/selectors';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import classes from './VoucherUnused.module.scss';
import gridClasses from './VoucherUnusedGrid.module.scss';
import {
  listParamsMap,
  listTabVoucherUnused,
} from 'utils/constanst/tmkVoucherConstant';
import { getDisplayFields } from 'utils/helper';
import PropTypes from 'prop-types';
import VoucherUnusedItem from './VoucherUnusedItem/VoucherUnusedItem';
import { exportVoucherUnusedList } from 'redux/actions/tmk/voucherAction';
class VoucherUnused extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isHeaderChecked: false,
      listParams: { ...listParamsMap },
    };
  }

  handleExportCSV = () => {
    const { voucherDetails } = this.props;
    this.props.exportVoucherUnusedList({ id: voucherDetails.id });
  };

  pushItemToArray = (array, start, maxIndex) => {
    const newArray = [];
    for (let i = start; i <= maxIndex; i++) {
      newArray.push(array[i]);
    }
    return newArray;
  };

  render() {
    const { voucherUnusedList, readOnly, isEditing } = this.props;
    const displayFields = getDisplayFields(
      this.state.listParams,
      listTabVoucherUnused
    );
    return (
      <div className={classes.VoucherUnused}>
        <div className={`${classes.List}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            {displayFields.map((item) => {
              item.label =
                voucherUnusedList.length > 0
                  ? `${item.label} (${voucherUnusedList.length})`
                  : item.label;
              return (
                <ListHeaderCol
                  key={item.name}
                  className={`${gridClasses.Col} ${
                    gridClasses[item.className]
                  }`}
                  {...item}
                />
              );
            })}
            <div className={classes.ActionExport}>
              <Button
                disabled={!isEditing}
                variant="contained"
                color="primary"
                onClick={this.handleExportCSV}
              >
                Export to CSV
              </Button>
            </div>
          </div>
            {voucherUnusedList.length > 0 ? (
              <>
                <div className={classes.ItemContainer}>
                  {voucherUnusedList.length > 0 ? (
                    voucherUnusedList.map((item, index) => (
                      <VoucherUnusedItem
                        key={`voucher-unused-${
                          !!item.id && !!item.code && item.id + item.code
                        }`}
                        {...item}
                        index={index + 1}
                        readOnly={readOnly}
                      />
                    ))
                  ) : (
                    <p className="NoData">Không có data</p>
                  )}
                </div>
              </>
            ) : (
              <p className="NoData">Không có data</p>
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  voucherDetails: makeSelectVoucherDetails(),
  voucherUnusedList: makeSelectVoucherUnusedList(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    exportVoucherUnusedList: (payload) =>
      dispatch(exportVoucherUnusedList(payload)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(VoucherUnused);
VoucherUnused.propTypes = {
  // promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEditing: PropTypes.bool,
  onRemoveCustomerItem: PropTypes.func,
  onSaveCustomerItem: PropTypes.func,
};

VoucherUnused.defaultProps = {
  isEditing: false,
};
