import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectTestPromotionActives } from 'redux/selectors';

import PromotionActive from './PromotionActive/PromotionActive';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './PromotionActivesGrid.module.scss';
import classes from './PromotionActives.module.scss';

class PromotionActives extends PureComponent {
  render() {
    const { promotionActives } = this.props;

    return (
      <div className={`${classes.Wrap}`}>
        <div className={classes.Toolbar}>
          <h4><strong>Khuyến mãi đã tính</strong></h4>
        </div>
        <div className={`${classes.List}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            <ListHeaderCol
              className={gridClasses.Col}
              label="CTKM"
            />
            <ListHeaderCol
              className={gridClasses.Col}
              label="Hàng tặng"
            />
            <ListHeaderCol
              className={gridClasses.Col}
              label="Số lượng"
            />
            <ListHeaderCol
              className={gridClasses.Col}
              label="Số tiền"
            />
          </div>
          <div className={`${classes.Body}`}>
            {promotionActives.length ? (
              promotionActives.map((item, index) => (
                <PromotionActive
                  key={`promotion-active-${item.id || item.idStr}-${index}`}
                  {...item}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PromotionActives.propTypes = {
};

PromotionActives.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  promotionActives: makeSelectTestPromotionActives(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(PromotionActives);
