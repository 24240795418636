import axiosAuth from 'utils/axios/axiosAuth';

const uomApi = {
  getAllUom: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_product/`, {
      params: {
        ...queryParams,
        type: 'uom',
      }
    });
  },
  getAllUomBase: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_uom_base/`, {
      params: queryParams,
    });
  },
  getProductUnits: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_product_unit/`, {
      params: queryParams,
    });
  },
};

export default uomApi;
